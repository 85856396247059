import React, { Component } from 'react';
import { CardElement, injectStripe } from "react-stripe-elements";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      address: '',
      city: '',
      state: '',
      zipcode: ''
    }
  }

  // handleSubmit = (e) => {
  //   // State obj deconstruction
  //   let {
  //     firstname, 
  //     lastname,
  //     address,
  //     city,
  //     state,
  //     zipcode
  //   } = this.state;

  //   // Prevent default form submission
  //   e.preventDefault();

  //   this.props.stripe
  //     .createPaymentMethod('card', {billing_details: {name: `${firstname} ${lastname}`}})
  //     .then(({ paymentMethod }) => {
  //       console.log('Recieved Stripe Payment Method: ' + paymentMethod)
  //     });
  // } 

  handleSubmit = async e => {
    e.preventDefault();

    if (this.props.stripe) {
      this.props.stripe.createToken().then((payload) => {
        if (payload.error) {
          throw new Error('error in creating token');
        }
        // const token = payload.token.id;
        console.log(payload);
        // this.props.userMakePayment({ assetId, token });
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(this.state[e.target.name]);
  }

  render() {
    return (
      <div className="checkout">
        <form onSubmit={this.handleSubmit}>
            <h1>Shipping &amp; Billing Information</h1>
            <div className="form-inputs">
              <div className="form-input-row">
                <label>First Name</label>
                <input 
                  type="text" 
                  placeholder="First Name" 
                  onChange={ (e) => this.handleChange(e) }
                />
              </div>
              <div className="form-input-row">
                <label>Last Name</label>
                <input 
                  type="text" 
                  placeholder="Last Name" 
                  onChange={ (e) => this.handleChange(e) }
                />
              </div>
              <div className="form-input-row">
                <label>Address</label>
                <input 
                  type="text" 
                  placeholder="123 Front St"
                  onChange={ (e) => this.handleChange(e) }
                />
              </div>
              <div className="form-input-row">
                <label>City</label>
                <input 
                  type="text" 
                  placeholder="New York" 
                  onChange={ (e) => this.handleChange(e) }
                />
              </div>
              <div className="form-input-row">
                <label>State</label>
                <input 
                  type="text" 
                  placeholder="New York" 
                  onChange={ (e) => this.handleChange(e) }
                />
              </div>
              <div className="form-input-row">
                <label>Zipcode</label>
                <input 
                  type="text" 
                  placeholder="10000" onChange
                  ={ (e) => this.handleChange(e) }/>
              </div>
            </div>
            <h1>Payment Information</h1>
            <div className="form-inputs">
              <div className="form-input-row" id="card-element">
                <CardElement style={ { base: { fontSize: '1.2rem' } } }/>
                <div id="card-errors" role="alert"></div>
              </div>
            </div>
            <button type="Submit" className="payment-btn">Purchase Now</button>
        </form>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);