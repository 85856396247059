import React, { Component, Fragment } from "react";

class Camera extends Component {
  componentDidMount() {
    const constraints = {
      video: true
    };
    this.videoPlayer.autoplay = true;
    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      if (!stream.stop && stream.getTracks) {
        stream.stop = function() {
          this.getTracks().forEach(function(track) {
            track.stop();
          });
        };
      }

      if (this.videoPlayer) {
        this.videoPlayer.srcObject = stream;
      }
    });
  }

  componentWillUnmount() {

    const videoPlayer = this.videoPlayer

    setTimeout(() => {
      videoPlayer.srcObject &&
      videoPlayer.srcObject.stop &&
      videoPlayer.srcObject.stop();
    })
    
  }

  captureClickHandler = () => {
    this.props.clickHandler(this.videoPlayer);
  };

  render() {
    return (
      <Fragment>
        <video
          ref={ref => {
            this.videoPlayer = ref;
          }}
          height={180}
        />
        <div className="validate__btn">
          <button
            className="btn validate__capture__btn"
            onClick={this.captureClickHandler}
          >
            Take Photo
          </button>
        </div>
      </Fragment>
    );
  }
}

export default Camera;
