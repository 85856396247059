import React from "react";
import { Field } from "redux-form";
import NumberFormat from "react-number-format";

const required = value => value ? undefined : 'Required'

const renderNumberField = ({
  input,
  label,
  prefix,
  suffix,
  meta: { touched, error }
}) => (
  <NumberFormat
    {...input}
    className={
      touched && error ? "form__input form__input--error" : "form__input"
    }
    placeholder={label}
    thousandSeparator={true}
    suffix={suffix}
    prefix={prefix}
    allowNegative={false}
  />
);

const NumberInput = values => {
  const { name, label, prefix, suffix } = values;

  return (
    <div className="form__group">
      <Field
        name={name}
        component={renderNumberField}
        label={label}
        prefix={prefix}
        suffix={suffix}
        validate={required}
      />
    </div>
  );
};

export default NumberInput;
