import React from "react";
import ChatAvatar from "./ChatAvatar";

const ChatAvatarGroup = props => {
  let { names } = props;
  let listOfNames = names.length > 4 ? names.splice(0, 3) : names;

  let normalFlow = names.map((name, index) => {
    return <ChatAvatar key={index} name={name} />;
  });

  let overflow = (
    <div>
      {listOfNames.map((name, index) => {
        return <ChatAvatar key={index} name={name} />;
      })}
      <ChatAvatar name={`+ ${names.length - 3}`} />
    </div>
  );

  return (
    <div className="avatar__column">
      {names.length > 4 ? overflow : normalFlow}
    </div>
  );
};

export default ChatAvatarGroup;
