import { ChatManager, TokenProvider } from '@pusher/chatkit-client';

export const chatkitConnect = (id) => {
  const chatManager = new ChatManager({
    instanceLocator: 'v1:us1:5749bad9-c01f-4bca-867e-2bdfa2708751',
    userId: id,
    tokenProvider: new TokenProvider({ url: 'https://us1.pusherplatform.io/services/chatkit_token_provider/v1/5749bad9-c01f-4bca-867e-2bdfa2708751/token'})
  });
  return chatManager;
}
