import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import GoogleLogo from "../../images/google-logo.png";
import { GoogleLogin } from "react-google-login";
import bchainLogo from "../../images/bx-logo-main-white.png";
import FormInput from "../FormInput/FormInput";

const loginFormFields = [
  {
    name: "email",
    label: "Email",
    type: "email",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
  },
];

class LoginForm extends Component {
  state = {
    isSmallScreen: null,
  };

  componentDidMount() {
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
  }

  updateWindowSize = () => {
    const { innerWidth } = window;
    if (innerWidth < 1000) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }
  };
  redirection = () => {
    if (
      this.props.history.location.state &&
      this.props.history.location.state.from === "singlePage"
    ) {
      const path = `/assets/${this.props.history.location.state.address}`;
      this.props.history.push(path);
    } else {
      const path = this.props.redirectPath || "/";
      this.props.history.push(path);
    }
    this.props.reset();
  };

  onLogin = (values) => {
    this.props.userLogin(values, this.redirection);
  };

  onGoogleSignInSuccess = (result) => {
    const key = Object.keys(result)[2];
    const keysValuePair = Object.entries(result[key]);
    // console.log('[result]', Object.entries(result[key]));
    // console.log('[result]', keysValuePair[1][1]);

    let userData = {
      email: keysValuePair[5][1],
      name: keysValuePair[1][1],
      avatar_image: keysValuePair[4][1],
      method: "OAuth",
      provider: "google",
      password: keysValuePair[0][1],
      _id: keysValuePair[0][1],
    };

    this.props.userLoginWithSocialMedia(userData, this.redirection);
  };

  onGoogleSignInFailure = (result) => {
    // console.log(result)
  };

  render() {
    console.log(this.props);
    const { userLoginError } = this.props;
    return (
      <div className="form">
        <Link to="/" className="form__close login__form-close">
          &times;
        </Link>
        <Link to="/">
          <img
            className="form__logo login__form-logo"
            src={bchainLogo}
            alt="logo"
          />
        </Link>

        {/* <div className="login__new-member">
          New Member? <Link to="/register">Register Now</Link>
        </div> */}

        <form onSubmit={this.props.handleSubmit(this.onLogin)}>
          {/* {loginFormFields.map((field, idx) => {
            return <FormInput {...field} key={idx}/>;
          })} */}

          <div className="login__set">
            <div
              className="login__set-label"
              style={{
                display: "flex",
                flexDirection: this.state.isSmallScreen ? "column" : "row",
              }}
            >
              <h3 style={{ marginBottom: this.state.isSmallScreen ? 8 : 0 }}>
                Email
              </h3>
              <h5>
                Don't have an account?&nbsp;&nbsp;
                <a href="/register">Register Now</a>
              </h5>
            </div>
            <FormInput {...loginFormFields[0]} />
          </div>

          <div className="login__set">
            <div
              className="login__set-label"
              style={{
                display: "flex",
                flexDirection: this.state.isSmallScreen ? "column" : "row",
              }}
            >
              <h3 style={{ marginBottom: this.state.isSmallScreen ? 8 : 0 }}>
                Password
              </h3>
              <h5>
                Forgot Password?&nbsp;&nbsp;
                <a href="/register">Reset Password</a>
              </h5>
            </div>
            <FormInput {...loginFormFields[1]} />
          </div>

          <div className="login__incorrect-login">{userLoginError}</div>
          {/* Filler */}
          <div style={{ height: "1.6rem", width: "100%" }}></div>
          <button className="form__button form__button--login">Login</button>
        </form>
        <GoogleLogin
          clientId="146862981436-t7qhl6ontpaldps1udd8oveh5d0vp0v9.apps.googleusercontent.com"
          render={(renderProps) => (
            <button
              className="form__button form__button--google"
              onClick={renderProps.onClick}
            >
              <img src={GoogleLogo} alt="google logo" /> Login with Google
            </button>
          )}
          onSuccess={this.onGoogleSignInSuccess}
          onFailure={this.onGoogleSignInFailure}
          cookiePolicy={"single_host_origin"}
          // isSignedIn={true}
        />

        {/* <Link to="/forgotPassword" className="login__forgot-password">
          Forgot password?
        </Link> */}

        <div className="login__terms">
          <h4>
            By continuing you accept our{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </h4>
          <h4>&copy;&nbsp;2019 BChain LLC</h4>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = ["email", "password"];

  categories.forEach((category) => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

const mapStateToProps = (state) => {
  return {
    userLoginError: state.auth.userLoginError,
  };
};

export default reduxForm({ validate, form: "login" })(
  connect(mapStateToProps, actions)(withRouter(LoginForm))
);
