import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "axios";

class ChatBriefcaseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      isLoaded: false
    };
  }

  convertToCurrency = price => {
    if (typeof parseInt(price) !== "number") return "N/A";
    return parseInt(price).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  };

  componentDidMount() {
    let { itemId } = this.props;
    axios
      .get(`https://api-dev.bazaarxchanges.com/api/v2/listings/${itemId}`)
      .then(res => {
        this.setState({
          item: res.data,
          isLoaded: !this.state.isLoaded
        });
        console.log(this.state.item);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    if (this.state.isLoaded) {
      let { item } = this.state;
      return (
        <div className="briefcase briefcase-details">
          <button className="back-btn" onClick={this.props.handlePrev}>
            &#10229;&nbsp;&nbsp;Back to Swaps
          </button>
          <img src={item.images[0]} />
          <div className="images-details-grid">
            {item.images.length > 1
              ? item.images.map((x, i) => {
                  return (
                    <img
                      src={x}
                      key={i}
                      className="extra-images"
                      alt="additional view"
                    />
                  );
                })
              : ""}
          </div>
          <br />
          <h1 style={{ color: "#000" }}>{item.title}</h1>
          <br />
          <h2>{this.convertToCurrency(item.estimated_value)}</h2>
          <h2>{item.category}</h2>
          <h3>{item.zipcode}</h3>
          <br />
          <br />
          <h2>{item.description}</h2>
          <br />
          <div>
            {item.preferences.map((x, i) => (
              <h2 key={i}>
                Preference #{i}: {x}
              </h2>
            ))}
          </div>
          <br />
          <h2>
            <a
              target="_blank"
              href={`https://api-dev.bazaarxchanges.com/listings/${item._id}`}
            >
              View Full Page &#10230;
            </a>
          </h2>
          {/* {
            this.props.user && this.props.myAssets.length > 0  ?
            this.props.myAssets[0].userId === this.props.user._id ?
              <button 
                className="add-to-briefcase-btn"
                onClick={this.props.handleNext}
              >
                Add Assets to Deal&nbsp;&#10230;
              </button> 
                : "" 
              : "LOADING..."
          } */}
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    item: state.asset.singleAsset[0]
  };
};

export default connect(mapStateToProps, actions)(ChatBriefcaseDetail);
