import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AssetFormArtPrice from "../AssetFormComponents/AssetFormArtPrice";
import AssetFormPrice from "../AssetFormComponents/AssetFormPrice";
import FormInputArt from "../../FormInput/FormInputArt";
import SelectFormInput from "../../FormInput/SelectFormInput";
import AssetFormBasics from "../AssetFormComponents/AssetFormBasics";
import TextArea from "../../FormInput/TextArea";
import AssetFileUpload from "../AssetUpload/AssetFileUpload";
import AssetPdfUpload from "../AssetUpload/AssetPdfUpload";
import AssetFormAddress from "../AssetFormComponents/AssetFormAddress";
import AssetFormHeader from "../AssetFormComponents/AssetFormHeader";
import AssetFormActions from "../AssetFormComponents/AssetFormActions";
import AssetFormSwap from "../AssetFormComponents/AssetFormSwap";

class AssetFormArtDetails extends Component {
  onSubmit = values => {
    let submitValues = { ...values, collection: this.props.value !== "single" };
    this.props.onFormSubmit(submitValues);
  };

  render() {
    const {
      handleActions,
      handleDetailChange,
      formFields,
      handleSubmit,
      updateFiles,
      editAddress,
      formFields: { photos, videos, pdfs, swap, swapDetails }
    } = this.props;
    
    const subCategoryOptions = [
      {
        display: "Choose A Subcategory",
        disable: true,
        value: ""
      },
      {
        display: "Ceramics",
        disable: false,
        value: "Ceramics"
      },
      {
        display: "Illustrations",
        disable: false,
        value: "Illustrations"
      },
      {
        display: "Paintings",
        disable: false,
        value: "Paintings"
      },
      {
        display: "Photography",
        disable: false,
        value: "Photography"
      },
      {
        display: "Sculpture",
        disable: false,
        value: "Sculpture"
      }];

    return (
      <AssetFormHeader {...this.props}>
        <form onSubmit={handleSubmit(this.onSubmit)} encType="multipart/form-data">
          <AssetFormActions handleActions={handleActions} {...formFields} />
          {swap && (
            <AssetFormSwap
              swapDetails={swapDetails}
              handleDetailChange={handleDetailChange}
            />
          )}
          <AssetFileUpload
            name="photos"
            photos={photos}
            videos={videos}
            updateFiles={updateFiles}
          />
          
          <AssetPdfUpload
            name="pdfs"
            pdfs={pdfs}
            updateFiles={updateFiles}
          />
          <AssetFormAddress editAddress={editAddress} />
          <AssetFormBasics />
          <AssetFormPrice />
          <div className="add-asset__template__box">
            {/* still need a sub category input SelectFormInput */}
            <div className="add-asset__template__label">Asset Details</div>
            <SelectFormInput 
              {...{name: "subCategory", 
              label: "Art Subcategory", 
              type:"text", 
              options: subCategoryOptions}} 
              key={"cateKey"}
            />
            <FormInputArt label="Artist Name" name="artist" />
            <FormInputArt label= "Declared Origin" name="declaredOrigin"/>
            <FormInputArt label= "Authenticity Certificate" name="authenticityCert" />
            <FormInputArt label= "Condition" name="condition" />
            {/* The purchase history field should be an array */}
            <FormInputArt label= "Purchase History" name = "purchaseHistory"/>
          </div>
          
          <div className="add-asset__template__btn">
            <button className="form__button">Add Asset</button>
          </div>
        </form>
      </AssetFormHeader>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = 
    [
      "photos",
      "subCategory",
      "title",
      "declaredOrigin",
      "authenticityCert",
      "appraisalValue",
      "condition",
      "fullDescription"
    ];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

export default reduxForm({
  form: "assetForm",
  destroyOnUnmount: false
})(
  connect(
    null,
    actions
  )(AssetFormArtDetails)
);
