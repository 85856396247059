export const formatNumber = (num) => {
  if (!num) {
    return num;
  }
  let string = num.toString().split("").reverse("")
  let final = []

  for (let i=0; i<string.length; i++) {
    if (i%3 === 0 && i>0) {
      final.push(',')
    }

    final.push(string[i])
  }

  return final.reverse("").join("")
}

export const formatSearchQuery = (assetType, keyWord) => {
  let queryBody = {};
  switch (assetType) {
    case 'all':
      queryBody = {
        type: assetType,
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool: {
              should: [
                {
                  multi_match : {
                    fields : 
                      [ "postTitle",
                        "subCategory",
                        "artist",
                        "declaredOrigin",
                        "fullDescription"
                      ],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string: {
                    query: keyWord,
                    fuzziness: 2
                  }
                },                
              ]
            }            
          }
        }
      };
      break;
    case 'aircrafts':
      queryBody = {
        type: assetType, 
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool : {
              should : [
                {
                  multi_match : {
                    fields : 
                      ["category",
                      "subCategory",
                      "tailNumber",
                      "postTitle",
                      "airCert",
                      "faaCert",
                      "registrationCert",
                      "ownershipHistory",
                      "specs.exterior",
                      "specs.interior",
                      "specs.manufacturer",
                      "specs.model",
                      "specs.serialNumber",
                      "specs.engine.manufacturer",
                      "features",
                      "inspectionStatus",
                      "maintenanceProgram",
                      "weight.gross",
                      "weight.maxTakeOff",
                      "fuelCap"],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string : {
                    query : keyWord
                  }
                }
              ]
            }
          }
        }
      };
      break; 
    case 'arts':
      queryBody = {
        type: assetType, 
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool : {
              should : [
                {
                  multi_match : {
                    fields : 
                      ["category",
                      "subCategory",
                      "artist",
                      "postTitle",
                      "declaredOrigin",
                      "authenticityCert",
                      "purchaseHistory",
                      "appraisalValue",
                      "condition"],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string : {
                    query : keyWord
                  }
                }
              ]
            } 
          }
        }
      };
      break;
    case 'autos':
      queryBody = {
        type: assetType, 
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool : {
              should : [
                {
                  multi_match : {
                    fields : 
                      ["category",
                      "subCategory",
                      "postTitle",
                      "make",
                      "model",
                      "year",
                      "autoCategory",
                      "specs.interior.condition",
                      "specs.interior.material",
                      "specs.exterior.condition",
                      "specs.exterior.color",
                      "specs.drivetrain",
                      "specs.engine.Cyliner",
                      "vin",
                      "features"],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string : {
                    query : keyWord
                  }
                }
              ]
            } 
          }
        }
      };
      break;
    case 'boats':
        queryBody = {
          type: assetType, 
          searchQuery : {
            from: 0,
            size: 10000,
            query: {
              bool : {
                should : [
                  {
                    multi_match : {
                      fields : 
                        ["category",
                        "subCategory",
                        "postTitle",
                        "vesselType",
                        "ownershipCert",
                        "appraisalValue",
                        "charterHistory",
                        "architect",
                        "specs.interior.stylist",
                        "specs.exterior.build.builder",
                        "specs.exterior.build.builderLocation",
                        "specs.exterior.build.stylist",
                        "specs.exterior.hull.length",
                        "specs.performance.engines",
                        "specs.performance.engineModel",
                        "specs.performance.waterCapacity",
                        "specs.performance.fuelCapacity"],
                      query : keyWord,
                      type : "phrase_prefix",
                      slop: 2
                    }
                  },
                  {
                    query_string : {
                      query : keyWord
                    }
                  }
                ]
              } 
            }
          }
        };
      break;
    case 'jewelrys':
      queryBody = {
        type: assetType, 
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool : {
              should : [
                {
                  multi_match : {
                    fields : 
                    ["category",
                    "subCategory",
                    "postTitle",
                    "declaredOrigin",
                    "authenticityCert",
                    "purchaseHistory",
                    "appraisalValue",
                    "gradingReport"],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string : {
                    query : keyWord
                  }
                }
              ]
            } 
          }
        }
      };
      break;
    case 'realestates':
      queryBody = {
        type: assetType, 
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool : {
              should : [
                {
                  multi_match : {
                    fields : 
                    ["category",
                    "subCategory",
                    "postTitle",
                    "assetHeldBy",
                    "appraisalValue",
                    "disclosureForms",
                    "fullDescription",
                    "website",
                    "incomeDetails.confirmed",
                    "incomeDetails.futureEarnings"],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string : {
                    query : keyWord
                  }
                }
              ]
            } 
          }
        }
      };
      break;
    default:
      queryBody = {
        type: assetType,
        searchQuery : {
          from: 0,
          size: 10000,
          query: {
            bool: {
              should: [
                {
                  multi_match : {
                    fields : 
                      [ "postTitle",
                        "subCategory",
                        "fullDescription"
                      ],
                    query : keyWord,
                    type : "phrase_prefix",
                    slop: 2
                  }
                },
                {
                  query_string: {
                    query: keyWord,
                    fuzziness: 2
                  }
                },                
              ]
            }            
          }
        }
      };
      break;
  }
  return queryBody;
}

export const compareValues = (key, order='asc') => {
  return (a, b) => {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0; 
    }
    
    const varA = (typeof a[key] === 'string') ? 
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ? 
      b[key].toUpperCase() : b[key];
      
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order == 'desc') ? 
      (comparison * -1) : comparison
    );
  };
}

export const downloadBase64File = (base64Data, filename) => {
  var element = document.createElement('a');
  element.setAttribute('href', base64Data);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const base64StringtoFile = (base64String, filename) => {
  var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

export const extractImageFileExtensionFromBase64 = (base64Data) =>{
  return base64Data.substring("data:image/".length, base64Data.indexOf(";base64"))
}

export const image64toCanvasRef = async (canvasRef, image64, pixelCrop) => {
   const canvas = canvasRef;
  // const canvas = document.createElement('canvas');
  const image = new Image();   
  image.src = image64;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  console.log(scaleX);
  console.log(scaleY);
  console.log(pixelCrop.x);
  console.log(pixelCrop.y);
  console.log(pixelCrop.width);
  console.log(pixelCrop.height);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');
  
  image.onload = function() {
      ctx.drawImage(
        image,
        pixelCrop.x * scaleX,
        pixelCrop.y * scaleY,
        pixelCrop.width * scaleX,
        pixelCrop.height * scaleY,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height    
      )
    }
    console.log(canvas.width);
    console.log(canvas.height);
}
