import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import English from "../../images/English.png";
import Chinese from "../../images/Chinese.png";
import Spanish from "../../images/Spanish.png";
import French from "../../images/French.png";

const countryFlags = [English, Chinese, Spanish, French];
const languages = {
  English: 0,
  Chinese: 1,
  Spanish: 2,
  French: 3
};

class TopNavbar extends Component {
  state = {
    flag: 0,
    inputs: "",
  }

  switchLanguage = e => {
    let flag = languages[e.target.value];
    this.setState({
      flag
    });
  };

  userSignOut = () => {
    this.props.userSignOut(() => {
      this.props.history.push("/");
    });
  };

  render() {
    let { userInfo } = this.props
    return (
      <div className="top_navbar">
        <div className="top_navbar__sign-in">
          Hi, {userInfo ? `${userInfo.firstName}` : "Guest"}
        </div>
        <div className="top_navbar__info">
          {userInfo ? <div onClick={this.userSignOut} style={{width: "30%"}}> Sign Out</div> : ""}
          <i className="far fa-bell fa-lg"></i>
          <div className="m-spacer"></div>
          <img id="language_flag" src={countryFlags[this.state.flag]} alt="flag"/>
          <div className="sm-spacer"></div>
          <select id="language_selected" onChange={this.switchLanguage}>
            <option value="English">English</option>
            <option value="Chinese">Chinese</option>
            <option value="Spanish">Spanish</option>
            <option value="French">French</option>
          </select>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(mapStateToProps, actions)(withRouter(TopNavbar));