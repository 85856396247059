import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Wrapper from "../Layout/Wrapper";
import Logo from "../../images/bx-logo-main-black.png";
import ModernMenuDrop from "./ModernMenuDrop";
import ModernMenuSearchDrop from "./ModernMenuSearchDrop";
import { AlertTriangle, ShoppingCart, Search } from 'react-feather';

class UserModernMenu extends Component {
  state = {
    isSearchVisible: false,
    showDropMenu: false,
    stripeDialogIsActive: false
  };

  onHoverEnter = () => {
    this.setState({
      showDropMenu: true
    });
  };

  onHoverExit = () => {
    this.setState({
      showDropMenu: false
    });
  };

  userSignOut = () => {
    this.props.userSignOut(() => {
      this.props.history.push("/");
    });
  };

  handleSearchVisiblity = () => {
    this.setState({
      isSearchVisible: !this.state.isSearchVisible
    });
  };

  handleAddItem = () => {
    let { stripeId } = this.props.user;
    stripeId ?
    window.location.href="/user/asset/addasset" :
    this.setState({ stripeDialogIsActive: !this.state.stripeDialogIsActive });
  }

  handleCloseDialog = () => {
    this.setState({
      stripeDialogIsActive: !this.state.stripeDialogIsActive
    });
  }

  render() {
    let { isSearchVisible, showDropMenu, stripeDialogIsActive } = this.state;
    let visiblity = isSearchVisible ? "showsearch" : "hidesearch";
    let { _id, email, country, firstName, lastName, phoneNumber } = this.props.user;
    console.log(this.props.user);
    let activeDialog = stripeDialogIsActive ? "stripe-dialog-show" : "stripe-dialog-hide";
    let notify =
      this.props.user ?
      this.props.user.pendingInvites.length === 0
        ? "hide-notifications"
        : "show-notifications"
      : "";

    return (
      <div className="menu__modern">

        <div className={`stripe-dialog ${activeDialog}`} onClick={this.handleCloseDialog}>
          <div className="stripe-dialog-modal">
            <button className="stripe-dialog-exit-btn" onClick={this.handleCloseDialog}>X</button>
            <img src={Logo} alt="logo"/>
            <div className="stripe-dialog-content">
              <AlertTriangle color={"#F89200"} size={40} />
              <h3>
                To start the process of adding assets to BazaarXchanges, you'll need to create or login to a merchant account with Stripe. Stripe allows you to get paid quickly and keep all of your personal and payment information safe and secure.
              </h3>
            </div>
            <div className="stripe-dialog-content">
              <AlertTriangle color={"#F89200"} size={40} />
              <h3>
                Once you fill out the required information for Stripe. You will be redirected back to the BazaarXchanges site where you'll now have the ability to add assets.
              </h3>
            </div>
            <a 
              href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=http://localhost:3000/assets&client_id=ca_FuyelNIo4oT9xg0WtYuZBCBArfdGMGLv&state={${_id}}&stripe_user[email]=${email}&stripe_user[country]=${country || 'US'}&stripe_user[phone_number]=${phoneNumber}&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&stripe_user[business_type]=${'individual'}&suggested_capabilities[]=transfers`}
              className="stripe-btn-link" 
            >
              <button className="connect-btn">Connect with <i className="fab fa-stripe fa-2x"></i></button>
            </a>
          </div>
        </div>

        <Wrapper>
          <div className="menu__modern-content">
            <div className="menu__modern-content--a">
              <p
                onClick={this.userSignOut}
                className="menu__modern-content--signout"
              >
                SIGN&nbsp;OUT
              </p>
              <a href="/messages" className="menu__modern-content--invites">
                {/* <i className="far fa-envelope-open"></i> */}
                &nbsp;
                INVITES
                <div className={`invite-notification ${notify}`}></div>
              </a>
              <a href="/messages" className="menu__modern-content--invites">
                <i className="far fa-comments"></i>
                &nbsp;
                CHAT
              </a>
              <div
                className="menu__modern-content--explore"
                onMouseEnter={this.onHoverEnter}
                onMouseLeave={this.onHoverExit}
              >
                EXPLORE&nbsp;&nbsp;<i className="fas fa-chevron-down fa-xs"></i>
                <ModernMenuDrop
                  shown={showDropMenu}
                  closeDropdown={this.onHoverExit}
                />
              </div>
            </div>
            <a href="/" className="menu__modern-content--logo">
              <img src={Logo} alt="brand logo" />
            </a>
            <div className="menu__modern-content--b">
              <div
                // href="/user/asset/addasset"
                className="menu__modern-content--add"
              >
                <button onClick={this.handleAddItem}>
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              <div className="menu__modern-content--search">
                <button onClick={this.handleSearchVisiblity}>
                  <Search size={14}/>
                </button>
              </div>
              <a href="/cart" className="cart-logo">
                <ShoppingCart size={20} />&nbsp;&nbsp;CART({this.props.user.shoppingCart.length})
              </a>
              <a
                href="/user/accountsettings/userassets"
                className="menu__modern-content--user"
              >
                <i className="far fa-user fa-lg"></i>
                <div className="user-name">
                  <p>{this.props.user.firstName}</p>
                  <p>{this.props.user.lastName}</p>
                </div>
              </a>
            </div>
            <ModernMenuSearchDrop
              visible={visiblity}
              handleSearch={this.handleSearchVisiblity}
            />
          </div>
        </Wrapper>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(UserModernMenu));


