import React, { Component } from 'react';
import Lottie from 'react-lottie';
import * as animationData from "../../images/loaders/loading-script.json"

class AssetLoader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (
      <div className="loading-full-screen">
        <Lottie 
          options={defaultOptions}
          height={'100%'}
          width={'50%'}
          isStopped={this.props.dataLoaded}
        />
      </div>
    );
  }
}

export default AssetLoader;