import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AccountSettingsInput from "../AccountSettingsInput";
import AccountSettingsLayout from "../AccountSettingsLayout";

const renderFields = [
  {
    label: "User Name",
    type: "text",
    name: "userName"
  },
  {
    label: "First Name",
    type: "text",
    name: "firstName"
  },
  {
    label: "Last Name",
    type: "text",
    name: "lastName"
  },
  {
    label: "Email",
    type: "email",
    name: "email"
  },
  {
    label: "Phone Number",
    type: "text",
    name: "phoneNumber"
  }
];

class AccountSettingsUser extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.props.initialize(this.props.userInfo);
  }

  onUpdateUser = async values => {
    await this.props.userUpdateInfo(values);
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  };

  render() {
    const { userUpdateInfoError } = this.props;
    const { loading } = this.state;
    return (
      <AccountSettingsLayout>
        <div className="accountsettings__account">
          <div className="accountsettings__title">Account Settings</div>
          <form onSubmit={this.props.handleSubmit(this.onUpdateUser)}>
            {renderFields.map(({ label, type, name }) => (
              <AccountSettingsInput
                key={label}
                label={label}
                type={type}
                name={name}
              />
            ))}
            <div className="accountsettings__account__error">
              {userUpdateInfoError}
            </div>
            <div className="center-flex">
              <button className="btn accountsettings__assets__edit__form__btn">
                <div className="accountsettings__assets__edit__form__btn__label">
                  Save
                </div>
                {loading && (
                  <div className="accountsettings__assets__edit__form__btn__spinner">
                    <i className="fas fa-spinner fa-spin" />
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </AccountSettingsLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    userUpdateInfoError: state.auth.userUpdateInfoError
  };
};

function validate(values) {
  const errors = {};
  const categories = ["firstName", "lastName", "email", "phoneNumber"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

export default reduxForm({
  validate,
  form: "updateUserInfo"
})(
  connect(
    mapStateToProps,
    actions
  )(AccountSettingsUser)
);
