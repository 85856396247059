import React, { Component } from "react";
import ChatroomGroups from "./ChatroomGroups";
import ChatroomInvites from "./ChatroomInvites";
import Logo from "../../images/bchain-full-logo.png";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class ChatroomSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      name: "groupchat"
    };
  }

  render() {
    if (!this.props.groups) {
      return <h1>Loading...</h1>;
    }
    let { name } = this.state;
    let groupSelected = name === "groupchat" ? "tab-selected" : "";
    let inviteSelected = name !== "groupchat" ? "tab-selected" : "";

    return (
      <div className={`chatroom__sidebar ${this.props.sidebarClass}`}>
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        {/* START - Exit button for mobile sidebar only */}
        <div className="exit-sidebar">
          <button onClick={this.props.toggleActive}>
            <i className="far fa-times-circle fa-2x" />
          </button>
        </div>
        {/* END - Exit button for mobile sidebar only */}

        <div className="tab__bar">
          <div
            className={`tab__bar--a ${groupSelected}`}
            onClick={() =>
              this.setState({
                name: "groupchat"
              })
            }
          >
            <i className="far fa-comments fa-3x" />
          </div>
          <div
            className={`tab__bar--b ${inviteSelected}`}
            onClick={() =>
              this.setState({
                name: "invitations"
              })
            }
          >
            <i className="far fa-user fa-3x" />
          </div>
        </div>

        <div className="group__list">
          {name === "groupchat" ? (
            <ChatroomGroups
              loadChat={this.props.loadChat}
              lastMessage={this.props.lastMessage}
              activeChat={this.props.activeChat}
            />
          ) : (
            <ChatroomInvites socket={this.props.socket} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(ChatroomSidebar);
