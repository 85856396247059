import React, { Component } from "react";
import { Field } from "redux-form";

class FormInput extends Component {
  onChange = e => {
    const { onChange } = this.props.input;
    const file = e.target.files[0];
    onChange(file);

    this.props.displayPhoto(file);
  };
 
  clearField = () => {
    this.fileInput.value = "";

    this.props.removePhoto();
  };

  render() {
    const {
      label,
      meta: { touched, error }
    } = this.props;
    return (
      <div className="form__clearable">
        <label
          className={
            touched && error ? `form__label form__label--error` : `form__label`
          }
          htmlFor="file"
        >
          {label}
          <i className="fas fa-upload"></i>
        </label>

        <input
          className="form__input form__file"
          type="file"
          id="file"
          accept="image/jpg, image/png, image/jpeg"
          multiple
          onChange={this.onChange}
          ref={ref => (this.fileInput = ref)}
        />

        <div className="form__file__clear" onClick={this.clearField}>
          &times;
        </div>
      </div>
    );
  }
}

const FileInput = values => {
  const { name, label, displayPhoto, removePhoto } = values;

  return (
    <div className="form__group">
      <Field
        name={name}
        component={FormInput}
        label={label}
        displayPhoto={displayPhoto}
        removePhoto={removePhoto}
      />
    </div>
  );
};

export default FileInput;
