import React, { Component } from "react";
import InviteToChatroom from "../Chat/InviteToChatroom";

import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "axios";

class ChatroomNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSaveButton: false,
      showInviteButton: false,
      showExitButton: false
    };
  }

  /**
   * removes user from groupchat in database, sends socket notification to other group members, and load the adjacent chatroom
   */
  leaveGroup = async () => {
    var currentChat = this.props.groupID;
    await axios.patch(
      "http://localhost:4000/api/v1/groupchat/removeMember/" + currentChat,
      {
        userID: this.props.user._id
      }
    );
    this.props.socket.emit("leaveGroup", currentChat);

    var currentGroups = Object.keys(this.props.groups);
    var index = currentGroups.indexOf(currentChat);
    var newIndex = index === 0 ? 1 : index - 1;
    this.props.loadChat(currentGroups[newIndex]);
  };

  async componentDidMount() {}

  async componentDidUpdate(prevProps) {
    if (prevProps.groupID !== this.props.groupID) {
      if (this.props.groupID) {
        this.setState({ showExitButton: true });
      }
      if (this.props.groups[this.props.groupID].owner === this.props.user._id) {
        this.setState({ showInviteButton: true, showSaveButton: true });
      } else {
        this.setState({ showInviteButton: false, showSaveButton: false });
      }
    }
  }

  render() {
    return (
      <div className="chatroom__main--notification">
        <button
          className="mobile-btn-sidebar"
          onClick={this.props.toggleSidebar}
        >
          <i className="fas fa-bars fa-2x" />
        </button>
        {this.state.showSaveButton ? (
          <button className="archive" disabled={this.props.groupID === ""}>
            {/* <i className="far fa-plus-square fa-lg"></i> */}
            <p>Save</p>
          </button>
        ) : (
          ""
        )}

        {this.state.showInviteButton ? (
          <InviteToChatroom
            groupID={this.props.groupID}
            socket={this.props.socket}
          />
        ) : (
          ""
        )}

        {this.state.showExitButton ? (
          <button
            className="exit"
            onClick={() => this.leaveGroup()}
            disabled={this.props.groupID === ""}
          >
            {/* <i className="far fa-plus-square fa-lg"></i> */}
            <p>Exit</p>
          </button>
        ) : (
          ""
        )}

        {/* <StandardInvite socket={this.props.socket} /> */}
        <button
          className="mobile-btn-assetbar"
          onClick={this.props.toggleAssetBar}
        >
          <i className="far fa-caret-square-down fa-2x" />
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(ChatroomNotification);
