import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class ChatBriefcaseSelectAssets extends Component {
  render() {
    console.log(this.props);
    return (
      <div>
        SELECT AN ASSET!  
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    myAssets: state.asset.myAssets
  };
};

export default connect(mapStateToProps, actions)(ChatBriefcaseSelectAssets);