import React, { Component } from "react";
import { UploadCloud } from "react-feather";
import Select from "react-select";
import { categories } from "../../utility/constants";

class UploadImages extends Component {
  render() {
    let {
      nextFrame,
      prevFrame,
      selectImages,
      previewImages,
      removePhoto,
      category,
      setCategory
    } = this.props;

    const customStyles = {
      container: provided => ({
        ...provided,
        width: "61%"
      }),
      control: provided => ({
        ...provided,
        minHeight: "4rem",
        height: "4rem"
      }),
      input: provided => ({
        ...provided,
        height: "4rem"
      }),
      indicatorsContainer: provided => ({
        ...provided,
        height: "4rem"
      }),
      singleValue: provided => ({
        ...provided,
        height: "3rem",
        lineHeight: "2.4rem",
        fontSize: "2rem"
      }),
      placeholder: provided => ({
        ...provided,
        height: "3rem",
        lineHeight: "2.4rem",
        fontSize: "2rem"
      })
    };

    return (
      <div className="uploads__image-container">
        <div className="component-title">
          <h5>Choose your category</h5>
          <Select
            value={category}
            onChange={setCategory}
            options={categories}
            placeholder={"Filter by Category"}
            // className="category__filter"
            styles={customStyles}
          />
          <h1>Upload Image(s)</h1>
          <form>
            <input
              multiple
              type="file"
              accept="image/*"
              placeholder="Upload Here"
              onChange={selectImages}
            />
          </form>
            {/* <UploadCloud /> */}
          <div className="image-preview">
            {previewImages &&
              previewImages.map((photo, index) => (
                <div className="image-item" key={index}>
                  <img src={photo.tempUrl} alt={`preview-${index}`} />
                  <p onClick={() => removePhoto(photo)}>Remove</p>
                </div>
              ))}
          </div>
        </div>
        <div className="btn-container">
          <button onClick={nextFrame}>Add Details &nbsp;&rarr;</button>
        </div>
      </div>
    );
  }
}

export default UploadImages;
