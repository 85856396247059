import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Layout from "../Layout/Layout";
import Wrapper from '../Layout/Wrapper';

class ShowAsset extends Component {
  async componentDidMount() {
    await this.props.userGetAsset(
      this.props.assetId || this.props.match.params.id
    );
  }

  render() {
    if (!this.props.asset.assetId) return <div>Loading</div>;

    return (
      <Layout>
        <div className="page">
          <Wrapper>
            <div className="asset-container">
              <div className="asset-container--images">
                <img src={this.props.asset.images[0]} alt="painting"/>
              </div>
              <div className="asset-container--content">
                <ul className="asset-data">
                  <li>Type: {this.props.asset.assetType}</li>
                  <li>{this.props.asset.description}</li>
                  <li>Price: {this.props.asset.price}</li>
                  <li>Views: {this.props.asset.visits}</li>
                </ul>
              </div>
            </div>
          </Wrapper>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    asset: state.asset.assetInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(ShowAsset);
