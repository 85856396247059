import React, { Component } from 'react';
import { StripeProvider, Elements, CardElement, PaymentRequestButtonElement } from "react-stripe-elements";
import CheckoutForm from './CheckoutForm';

class MyCartCheckout extends Component {
  render() {
    return (
      <StripeProvider apiKey="pk_test_JOFS7cOLDMOkdhinTo5xXZKN00rvkyQT4B">
        <Elements>
          <CheckoutForm />
        </Elements>
      </StripeProvider>
    );
  }
}

export default MyCartCheckout;