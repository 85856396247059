import React from "react";
import AccountSettingsNav from "./AccountSettingsNav";
import Layout from "../Layout/Layout";

const AccountSettingsLayout = props => {
  return (
    <Layout>
      <div className="accountsettings">
        <AccountSettingsNav />
        <div className="accountsettings__info">{props.children}</div>
      </div>
    </Layout>
  );
};

export default AccountSettingsLayout;
