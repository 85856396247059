import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import { assetFormFields } from "../../../formFields/formFields";
import FormInput from "../../FormInput/FormInput";
import NumberInput from "../../FormInput/NumberInput";

class AssetInfoEdit extends Component {
  renderInfo = formFields => {
    const { category } = formFields;
    let info = assetFormFields[category];

    return info.map(({ label, name, suffix, type }) => { 
      return (
        <div className="accountsettings__assets__edit__info__row" key={label}>
          <div className="accountsettings__row">
            <div className="accountsettings__label">{label}</div>
            {type ? (
              <NumberInput
                key={label}
                label={label}
                name={name}
                suffix={suffix}
              />
            ) : (
              <FormInput key={label} label={label} name={name} />
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="accountsettings__account">
        <div className="accountsettings__title">Edit Details Of Asset</div>
        <div className="accountsettings__box">
          {this.renderInfo(this.props.formFields)}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(AssetInfoEdit);
