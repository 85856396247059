import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { base64StringtoFile } from "../../helperFunctions/helperFunctions";
import { image64toCanvasRef } from "../../helperFunctions/helperFunctions";
import { downloadBase64File } from "../../helperFunctions/helperFunctions";
import { extractImageFileExtensionFromBase64 } from "../../helperFunctions/helperFunctions";
// import "react-image-crop/lib/ReactCrop.scss";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "blue",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

const divStyle = {
  margin: "10rem",
  textAlign: "center"
};

class ImgDropAndCrop extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      imgSrc: null,
      crop: {}
    };
  }

  handleReset = () => {
    this.setState({
      imgSrc: null
    });
    this.props.history.push("user/asset/dragndrop");
  };
  handleOnDrop = (files, rejectedFiles) => {
    console.log(files);
    //add verification method
    const currentFile = files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.setState({
          imgSrc: reader.result
        });
      },
      false
    );
    reader.readAsDataURL(currentFile);
  };

  handleImageLoaded = image => {
    // this.setState({ crop: { width: image.width, height: image.height } });
    // return false; // Return false when setting crop state in here.
    this.imageRef = image;
  };

  handleOnCropComplete = (crop, pixelCrop) => {
    const canvasRef = this.imagePreviewCanvasRef.current;
    const { imgSrc } = this.state;
    // image64toCanvasRef(canvasRef, imgSrc, crop);
    this.makeClientCrop(crop);
  };

  handleOnCropChange = crop => {
    console.log(crop);
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  handleDownloadClick = event => {
    event.preventDefault();
    const canvasRef = this.imagePreviewCanvasRef.current;
    const { imgSrc } = this.state;

    const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
    const imageData64 = canvasRef.toDataURL("image/" + fileExtension);
    const filename = "previewFile." + fileExtension;

    //file to be uploaded
    const myNewCroppedFile = base64StringtoFile(imageData64, filename);
    const formData = new FormData();
    formData.append("cropped img", myNewCroppedFile);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    //download
    downloadBase64File(imageData64, filename);
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const { imgSrc, crop, croppedImageUrl } = this.state;
    return (
      <div style={divStyle}>
        {imgSrc !== null ? (
          <div>
            {/* {imgSrc}
            <img src={imgSrc} alt="Preview"/>  */}
            <ReactCrop
              src={imgSrc}
              crop={this.state.crop}
              onChange={this.handleOnCropChange}
              onImageLoaded={this.handleImageLoaded}
              onComplete={this.handleOnCropComplete}
              style={{
                margin: "10px",
                height: "auto",
                width: "auto",
                maxeight: "50%",
                maxWidth: "50%",
                border: "5px solid pink"
              }}
              ruleOfThirds={true}
            />
            <br />
            <p>Preview</p>
            {/* <canvas ref={this.imagePreviewCanvasRef} 
              width="100%" height="100%">
            </canvas> */}
            {croppedImageUrl && (
              <img
                alt="Crop"
                style={{ maxWidth: "100%" }}
                src={croppedImageUrl}
              />
            )}
            <button onClick={this.handleDownloadClick}>Download</button>
          </div>
        ) : (
          <Dropzone
            className="dropzone"
            onDrop={this.handleOnDrop}
            accept="image/*"
            multiple={true}
            maxSize={1000000000}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} style={baseStyle}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

export default ImgDropAndCrop;
