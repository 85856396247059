import React, { Component, Fragment } from "react";
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper";
import ShowAssetProduct from "../ShowAsset/ShowAssetProduct";
import { Link, StaticRouter } from "react-router-dom";
import Invite from "../Chat/StandardInvite";
import ClearHeart from "../../images/heart-clear-icon.png";
import FilledHeart from "../../images/heart-filled-icon.png";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { CheckCircle, Plus } from "react-feather";
import { category_icons } from "../../utility/constants";
import GoogleMaps from "./GoogleMaps";
import { GOOGLE_MAP_API_KEY } from "../../utility/google_maps";
import axios from "axios";
import Loader from "../Loader/AssetLoader";
import {FaRegHeart, FaHeart} from 'react-icons/fa'

const placeholder =
  "https://i0.wp.com/ahfirstaid.org/wp-content/uploads/2014/07/avatar-placeholder.png?fit=204%2C204";
const brandColor = '#DF233A';

class SingleListing extends Component {
  state = {
    item: null,
    displaySlideshow: false,
    slideshow: false,
    swapError: false,
    mustLogInOrRegister: "",
    isFavorited: false
  };

  capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  handleUserNotLoggedIn = () => {
    let { asset } = this.props.single[0];
    this.setState({
      mustLogInOrRegister: (
        <h4>
          Please{" "}
          <Link
            to={{
              pathname: "/login",
              state: {
                from: "singlePage",
                address: `${asset.category}/${asset._id}`
              }
            }}
          >
            Login
          </Link>{" "}
          or{" "}
          <Link
            to={{
              pathname: "/register",
              state: {
                from: "singlePage",
                address: `${asset.category}/${asset._id}`
              }
            }}
          >
            Register
          </Link>{" "}
          to add an item to your cart.
        </h4>
      )
    });
  };

  displaySlideshow = links => {
    console.log("Slideshow: ", this.state.slideshow);
    this.setState({ slideshow: true, slideshowLinks: links });
  };

  hideSlideshow = () => {
    this.setState({ slideshow: false });
  };

  getSingleListing = () => {
    console.log("singleListing");
    let listing_id = this.props.match.params.id;
    axios
      .get(`https://api-dev.bazaarxchanges.com/api/v2/listings/${listing_id}`)
      // .get(`http://localhost:4000/api/v2/listings/${listing_id}`)
      .then(res => {
        console.log(res.data);
        this.setState({
          item: res.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSwap = () => {
    if (!this.props.userInfo) {
      this.setState({ swapError: !this.state.swapError });
    } else {
      this.setState({ swapError: false });
    }
  };

  handleHeartPress = async itemId => {
    const{addFavoriteUpdated, userInfo, refreshUser} = this.props
    let userId = userInfo._id
    
    try{
      let isFavorited = this.isFavorited(itemId)
      const data = {itemId, userId, isFavorited};
      await addFavoriteUpdated(data)
    }catch(error){
      console.log('[error]',error)
    }
  
    try {
      refreshUser(userId);
    } catch (error) {}
  }

  isFavorited = (itemId) =>{
    const{userInfo}=this.props
    const{favorites}=userInfo
    return favorites.includes(itemId)
  }

  componentDidMount() {
    this.getSingleListing();
  }

  render() {
    let { item, mustLogInOrRegister } = this.state;

    if (!item) {
      return (
        <Layout>
          <Wrapper>
            <Loader />
          </Wrapper>
        </Layout>
      );
    }

    return (
      <Layout>
        <Wrapper>
          <div className="single-asset__container">
            <ShowAssetProduct
              asset={item}
              displaySlideshow={this.displaySlideshow}
            />
            <div className="single-asset__content">
              <div className="single-asset__heading">
                <div className="asset-title-sub">
                  {item.category.toUpperCase()}
                </div>
                <div className="asset-buttons">
                    <button
                      className="heart-btn"
                      onClick={()=>this.handleHeartPress(item._id)}
                    >
                      {this.isFavorited(item._id) ? (
                        <FaHeart color={brandColor} size={20}/>
                      ) : (
                        <FaRegHeart color={brandColor} size={20}/>
                      )}
                    </button>
                </div>
              </div>
              <div className="asset-title">{item.title}</div>
              <div className="asset-price">
                {parseInt(item.estimated_value).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}
                <span
                  style={{ fontSize: "1.2rem", color: "#111E29", opacity: 0.3 }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Est. Value
                </span>
              </div>
              <div className="warning">{mustLogInOrRegister}</div>
              <div className="content-border" />
              <div className="asset-details" style={{ paddingBottom: 0 }}>
                <h4>Condition:</h4>
                <ul className="asset-details--list">
                  <li>
                    <p style={{ color: "#DF233A", fontSize: "1.8rem" }}>
                      {item.condition}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="asset-summary">
                <h4>Summary:</h4>
                <p>{item.description}</p>
              </div>
              <div className="asset-details">
                <h4>Preferences:</h4>
                <ul className="asset-details--list">
                  {item.preferences.map((pref, index) => (
                    <li key={index}>
                      <img src={category_icons[pref]} alt={item.category} />
                      <p style={{ marginLeft: "2rem" }}>{pref}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="message-container">
                {/* <button className="send-message" onClick={this.handleSwap}>
                  Send a Swap Offer
                </button> */}
                <Invite
                  assetID={item._id}
                  ownerID={item.owner._id}
                  title={item.title}
                  receiverID={item.owner._id}
                  assetCategory={item.category}
                  assetId={item._id}
                  assetTitle={item.title}
                  assetLink={`/listings/${item._id}`}
                  assetImageUrl={item.images[0]}
                  assetPrice={item.estimated_value}
                  assetUserId={item.owner._id}
                  fullItem={item}
                />
                {this.state.swapError ? (
                  <div className="swap-warning">
                    Please login to send a swap
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
              <div className="asset-owner">
                <img
                  src={
                    item.owner && item.owner.avatar_image
                      ? item.owner.avatar_image
                      : placeholder
                  }
                  alt="user avatar"
                />
                <p>{item.owner && item.owner.name}</p>
                <i className="fas fa-star fa-3x"></i>
                <h4>{item.owner && item.owner.rating.toFixed(1)}</h4>
                <p>({item.owner && item.owner.rating_votes} votes)</p>
              </div>
              <div style={{ width: "100%", height: "40vh", marginTop: "3rem" }}>
                <GoogleMaps
                  zoom={14}
                  lat={item.location.coordinates[1]}
                  lng={item.location.coordinates[0]}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(mapStateToProps, actions)(SingleListing);
