import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { FaRegHeart, FaHeart } from "react-icons/fa";

const placeholder =
  "http://goodsparkgarage.com/wp-content/uploads/2012/02/mungenast_shop3a.jpg";

const avatar =
  "http://wilkinsonschool.org/wp-content/uploads/2018/10/user-default-grey.png";

class ListingCard extends Component {
  state = {
    isSmallScreen: false,
  };
  componentDidMount() {
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
  }
  updateWindowSize = () => {
    const { innerWidth } = window;
    if (innerWidth < 1000) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }
  };

  handleHeartPress = async (itemId) => {
    const { addFavoriteUpdated, userInfo, refreshUser } = this.props;
    let userId = userInfo._id;

    try {
      let isFavorited = this.isFavorited(itemId);
      const data = { itemId, userId, isFavorited };
      await addFavoriteUpdated(data);
    } catch (error) {
      console.log("[error]", error);
    }

    try {
      refreshUser(userId);
    } catch (error) {}
  };

  isFavorited = (itemId) => {
    const { userInfo } = this.props;
    const { favorites } = userInfo;
    if (favorites) {
      return favorites.includes(itemId);
    }
  };

  render() {
    let { item, calculateDistance } = this.props;
    return (
      <div
        className="listings__grid-card"
        style={{
          width: this.state.isSmallScreen ? 400 : "100%",
          display: "flex",
          marginTop: 85,
          marginRight: 10,
          marginLeft:this.state.isSmallScreen ? 0: 0,
          borderColor: "#e3e3e3",
          borderWidth: 1,
          borderRadius: 11,
          justifyContent:'center',
          alignItems: "center",
        }}
      >
        <a href={`/listings/${item._id}`}>
          {/* <div style={{borderColor:'gray', borderWidth:1, borderStyle:'solid'}}> */}
          <img
            src={(item.images && item.images[0]) || placeholder}
            alt="placeholder"
            style={{
              borderRadius: 10,
              // marginLeft:3,
              // marginRight:3,
              // marginTop:1,
              height: this.state.isSmallScreen ? 200 : 300,
              width: this.state.isSmallScreen ? "100%" : "100%",
              // borderBottomColor: "gray",
              // borderBottomWidth: 1,
              // borderBottomStyle: "solid",
            }}
          />
          {/* </div> */}
        </a>
        <div className="referenceBar">
          <div className="reference-user">
            <img
              className="card-avatar"
              src={(item.owner && item.owner.avatar_image) || avatar}
              alt="avatar"
            />
            <i className="fas fa-star fa-2x"></i>
            <h4>
              {(item.owner && item.owner.rating.toFixed(1)) || "N/A"}
              {item.owner ? (
                <span>{`(${item.owner &&
                  item.owner.rating_votes + " votes"})`}</span>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </h4>
          </div>
          <Button
            style={{
              backgroundColor: "#FFF",
              width: 40,
              marginLeft: 0,
              marginRight: 0,
              display: "flex",
              padding: 10,
              alignItems: "center",
            }}
            onClick={() => this.handleHeartPress(item._id)}
          >
            {this.isFavorited(item._id) ? (
              <FaHeart size={30} />
            ) : (
              <FaRegHeart size={30} />
            )}
          </Button>
        </div>
        <div
          className="card-title"
          style={{
            borderTopColor: "#e3e3e3",
            borderTopWidth: 1,
            borderTopStyle: "solid",
          }}
        >
          <h1>
            {item.title.length > 20
              ? item.title.substring(0, 20) + "..."
              : item.title}
          </h1>
        </div>
        <div
          className="distance-bar"
          style={{
            borderRadius: 10,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#e3e3e3",
          }}
        >
          <div
            className="mileage"
            style={{
              borderRadius: 10,
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#e3e3e3",
            }}
          >
            <i className="fas fa-map-marker-alt"></i>
            {" ~ " +
              Math.floor(
                calculateDistance({
                  latitude: item.location.coordinates[1],
                  longitude: item.location.coordinates[0],
                }) * 0.000621371192
              ) +
              " miles away"}
          </div>
          {/* <div className="card-share">Share</div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
  };
};

export default connect(mapStateToProps, actions)(ListingCard);
