import React, { Component } from "react";
import Logo from "../../images/bchain-full-logo.png";
import Wrapper from "../Layout/Wrapper";

class SidebarMenu extends Component {
  render() {
    return (
      <div className="sidebar__menu">
        <Wrapper>
          <div className="sidebar__menu-logo">
            <img src={Logo} alt="brand logo" />
          </div>
        </Wrapper>
        <div className="link-content">
          <div className="hide-scroll"></div>
          <i className="fas fa-chevron-left fa-3x"></i>
          <ul className="link-content--links">
            <li>All&nbsp;Autos</li>
            <li>Sports&nbsp;Cars</li>
            <li>Sedans</li>
            <li>Trucks&nbsp;/&nbsp;SUV's</li>
            <li>All&nbsp;Art</li>
            <li>Ceramics</li>
            <li>Paintings</li>
            <li>Photography</li>
            <li>Illustrations</li>
            <li>Sculpture</li>
            <li>All&nbsp;Boats</li>
            <li>Yachts</li>
            <li>Sailboats</li>
            <li>All&nbsp;Aircraft</li>
            <li>Leisure&nbsp;Planes</li>
            <li>Business&nbsp;Jets</li>
            <li>Helicopters</li>
            <li>All&nbsp;Collectibles</li>
            <li>Memorabilia</li>
            <li>Watches</li>
            <li>Jewelry</li>
            <li>Antiques</li>
            <li>All&nbsp;Real&nbsp;Estate</li>
            <li>Commercial&nbsp;Property</li>
            <li>Residential&nbsp;Property</li>
          </ul>
          <i className="fas fa-chevron-right fa-3x"></i>
        </div>
      </div>
    );
  }
}

export default SidebarMenu;
