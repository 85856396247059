// User Auth/Validation
export const CHECK_USER_STATUS = "CHECK_USER_STATUS";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const PHONE_VALIDATE = "PHONE_VALIDATE";
export const SAVE_REDIRECT_PATH = "SAVE_REDIRECT_PATH";
export const USER_LOGIN = "USER_LOGIN";
export const USER_REGISTER = "USER_REGISTER";
export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_UPDATE_INFO = "USER_UPDATE_INFO";
export const USER_UPDATE_INFO_ERROR = "USER_UPDATE_INFO_ERROR";
export const USER_UPDATE_PASSWORD = "USER_UPDATE_PASSWORD";
export const USER_UPDATE_PASSWORD_ERROR = "USER_UPDATE_PASSWORD_ERROR";
export const USER_SIGNOUT = "USER_SIGNOUT";
export const USER_LOGIN_WITH_SOCIAL_MEDIA = "USER_LOGIN_WITH_SOCIAL_MEDIA"
export const REFRESH_USER = "REFRESH_USER"

// Assets

export const FETCH_USER_BCHAIN_ADD_ASSET = "FETCH_USER_BCHAIN_ADD_ASSET";
export const FETCH_USER_BCHAIN_ADD_ASSET_SUCCESS = "FETCH_USER_BCHAIN_ADD_ASSET_SUCCESS";
export const FETCH_USER_BCHAIN_ADD_ASSET_FAILURE = "FETCH_USER_BCHAIN_ADD_ASSET_FAILURE";
export const USER_ADD_ASSET = "USER_ADD_ASSET";
export const USER_GET_ASSET = "USER_GET_ASSET";
export const USER_GET_MY_ASSETS = "USER_GET_MY_ASSETS";
export const USER_FIND_ASSET = "USER_FIND_ASSET";
export const USER_FIND_RELEVANT_ASSET = "USER_FIND_RELEVANT_ASSET";
export const USER_MADE_PAYMENT = "USER_MADE_PAYMENT";
export const USER_UPDATE_ASSET = "USER_UPDATE_ASSET";
export const USER_GET_CONNECTIONS_LIST = "USER_GET_CONNECTIONS_LIST";
export const USER_TOGGLE_ASSET_FILTER = "USER_TOGGLE_ASSET_FILTER";
export const USER_GET_SINGLE_ASSET = "USER_GET_SINGLE_ASSET";
export const SEARCH_ASSETS_BY_USERID = "SEARCH_ASSETS_BY_USERID";
export const SEARCH_ASSET = "SEARCH_ASSET";
export const UPDATE_QUERY = "UPDATE_QUERY";
export const DISPLAY_KEYWORD = "DISPLAY_KEYWORD";
export const GROUP_ASSETS = "GROUP_ASSETS";
export const GET_ALL_ELASTIC_RESULTS = "GET_ALL_ELASTIC_RESULTS";
export const GET_ASSETS_BY_USER_ID = "GET_ASSETS_BY_USER_ID";
export const GET_RECENTLY_VISITED = "GET_RECENTLY_VISITED";
export const GET_LOCATION_AND_LISTINGS = 'GET_LOCATION_AND_LISTINGS';

// Chat
export const USER_GET_MESSAGES = "USER_GET_MESSAGES";
export const USER_GET_INVITES = "USER_GET_INVITES";
export const USER_GET_GROUPS = "USER_GET_GROUPS";
export const USER_CURRENT_CHAT = "USER_CURRENT_CHAT";
export const USER_NEW_MESSAGE = "USER_NEW_MESSAGE";
export const USER_LEAVE_GROUP = "USER_LEAVE_GROUP";
export const USER_NEW_GROUP = "USER_NEW_GROUP";
export const USER_SEARCH = "USER_SEARCH";
export const USER_SOCKET = "USER_SOCKET";
export const USER_GET_GROUP_ASSETS = "USER_GET_GROUP_ASSETS";

// Artists
export const GET_ALL_ARTISTS = "GET_ALL_ARTISTS";

//FAVORITES
export const FETCH_ADD_FAVORITE = 'FETCH_ADD_FAVORITE';
export const FETCH_ADD_FAVORITE_SUCCESS = 'FETCH_ADD_FAVORITE_SUCCESS';
export const FETCH_ADD_FAVORITE_FAILURE = 'FETCH_ADD_FAVORITE_FAILURE';
export const FETCH_GET_FAVORITE = 'FETCH_GET_FAVORITE';
export const FETCH_GET_FAVORITE_SUCCESS = 'FETCH_GET_MYLISTINGS_SUCCESS';
export const FETCH_GET_FAVORITE_FAILURE = 'FETCH_GET_MYLISTINGS_FAILURE';
export const FETCH_FIND_ROOMS = "FETCH_FIND_ROOMS";
export const FETCH_FIND_ROOMS_SUCCESS = "FETCH_FIND_ROOMS_SUCCESS";
export const FETCH_FIND_ROOMS_FAILURE = "FETCH_FIND_ROOMS_FAILURE";
export const FETCH_DELETE_LISTING = "FETCH_DELETE_LISTING";
