import AntiquesIcon from "../icons/Antiques-Icon.png";
import ApartmentsIcon from "../icons/Apartment-Icon.png";
import AnythingIcon from "../icons/Anything-Icon.png";
import ArtIcon from "../icons/Art-Icon.png";
import AudioVideoIcon from "../icons/Audio-Video-Icon.png";
import AutoPartsIcon from "../icons/AutoParts-Icon.png";
import BabyKidsIcon from "../icons/BabyKids-Icon.png";
import BoatsMarineIcon from "../icons/BoatsMarine-Icon.png";
import BooksMagazinesIcon from "../icons/BooksMagazines-Icon.png";
import CarsTrucksIcon from "../icons/CarsTrucks-Icon.png";
import CDDVDIcon from "../icons/CDDVD-Icon.png";
import CellularPhonesIcon from "../icons/CellularPhones-Icon.png";
import ClothingIcon from "../icons/Clothing-Icon.png";
import CollectiblesIcon from "../icons/Collectibles-Icon.png";
import ComputersIcon from "../icons/Computers-Icon.png";
import ElectronicsIcon from "../icons/Electronics-Icon.png";
import ExerciseGymIcon from "../icons/ExerciseGym-Icon.png";
import FurnitureIcon from "../icons/Furniture-Icon.png";
import HomeGardenIcon from "../icons/HomeGarden-Icon.png";
import HouseholdIcon from "../icons/Household-Icon.png";
import JewelryAccessoriesIcon from "../icons/JewelryAccessories-Icon.png";
import MiscellaneousIcon from "../icons/Miscellaneous-Icon.png";
import MusicalInstrumentsIcon from "../icons/MusicalInstruments-Icon.png";
import OutdoorsIcon from "../icons/Outdoors-Icon.png";
import PetSuppliesIcon from "../icons/PetSupplies-Icon.png";
import RealEstateIcon from "../icons/RealEstate-Icon.png";
import ShoesSneakersIcon from "../icons/ShoesSneakers-Icon.png";
import SportsEquipmentIcon from "../icons/SportsEquipment-Icon.png";
import TicketsIcon from "../icons/Tickets-Icon.png";
import ToolsIcon from "../icons/Tools-Icon.png";
import VideoGamesIcon from "../icons/VideoGames-Icon.png";
import WeddingIcon from "../icons/Wedding-Icon.png";

export const categories = [
  { label: "Antiques", value: "Antiques" },
  { label: "Art", value: "Art" },
  { label: "Boats", value: "Boats" },
  { label: "Books", value: "Books" },
  { label: "Cars", value: "Cars" },
  { label: "Clothing", value: "Clothing" },
  { label: "Electronics", value: "Electronics" },
  { label: "Furniture", value: "Furniture" },
  { label: "Jewelry", value: "Jewelry" },
  { label: "Memorabilia", value: "Memorabilia" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Outdoors", value: "Outdoors" },
  { label: "Miscellaneous", value: "Miscellaneous" },
];

export const new_product_categories = [
  {title: 'Antiques'},
  {title: 'Art'},
  {title: 'Boats'},
  {title: 'Books'},
  {title: 'Cars'},
  {title: 'Clothing'},
  {title: 'Electronics'},
  {title: 'Furniture'},
  {title: 'Jewelry'},
  {title: 'Memorabilia'},
  {title: 'Real Estate'},
  {title: 'Outdoors'},
  {title: 'Miscellaneous'}
];

// Listing Condition
export const listing_conditions = [
  { label: "New - Never Used", value: "New" },
  { label: "Like New - Used a Few Times", value: "Like New" },
  { label: "Good - Gently Used", value: "Good" },
  { label: "Used - Shows Wear", value: "Used" },
  { label: "Poor - Heavily Used or Damaged", value: "Poor" }
];

export const popular_locations = [
  { city: "New York" },
  { city: "Los Angeles" },
  { city: "Chicago" },
  { city: "Houston" },
  { city: "Phoenix" },
  { city: "Philadelphia" },
  { city: "San Antonio" },
  { city: "San Diego" },
  { city: "Dallas" },
  { city: "San Jose" },
  { city: "Austin" },
  { city: "Jacksonville" },
  { city: "Fort Worth" },
  { city: "Columbus" },
  { city: "San Francisco" },
  { city: "Charlotte" },
  { city: "Indianapolis" },
  { city: "Seattle" },
  { city: "Denver" },
  { city: "Washington D.C." },
  { city: "Boston" },
  { city: "El Paso" },
  { city: "Detroit" },
  { city: "Nashville" },
  { city: "Portland" },
  { city: "Memphis" },
  { city: "Oklahoma City" },
  { city: "Las Vegas" },
  { city: "Louisville" },
  { city: "Honolulu" },
  { city: "Oakland" },
  { city: "Cleveland" },
  { city: "Atlanta" },
  { city: "Miami" },
  { city: "Sacramento" },
  { city: "Minneapolis" },
  { city: "Pittsburgh" },
  { city: "Tampa" },
  { city: "Buffalo" },
  { city: "Reno" },
  { city: "Baton Rouge" },
  { city: "Salt Lake City" },
  { city: "Stamford" },
  { city: "Knoxville" },
  { city: "Jackson Hole" },
  { city: "Colorado Springs" },
  { city: "Greenwich" },
  { city: "St. Louis" },
  { city: "Raleigh" },
  { city: "Newark" },
  { city: "Norfolk" },
  { city: "Cincinnati" },
  { city: "Kansas City" },
  { city: "Milwaukee" }
];

export const category_icons = {
  Anything: AnythingIcon,
  Apartments: ApartmentsIcon,
  "Art": ArtIcon,
  Antiques: AntiquesIcon,
  "Audio/Video": AudioVideoIcon,
  "Auto Parts": AutoPartsIcon,
  "Baby & Kids": BabyKidsIcon,
  "Boats": BoatsMarineIcon,
  "Books": BooksMagazinesIcon,
  "Cars": CarsTrucksIcon,
  "CD's & DVD's": CDDVDIcon,
  "Cellular Phones": CellularPhonesIcon,
  Clothing: ClothingIcon,
  Memorabilia: CollectiblesIcon,
  Computers: ComputersIcon,
  Electronics: ElectronicsIcon,
  "Exercise & Gym": ExerciseGymIcon,
  Furniture: FurnitureIcon,
  "Home & Garden": HomeGardenIcon,
  Household: HouseholdIcon,
  Jewelry: JewelryAccessoriesIcon,
  Miscellaneous: MiscellaneousIcon,
  "Musical Instruments": MusicalInstrumentsIcon,
  Outdoors: OutdoorsIcon,
  "Pet Supplies": PetSuppliesIcon,
  "Real Estate": RealEstateIcon,
  "Sports Equipment": SportsEquipmentIcon,
  "Shoes & Sneakers": ShoesSneakersIcon,
  Tickets: TicketsIcon,
  Tools: ToolsIcon,
  "Video Games": VideoGamesIcon,
  Wedding: WeddingIcon
};

export const chunk = (inputArray, chunkCount) => {
  return inputArray.reduce((chunkedArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkCount);

    if (!chunkedArray[chunkIndex]) {
      chunkedArray[chunkIndex] = [];
    }

    chunkedArray[chunkIndex].push(item);
    return chunkedArray;
  }, []);
};

export const isValidNumber = input =>
  Number.isInteger(parseInt(input)) ? true : false;
export const isFiveDigits = input => (input.length === 5 ? true : false);


export const awsConfig = {
  accessKey: 'AKIAUEL6G4DZQDRKRYVJ',
  secretKey: '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',
  region: 'us-east-1',
  idBucket: 'bchainuserdocuments',
  productImageBucket: 'bchainco-product-files',
  productVideoBucket: 'bchainco-video-files',
  productDocumentBucket: 'bchainco-product-original-documents',
  productImageURLPrefix: 'https://s3.amazonaws.com/bchainco-product-filesresized/',
  productVideoURLPrefix: 'https://s3.amazonaws.com/bchainco-video-files-processed/',
  productDocumentURLPrefix: 'https://s3.amazonaws.com/bchainco-product-original-documents/',
  productVideoThumbnailURLPrefix: 'https://s3.amazonaws.com/bchainco-video-files-thumbnails/',
  elasticSearchURLPrefix: 'https://search-dev-y5wivj5gwx7gulu5lk44tset4m.us-east-1.es.amazonaws.com'
};

export const s3Config = {
  bucketName: 'bchainco-product-files',
  region: 'us-east-1',
  accessKeyId: 'AKIAUEL6G4DZQDRKRYVJ',
  secretAccessKey: '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',
  s3Url: 'https://s3.amazonaws.com/bchainco-product-filesresized/'
}