import React from "react";
import FormInput from "../../FormInput/FormInput";
import NumberInput from "../../FormInput/NumberInput";
import SelectFormInput from "../../FormInput/SelectFormInput";

const AssetFormCarDetails = () => {
  const subCategoryOptions = [
    {
      display: "Choose A Subcategory",
      disable: true,
      value: ""
    },
    {
      display: "Sports Car",
      disable: false,
      value: "Sports Cars"
    },
    {
      display: "Sedans",
      disable: false,
      value: "Sedans"
    },
    {
      display: "Trucks & SUV's",
      disable: false,
      value: "Trucks & SUV's"
    }];
    const carConditionOptions = [
      {
        display: "Vehicle Condition",
        disable: true,
        value: ""
      },
      {
        display: "Brand New",
        disable: false,
        value: "Brand New"
      },
      {
        display: "Used: Less Than 1 Year",
        disable: false,
        value: "Less Than 1 Year"
      },
      {
        display: "Used: 1-2 Years",
        disable: false,
        value: "Sedan"
      },
      {
        display: "Used: 2-5 Years",
        disable: false,
        value: "2-5 Years"
      },
      {
        display: "Used: More Than 5 Years",
        disable: false,
        value: "More Than 5 Years"
      },
      {
        display: "Vintage",
        disable: false,
        value: "Vintage"
      },];
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Details of Car</div>
      <SelectFormInput 
        {...{name: "subCategory", 
        label: "Car Subcategory", 
        type:"text", 
        options: subCategoryOptions}} 
        key={"categoryKey"}
        />
      <FormInput label="Make" name="make" />
      <FormInput label="Model" name="model" />
      <FormInput label="Year" name="year" />
      <FormInput label="Interior Car Condition" name="specs.interior.condition" />
      <FormInput label="Interior Material" name="specs.interior.material" />
      <NumberInput label="Passenger Capacity" name="specs.interior.passengerCapacity" />
      <SelectFormInput 
        {...{name: "specs.exterior.condition", 
        label: "Exterior Car Condition", 
        type:"text", 
        options: carConditionOptions}} 
        key={"conditionKey"}
        />
      <FormInput label="Exterior Color" name="specs.exterior.color" />
      <FormInput label="Drivetrain" name="specs.drivetrain" />
      <FormInput label="Engine Cylinder" name="specs.engine.cylinder" />
      <NumberInput label="Engine Horsepower" name="specs.engine.horsepower" />
      <NumberInput label="Mileage" name="mileage" />
      <NumberInput label="Miles Per Gallon (mpg)" name="mpg" />
      <FormInput label="VIN" name="vin" />
      <FormInput label="Features" name="features" />
    </div>
  );
};

export default AssetFormCarDetails;
