import axios from "axios";
import {
  USER_GET_INVITES,
  USER_GET_GROUPS,
  USER_NEW_GROUP,
  USER_SOCKET,
  USER_GET_GROUP_ASSETS,
  FETCH_FIND_ROOMS,
  FETCH_FIND_ROOMS_SUCCESS,
  FETCH_FIND_ROOMS_FAILURE
} from "./types";

axios.defaults.withCredentials = true;

const groupchatUrl = api => {
  return "https://api-dev.bazaarxchanges.com/api/v1/groupchat/" + api;
};

const userUrl = api => {
  return "https://api-dev.bazaarxchanges.com/api/v1/user/" + api;
};

const getUrl = (api) => {
  return 'https://api-dev.bazaarxchanges.com' + api;
  // return "http://localhost:4000" + api;
};

const newGroup = (owner, members, callback) => async dispatch => {
  try {
    members.push(owner);
    //var groupID;
    await axios
      .post(groupchatUrl("create/"), {
        owner: owner,
        members: members
      })
      .then(async res => {
        const group = res.data;
        var members = group.members.reduce((map, member) => {
          map[member.userID] = member.name;
          return map;
        }, {});
        var payload = {
          groupID: group._id,
          archive: group.archive,
          owner: group.owner,
          members: members
        };
        dispatch({
          type: USER_NEW_GROUP,
          payload
        });
        callback(group._id);
      });
    //return groupID;
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

const getPendingInvites = userID => async dispatch => {
  try {
    var res = await axios.get(groupchatUrl("getInvitesByUser/" + userID));
    var payload = res.data;

    dispatch({
      type: USER_GET_INVITES,
      payload
    });
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

const getGroups = userID => async dispatch => {
  try {
    var payload = [];
    await axios.get(userUrl("getGroupsInfo/" + userID)).then(async res => {
      payload = res.data;
      dispatch({
        type: USER_GET_GROUPS,
        payload
      });
    });
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

const leaveGroup = (userID, groupID) => async dispatch => {
  try {
    console.log("user: " + userID + " group: " + groupID);

    await axios
      .patch(userUrl("leaveGroup/") + userID, {
        groupID: groupID
      })
      .then(async () => {
        await axios.patch(groupchatUrl("removeMember/") + groupID, {
          userID: userID
        });
      });

    // var payload = {
    //   userID: userID,
    //   groupID: groupID
    // };
    // dispatch({
    //   type: USER_LEAVE_GROUP,
    //   payload
    // });
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

const saveSocket = socket => async dispatch => {
  try {
    var payload = socket;
    dispatch({
      type: USER_SOCKET,
      payload
    });
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

const getGroupsInfo = groupId => async dispatch => {
  try {
    await axios.get(groupchatUrl(`getGroupAssets/${groupId}`))
      .then(res => {
        dispatch({
          type: USER_GET_GROUP_ASSETS,
          payload: res.data
        })
      });
  } catch(err) {
    console.log(err);
    return err.response.data;
  }
}

const findRooms = senderId => async dispatch => {
  dispatch({type: FETCH_FIND_ROOMS});
  try {
    const {data} = await axios.get(
      getUrl(`/api/v2/message/findRooms/${senderId}`),
    );

    var sortedData = data
      .map(chatroom => {
        var tempChat = chatroom;
        const {messages} = chatroom;

        if(messages.length > 0){
          let sortedMessages = messages.sort((m1, m2) => {
            let timetoken1 = m1.timetoken;
            let timetoken2 = m2.timetoken;
  
            return timetoken1 - timetoken2;
          });
          tempChat.messages = sortedMessages;
        }

        return tempChat;
      })
      .sort((c1, c2) => {
        let c1Messages = c1.messages;
        let c2Messages = c2.messages;
        let c1createdAt = (new Date(c1.chat.createdAt)).getTime() * 10000
        let c2createdAt = (new Date(c2.chat.createdAt)).getTime() * 10000
        let lastC1MessageTime = c1Messages.length > 0 ? c1Messages[c1Messages.length - 1].timetoken : c1createdAt;
        let lastC2MessageTime = c2Messages.length > 0 ? c2Messages[c2Messages.length - 1].timetoken : c2createdAt;

        let timetoken1 = lastC1MessageTime;
        let timetoken2 = lastC2MessageTime;

        return timetoken2 - timetoken1;
      });

    // console.log('[payload]', sortedData);
    dispatch({type: FETCH_FIND_ROOMS_SUCCESS, payload: sortedData});
  } catch (error) {
    console.log('[payload]', error);
    dispatch({type: FETCH_FIND_ROOMS_FAILURE, error: error});
  }
};

export {
  newGroup,
  getPendingInvites,
  getGroupsInfo,
  getGroups,
  leaveGroup,
  saveSocket,
  findRooms
};