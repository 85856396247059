import React from 'react';
import PopCard from "./PopCard";
import SlimCard from "./SlimCard";

// Array shuffle func
const shuffle = array => {
  let m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

// Sorted by newest asset by date
const PopCardSortByDateDesc = (props) => {
  return props.results.sort((a,b) => {
    a = new Date(a._source.createdAt);
    b = new Date(b._source.createdAt);
    return b - a
  }).slice(0,8).map((elem, index) => {
    if ( elem._source.category === "realestate" ) {
      elem._source.category = "Real Estate"
    }
    return (
      <PopCard 
        key={index}
        id={elem._id}
        imgSrc={elem._source.images[0]}
        category={
          `${elem._source.category.charAt(0).toUpperCase() + elem._source.category.slice(1)} - ${elem._source.subCategory}`
        }
        urlCategory={elem._source.category}
        postTitle={elem._source.postTitle}
        byLine={elem._source.artist}
        price={elem._source.price}
        pref={
          elem._source.sell && elem._source.swap && elem._source.lease ? "SALE | SWAP | LEASE" :
          elem._source.sell && elem._source.swap ? "FOR SALE or SWAP" :
          elem._source.sell && elem._source.lease ? "FOR SALE or LEASE" :
          elem._source.lease && elem._source.swap ? "SWAP or LEASE" :
          elem._source.sell ? "FOR SALE" :
          elem._source.swap ? "SWAP ONLY" : "LEASE ONLY"
        }
        // location={elem._source.address}
      />
    )
  })
};

// Sorted by newest asset by date - Slim Card
const SlimCardSortByDateDesc = (props) => {
  return props.results.sort((a,b) => {
    a = new Date(a._source.createdAt);
    b = new Date(b._source.createdAt);
    return b - a
  }).filter(x => x._type === props.category).slice(0,5).map((elem, index) => {
    // if ( elem._source.category === "realestate" ) {
    //   elem._source.category = "Real Estate"
    // }
    return (
      <SlimCard 
        key={index}
        id={elem._id}
        imgSrc={elem._source.images[0]}
        category={
          `${elem._source.category.charAt(0).toUpperCase() + elem._source.category.slice(1)} - ${elem._source.subCategory}`
        }
        urlCategory={elem._source.category}
        postTitle={elem._source.postTitle}
        byLine={elem._source.artist}
        price={elem._source.price}
        pref={
          elem._source.sell && elem._source.swap && elem._source.lease ? "SALE | SWAP | LEASE" :
          elem._source.sell && elem._source.swap ? "FOR SALE or SWAP" :
          elem._source.sell && elem._source.lease ? "FOR SALE or LEASE" :
          elem._source.lease && elem._source.swap ? "SWAP or LEASE" :
          elem._source.sell ? "FOR SALE" :
          elem._source.swap ? "SWAP ONLY" : "LEASE ONLY"
        }
        // location={elem._source.address}
      />
    )
  })
};

// Sorted by highest price 
const PopCardSortByPriceDesc = (props) => {
  return props.results.sort((a,b) => {
    a = parseInt(a._source.price);
    b = parseInt(b._source.price);
    return b - a
  }).slice(0,8).map((elem, index) => {
    if ( elem._source.category === "realestate" ) {
      elem._source.category = "Real Estate"
    }
    return (
      <PopCard 
        key={index}
        id={elem._id}
        imgSrc={elem._source.images[0]}
        category={
          `${elem._source.category.charAt(0).toUpperCase() + elem._source.category.slice(1)} - ${elem._source.subCategory}`
        }
        urlCategory={elem._source.category}
        postTitle={elem._source.postTitle}
        byLine={elem._source.artist}
        price={elem._source.price}
        pref={
          elem._source.sell && elem._source.swap && elem._source.lease ? "SALE | SWAP | LEASE" :
          elem._source.sell && elem._source.swap ? "FOR SALE or SWAP" :
          elem._source.sell && elem._source.lease ? "FOR SALE or LEASE" :
          elem._source.lease && elem._source.swap ? "SWAP or LEASE" :
          elem._source.sell ? "FOR SALE" :
          elem._source.swap ? "SWAP ONLY" : "LEASE ONLY"
        }
        // location={elem._source.address}
      />
    )
  })
};

// shuffled assets 
const PopCardShuffle = (props) => {
  // let mixed = shuffle(props.results);
  return (
    <div className="asset-items__popular">
      <div className="items-grid">
        { 
          props.results.map((elem, index) => {
            if ( elem._source.category === "realestate" ) {
              elem._source.category = "Real Estate"
            }
          return (
            <PopCard 
              key={index}
              id={elem._id}
              imgSrc={elem._source.images[0]}
              category={
                `${elem._source.category.charAt(0).toUpperCase() + elem._source.category.slice(1)} - ${elem._source.subCategory}`
              }
              urlCategory={elem._source.category}
              postTitle={elem._source.postTitle}
              byLine={elem._source.artist}
              price={elem._source.price}
              pref={
                elem._source.sell && elem._source.swap && elem._source.lease ? "SALE | SWAP | LEASE" :
                elem._source.sell && elem._source.swap ? "FOR SALE or SWAP" :
                elem._source.sell && elem._source.lease ? "FOR SALE or LEASE" :
                elem._source.lease && elem._source.swap ? "SWAP or LEASE" :
                elem._source.sell ? "FOR SALE" :
                elem._source.swap ? "SWAP ONLY" : "LEASE ONLY"
              }
              // location={elem._source.address}
            />
          )})
        }
      </div>
    </div>
  )
};

export {
  PopCardSortByDateDesc,
  SlimCardSortByDateDesc,
  PopCardSortByPriceDesc,
  PopCardShuffle
};