import React, { Component } from "react";
import { Field } from "redux-form";

class FormInput extends Component {
  onChange = e => {
    const { onChange } = this.props.input;

    if (e.target.files.length > 2) {
      this.clearField();
    } else {
      onChange(e.target.files);
    }
  };

  clearField = () => {
    this.fileInput.value = "";
    this.props.clearList(this.props.input.name);
  };

  render() {
    const {
      label,
      input: { value },
      meta: { touched, error }
    } = this.props;

    return (
      <div className="form__clearable">
        <label
          className={
            touched && error ? `form__label form__label--error` : `form__label`
          }
          htmlFor="video"
        >
          {Object.keys(value).length
            ? `${Object.keys(value).length} Video Uploaded`
            : label}
          <div className="form__label__icon">
            <i className="fas fa-upload" />
          </div>
        </label>

        <input
          className="form__input form__file"
          type="file"
          id="video"
          accept="video/mp4"
          multiple
          onChange={this.onChange}
          ref={ref => (this.fileInput = ref)}
        />

        <div className="form__file__clear" onClick={this.clearField}>
          &times;
        </div>
      </div>
    );
  }
}

const VideoInput = ({ name, label, clearList }) => {
  return (
    <div className="form__group">
      <Field
        name={name}
        component={FormInput}
        label={label}
        clearList={clearList}
      />
    </div>
  );
};

export default VideoInput;
