import React, { Component } from "react";
import { Link } from "react-router-dom";

class AssetComplete extends Component {

  componentDidMount() {
    window.scrollTo(0,0);
  }

  render() {
    return (
      <div className="add-asset__complete">
        <div className="add-asset__complete__title">
          Completed Asset Listing Transaction
        </div>
        <div className="add-asset__complete__message">
          Thank you for adding an asset. Your asset will now be shown to other
          verified users in the Bchain network.
        </div>
        <div className="add-asset__complete__message">
          Please click here to visit the main website.
        </div>

        <Link to="/assets">
          <div className="add-asset__complete__button btn">
            Go To Main Website
          </div>
        </Link>
      </div>
    );
  }
}

export default AssetComplete;
