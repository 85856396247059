import React from "react";
import FormInput from "../../FormInput/FormInput";
import TextArea from "../../FormInput/TextArea";

const AssetFormBasics = () => {
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Title & Description</div>
      <FormInput name="postTitle"  label="Title" />
      <TextArea name="fullDescription" label=" Description/Summary" />
    </div>
  );
};

export default AssetFormBasics;
