import React, { Component } from "react";
import Navbar from "../Navbar/Navbar";
import Wrapper from "../Layout/Wrapper";
import UploadSelection from "./UploadSelection";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import * as actions from "../../redux/actions";
import { connect } from "react-redux";

import {
  categories,
  listing_conditions,
  awsConfig,
} from "./../../utility/constants";

import ListingUploadImg from "../../images/listingupload.png";
import {
  IoIosCar,
  IoMdImage,
  IoIosBook,
  IoIosBulb,
  IoIosBed,
  IoIosHourglass,
  IoIosColorFill,
  IoIosShirt,
  IoIosUmbrella,
  IoIosStopwatch,
  IoIosBoat,
  IoIosSchool,
  IoMdGlasses,
  IoIosClose,
} from "react-icons/io";
import UserNavbar from "../Navbar/UserNavbar";
import MobileNavbar from "../Navbar/MobileNavbar";

const animatedComponents = makeAnimated();

class ArtSelection extends Component {
  state = {
    isSmallScreen: false,
    selectedCategory: -1,
    images: [],
    showProductDetailsPage: false,
    title: "",
    value: "",
    condition: "",
    description: "",
    preferences: [],
    longitude: null,
    latitude: null,
  };
  componentDidMount() {
    this._handleGeolocation();
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
  }
  updateWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    // if (innerWidth < 608 && innerHeight < 813) {
    if (innerWidth < 608) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }
  };

  setPreferences = (pref) => {
    this.setState({
      preferences: pref,
    });
  };

  setCondition = (condition) => {
    this.setState({ condition: condition });
  };

  _setCategory = async (category) => {
    console.log(category);
    this.setState({ category });
  };

  handleCategory = (index, newIcons) => {
    this.setState({ selectedCategory: index, category: newIcons[index].name });
  };

  handleSelectedAsset = (event) => {
    const images = Array.from(event.target.files);
    [...images].map(
      (file, index, arr) =>
        (images[index].tempUrl = URL.createObjectURL(arr[index]))
    );
    console.log("[images]", images);
    this.setState({
      images,
    });
  };

  _handleListingSubmit = async () => {
    const { addNewListing, userInfo } = this.props;
    let latitude = this.state.latitude;
    let longitude = this.state.longitude;
    let owner = userInfo;
    let values = {
      // images: this.state.images[0].tempUrl.slice(5),
      images: this.state.images,
      category: this.state.category,
      title: this.state.title,
      estimated_value: parseInt(this.state.value),
      description: this.state.description,
      owner,
      condition: this.state.condition.value,
      preferences: this.state.preferences.map((x) => x.value),
      location: { coordinates: [longitude, latitude] },
      priceCut: false,
      hidePrice: false,
      hideCondition: false,
    };
    addNewListing(values, this.props.history);
  };

  _handleGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {
          coords: { latitude, longitude },
        } = position;
        this.setState({ latitude, longitude });
      },
      () => this.setDefaultLatLng(),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 10000 }
    );
  };

  setDefaultLatLng = async () => {
    this.setState({
      latitude: 40.76203408358093,
      longitude: -73.97892881890559,
    });
  };

  renderProductDetailsPage = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "fixed",
          zIndex: 1,
          top: "20vh",
          height: "70vh",
          width: "80vw",
          // maxWidth:300,
          // minHeight: 400,
          // maxHeight: 400,
          backgroundColor: "white",
          borderRadius: 20,
          borderColor: "#757575",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        {/* <div style={{display:'flex', alignSelf:'center'}}> */}
        <button
          style={{
            position: "absolute",
            left: 5,
            top: 5,
            outline: "none",
            borderRadius: 100,
            backgroundColor: "red",
            opacity: 0.8,
            width: 30,
            height: 30,
          }}
          onClick={() =>
            this.setState({
              showProductDetailsPage: !this.state.showProductDetailsPage,
            })
          }
        >
          <IoIosClose size={22} style={{ color: "white" }} />
        </button>
        <h1 style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>
          Listing information
        </h1>
        {/* </div> */}
        <input
          placeholder="Add title"
          style={{
            width: "60vw",
            height: 30,
            borderRadius: 10,
            outline: "none",
            textAlign: "center",
            backgroundColor: "#EDEDED",
            marginTop: 10,
            marginBottom: 10,
          }}
          onChange={(e) => this.setState({ title: e.target.value })}
        />
        <input
          placeholder="Add Estimated Value"
          style={{
            width: "60vw",
            height: 30,
            borderRadius: 10,
            outline: "none",
            textAlign: "center",
            backgroundColor: "#EDEDED",
            marginTop: 10,
            marginBottom: 10,
          }}
          onChange={(e) => this.setState({ value: e.target.value })}
        />
        <div style={{ width: "60vw", marginBottom: 10, marginTop: 10 }}>
          <Select
            value={this.state.condition}
            onChange={this.setCondition}
            options={listing_conditions}
            placeholder={"Select a Condition"}
            className="condition__filter"
            classNamePrefix="react-select"
          />
        </div>
        <div style={{ width: "60vw", marginBottom: 10, marginTop: 10 }}>
          <Select
            value={this.state.preferences}
            onChange={this.setPreferences}
            components={animatedComponents}
            options={categories}
            className="condition__filter"
            classNamePrefix="react-select"
            isMulti
          />
        </div>
        <textarea
          style={{
            width: "60vw",
            height: "20vh",
            marginBottom: 10,
            marginTop: 10,
            padding: 10,
            borderRadius: 10,
            resize: "none",
            backgroundColor: "#EDEDED",
            outline: "none",
          }}
          type="text"
          placeholder="Add Description"
          onChange={(e) => this.setState({ description: e.target.value })}
          value={this.state.description}
        />
        <button
          style={{
            width: "20vw",
            height: 40,
            minWidth: 150,
            maxWidth: 200,
            borderRadius: 10,
            backgroundColor: "red",
            color: "white",
            fontWeight: "bold",
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: "center",
            outline: "none",
          }}
          onClick={this._handleListingSubmit}
        >
          Submit Listing &nbsp;&rarr;
        </button>
      </div>
    );
  };

  render() {
    let { nextFrame, prevFrame, handleSelection, isCollection } = this.props;
    const { isSmallScreen, showProductDetailsPage } = this.state;
    const newIcons = [
      {
        icon: <IoMdImage size={22} style={{ color: "black" }} />,
        name: "Antiques",
      },
      {
        icon: <IoIosColorFill size={22} style={{ color: "black" }} />,
        name: "Art",
      },
      {
        icon: <IoIosBoat size={22} style={{ color: "black" }} />,
        name: "Boats",
      },
      {
        icon: <IoIosBook size={22} style={{ color: "black" }} />,
        name: "Books",
      },
      { icon: <IoIosCar size={22} style={{ color: "black" }} />, name: "Cars" },
      {
        icon: <IoIosShirt size={22} style={{ color: "black" }} />,
        name: "Clothing",
      },
      {
        icon: <IoIosBulb size={22} style={{ color: "black" }} />,
        name: "Electronics",
      },
      {
        icon: <IoIosBed size={22} style={{ color: "black" }} />,
        name: "Furniture",
      },
      {
        icon: <IoIosStopwatch size={22} style={{ color: "black" }} />,
        name: "Jewelry",
      },
      {
        icon: <IoMdGlasses size={22} style={{ color: "black" }} />,
        name: "Memorabilia",
      },
      {
        icon: <IoIosSchool size={22} style={{ color: "black" }} />,
        name: "Real Estate",
      },
      {
        icon: <IoIosUmbrella size={22} style={{ color: "black" }} />,
        name: "Outdoors",
      },
      {
        icon: <IoIosHourglass size={22} style={{ color: "black" }} />,
        name: "Miscellaneous",
      },
    ];

    const showProductDetailOpacity =
      this.state.images.length === 0 || this.state.selectedCategory === -1;
    return (
      <div style={{  height: "100vh",     whiteSpace: 'nowrap'}}>
        {isSmallScreen ? (
          <MobileNavbar user={this.props.userInfo} />
        ) : (
          <UserNavbar user={this.props.userInfo} />
        )}
        <div
          style={{
            whiteSpace: 'nowrap',
            display: "flex",
            overflow: "hidden",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100vw",
            // backgroundColor: "red",
          }}
        >
          {showProductDetailsPage && this.renderProductDetailsPage()}
          <h1 style={{ fontSize: 20, marginBottom: 20, marginTop: 30 }}>
            What are you swapping?
          </h1>
          {newIcons.map((item, index) => (
            <div
              onClick={() => this.handleCategory(index, newIcons)}
              style={{
                backgroundColor:
                  this.state.selectedCategory === index ? "red" : "white",
                  color:   this.state.selectedCategory === index ? "white" : "black",
                marginBottom: 4,
                height: "4vh",
                display: "flex",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: isSmallScreen ? "70%" : "40%",
                borderWidth: 1,
                borderColor: "#757575",
                borderStyle: "solid",
                borderRadius: 20,
                marginRight: 20,
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              {/* {item.icon} */}
              {item.name}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              // height: "40vh",
              minHeight: 250,
              // minWidth:300,
              width: '90vw',
              borderWidth: 1,
              borderColor: "#757575",
              borderStyle: "solid",
              borderRadius: 20,
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
            // onClick={() => }
            onDrag={(e) => console.log("[e]", e)}
          >
            <h1 style={{ fontSize: 25, marginBottom: 2 }}>
              {this.state.selectedCategory !== -1 &&
                newIcons[this.state.selectedCategory].name}
            </h1>
            {this.state.images.length !== 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginBottom: 10,
                  maxWidth: "20vw",
                  overflowX: "scroll",
                  overflowY: "hidden",
                  // borderColor: "#757575",
                  // borderStyle: "solid",
                  // borderWidth: 1,
                }}
              >
                {this.state.images.map((photo, index) => (
                  <img
                    src={photo.tempUrl}
                    alt={`preview-${index}`}
                    style={{
                      width: 70,
                      borderRadius: 20,
                      marginRight: 5,
                      height: 70,
                      margin: 10,
                    }}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <IoMdImage
                  size={70}
                  style={{ color: "#757575", marginBottom: 10 }}
                />
              </div>
            )}
            <button
              style={{
                opacity: showProductDetailOpacity ? 0.5 : 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 30,
                marginBottom:15,
                borderRadius: 30,
                background: "green",
                outline: "none",
              }}
              disabled={showProductDetailOpacity}
              onClick={() =>
                this.setState({
                  showProductDetailsPage: !this.state.showProductDetailsPage,
                })
              }
            >
              <p style={{ margin: 10, color: "white", fontSize: 18 }}>
                Add product details
              </p>
            </button>
            <div
              style={{
                position: "fixed",
                bottom: "2vh",
                display: "flex",
                borderRadius: 50,
                height: "5vh",
                minHeight: 40,
                shadowBox: "5px 10px",
                backgroundColor: "red",
                opacity: 0.8,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  display: "flex",
                  alignSelf: "center",
                  position: "absolute",
                  color: "white",
                }}
              >
                Select listings
              </h1>
              <input
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  alignSelf: "center",
                  opacity: 0,
                  // marginLeft: "30%",
                  // marginTop: 30,
                }}
                type="file"
                multiple
                max={5}
                accept="image/jpg, image/png, image/jpeg"
                onChange={this.handleSelectedAsset}
              />
            </div>
          </div>
        </div>
      </div>
      // <div className="art-selection" style={{ height: "100vh" }}>
      //     <img src={ListingUploadImg} alt="mobile logo"  style={{position:'absolute', zIndex:-1, opacity:0.1, width:this.state.isSmallScreen? "50vh" : '50vw', height:'100%'}}/>
      //   <div className="component-title">
      //     <h1>Upload Type</h1>
      //   </div>
      //   <div
      //     className="art-selection-group"
      //     style={{
      //       height: this.state.isSmallScreen ? "25vh" : "45vh",
      //       // width: this.state.isSmallScreen ? "100vw" : "10vw",
      //     }}
      //   >
      //     <div
      //     style={{ width: this.state.isSmallScreen ? "100vw" : 340}}
      //       name="single"
      //       onClick={() => handleSelection("single")}
      //       className={
      //         !isCollection
      //           ? `art-selection-group--item art-selected`
      //           : "art-selection-group--item"
      //       }
      //     >
      //       <h2>Upload Single Item</h2>
      //       <h5>Upload a single work of art.</h5>
      //     </div>
      //     {!this.state.isSmallScreen && (
      //       <div
      //         style={{ height: "40vh", width: "50vw", marginLeft: "10rem", opacity:1 }}
      //         name="collection"
      //         onClick={() => handleSelection("collection")}
      //         className={
      //           isCollection
      //             ? `art-selection-group--item art-selected`
      //             : "art-selection-group--item"
      //         }
      //       >
      //         <h2>Upload Collection</h2>
      //         <h5>
      //           Upload a collection of work. After uploading, under your account
      //           settings, you can edit each item at will.*
      //           <br />
      //           <br />
      //           <i>
      //             *Each image uploaded will become a single item by default.
      //             Additionally, any entered information will display the same
      //             way on all uploaded items until edited.
      //           </i>
      //         </h5>
      //       </div>
      //     )}
      //   </div>
      //   <div className="btn-container">
      //     <button onClick={nextFrame} style={{marginTop:this.state.isSmallScreen ? 40 : 0}}>
      //       Upload {!isCollection ? "Single Item" : "Collection"} &nbsp;&rarr;
      //     </button>
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, actions)(ArtSelection);
