import React, { Component } from "react";
import Address from "../../FormInput/Address";

// const addressInfo = {
//   locality: "city",
//   postal_code: "zipcode",
//   administrative_area_level_1: "state",
//   country: "country"
// };

class AssetFormAddress extends Component {

  onChange = (values) => {
    this.props.editAddress(values.formatted_address)
  }

  render() {
    return (
      <div className="add-asset__template__box">
        <div className="add-asset__template__label">Address</div>
        <Address onPlaceLoaded={this.onChange} />
      </div>
    );
  }
}

export default AssetFormAddress;
