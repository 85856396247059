import React, { Component } from "react";
import NewForm from "../Menu/NewForm";
const assets = ["art", "realestate", "boat", "auto", "collectible", "aircraft"];

class ChooseAsset extends Component {
  state = {
    formFrame: 1,
    assetChosen: ""
  };

  chooseAssetType = e => {
    console.log(e.target.name);
    this.setState({
      formFrame: 2,
      assetChosen: e.target.name
    });
  };

  render() {
    console.log(this.state);
    const styles = { margin: "3rem", border: "2px solid black" };
    return this.state.formFrame === 1 ? (
      <div>
        <button
          style={styles}
          name="art"
          onClick={e => this.chooseAssetType(e)}
        >
          Art
        </button>
        <button
          style={styles}
          name="realestate"
          onClick={e => this.chooseAssetType(e)}
        >
          Real Estate
        </button>
        <button
          style={styles}
          name="boat"
          onClick={e => this.chooseAssetType(e)}
        >
          Boat
        </button>
        <button
          style={styles}
          name="auto"
          onClick={e => this.chooseAssetType(e)}
        >
          Auto
        </button>
        <button
          style={styles}
          name="collectible"
          onClick={e => this.chooseAssetType(e)}
        >
          Collectible
        </button>
        <button
          style={styles}
          name="aircraft"
          onClick={e => this.chooseAssetType(e)}
        >
          Aircraft
        </button>
      </div>
    ) : (
      <NewForm asset={this.state.assetChosen} />
    );
  }
}

export default ChooseAsset;
