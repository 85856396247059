import React, { Component } from "react";
import Layout from "./Layout";
import Wrapper from "./Wrapper";
import { categories, listing_conditions, awsConfig } from "./../../utility/constants";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactS3 from 'react-aws-s3';
const options = {
  s3Url: awsConfig.productImageURLPrefix,
  bucketName: awsConfig.productImageBucket,
  region: awsConfig.region,
  accessKeyId: awsConfig.accessKey,
  secretAccessKey: awsConfig.secretKey,
};
const animatedComponents = makeAnimated();
const S3Upload = new ReactS3(options);

class EditListing extends Component {
  state = {
    title: "",
    imagesUpdated: false,
    s3images: []
  };

  handleUploadState = (name, evt) => {
    const {
      target: { value }
    } = evt;
    if (name === "value") {
      let formatNumber = parseInt(value.replace(/,/gi, "")).toLocaleString();
      this.setState({
        value: parseInt(value),
        displayedValue: formatNumber
      });
    }

    this.setState({
      [name]: value
    });
  };

  setCondition = condition => {
    this.setState({ condition: condition });
  };

  setPreferences = pref => {
    console.log(pref);
    this.setState({
      preferences: pref
    });
  };

  getListingInformation = () => {
    axios
      .get(
        `https://api-dev.bazaarxchanges.com/api/v2/listings/${this.props.match.params.id}`
      )
      .then(async res => {
        let listingPreferences = res.data.preferences;
        let listingCategory = res.data.category;
        let listingCondition = res.data.condition;

        let selectedCategory = [...categories].filter(
          x => x.value === listingCategory
        );
        let selectedPreferences = [...categories].filter(x =>
          listingPreferences.includes(x.value)
        );
        let selectedCondition = [...listing_conditions].filter(
          x => x.value === listingCondition
        );

        let data = {
          ...res.data,
          category: selectedCategory,
          preferences: selectedPreferences,
          condition: selectedCondition
        }

        await this.setState(data);
        await console.log(this.state);
      })
      .catch(error => console.log(error));
  };

  removePhoto = item => {
    let updatedImages = this.state.images.filter(x => x !== item);
    this.setState({
      images: updatedImages
    });
  };

  imagesSelectedHandler = evt => {
    const images = Array.from(evt.target.files);
    [...images].map(
      (file, index, arr) =>
        (images[index].tempUrl = URL.createObjectURL(arr[index]))
    );
    console.log(images);
    this.setState({
      images,
      imagesUpdate: !this.state.imagesUpdated
    });
  };

  submitEdit = async () => {    
    if (this.state.imagesUpdate) {
      this.state.images.map(image => {
        S3Upload
          .uploadFile(image, image.name)
          .then(data => this.setState({ s3images: [...this.state.s3images, data]}))
          .catch(err => console.log(err));
      });
      console.log(this.state);
    }
  }

  componentDidMount() {
    this.getListingInformation();
  }

  render() {
    return (
      <Layout>
        <Wrapper>
          <div className="info-container">
            <div className="info-container__title">
              <h2>Edit Details</h2>
              {
                <h3>
                  {this.state.inCollection ? "Collection" : "Single Listing"}
                </h3>
              }
            </div>
            <div className="component-title">
              <h1>Image(s)</h1>
              <form>
                <input
                  multiple
                  type="file"
                  placeholder="Upload Here"
                  onChange={this.imagesSelectedHandler}
                />
              </form>
              <div className="image-preview">
                {this.state.images &&
                  this.state.images.map((photo, index) => (
                    <div className="image-item" key={index}>
                      <img src={photo.tempUrl || photo} alt={`preview-${index}`} style={{ height: '12rem', width: '12rem', objectFit: 'cover', margin: '2rem 0rem'}} />
                      <p onClick={() => this.removePhoto(photo)}>Remove</p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="info-container__form-container">
              <form className={"form-height-std"}>
                <input
                  type="text"
                  placeholder="Add Title"
                  value={this.state.title}
                  onChange={e => this.handleUploadState("title", e)}
                />
                <input
                  type="number"
                  placeholder="Add Title"
                  value={this.state.estimated_value || 0}
                  onChange={e => this.handleUploadState("estimated_value", e)}
                />
                <Select
                  value={this.state.condition}
                  onChange={this.setCondition}
                  options={listing_conditions}
                  placeholder={"Select a Condition"}
                  className="condition__filter"
                  classNamePrefix="react-select"
                />
                <Select
                  value={this.state.preferences}
                  onChange={this.setPreferences}
                  components={animatedComponents}
                  options={categories}
                  className="condition__filter"
                  classNamePrefix="react-select"
                  isMulti
                />
                <textarea
                  type="text"
                  placeholder="Add Description"
                  onChange={e => this.handleUploadState("description", e)}
                  value={this.state.description}
                />
              </form>
              <div className="btn-container">
                <button onClick={this.submitEdit}>Update Listing &nbsp;&rarr;</button>
              </div>
            </div>
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

export default EditListing;
