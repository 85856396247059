import React from "react";
import Wrapper from "../Layout/Wrapper";
import Layout from "../Layout/Layout";

const Privacy = () => {
  return (
    <Layout>
      <Wrapper>
        <div className="policy">
          <div className="heading">Privacy Policy</div>
          <div className="content">
            This privacy policy discloses the privacy practices for
            www.bazaarxchanges.com. This privacy policy applies solely to
            information collected by this web site. It will notify you of the
            following:
          </div>
          <div className="content">
            What personally identifiable information is collected from you
            through the web site, how it is used and with whom it may be shared.
          </div>
          <div className="content">
            What choices are available to you regarding the use of your data.
          </div>
          <div className="content">
            The security procedures in place to protect the misuse of your
            information.
          </div>
          <div className="content">
            How you can correct any inaccuracies in the information.
          </div>
          <div className="content">
            Personal data received or transferred to our website is subject to
            the regulatory enforcement powers of the U.S. Federal Trade
            Commission. In certain situations, we may be required to disclose
            personal data in response to lawful requests by public authorities,
            including to meet national security or law enforcement requirements.
          </div>
          <div className="title">INFORMATION COLLECTION, USE, AND SHARING</div>
          <div className="content">
            The user recognizes that non-public information concerning the user
            disclosed by the user to AllocateRite (“the Company”), or other
            agents of either party (the “information”) (such as the user’s name,
            company name, address, assets under management, type of business,
            etc.) (i) may be disclosed to the general partner, attorneys,
            accountants and auditors in furtherance of the Company’s business
            and to other service providers such as brokers who may have a need
            for the information in connection with providing services to the
            company, (ii) to third party service providers or financial
            institutions who may be providing marketing services to the funds
            provided that such persons must agree to protect the confidentiality
            of the information and use the information only for the purposes of
            providing services to the Company and (iii) as otherwise required or
            permitted by law. The Company restricts access to the information to
            their employees who need to know the information to provide services
            to the Company, and maintain physical, electronic and procedural
            safeguards that comply with U.S. federal standards to guard the
            information.
          </div>
          <div className="title">
            YOUR ACCESS TO AND CONTROL OVER INFORMATION
          </div>
          <div className="content">
            You may opt out of any future contacts from us at any time. You can
            do the following at any time by contacting us via the email address
            or phone number provided on our website:
          </div>
          <div className="content">
            See what data we have about you, if any.
          </div>
          <div className="content">
            Change/correct any data we have about you.
          </div>
          <div className="content">
            Have us delete any data we have about you.
          </div>
          <div className="content">
            Express any concern you have about our use of your data.
          </div>
          <div className="title">REGISTRATION</div>
          <div className="content">
            In order to use this website, a user must first complete the
            registration form. During registration a user is required to give
            certain information (such as name and email address). This
            information is used to contact you about the products/services on
            our site in which you have expressed interest.
          </div>
          <div className="title">DURATION</div>
          <div className="content">
            We will retain your personal information for as long as is necessary
            and as permitted by applicable laws.
          </div>
          <div className="title">YOUR RIGHTS</div>
          <div className="content">
            We always will enable you to exercise your rights as provided by
            data protection laws.
          </div>
          <div className="content">
            Depending on the laws of the country where you live, you may have
            other rights in respect of your personal information. If you wish to
            exercise any legal right in respect of your personal information,
            please contact us.
          </div>
          <div className="content">
            In some cases, we may be entitled to decline a request you have made
            regarding your personal information, in accordance with application
            laws. We will explain if that is the case. Otherwise, we will meet
            your request as promptly as we reasonably can. If you have requested
            that we stop sending you marketing messages, please note that you
            may still receive them for a short period while the request is
            processed.
          </div>
          <div className="title">COOKIES</div>
          <div className="content">
            This site uses cookies and other tracking technologies to assist
            with navigation, use, authority, accessing content, and website use
            practices.
          </div>
          <div className="title">WHAT ARE COOKIES?</div>
          <div className="content">
            Cookies are small tracking files that are stored by the on your
            computer or mobile phone. They allow websites to store user habits
            and preferences.
          </div>
          <div className="content">
            Some cookies are required for users to be able to log in to our
            sites.
          </div>
          <div className="content">
            We use “cookies” on this site. A cookie is a piece of data stored on
            a site visitor’s hard drive to help us improve your access to our
            site and identify repeat visitors to our site. For instance, when we
            use a cookie to identify you, you would not have to log in a
            password more than once, thereby saving time while on our site.
            Cookies can also enable us to track and target the interests of our
            users to enhance the experience on our site. Usage of a cookie is in
            no way linked to any personally identifiable information on our
            site. Some of our business partners may use cookies on our site (for
            example, advertisers). However, we have no access to or control over
            these cookies.
          </div>
          <div className="title">LINKS</div>
          <div className="content">
            This web site may contain links to other sites. Please be aware that
            we are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </div>
          <div className="title">SECURITY</div>
          <div className="content">
            We take precautions to protect your information. When you submit
            sensitive information via the website, your information is protected
            both online and offline.
          </div>
          <div className="content">
            Wherever we collect sensitive information, that information is
            encrypted and transmitted to us in a secure way. You can verify this
            by looking for a closed lock icon at the bottom of your web browser,
            or looking for “https” at the beginning of the address of the web
            page.
          </div>
          <div className="content">
            While we use encryption to protect sensitive information transmitted
            online, we also protect your information offline. Only employees who
            need the information to perform a specific job (for example, billing
            or customer service) are granted access to personally identifiable
            information. The computers/servers in which we store personally
            identifiable information are kept in a secure environment.
          </div>
          <div className="title">UPDATES</div>
          <div className="content">
            Our Privacy Policy may change from time to time and all updates will
            be posted on this page.
          </div>
          <div className="content">
            If you feel that we are not abiding by this privacy policy, you
            should contact our customer support department immediately via
            telephone at (212) 995-9191 or via email at&nbsp;
            <a
              href="mailto:support@bazaarxchanges.com"
              style={{ color: "blue" }}
            >
              support@bazaarxchanges.com
            </a>
            .
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Privacy;
