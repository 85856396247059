import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AccountSettingsInput from "../AccountSettingsInput";
import AccountSettingsLayout from "../AccountSettingsLayout";

const renderFields = [
  {
    label: "Current Password",
    type: "password",
    name: "currentPassword",
    placeholder: "*Enter current password*"
  },
  {
    label: "New Password",
    type: "password",
    name: "newPassword",
    placeholder: "*Enter new password*"
  },
  {
    label: "Confirm New Password",
    type: "password",
    name: "confirmPassword",
    placeholder: "*Confirm password*"
  }
];

class AccountSettingsPassword extends Component {
  state = {
    loading: false
  };

  onPasswordChange = values => {
    const { userEmail, userUpdatePassword } = this.props;
    const userInfo = { ...values, email: userEmail };
    userUpdatePassword(userInfo);

    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);
  };

  render() {
    const { userUpdatePasswordError } = this.props;
    const { loading } = this.state
    return (
      <AccountSettingsLayout>
        <div className="accountsettings__account">
          <div className="accountsettings__title">Password Settings</div>
          <form onSubmit={this.props.handleSubmit(this.onPasswordChange)}>
            {renderFields.map(({ label, type, name, placeholder }) => {
              return (
                <AccountSettingsInput
                  key={label}
                  label={label}
                  type={type}
                  name={name}
                  placeholder={placeholder}
                />
              );
            })}
            <div className="accountsettings__account__error">
              {userUpdatePasswordError}
            </div>
            <div className="center-flex">
              <button className="btn accountsettings__assets__edit__form__btn">
                <div className="accountsettings__assets__edit__form__btn__label">
                  Save
                </div>
                {loading && (
                  <div className="accountsettings__assets__edit__form__btn__spinner">
                    <i className="fas fa-spinner fa-spin" />
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </AccountSettingsLayout>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = ["currentPassword", "newPassword", "confirmPassword"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Please enter a value`;
    }
  });

  if (values["newPassword"] !== values["confirmPassword"]) {
    errors["confirmPassword"] = "Please make sure your passwords match";
  }
  return errors;
}

const mapStateToProps = state => {
  return {
    userEmail: state.auth.userInfo.email,
    userUpdatePasswordError: state.auth.userUpdatePasswordError
  };
};

export default reduxForm({ validate, form: "updateUserPassword" })(
  connect(
    mapStateToProps,
    actions
  )(AccountSettingsPassword)
);
