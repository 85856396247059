import React, { Component } from "react";
import bchainLogo from "../../images/bchain-logo.png";

class ValidateFormConfirm extends Component {
  render() {
    const { userDocument, userImage, firstName, lastName } = this.props.formFields;
    return (
      <div className="form">
        <button
          className="form__close form__back validate__back"
          onClick={this.props.onFormBackward}
        >
          &#8617;
        </button>
        <img className="form__logo" src={bchainLogo} alt="logo" />

        <div className="validate__field">
          <span className="validate__field__title">Full Legal Name: </span>
          {firstName} {lastName}
        </div>

        {userDocument ? (
          <div className="validate__photo">
            <img
              className="validate__photo__img"
              src={URL.createObjectURL(userDocument)}
              alt="user document"
            />
            <div>
              <img
                className="validate__photo__img"
                src={URL.createObjectURL(userImage)}
                alt="user pic"
              />
            </div>
          </div>
        ) : null}

        <div className="validate__confirmation">
          ***Please confirm all the fields are correct before submitting.***
        </div>

        <button className="form__button validate__btn" onClick={this.props.onFormSubmit}>
          Submit For Review
        </button>
      </div>
    );
  }
}

export default ValidateFormConfirm;
