import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { registerFormFields } from "../../formFields/formFields";
import * as actions from "../../redux/actions";
import FormInput from "../FormInput/FormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import PhoneNumber from "../FormInput/PhoneNumber";
import GoogleLogo from "../../images/google-logo.png";
import bchainLogo from "../../images/bx-logo-main-white.png";
import { GoogleLogin } from 'react-google-login'

class RegisterForm extends Component {

  removeFormatPrice = price => {
    return "+" + price.replace(/[^0-9]/g, "");
  };

  onRegister = values => {
    //const phoneNumber = this.removeFormatPrice(values.phoneNumber);
    //const newValues = { ...values, phoneNumber };
    console.log(values)

    this.props.userRegister(values, () => this.redirection('email'));
  };

  redirection =  (provider) => {
    if (provider === 'email') {
      const path = this.props.redirectPath || "/login";
      this.props.history.push(path);
    } else {
      const path = this.props.redirectPath || "/";
      this.props.history.push(path);
    }
    this.props.reset();
  }

  onGoogleSignInSuccess = result => {
    const{Ad, QK, yu, MU} = result.Pt
    let userData = {
      email: yu,
      name: Ad,
      avatar_image: QK,
      method: 'OAuth',
      provider: 'google',
      password: MU, 
      _id: MU
    }
    
    this.props.userLoginWithSocialMedia(userData, () => this.redirection('google'))
  }

  onGoogleSignInFailure = result => {
    // console.log(result)
  }

  render() {
    const options = [
      {
        display: "Account Type",
        disable: true,
        value: ""
      },
      {
        display: "Individual",
        disable: false,
        value: "individual"
      },
      {
        display: "Company",
        disable: false,
        value: "company"
      }
    ];

    const countryCodes = [
      { display: "Select Country", disable: true, value: "" },
      { display: "Albania", disable: false, value: "AL" },
      { display: "American Samoa", disable: false, value: "AS" },
      { display: "Andorra", disable: false, value: "AD" },
      { display: "Anguilla", disable: false, value: "AI" },
      { display: "Antarctica", disable: false, value: "AQ" },
      { display: "Antigua and Barbuda", disable: false, value: "AG" },
      { display: "Argentina", disable: false, value: "AR" },
      { display: "Armenia", disable: false, value: "AM" },
      { display: "Aruba", disable: false, value: "AW" },
      { display: "Australia", disable: false, value: "AT" },
      { display: "Bahamas", disable: false, value: "BS" },
      { display: "Bahrain", disable: false, value: "BH" },
      { display: "Bangladesh", disable: false, value: "BD" },
      { display: "Barbados", disable: false, value: "BB" },
      { display: "Belarus", disable: false, value: "BY" },
      { display: "Belgium", disable: false, value: "BE" },
      { display: "Belize", disable: false, value: "BZ" },
      { display: "Benin", disable: false, value: "BJ" },
      { display: "Bermuda", disable: false, value: "BM" },
      { display: "Bhutan", disable: false, value: "BT" },
      {
        display: "Bolivia, Plurinational State o",
        disable: false,
        value: "BO"
      },
      {
        display: "Bonaire, Sint Eustatius and Saba",
        disable: false,
        value: "BQ"
      },
      { display: "Bosnia and Herzegovina", disable: false, value: "BA" },
      { display: "Botswana", disable: false, value: "BW" },
      { display: "Bouvet Island", disable: false, value: "BV" },
      { display: "Brazil", disable: false, value: "BR" },
      {
        display: "British Indian Ocean Territory",
        disable: false,
        value: "IO"
      },
      { display: "Brunei Darussalam", disable: false, value: "BN" },
      { display: "Bulgaria", disable: false, value: "BG" },
      { display: "Cambodia", disable: false, value: "KH" },
      { display: "Cameroon", disable: false, value: "CM" },
      { display: "Canada", disable: false, value: "CA" },
      { display: "Cape Verde", disable: false, value: "CV" },
      { display: "Cayman Islands", disable: false, value: "KY" },
      { display: "Chad", disable: false, value: "TD" },
      { display: "Chile", disable: false, value: "CL" },
      { display: "China", disable: false, value: "CN" },
      { display: "Christmas Island", disable: false, value: "CX" },
      { display: "Colombia", disable: false, value: "CO" },
      { display: "Comoros", disable: false, value: "KM" },
      { display: "Cook Islands", disable: false, value: "CK" },
      { display: "Costa Rica", disable: false, value: "CR" },
      { display: "Côte d'Ivoire", disable: false, value: "CI" },
      { display: "Croatia", disable: false, value: "HR" },
      { display: "Curaçao", disable: false, value: "CW" },
      { display: "Cyprus", disable: false, value: "CY" },
      { display: "Czech Republic", disable: false, value: "CZ" },
      { display: "Denmark", disable: false, value: "DK" },
      { display: "Djibouti", disable: false, value: "DJ" },
      { display: "Dominica", disable: false, value: "DM" },
      { display: "Dominican Republic", disable: false, value: "DO" },
      { display: "Ecuador", disable: false, value: "EC" },
      { display: "Egypt", disable: false, value: "EG" },
      { display: "El Salvador", disable: false, value: "SV" },
      { display: "Equatorial Guinea", disable: false, value: "GQ" },
      { display: "Eritrea", disable: false, value: "ER" },
      { display: "Estonia", disable: false, value: "EE" },
      { display: "Ethiopia", disable: false, value: "ET" },
      { display: "Falkland Islands", disable: false, value: "FK" },
      { display: "Faroe Islands", disable: false, value: "FO" },
      { display: "Fiji", disable: false, value: "FJ" },
      { display: "Finland", disable: false, value: "FI" },
      { display: "France", disable: false, value: "FR" },
      { display: "French Guyana", disable: false, value: "GF" },
      { display: "French Polynesia", disable: false, value: "PF" },
      { display: "Gabon", disable: false, value: "GA" },
      { display: "Gambia", disable: false, value: "GM" },
      { display: "Georgia", disable: false, value: "GE" },
      { display: "Germany", disable: false, value: "DE" },
      { display: "Ghana", disable: false, value: "GH" },
      { display: "Gibraltar", disable: false, value: "GI" },
      { display: "Greece", disable: false, value: "GR" },
      { display: "Greenland", disable: false, value: "GL" },
      { display: "Grenada", disable: false, value: "GD" },
      { display: "Guadeloupe", disable: false, value: "GP" },
      { display: "Guam", disable: false, value: "GU" },
      { display: "Guatemala", disable: false, value: "GT" },
      { display: "Guernsey", disable: false, value: "GG" },
      { display: "Guinea", disable: false, value: "GN" },
      { display: "Guinea-Bissau", disable: false, value: "GW" },
      { display: "Guyana", disable: false, value: "GY" },
      { display: "Haiti", disable: false, value: "HT" },
      { display: "Honduras", disable: false, value: "HN" },
      { display: "Hong Kong", disable: false, value: "HK" },
      { display: "Hungary", disable: false, value: "HU" },
      { display: "Iceland", disable: false, value: "IS" },
      { display: "India", disable: false, value: "IN" },
      { display: "Indonesia", disable: false, value: "ID" },
      { display: "Ireland", disable: false, value: "IE" },
      { display: "Isle of Man", disable: false, value: "IM" },
      { display: "Israel", disable: false, value: "IL" },
      { display: "Italy", disable: false, value: "IT" },
      { display: "Jamaica", disable: false, value: "JM" },
      { display: "Japan", disable: false, value: "JP" },
      { display: "Jersey", disable: false, value: "JE" },
      { display: "Jordan", disable: false, value: "JO" },
      { display: "Kazakhstan", disable: false, value: "KZ" },
      { display: "Kenya", disable: false, value: "KE" },
      { display: "Kiribati", disable: false, value: "KI" },
      { display: "South Korea", disable: false, value: "KP" },
      { display: "Kuwait", disable: false, value: "KW" },
      { display: "Kyrgyzstan", disable: false, value: "KG" },
      { display: "Laos", disable: false, value: "LA" },
      { display: "Latvia", disable: false, value: "LV" },
      { display: "Lesotho", disable: false, value: "LS" },
      { display: "Liechtenstein", disable: false, value: "LI" },
      { display: "Lithuania", disable: false, value: "LT" },
      { display: "Luxembourg", disable: false, value: "LU" },
      { display: "Macao", disable: false, value: "MO" },
      { display: "Macedonia", disable: false, value: "MK" },
      { display: "Madagascar", disable: false, value: "MG" },
      { display: "Malawi", disable: false, value: "MW" },
      { display: "Malaysia", disable: false, value: "MY" },
      { display: "Maldives", disable: false, value: "MV" },
      { display: "Mali", disable: false, value: "ML" },
      { display: "Malta", disable: false, value: "MT" },
      { display: "Marshall Islands", disable: false, value: "MH" },
      { display: "Martinique", disable: false, value: "MQ" },
      { display: "Mauritania", disable: false, value: "MR" },
      { display: "Mauritius", disable: false, value: "MU" },
      { display: "Mayotte", disable: false, value: "YT" },
      { display: "Mexico", disable: false, value: "MX" },
      {
        display: "Micronesia, Federated States of",
        disable: false,
        value: "FM"
      },
      { display: "Moldova", disable: false, value: "MD" },
      { display: "Monaco", disable: false, value: "MC" },
      { display: "Mongolia", disable: false, value: "MN" },
      { display: "Montenegro", disable: false, value: "ME" },
      { display: "Montserrat", disable: false, value: "MS" },
      { display: "Morocco", disable: false, value: "MA" },
      { display: "Mozambique", disable: false, value: "MZ" },
      { display: "Myanmar", disable: false, value: "MM" },
      { display: "Namibia", disable: false, value: "NA" },
      { display: "Nauru", disable: false, value: "NR" },
      { display: "Nepal", disable: false, value: "NP" },
      { display: "Netherlands", disable: false, value: "NL" },
      { display: "New Caledonia", disable: false, value: "NC" },
      { display: "New Zealand", disable: false, value: "NZ" },
      { display: "Nicaragua", disable: false, value: "NI" },
      { display: "Niger", disable: false, value: "NE" },
      { display: "Nigeria", disable: false, value: "NG" },
      { display: "Niue", disable: false, value: "NU" },
      { display: "Norfolk Island", disable: false, value: "NF" },
      { display: "Northern Mariana Islands", disable: false, value: "MP" },
      { display: "Norway", disable: false, value: "NO" },
      { display: "Oman", disable: false, value: "OM" },
      { display: "Pakistan", disable: false, value: "PK" },
      { display: "Palau", disable: false, value: "PW" },
      { display: "Panama", disable: false, value: "PA" },
      { display: "Papa New Guinea", disable: false, value: "PG" },
      { display: "Paraguay", disable: false, value: "PY" },
      { display: "Peru", disable: false, value: "PE" },
      { display: "Philippines", disable: false, value: "PH" },
      { display: "Pitcairn", disable: false, value: "PN" },
      { display: "Poland", disable: false, value: "PL" },
      { display: "Portugal", disable: false, value: "PT" },
      { display: "Puerto Rico", disable: false, value: "PR" },
      { display: "Qatar", disable: false, value: "QA" },
      { display: "Réunion", disable: false, value: "RE" },
      { display: "Romania", disable: false, value: "RO" },
      { display: "Rwanda", disable: false, value: "RW" },
      { display: "Saint Barthélemy", disable: false, value: "BL" },
      {
        display: "Saint Helena, Ascension and Tristan da Cunha",
        disable: false,
        value: "SH"
      },
      { display: "Saint Kitts and Nevis", disable: false, value: "KN" },
      { display: "Saint Lucia", disable: false, value: "LC" },
      { display: "Saint Martin (French)", disable: false, value: "MF" },
      { display: "Saint Pierre and Miquelon", disable: false, value: "PM" },
      {
        display: "Saint Vincent and the Grenadines",
        disable: false,
        value: "VC"
      },
      { display: "Samoa", disable: false, value: "WS" },
      { display: "San Marino", disable: false, value: "SM" },
      { display: "Sao Tome and Principe", disable: false, value: "ST" },
      { display: "Saudi Arabia", disable: false, value: "SA" },
      { display: "Senegal", disable: false, value: "SN" },
      { display: "Serbia", disable: false, value: "RS" },
      { display: "Seychelles", disable: false, value: "SC" },
      { display: "Sierra Leone", disable: false, value: "SL" },
      { display: "Singapore", disable: false, value: "SG" },
      { display: "Saint Maarten (Dutch)", disable: false, value: "SX" },
      { display: "Slovakia", disable: false, value: "SK" },
      { display: "Slovenia", disable: false, value: "SI" },
      { display: "Solomon Islands", disable: false, value: "SB" },
      { display: "South Africa", disable: false, value: "ZA" },
      { display: "Spain", disable: false, value: "ES" },
      { display: "Sri Lanka", disable: false, value: "LK" },
      { display: "Suriname", disable: false, value: "SR" },
      { display: "Swaziland", disable: false, value: "SZ" },
      { display: "Sweden", disable: false, value: "SE" },
      { display: "Switzerland", disable: false, value: "CH" },
      { display: "Taiwan", disable: false, value: "TW" },
      { display: "Rwanda", disable: false, value: "RW" },
      { display: "Tajikistan", disable: false, value: "TJ" },
      { display: "Tanzania", disable: false, value: "TZ" },
      { display: "Thailand", disable: false, value: "TH" },
      { display: "Timor-Leste", disable: false, value: "TL" },
      { display: "Togo", disable: false, value: "TG" },
      { display: "Tokelau", disable: false, value: "TK" },
      { display: "Tonga", disable: false, value: "TO" },
      { display: "Trinidad and Tobago", disable: false, value: "TT" },
      { display: "Tunisia", disable: false, value: "TN" },
      { display: "Turkey", disable: false, value: "TR" },
      { display: "Turkmenistan", disable: false, value: "TM" },
      { display: "Turks and Caicos Islands", disable: false, value: "TC" },
      { display: "Tuvalu", disable: false, value: "TV" },
      { display: "Uganda", disable: false, value: "UG" },
      { display: "United Arab Emirates", disable: false, value: "AE" },
      { display: "United Kingdom", disable: false, value: "GB" },
      { display: "United States", disable: false, value: "US" },
      {
        display: "United States Minor Outlying Islands",
        disable: false,
        value: "UM"
      },
      { display: "Uruguay", disable: false, value: "UY" },
      { display: "Uzbekistan", disable: false, value: "UZ" },
      { display: "Vanuatu", disable: false, value: "VU" },
      { display: "Vietnam", disable: false, value: "VN" },
      { display: "Virgin Islands, British", disable: false, value: "VG" },
      { display: "Virgin Islands, U.S.", disable: false, value: "VI" },
      { display: "Zambia", disable: false, value: "ZM" },
      { display: "Zimbabwe", disable: false, value: "ZW" }
    ];

    const style = {
      lineHeight: "2rem"
    };

    return (
      <div className="form">
        <Link to="/" className="form__close register__form-close">
          &times;
        </Link>
        <Link to="/">
          <img
            className="form__logo register__form-logo"
            src={bchainLogo}
            alt="logo"
          />
        </Link>
        <div className="register__form-header">
          <h3>Create an account</h3>
          <h5>
            Already have an account?&nbsp;&nbsp;<a href="/login">Login</a>
          </h5>
        </div>
        <form onSubmit={this.props.handleSubmit(this.onRegister)}>
          {/* <div className="register__form-select">
            <SelectFormInput 
              {...{name: "accountType", label: "Account Type", type:"text" , options: options}} 
              key={"a key"}
            />
            <SelectFormInput 
              {...{ name: 'country', label: 'Country', type: 'text', options: countryCodes }}
              key={'countries'}
            />
          </div> */}
          {/* {registerFormFields.map((field, idx) => {
              if (field.name === "phoneNumber") {
                return <PhoneNumber {...field} key={idx} />;
              } else if (field.label) {
                return (
                  <div className="register__set set-inline">
                    <div className="register__set-label">
                      <h3>{field.label}</h3>
                    </div>
                    <FormInput {...field} key={idx} />
                  </div>
                )
              } else {
                return (
                  <div className="register__set">
                    <div className="register__set-label">
                      <h3>{field.label}</h3>
                    </div>
                    <FormInput {...field} key={idx} />
                  </div>
                )
              }
            })
          } */}
          <div className="register__flex">
            <div className="register__set">
              <div className="register__set-label">
                <h3>{registerFormFields[0].label}</h3>
              </div>
              <FormInput {...registerFormFields[0]} />
            </div>
          </div>
          <div className="register__set">
            <div className="register__set-label">
              <h3>{registerFormFields[2].label}</h3>
            </div>
            <FormInput {...registerFormFields[2]} />
          </div>
          <div className="register__flex">
            <div className="register__set-half">
              <div className="register__set-label">
                <h3>{registerFormFields[4].label}</h3>
              </div>
              <FormInput {...registerFormFields[4]} />
            </div>
            <div className="register__set-half">
              <div className="register__set-label">
                <h3>{registerFormFields[5].label}</h3>
              </div>
              <FormInput {...registerFormFields[5]} />
            </div>
          </div>

          <div>{this.props.userRegisterError}</div>
          {/* Filler */}
          <div style={{ height: "1.6rem", width: "100%" }}></div>
          <button className="form__button form__button--register">
            Sign Up for Free
          </button>
          <GoogleLogin
            clientId="146862981436-t7qhl6ontpaldps1udd8oveh5d0vp0v9.apps.googleusercontent.com"
            render={renderProps => (
              <button className="form__button form__button--google"
                onClick={renderProps.onClick}
              >
                <img src={GoogleLogo} alt="google logo"/> Sign Up with Google
              </button>
            )}
            onSuccess={this.onGoogleSignInSuccess}
            onFailure={this.onGoogleSignInFailure}
            cookiePolicy={'single_host_origin'}
          />
          {/* <button className="form__button">Register</button> */}
          <br />
          <br />
          <div>
            <p style={style} className="register__terms">
              * By registering your account, you agree <br /> to our Services
              Agreement and the <br />{" "}
              <a
                href="https://stripe.com/connect-account/legal"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#088FF2" }}
              >
                Stripe Connected Account Agreement
              </a>
              .
            </p>
          </div>
        </form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = [
    "accountType",
    "userName",
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "confirmedPassword",
    "password",
    "title"
  ];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });

  if (values["confirmedPassword"] !== values["password"]) {
    errors["password"] = "Passwords don\'t match";
  }

  return errors;
}

const mapStateToProps = state => {
  return {
    userRegisterError: state.auth.userRegisterError
  };
};

export default reduxForm({ validate, form: "register" })(
  connect(mapStateToProps, actions)(withRouter(RegisterForm))
);
