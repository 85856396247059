import axios from "axios";
import {
  FETCH_USER_BCHAIN_ADD_ASSET,
  FETCH_USER_BCHAIN_ADD_ASSET_SUCCESS,
  FETCH_USER_BCHAIN_ADD_ASSET_FAILURE,
  USER_ADD_ASSET,
  USER_GET_ASSET,
  USER_GET_MY_ASSETS,
  USER_FIND_ASSET,
  USER_FIND_RELEVANT_ASSET,
  USER_MADE_PAYMENT,
  USER_UPDATE_ASSET,
  USER_GET_SINGLE_ASSET,
  USER_GET_CONNECTIONS_LIST,
  USER_TOGGLE_ASSET_FILTER,
  SEARCH_ASSET,
  UPDATE_QUERY,
  SEARCH_ASSETS_BY_USERID,
  DISPLAY_KEYWORD,
  GROUP_ASSETS,
  GET_ALL_ELASTIC_RESULTS,
  GET_ASSETS_BY_USER_ID,
  GET_ALL_ARTISTS,
  GET_LOCATION_AND_LISTINGS,
  FETCH_DELETE_LISTING,
} from "./types";
import { formatSearchQuery } from "../../helperFunctions/helperFunctions";

axios.defaults.withCredentials = true;

function assetUrl(api = "") {
  // const baseUrl = process.env.REACT_APP_API_ENDPOINT || "";

  // deployment url
  // return "https://api-dev.bazaarxchanges.com/api/v1/asset" + api;

  // local development
  return "https://api-dev.bazaarxchanges.com/api/v1/asset" + api;
}

const getUrl = (api) => {
  return 'https://api-dev.bazaarxchanges.com' + api;
  // return "http://localhost:4000" + api;
};

const userAddAsset = (values, pdfs, redirectPath) => async (dispatch) => {
  try {
    const assetType = JSON.parse(values.get("assetData")).assetType;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const addAsset = await axios.post(assetUrl("/"), values, { headers });
    if (pdfs) {
      if (Object.keys(pdfs).length) {
        const formData = new FormData();
        Object.keys(pdfs).forEach((pdf) => {
          formData.append("documents", pdfs[pdf]);
        });
        formData.append("assetId", addAsset.data.assetId);
        formData.append("assetType", assetType);
        await axios.post(assetUrl("/documents"), formData, { headers });
      }
    }

    dispatch({ type: USER_ADD_ASSET, payload: addAsset.data.assetId });
    redirectPath();
  } catch (error) {
    console.log(error);
    // const response = error.response.data;
    // return response;
  }
};

const userUpdateAsset = (assetId, values, pdfs, redirectPath) => async (
  dispatch
) => {
  try {
    const assetType = JSON.parse(values.get("assetData")).assetType;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const updateAsset = await axios.put(assetUrl(`/${assetId}`), values, {
      headers,
    });
    if (pdfs) {
      if (Object.keys(pdfs).length) {
        const formData = new FormData();
        Object.keys(pdfs).forEach((pdf) => {
          formData.append("documents", pdfs[pdf]);
        });

        formData.append("assetId", assetId);
        formData.append("assetType", assetType);
        await axios.post(assetUrl("/documents"), formData, { headers });
      }
    }
    dispatch({ type: USER_UPDATE_ASSET, payload: updateAsset.data.assetId });
    setTimeout(() => {
      redirectPath();
    }, 5000);
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

const userGetMyAssets = () => async (dispatch) => {
  try {
    const getMyAssets = await axios.get(assetUrl("/myAssets"));
    dispatch({ type: USER_GET_MY_ASSETS, payload: getMyAssets.data.data });
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

// Get assets by user id => Elastic Search Call
const getAssetsByUserId = (userId, redirectPath) => async (dispatch) => {
  try {
    const allAssets = await axios.post(assetUrl("/assetByUserId"), {
      userId: userId,
    });
    dispatch({ type: SEARCH_ASSETS_BY_USERID, payload: allAssets.data });
    setTimeout(() => {
      redirectPath();
    }, 500);
  } catch (error) {
    console.log(error);
  }
};

// Get assets by user id => Mongoose Call
const getUserAssets = (userId) => async (dispatch) => {
  try {
    const userIdAssets = await axios.get(assetUrl(`/${userId}`));
    dispatch({ type: GET_ASSETS_BY_USER_ID, payload: userIdAssets.data });
  } catch (error) {
    console.log(error);
  }
};

//Elastic Search
const getSearchResults = (keyWord, assetType, redirectPath) => async (
  dispatch
) => {
  try {
    //writing an asset-type specific query for each sub-schemas
    const queryBody = formatSearchQuery(assetType, keyWord);
    const results = await axios.post(assetUrl("/searchAssets"), queryBody);
    dispatch({ type: DISPLAY_KEYWORD, payload: keyWord });
    dispatch({ type: SEARCH_ASSET, payload: results.data });
    setTimeout(() => {
      redirectPath();
    }, 500);
  } catch (error) {
    console.log(error);
  }
};

//Navbar Category Search
const getCategoryResults = (subCategory, assetType, redirectPath) => async (
  dispatch
) => {
  try {
    let queryBody = {};
    if (subCategory === "All") {
      queryBody = {
        type: assetType,
        searchQuery: {
          from: 0,
          size: 9000,
          query: {
            match_all: {},
          },
        },
      };
    } else {
      queryBody = {
        type: assetType,
        searchQuery: {
          from: 0,
          size: 9000,
          query: {
            query_string: {
              default_field: "subCategory",
              query: subCategory,
            },
          },
        },
      };
    }
    const results = await axios.post(assetUrl("/searchAssets"), queryBody);
    let result_keyword = "";
    if (subCategory === "All") {
      switch (assetType) {
        case "aircrafts":
          result_keyword = "All Aircraft";
          break;
        case "collectibles":
          result_keyword = "All Collectibles";
          break;
        case "arts":
          result_keyword = "All Art";
          break;
        case "boats":
          result_keyword = "All Boats";
          break;
        case "autos":
          result_keyword = "All Automobiles";
          break;
        case "realestates":
          result_keyword = "All Real Estates";
          break;
        default:
          result_keyword = "All";
          break;
      }
    } else {
      result_keyword = subCategory;
    }
    dispatch({ type: DISPLAY_KEYWORD, payload: result_keyword });
    dispatch({ type: SEARCH_ASSET, payload: results.data });
    setTimeout(() => {
      redirectPath();
    }, 500);
  } catch (error) {
    console.log(error);
  }
};

const userUpdateQuery = (event) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_QUERY, payload: event.target.value });
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

// User fetch all assets
const userGetAsset = (assetId) => async (dispatch) => {
  try {
    const asset = await axios.get(assetUrl(`/${assetId}`));

    dispatch({ type: USER_GET_ASSET, payload: asset.data });
  } catch (error) {
    console.log(error);
  }
};

//Updated User fetch specific asset
const userGetSingleAsset = (assetId, category) => async (dispatch) => {
  try {
    const singleAsset = await axios.get(assetUrl(`/${category}/${assetId}`));
    dispatch({ type: USER_GET_SINGLE_ASSET, payload: singleAsset.data });
  } catch (error) {
    console.log(error);
  }
};

const userFindAsset = (values) => async (dispatch) => {
  try {
    let queryString = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => key + "=" + values[key])
      .join("&");

    let findAsset = await axios.get(assetUrl(`/findAsset?${queryString}`));

    dispatch({ type: USER_FIND_ASSET, payload: findAsset.data.data });
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

const userFindRelevantAsset = () => async (dispatch) => {
  try {
    const findAsset = await axios.get(assetUrl("/findRelevantAsset"));
    dispatch({ type: USER_FIND_RELEVANT_ASSET, payload: findAsset.data.data });
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

const userMakePayment = (data) => async (dispatch) => {
  try {
    const makePayment = await axios.post(assetUrl("/payment"), data);
    dispatch({ type: USER_MADE_PAYMENT, payload: makePayment.data });
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

const userConnections = () => async (dispatch) => {
  try {
    const connections = await axios.get(assetUrl("/allConnections"));
    dispatch({ type: USER_GET_CONNECTIONS_LIST, payload: connections.data });
  } catch (error) {
    console.log(error);
    const response = error.response.data;
    return response;
  }
};

const userRequestConnection = (assetId) => async (dispatch) => {
  try {
    await axios.post(assetUrl("/requestConnection"), { assetId });
  } catch (err) {
    console.log(err);
  }
};

const userToggleVisibility = (assetId, category) => async (dispatch) => {
  try {
    await axios.post(assetUrl("/toggleVisibility"), { assetId, category });
  } catch (err) {
    console.log(err);
  }
};

const userToggleAssetFilter = (boolean) => async (dispatch) => {
  dispatch({ type: USER_TOGGLE_ASSET_FILTER, payload: boolean });
};

const getGroupAssets = (groupID) => async (dispatch) => {
  try {
    let res = await axios.get(
      "https://api-dev.bazaarxchanges.com/api/v1/groupchat/getAssets/" + groupID
    );
    dispatch({ type: GROUP_ASSETS, payload: res.data });
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

const userAddRecentPage = (userId, asset) => async (dispatch) => {
  try {
    await axios.post(assetUrl("/add/recent"), { userId, asset });
  } catch (error) {
    console.log(error);
  }
};

const getElasticResults = () => async (dispatch) => {
  try {
    let res = await axios.get(assetUrl("/findAllElastic"));
    dispatch({ type: GET_ALL_ELASTIC_RESULTS, payload: res.data });
  } catch (err) {
    console.log(err);
  }
};

const getArtistDirectory = () => async (dispatch) => {
  try {
    let res = await axios.get(
      "https://api-dev.bazaarxchanges.com/api/v1/artists/get"
    );
    dispatch({ type: GET_ALL_ARTISTS, payload: res.data });
  } catch (error) {
    console.log(error);
  }
};

const getListings = ({ distanceByMiles, longitude, latitude }) => async (
  dispatch
) => {
  try {
    let listings = await axios.get(
      getUrl(
        `/api/v2/listings/near?distance=${distanceByMiles}&long=${longitude}&lat=${latitude}`
      )
    );
    console.log("[getListings]", listings);
    dispatch({ type: GET_LOCATION_AND_LISTINGS, payload: listings.data });
  } catch (error) {
    console.log("[assetError]", error);
    dispatch({
      type: GET_LOCATION_AND_LISTINGS,
      payload: { error: error },
    });
  }
};

const addNewListing = (values, history) => async (dispatch) => {
  dispatch({ type: FETCH_USER_BCHAIN_ADD_ASSET });

  try {
    const userId = window.localStorage.getItem("bexToken");
    const images = values.images.map(async () => {
      const { data } = await axios.post(
        getUrl("/api/v2/listings/getSignedUrl"),
        {
          userId,
        }
      );
      return data;
    });
    Promise.all(images).then(async (allImages) => {
      let newImages = [];
      allImages.map(async (s3Image, index) => {
        newImages.push(
          `https://bchain-prod-s3.s3.amazonaws.com/${s3Image.key}`
        );
        const s3bucketResponse = await axios.put(
          s3Image.url,
          values.images[index],
          {
            headers: {
              "Content-Type": "image/jpeg",
            },
          }
        );
      });
      values.images = newImages;
      const { data } = await axios.post(
        getUrl("/api/v2/listings/create"),
        values
      );
      dispatch({
        type: FETCH_USER_BCHAIN_ADD_ASSET_SUCCESS,
        payload: data.newListingData,
      });
    });
    
   setTimeout(() => history.push("/"), 2000 ) 
  } catch (error) {
    dispatch({ type: FETCH_USER_BCHAIN_ADD_ASSET_FAILURE });
  }
};

const deleteListing = (itemData) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      getUrl("/api/v2/client/delete/listing"),
      itemData
    );
    dispatch({ type: FETCH_DELETE_LISTING });
  } catch (error) {}
};

const editListing = (itemData) => async (dispatch) => {
  // try {
  //   const { data } = await axios.post(
  //     getUrl("/api/v2/client/delete/listing"),
  //     itemData
  //   );
  //   dispatch({ type: FETCH_DELETE_LISTING });
  // } catch (error) {}
};

export {
  userAddAsset,
  getListings,
  userMakePayment,
  userFindAsset,
  userUpdateAsset,
  userGetSingleAsset,
  userFindRelevantAsset,
  userConnections,
  userRequestConnection,
  userGetAsset,
  userGetMyAssets,
  userToggleVisibility,
  getUserAssets,
  getAssetsByUserId,
  getSearchResults,
  getCategoryResults,
  userToggleAssetFilter,
  userUpdateQuery,
  getGroupAssets,
  getElasticResults,
  userAddRecentPage,
  getArtistDirectory,
  addNewListing,
  deleteListing,
  editListing,
};
