import {
  CHECK_USER_STATUS,
  PHONE_VALIDATE,
  SAVE_REDIRECT_PATH,
  USER_LOGIN,
  USER_REGISTER,
  USER_SIGNOUT,
  USER_UPDATE_INFO,
  USER_UPDATE_INFO_ERROR,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_ERROR,
  USER_LOGIN_WITH_SOCIAL_MEDIA,
  REFRESH_USER
} from "../actions/types";

const INITIAL_STATE = {
  userLoginError: "",
  userRegisterSuccess: false,
  userRegisterError: "",
  userUpdateInfoError: "",
  userUpdatePasswordError: "",
  phoneSuccess: false,
  userInfo: "",
  checkLoggedInApi: false,
  redirectPath: "",
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_REGISTER:
      return action.payload.error
        ? {
            ...state,
            userRegisterSuccess: false,
            userRegisterError: action.payload.error,
          }
        : {
            ...state,
            userRegisterSuccess: true,
            userRegisterError: "",
            userInfo: action.payload,
          };
    case USER_LOGIN:
      return action.payload.error
        ? {
            ...state,
            userLoginError: "User Login/Password error"
          }
        : {
            ...state,
            userLoginError: "",
            userInfo: action.payload
          };
    case USER_LOGIN_WITH_SOCIAL_MEDIA:
      return action.payload.error ? {
        ...state,
        userLoginError: 'Errors, please try again.'
      }
      : {
        ...state,
        userLoginError: '',
        userInfo: action.payload
      }
    case USER_SIGNOUT:
      return {
        ...state,
        userLoginError: "",
        userRegisterSuccess: false,
        userRegisterError: "",
        phoneSuccess: false,
        userInfo: "",
      };
    case CHECK_USER_STATUS:
      return action.payload.error
        ? {
            ...state,
            userInfo: "",
            checkLoggedInApi: true,
          }
        : {
            ...state,
            userLoginError: "",
            phoneSuccess: true,
            userInfo: action.payload.data,
            checkLoggedInApi: true,
          };
    case PHONE_VALIDATE:
      return action.payload.error
        ? { ...state, phoneSuccess: false }
        : { ...state, phoneSuccess: true, userInfo: action.payload.data };

    // User updating account details
    case USER_UPDATE_INFO:
      return {
        ...state,
        userInfo: action.payload,
        userUpdateInfoError: "",
      };
    case USER_UPDATE_INFO_ERROR:
      return {
        ...state,
        userUpdateInfoError: action.payload,
      };
    case USER_UPDATE_PASSWORD:
      return {
        ...state,
        userUpdatePasswordError: "",
      };
    case USER_UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        userUpdatePasswordError: action.payload,
      };
    case SAVE_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.payload,
      };
    case REFRESH_USER:
      return action.payload.error
        ? {
            ...state,
            userLoginError: 'User Refresh Failed.',
          }
        : {
            ...state,
            userLoginError: '',
            userInfo: action.payload,
          };
    default:
      return state;
  }
}
