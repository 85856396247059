import React from "react";
import FormInput from "../../FormInput/FormInput";
import NumberInput from "../../FormInput/NumberInput";
import SelectFormInput from "../../FormInput/SelectFormInput";

const AssetFormCollectibleDetails = () => {
  const subCategoryOptions = [
    {
      display: "Choose A Subcategory",
      disable: true,
      value: ""
    },
    {
      display: "Memorabilia",
      disable: false,
      value: "Memorabilia"
    },
    {
      display: "Watches",
      disable: false,
      value: "Watches"
    },
    {
      display: "Gemstones",
      disable: false,
      value: "Gemstones"
    },
    {
      display: "Antiques",
      disable: false,
      value: "Antiques"
    },
    {
      display: "Jewelry",
      disable: false,
      value: "Jewelry"
    }];
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Details of Collectibles</div>
      <SelectFormInput 
        {...{name: "subCategory", 
        label: "Collectible Subcategory", 
        type:"text", 
        options: subCategoryOptions}} 
        key={"categoryKey"}
        />
      <FormInput label="Declared Origin" name="declaredOrigin" />
      <FormInput label="Authenticity Cert" name="authenticityCert" />
      <FormInput label="Purchase History" name="purchaseHistory" />
      <FormInput label="Grading Report" name="gradingReport" />
    </div>
  );
};

export default AssetFormCollectibleDetails;
