import React, { Component } from "react";
import AssetFormSwapChoices from "./AssetFormSwapChoices";

class AssetFormLease extends Component {
  render() {
    const { leaseDetails, handleDetailChange } = this.props;
    return (
      <div className="add-asset__template__box">
        <div className="add-asset__template__label">Lease Details</div>
        <div className="add-asset__actions__description u-center-text">
          Please select which types of assets you would like to lease with:
        </div>
        <AssetFormSwapChoices
          {...leaseDetails}
          handleDetailChange={handleDetailChange}
          detailType="leaseDetails"
        />
      </div>
    );
  }
}

export default AssetFormLease;
