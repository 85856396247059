import React, { Component } from "react";
import PubNub, { generateUUID } from "pubnub";
import TimeAgo from "react-timeago";
import dateFormat from "dateformat";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";
import { IoIosPeople, IoMdSend} from 'react-icons/io'


const brandColor = '#DF233A';

class ChatRoomMessages extends Component {
  state = {
    channel: null,
    input: "",
    pubnub: null,
    typeBoxMarginTop:185
  };

  componentWillMount() {
    const { updateMessageList, messages, chat } = this.props;
    const pubnub = new PubNub({
      publishKey: "pub-c-9d3669c9-65a0-4632-9db7-5cb4202c53f6",
      subscribeKey: "sub-c-7ded1c68-997b-11ea-8e71-f2b83ac9263d",
      uuid: generateUUID,
    });
    console.log("[message]", chat.channel);
    this.setState({ pubnub });
    const listener = {
      message: (envelope) => {
        const { _id } = this.props.user;
        this.props.findRooms(_id);
        this.setState({typeBoxMarginTop: this.state.typeBoxMarginTop - 25})
        updateMessageList(envelope);
      },
    };

    pubnub && pubnub.addListener(listener);
    pubnub.subscribe({
      channels: [ chat.channel],
    });
    // window.addEventListener("beforeunload", pubnub.unsubscribe());
  }

  //   componentDidUpdate(prevProps) {
  //     if (prevProps.messages !== this.props.messages) {
  //       const pubnub = new PubNub({
  //         publishKey: "pub-c-29e9f766-0929-414b-8d41-b37d2f89f04c",
  //         subscribeKey: "sub-c-359dec10-85c8-11ea-885f-2621b2dc68c7",
  //         uuid: generateUUID,
  //       });
  //       this.setState({ pubnub });
  //       const listener = {
  //         message: (envelope) => {
  //           const { _id } = this.props.user;
  //           this.props.findRooms(_id);
  //           console.log("[props]", envelope);
  //           this.props.updateMessageList(envelope);
  //         },
  //       };

  //   pubnub && pubnub.addListener(listener);
  //   pubnub.subscribe({
  //     channels: [this.props.messages[0].channel],
  //   });
  //   this.state.pubnub.unsubscribe({
  //     channels: [this.props.messages[0].channel],
  //   })

  //     }
  // }

  componentWillUnmount() {
    this.state.pubnub.unsubscribe({
      channels: [this.props.chat.channel],
    });
  }

  renderMessageContainer = (item) => {
    const { user } = this.props;
    if (user._id == item.message.author) {
      return (
        <div
          style={{
            alignSelf: "flex-end",
            maxWidth: 500,
            minHeight: 55,
            width: "40rem",
            height: "auto",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            boxSizing: "border-box",
            margin: 5,
            borderRadius: 10,
            boxShadow: "none",
            alignItems: "center",
            backgroundColor: "gainsboro",
          }}
        >
          <div style={{ margin: 5 }}>
            {" "}
            {console.log(
              "[time]",
              new Date((item.timetoken / 10000000) * 1000).toISOString()
            )}
            {dateFormat(
              new Date((item.timetoken / 10000000) * 1000).toISOString(),
              "mmmm d, yyyy, h:mm:s TT"
            )}
          </div>
          <p
            style={{
              marginLeft: 10,
              fontSize: 15,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            {item.message.description}
          </p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            alignSelf: "flex-start",
            maxWidth: 500,
            minHeight: 55,
            width: "40rem",
            height: "auto",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            boxSizing: "border-box",
            margin: 5,
            borderRadius: 10,
            boxShadow: "none",
            alignItems: "center",
            backgroundColor: "#ffa48c",
          }}
        >
          <div style={{ margin: 5 }}>
            {" "}
            {dateFormat(
              new Date((item.timetoken / 10000000) * 1000).toISOString(),
              "mmmm d, yyyy, h:m:s TT"
            )}
          </div>
          <p
            style={{
              marginLeft: 10,
              fontSize: 15,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            {item.message.description}
          </p>
        </div>
      );
    }
  };

  sendMessage = () => {
    const { input, pubnub } = this.state;
    const { user, messages,chat } = this.props;
    const message = {
      title: input,
      description: input,
      author: user._id,
    };
    // Publish our message to the channel `chat`
    if (input.trim() !== "" && input !== null) {
      console.log('[channel]', chat.channel)
      try {
        pubnub.publish({
          channel: chat.channel,
          message,
        });
        const { _id } = this.props.user;
        this.props.findRooms(_id);
      } catch (error) {
        console.log("[test]", error);
      }

      this.setState({ input: "" });
    }
  };

  render() {
    console.log("[message]", this.props.messages);
    return (
      <div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.props.messages.map((item, index) =>
            this.renderMessageContainer(item)
          )}
          {/* {this.props.messagesLength === this.props.index + 1 && ( */}
          
          {/* )} */}
        </div>

        <div className="type-box" 
        // style={{position:'fixed', bottom:0, left:0}}
        style={{marginTop: this.props.messages.length < 7 ? this.state.typeBoxMarginTop : 0}}
        >
          <input
            style={{
              padding: 10
            }}
            type="text"
            value={this.state.input}
            onChange={(event) => this.setState({ input: event.target.value })}
            placeholder="type messages..."
            onKeyPress={(event)=>{
              if(event.key === 'Enter'){
                this.sendMessage()
              }
            }}
          />
          <button onClick={this.sendMessage}>
            <IoMdSend color={brandColor} size={30}/>
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(ChatRoomMessages);
