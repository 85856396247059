import React, { Component } from "react";
import ShowAssetProductFiles from "./ShowAssetProductFiles";

class ShowAssetModal extends Component {
  render() {
    const { image, handleClose } = this.props;

    return (
      <div className="show-asset__product__modal">
        <div
          className="form__close show-asset__product__modal__close"
          onClick={handleClose}
        >
          &times;
        </div>
        <ShowAssetProductFiles image={image} />
      </div>
    );
  }
}

export default ShowAssetModal;
