import React from "react";
import Wrapper from "./Wrapper";
import BexAdWeb from "../../images/bex-footer-banner.png";
import AndroidDownwloadButton from "../../images/download-on-the-app-store-button.png";
import AppleDownwloadButton from "../../images/download-on-the-apple-store-button.png";
import { Analytics } from "../../utility/analytics";

const MobileBanner = ({ screenWidth }) => {
  return (
    <Wrapper>
      <div className="ad-container">
        {screenWidth <= 900 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={BexAdWeb} alt={"app ad"} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                onClick={() => {
                  Analytics.eventsAnalytics(
                    "download-link",
                    "Android download"
                  );
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bexmobile&hl=en_US",
                    "_blank"
                  );
                }}
                src={AndroidDownwloadButton}
                style={{
                  width: 104,
                  height: 30,
                  marginRight: 20,
                  cursor: "pointer",
                }}
              />
              <img
                onClick={() => {
                  Analytics.eventsAnalytics("download-link", "iOS download");
                  window.open(
                    "https://apps.apple.com/us/app/bazaarxchanges/id1494528970",
                    "_blank"
                  );
                }}
                src={AppleDownwloadButton}
                style={{ width: 104, height: 60,  cursor: "pointer", }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={BexAdWeb} alt={"app ad"} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                onClick={() => {
                  Analytics.eventsAnalytics(
                    "download-link",
                    "Android download"
                  );
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bexmobile&hl=en_US",
                    "_blank"
                  );
                }}
                src={AndroidDownwloadButton}
                style={{
                  width: 104,
                  height: 30,
                  marginRight: 20,
                  cursor: "pointer",
                }}
              />
              <img
                onClick={() => {
                  Analytics.eventsAnalytics(
                    "download-link",
                    "iOS download"
                  );
                  window.open(
                    "https://apps.apple.com/us/app/bazaarxchanges/id1494528970",
                    "_blank"
                  );
                }}
                src={AppleDownwloadButton}
                style={{ width: 104, height: 60,  cursor: "pointer", }}
              />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default MobileBanner;
