import React from "react";
import Modal from "react-modal";
import Select from "react-select";
Modal.setAppElement("#root");
const distance_by_radius = [
  { label: "5 Mile Radius", value: 5 },
  { label: "10 Mile Radius", value: 10 },
  { label: "25 Mile Radius", value: 25 },
  { label: "50 Mile Radius", value: 50 },
  { label: "100 Mile Radius", value: 100 }
];

export const DistanceModal = props => {
  return (
    <div>
      <Modal
        isOpen={props.isModalOpen}
        contentLabel="Distance Modal"
        className="distance_modal"
      >
        <div className="modal_title">Select a Radius: </div>
        <div style={{ width: "100%", minHeight: "2rem" }}>
          <Select
            value={props.distance}
            onChange={props.setDistance}
            options={distance_by_radius}
            placeholder={"Select Radius"}
            className="category__filter"
          />
          <div className="button_group">
            <button className="update" onClick={props.submitDistance}>
              Update
            </button>
            <button className="toggle" onClick={props.toggleDistance}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const GeolocationModal = props => {
  return (
    <div>
      <Modal isOpen={props.isModalOpen} className="geolocation_modal">
        <div className="modal_title">Enter 5-Digit Zipcode: </div>
        <div style={{ width: "100%", minHeight: "2rem" }}>
          <input
            type="text"
            placeholder="Zipcode"
            onChange={props.handleInput}
            value={props.zipcode}
          />
          <h4 style={{ margin: "2rem 0rem", color: "#DF233A" }}>
            {props.error}
          </h4>
          <div className="button_group">
            <button className="update" onClick={props.submitZipcode}>
              Update
            </button>
            <button className="toggle" onClick={props.toggleGeolocation}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
