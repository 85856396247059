import React, { Component } from 'react'
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper"; 
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { ArrowRight, ArrowLeft } from 'react-feather';


class ArtistHighlight extends Component {
  async componentDidUpdate() {
    let userId = this.props.userInfo._id;
    let { asset } = this.props.single[0];

    await this.props.userAddRecentPage( userId, asset);
  }

  render() {
    return (
      <Layout>
        <Wrapper>
          <div className="artist-highlight-container">
            <div className="header">Artist Highlight&nbsp;&rarr;&nbsp;<span>Discovery</span></div>
            <br/>
            <h1 className="artist-highlight-title">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde consequuntur animi odit officia at?
            </h1>
            <h3>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nesciunt, rem!
            </h3>
            <div className="byline">By Marky Mark</div>
            <div className="highlight-image">
              <img src="https://nssdata.s3.amazonaws.com/images/galleries/18331/23-banksy-1.jpg" alt="highlight"/>
            </div>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum ab, deleniti amet autem illo est unde nesciunt minima neque, tempore dignissimos sequi sit nisi distinctio, temporibus alias eos praesentium. Temporibus ipsam mollitia maxime debitis vel? Exercitationem consectetur id nostrum consequuntur libero voluptas dignissimos at qui possimus ullam, incidunt tenetur quisquam facilis ratione sed? Nesciunt placeat amet commodi repellendus. Eum pariatur voluptatem officiis dignissimos eius eveniet facilis maiores. Inventore quaerat similique sint, eos cumque quisquam tempore. Aliquid sint veniam doloribus officia cum necessitatibus repudiandae harum praesentium unde facilis tenetur impedit voluptas magni deleniti alias amet, reiciendis, et distinctio. Amet, distinctio nostrum.
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque facilis, vitae vel optio asperiores, saepe sapiente ratione totam, dolore voluptatem consequuntur rem. Nulla facere reiciendis blanditiis explicabo, obcaecati, corporis accusamus cumque magni doloremque, quod numquam nobis quo harum voluptate. Molestias perferendis voluptatum, harum impedit hic assumenda neque minima itaque nihil a blanditiis consequatur, commodi necessitatibus ipsum illo voluptatibus. Deserunt beatae asperiores doloribus obcaecati consequatur nemo, mollitia sit ad ducimus voluptates officia nam culpa possimus, optio aspernatur qui! Cumque, odio exercitationem? Incidunt aperiam non doloremque saepe id excepturi molestiae architecto facere temporibus, aliquid illo odit deserunt optio expedita? Sequi, nemo aut.
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque facilis, vitae vel optio asperiores, saepe sapiente ratione totam, dolore voluptatem consequuntur rem. Nulla facere reiciendis blanditiis explicabo, obcaecati, corporis accusamus cumque magni doloremque, quod numquam nobis quo harum voluptate. Molestias perferendis voluptatum, harum impedit hic assumenda neque minima itaque nihil a blanditiis consequatur, commodi necessitatibus ipsum illo voluptatibus. Deserunt beatae asperiores doloribus obcaecati consequatur nemo, mollitia sit ad ducimus voluptates officia nam culpa possimus, optio aspernatur qui! Cumque, odio exercitationem? Incidunt aperiam non doloremque saepe id excepturi molestiae architecto facere temporibus, aliquid illo odit deserunt optio expedita? Sequi, nemo aut.
            </p>
            <div className="highlight-image">
              <img src="https://media.cntraveler.com/photos/56a79ed0c58591430b13136f/master/pass/banksy-calais-cr-getty.jpg" alt="highlight"/>
            </div>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum ab, deleniti amet autem illo est unde nesciunt minima neque, tempore dignissimos sequi sit nisi distinctio, temporibus alias eos praesentium. Temporibus ipsam mollitia maxime debitis vel? Exercitationem consectetur id nostrum consequuntur libero voluptas dignissimos at qui possimus ullam, incidunt tenetur quisquam facilis ratione sed? Nesciunt placeat amet commodi repellendus. Eum pariatur voluptatem officiis dignissimos eius eveniet facilis maiores. Inventore quaerat similique sint, eos cumque quisquam tempore. Aliquid sint veniam doloribus officia cum necessitatibus repudiandae harum praesentium unde facilis tenetur impedit voluptas magni deleniti alias amet, reiciendis, et distinctio. Amet, distinctio nostrum.
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque facilis, vitae vel optio asperiores, saepe sapiente ratione totam, dolore voluptatem consequuntur rem. Nulla facere reiciendis blanditiis explicabo, obcaecati, corporis accusamus cumque magni doloremque, quod numquam nobis quo harum voluptate. Molestias perferendis voluptatum, harum impedit hic assumenda neque minima itaque nihil a blanditiis consequatur, commodi necessitatibus ipsum illo voluptatibus. Deserunt beatae asperiores doloribus obcaecati consequatur nemo, mollitia sit ad ducimus voluptates officia nam culpa possimus, optio aspernatur qui! Cumque, odio exercitationem? Incidunt aperiam non doloremque saepe id excepturi molestiae architecto facere temporibus, aliquid illo odit deserunt optio expedita? Sequi, nemo aut.
            </p>
          </div>
          <br/>
          <div className="single-artist-selection">
            <a href="/directory/artists"><h2><ArrowLeft size={50}/>&nbsp;Prev</h2></a>
            <h2>Next<ArrowRight size={50}/></h2>
          </div>
        </Wrapper>
        {
          this.props.userInfo ?
          <Wrapper>
          <h1 className="assets-visited-title">Recently Visited</h1>
          <div className="assets-visited">
            <div className="assets-visited-row">
              {
                this.props.userInfo.recentlyVisited.map((item, index) => (
                  <div className="assets-visited-item">
                    <a href={`/assets/${item.category}/${item._id}`} key={index}>
                      <h6>{item.postTitle}</h6>
                      <img src={item.images[0]} alt="asset"/>
                      <h6>
                        {
                          !isNaN(item.price)
                          ? parseInt(item.price).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD"
                            })
                          : "Contact for Pricing"
                        }
                        </h6>
                      </a>
                    </div>
                  ))
                }
              </div>
            </div>
          </Wrapper> :
          ""
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(ArtistHighlight);