import React, { Component } from 'react';
import axios from 'axios';
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper";
import Auctions from "./EventsAuctions";
import Exhibits from "./EventsExhibits";

class Events extends Component {
  state = {
    auctionLinkActive: true,
    exhibitLinkActive: false,
    events: []
  }

  getAllEvents = () => {
    const eventsUrl = "https://api-dev.bazaarxchanges.com/api/v1/events/all";
    axios.get(eventsUrl)
    .then(res => {
      this.setState({
        events: res.data[0].events.filter(x => x!== null)
      });
      console.log(this.state);
    })
    .catch(err => {
      console.log(err);
    });
  }

  handleActiveLink = (e) => {
    let name = e.target.name;
    name === "auctions" ? 
    this.setState({ auctionLinkActive: true, exhibitLinkActive: false }) :
    this.setState({ auctionLinkActive: false, exhibitLinkActive: true });
  }

  componentDidMount() {
    this.getAllEvents();
  }

  render() {
    let { auctionLinkActive, exhibitLinkActive, events } = this.state;
    let auctionData = events.filter(x => x.type === "auction");
    let exhibitData = events.filter(x => x.type === "exhibit");
    let activeAuctionClass = auctionLinkActive ? "active" : "";
    let activeExhibitClass = exhibitLinkActive ? "active" : "";

    return (
      <Layout>
        <Wrapper>
          <div className="events">
            <div className="headings">
              <button 
                name="auctions"
                onClick={e => this.handleActiveLink(e)}
                className={`headings-auctions ${activeAuctionClass}`}
              >
                Auctions
              </button>
              <button 
                name="exhibits"
                onClick={e => this.handleActiveLink(e)}
                className={`headings-exhibitions ${activeExhibitClass}`}
              >
                Exhibitions
              </button>
            </div>
            { auctionLinkActive ? <Auctions data={auctionData}/> : <Exhibits data={exhibitData}/> }
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

export default Events;