import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import "react-animated-slider/build/horizontal.css";
import Wrapper from "./Wrapper";
import Layout from "./Layout";
import Banner from "./Banner";
import Filter from "./Filter";
import Listings from "./Listings";
import Locales from "./Locales";
import Divider from "./Divider";
import MobileBanner from "./MobileBanner";
import Geocode from "react-geocode";
import { GOOGLE_API_KEY } from "../../utility/google_maps";
import { getDistance } from "geolib";
import { DistanceModal, GeolocationModal } from "./Modals";
import axios from "axios";
import { isFiveDigits, isValidNumber } from "./../../utility/constants";

class ListingsByCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropMenu: false,
      screenWidth: window.innerWidth,
      bannerEvent: null,
      location: "New York, NY",
      selectedCategory: { label: "All Listings", value: "All" },
      selectedEstimate: null,
      distance: { label: "50 Mile Radius", value: 50 },
      zipcode: "",
      latitude: null,
      longitude: null,
      locationError: null,
      listingsDataLoaded: false,
      isDistanceModalOpen: false,
      isGeolocationModalOpen: false
    };
  }
  onHoverEnter = () => this.setState({ showDropMenu: true });
  onHoverExit = () => this.setState({ showDropMenu: false });
  handleResize = e => this.setState({ screenWidth: e.target.innerWidth });
  handleUpdateFilter = (input, selectedOption) =>
    this.setState({ [selectedOption.name]: input });
  toggleDistanceModal = () =>
    this.setState({ isDistanceModalOpen: !this.state.isDistanceModalOpen });
  toggleGeolocationModal = () =>
    this.setState({
      isGeolocationModalOpen: !this.state.isGeolocationModalOpen
    });
  setDistance = miles => this.setState({ distance: miles });
  updateZipcode = e => this.setState({ zipcode: e.target.value });

  getAllEvents = () => {
    axios
      .get("https://api-dev.bazaarxchanges.com/api/v1/events/all")
      .then(res => {
        let events = res.data[0].events.filter(
          (x, i, a) => x && x.image && !x.title.includes("#")
        );

        let randomIndex = Math.floor(Math.random() * events.length);
        this.setState({
          bannerEvent: events[randomIndex]
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  accessLocation = async () => {
    return new Promise(resolve => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => resolve(position),
          () => this.handleDefaultGeolocate(),
          { enableHighAccuracy: true, timeout: 20000, maximumAge: 10000 }
        );
      } else {
        resolve(false);
      }
    });
  };

  createLocationObject = () => {
    let { distance, latitude, longitude } = this.state;
    return Object.freeze({
      distanceByMiles: distance.value,
      latitude,
      longitude
    });
  };

  createLocationObjectAndSend = () => {
    let { distance, latitude, longitude } = this.state;
    this.props.getListings({
      distanceByMiles: distance.value,
      latitude,
      longitude
    });
  };

  setDefaultLatLng = async () => {
    console.log("settingState");
    this.setState({
      latitude: 40.76203408358093,
      longitude: -73.97892881890559
    });
  };

  handleDefaultGeolocate = async () => {
    this.setDefaultLatLng()
      .then(() => this.parseLocation())
      .then(() => this.createLocationObjectAndSend())
      .then(() =>
        this.setState({ listingsDataLoaded: !this.state.listingsDataLoaded })
      )
      .catch(error => console.log(error));
  };

  parseLocation = async () => {
    const { latitude, longitude } = this.state;
    const response = await Geocode.fromLatLng(latitude, longitude);
    const cityZip = response.results[0].address_components.length - 2;
    const city = response.results[0].address_components[2].long_name;
    const zipcode = response.results[0].address_components[cityZip].long_name;
    this.setState({
      zipcode,
      location: city
    });
  };

  getLocalListings = async () => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    const Geolocation = this.accessLocation();

    Geolocation.then(position => {
      if (position) {
        const {
          coords: { latitude, longitude }
        } = position;
        this.setState({ latitude, longitude });
      }
    })
      .then(() => this.parseLocation())
      .then(() => this.createLocationObjectAndSend())
      .then(() =>
        this.setState({ listingsDataLoaded: !this.state.listingsDataLoaded })
      )
      .catch(error => console.log(error));
  };

  calculateDistance = endCoords => {
    let startCoords = {
      latitude: this.state.latitude,
      longitude: this.state.longitude
    };

    return getDistance(startCoords, endCoords, 0.5);
  };

  validateZipcode = () => {
    let { zipcode } = this.state;
    console.log("validating zipcode...");
    if (!isValidNumber(zipcode) || !isFiveDigits(zipcode) || zipcode === "") {
      this.setState({ zipcodeError: "Please enter a valid zipcode" });
      console.log(isValidNumber(zipcode), isFiveDigits(zipcode), this.state);
    } else {
      this.setState(
        {
          zipcodeError: null,
          listingsDataLoaded: !this.state.listingsDataLoaded,
          isGeolocationModalOpen: !this.state.isGeolocationModalOpen
        },
        () => this.handleZipcodeUpdate()
      );
    }
  };

  handleZipcodeUpdate = async () => {
    const { zipcode } = this.state;
    console.log("handling zipcode", zipcode);
    await Geocode.setApiKey(GOOGLE_API_KEY);
    await Geocode.fromAddress(zipcode)
      .then(data => {
        console.log("ZIPCODE DATA RES: ", data);
        const latitude = data.results[0].geometry.location.lat;
        const longitude = data.results[0].geometry.location.lng;
        const city = data.results[0].address_components[1].long_name;
        console.log("City: ", city);
        this.setState({
          location: city,
          latitude,
          longitude
        });
      })
      .then(() => {
        const newLocation = this.createLocationObject();
        this.props.getListings(newLocation);
      })
      .then(() => {
        this.setState({ listingsDataLoaded: !this.state.listingsDataLoaded });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleUpdateListingsByDistance = async () => {
    let updatedRadius = this.createLocationObject();
    await this.props.getListings(updatedRadius);
    await this.setState({
      isDistanceModalOpen: !this.state.isDistanceModalOpen
    });
  };

  getCityLocation = async () => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    const city = this.props.match.params.city;
    const locationResults = Geocode.fromAddress(city)
    return locationResults;
  };

  parseCity = async (location) => {
    let { results } = location;
    const city = results[0].address_components[0].long_name;
    const latitude = results[0].geometry.location.lat;
    const longitude = results[0].geometry.location.lng;
    this.setState({
      location: city,
      latitude,
      longitude
    })
  }

  getCityListings = async () => {
    try {
      const location = await this.getCityLocation();
      await this.parseCity(location);
      await this.createLocationObjectAndSend();
      await this.setState({ listingsDataLoaded: !this.state.listingsDataLoaded });
    } catch (error) {
      console.log(error);
    }
  }

  async componentDidMount() {
    this.getAllEvents();
    this.getCityListings();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    let {
      bannerEvent,
      screenWidth,
      selectedCategory,
      selectedEstimate,
      zipcode,
      zipcodeError,
      distance,
      location,
      isDistanceModalOpen,
      isGeolocationModalOpen
    } = this.state;
    console.log("Category: ", selectedCategory);

    return (
      <Layout>
        <Wrapper>
          <Banner screenWidth={screenWidth} bannerEvent={bannerEvent} />
          <Filter
            distance={distance}
            location={location}
            category={selectedCategory}
            handleUpdate={this.handleUpdateFilter}
            toggleDistance={this.toggleDistanceModal}
            toggleGeolocation={this.toggleGeolocationModal}
          />
          <DistanceModal
            distance={distance}
            setDistance={this.setDistance}
            isModalOpen={isDistanceModalOpen}
            toggleDistance={this.toggleDistanceModal}
            submitDistance={this.handleUpdateListingsByDistance}
          />
          <GeolocationModal
            isModalOpen={isGeolocationModalOpen}
            toggleGeolocation={this.toggleGeolocationModal}
            handleInput={this.updateZipcode}
            submitZipcode={this.validateZipcode}
            zipcode={zipcode}
            error={zipcodeError}
          />
        </Wrapper>
        <Listings
          city={location}
          category={selectedCategory}
          cityParams={this.props.match.params.city}
          isLoaded={this.state.listingsDataLoaded}
          calculateDistance={this.calculateDistance}
        />
        <Divider />
        <Locales />
        <MobileBanner screenWidth={screenWidth} />
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  console.log("REDUX STATE: ", state);
  return {
    userInfo: state.auth.userInfo,
    query: state.asset.query,
    elasticResults: state.asset.elasticResults
  };
};

export default connect(mapStateToProps, actions)(withRouter(ListingsByCity));
