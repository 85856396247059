import React from "react";
import { Field } from "redux-form";

const renderTextField = ({
  label,
  input,
  placeholder,
  input: { value, onChange },
  meta: { touched, error }
}) => {
  let newError = touched && error;
  return (
    <div className="accountsettings__row">
      <div className="accountsettings__label">{label}</div>
      <div>
        <input
          {...input}
          className={
            newError
              ? `form__input accountsettings__input form__input--error`
              : `form__input accountsettings__input`
          }
          type="text"
          value={value}
          onChange={onChange}
          placeholder={newError ? error : placeholder}
        />
      </div>
    </div>
  );
};

const AccountSettingsInput = props => {
  const { label, name, placeholder } = props;
  return (
    <Field
      label={label}
      name={name}
      placeholder={placeholder}
      component={renderTextField}
    />
  );
};

export default AccountSettingsInput;
