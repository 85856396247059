import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Footer from "../Footer/Footer";
// import SidebarMenu from "../Menu/SidebarMenu";
// import ModernMenu from "../Menu/ModernMenu";
// import UserModernMenu from "../Menu/UserModernMenu";
// import UserSidebarMenu from "../Menu/UserSidebarMenu";
import UserNavbar from "../Navbar/UserNavbar";
import Navbar from "../Navbar/Navbar";
import MobileNavbar from "./../Navbar/MobileNavbar";

const Layout = props => {
  //const settingsPage = path.split("/").filter(x => x === "accountsettings").length;
  if (props.userInfo) {
    return (
      <div className="page">
        <UserNavbar user={props.userInfo} />
        <MobileNavbar user={props.userInfo} />
        {/* <UserModernMenu user={props.userInfo} />
        <UserSidebarMenu user={props.userInfo} /> */}
        <div className="layout__body">{props.children}</div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="page">
        <Navbar />
        <MobileNavbar  user={props.userInfo}/>
        <div className="layout__body">{props.children}</div>
        <Footer />
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(mapStateToProps, actions)(withRouter(Layout));
