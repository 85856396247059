import React, { Component } from "react";
import Wrapper from "../Layout/Wrapper";
import Layout from "../Layout/Layout";
import axios from "axios";
import MessageList from "./MessageList";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import SendMessageForm from "./SendMessageForm";
import { chatkitConnect } from "./ChatManager";
import AddOrRemoveAssetModal from "./AddOrRemoveAssetModal";
import FileUploadDialog from "./FileUploadDialog";
import { Send, Save } from "react-feather";
import ChatInviteModal from "./ChatInviteModal";
import ChatkitUpload from "./ChatkitUpload";
import Logo from "../../images/bx-logo-main-black.png";

class ChatScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      currentRoom: "",
      currentUser: "",
      currentUserId: "",
      selectedImages: [],
      text: "",
      urls: [],
      files: [],
      isOpen: false,
      fileMessage: "",
      usersTyping: [],
      receipientEmail: "",
      emailFrame: "default",
      showTyping: false,
      showFileUploadDialog: false
    };
    this.messageRef = React.createRef();
    this.fileAttachment = React.createRef();
  }

  handleInput = async e => {
    const { value, name } = e.target;
    await this.state.currentUser
      .isTypingIn({ roomId: this.state.currentRoom.id })
      .then(() => console.log("typing!!!!!"))
      .catch(err => console.log(err));
    await this.setState({ [name]: value });
  };

  toggleFileUploadDialog = () => {
    console.log("toggling...");
    this.setState({ showFileUploadDialog: !this.state.showFileUploadDialog });
  };

  addToSelectedImages = url => {
    this.setState({
      selectedImages: [...this.state.selectedImages, url]
    });
  };

  removeFromSelectedImages = url => {
    let selected = [...this.state.selectedImages];
    this.setState({
      selectedImages: selected.filter(x => x !== url)
    });
  };

  uploadImgUrl = async e => {
    await e.preventDefault();
    const parts = [];
    const files = this.state.selectedImages;
    if (files.length === 0) return;

    await files.forEach(async file => {
      console.log(file);
      await parts.push({
        type: `image/png`,
        url: file
      });
    });

    await console.log(parts);

    await this.state.currentUser
      .sendMultipartMessage({
        roomId: this.state.currentRoom.id,
        parts
      })
      .then(() => {
        this.setState({
          showFileUploadDialog: !this.state.showFileUploadDialog
        });
      });
  };

  uploadAttachment = async e => {
    await e.preventDefault();
    const parts = [];
    const files = this.fileAttachment.current.files;
    if (files.length === 0) return;

    if (this.state.fileMessage.trim() !== "") {
      await parts.push({
        type: "text/plain",
        content: this.state.fileMessage
      });
    }

    await Object.values(files).forEach(async file => {
      const filetype = file.name.split(".");
      console.log(file);
      await parts.push({
        type: `image/${filetype[filetype.length - 1]}`,
        file
      });
    });

    await console.log(parts);

    this.state.currentUser
      .sendMultipartMessage({
        roomId: this.state.currentRoom.id,
        parts
      })
      .then(() => {
        // this.setState({ showFileUploadDialog: !this.state.showFileUploadDialog });
        console.log(this.state.messages);
      });
  };

  submitText = async e => {
    e.preventDefault();
    await this.sendMessage(this.state.text);
    await this.setState({ text: "" });
  };

  sendMessage = () => {
    this.state.currentUser.sendMessage({
      roomId: this.state.currentRoom.id,
      text: this.state.text || ""
    });
  };

  handleModal = () => {
    if (this.state.emailFrame !== "loading") {
      this.setState({
        isOpen: !this.state.isOpen,
        receipientEmail: "",
        emailFrame: "default"
      });
    }
  };

  handleEmail = e => {
    this.setState({
      receipientEmail: e.target.value,
      emailError: ""
    });
  };

  handleChatInvite = async e => {
    const { user } = this.props;
    e.preventDefault();

    if (!this.state.receipientEmail.includes("@")) {
      this.setState({
        emailError: "Please enter a valid email"
      });
    }

    const inviteBody = {
      receipientEmail: this.state.receipientEmail,
      roomId: this.state.currentRoom.id,
      senderEmail: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    };

    await axios.post(
      "http://www.localhost:4000/api/v1/groupchat/sendInvite",
      inviteBody
    );

    /** Email Frame UI State Timeouts **/
    await this.setState({
      emailFrame: "loading"
    });

    await setTimeout(() => {
      this.setState({ emailFrame: "success" });
    }, 2500);

    await setTimeout(() => {
      this.setState({
        isOpen: !this.state.isOpen,
        receipientEmail: "",
        emailFrame: "default"
      });
    }, 4500);

    console.log("submit func");
    console.log(this.state.emailFrame);
  };

  componentDidMount() {
    const { user, roomId } = this.props;
    const chatManager = chatkitConnect(user._id);

    chatManager
      .connect()
      .then(currentUser => {
        console.log("Successful connection", currentUser);
        this.setState({ currentUser, currentUserId: currentUser.id });
        return currentUser.subscribeToRoomMultipart({
          roomId: roomId,
          messageLimit: 100,
          hooks: {
            onMessage: message => {
              this.setState({
                messages: [...this.state.messages, message],
                showTyping: false
              });
            },
            onUserStartedTyping: user => {
              this.setState({
                usersTyping: [...this.state.usersTyping, user.name],
                showTyping: true
              });
            },
            onUserStoppedTyping: user => {
              this.setState({
                usersTyping: this.state.usersTyping.filter(
                  name => name !== user.name
                ),
                showTyping: false
              });
            }
          }
        });
      })
      .then(currentRoom => {
        this.setState({ currentRoom });
      })
      .catch(error => {
        console.log("Error on Connection", error);
      });
  }

  UNSAFE_componentWillUpdate() {
    const { current } = this.messageRef;
    this.shouldScrollToBottom =
      current.scrollTop + current.clientHeight >= current.scrollHeight;
  }

  componentDidUpdate() {
    const { current } = this.messageRef;
    if (this.shouldScrollToBottom) {
    }
    current.scrollTop = current.scrollHeight;
  }

  render() {
    let { usersTyping, showTyping, isOpen } = this.state;
    let modalAction = isOpen ? "reveal-modal" : "hide-modal";
    let properAssets =
      this.props.briefcase.sender.owner._id === this.props.user._id
        ? this.props.briefcase.sender
        : this.props.briefcase.receiver;
    let isChatOwner =
      this.props.briefcase.sender.owner._id === this.props.user._id;

    return (
      <div className="chatscreen">
        <ChatInviteModal
          modalAction={modalAction}
          handleModal={this.handleModal}
          imgLogo={Logo}
          handleChatInvite={this.handleChatInvite}
          handleEmail={this.handleEmail}
          receipientEmail={this.state.receipientEmail}
          emailError={this.state.emailError}
          emailFrame={this.state.emailFrame}
        />
        <AddOrRemoveAssetModal
          assetModalAction={this.props.assetModalAction}
          handleAssetModal={this.props.handleAssetModal}
          reRender={this.props.reRenderRoom}
          currentRoomId={this.state.currentRoom.id}
        />
        <div className="messages-header">
          <div className="send-invitation">
            <Send size={25} onClick={this.handleModal} /> <h4>Send Invite</h4>
          </div>
          {/* <Save size={25}/> */}
        </div>
        <div className="messages-container" ref={this.messageRef}>
          <MessageList
            messages={this.state.messages}
            user={this.state.currentUserId}
          />
          {this.state.showFileUploadDialog ? (
            <ChatkitUpload
              allAssets={this.user.items_posted}
              chatAssets={properAssets}
              toggleUpload={this.toggleFileUploadDialog}
              addImage={this.addToSelectedImages}
              removeImage={this.removeFromSelectedImages}
              selectedImages={this.state.selectedImages}
              uploadImgUrl={this.uploadImgUrl}
              isChatOwner={isChatOwner}
            />
          ) : null}
        </div>
        <div
          className={`users-typing ${
            showTyping ? "typing-show" : "typing-hide"
          }`}
        >
          <div className="indicator-container">
            <div className="indicator">
              <i
                className="fas fa-circle fa-sm dot1"
                style={{ color: "#c0c0c0" }}
              ></i>
              <i
                className="fas fa-circle fa-sm dot2"
                style={{ color: "#c0c0c0" }}
              ></i>
              <i
                className="fas fa-circle fa-sm dot3"
                style={{ color: "#c0c0c0" }}
              ></i>
            </div>
            {usersTyping.length === 0 ? (
              ""
            ) : usersTyping.length === 1 ? (
              <p>{`${usersTyping[0]} is typing...`}</p>
            ) : (
              <p>{`${usersTyping.length} people are typing...`}</p>
            )}
          </div>
        </div>
        <div className="messages-input">
          <SendMessageForm
            submitText={this.submitText}
            handleInput={this.handleInput}
            text={this.state.text}
            handleDialog={this.toggleFileUploadDialog}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo
  };
};

export default connect(mapStateToProps, actions)(ChatScreen);
