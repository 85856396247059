import React, { Component } from 'react';
import Wrapper from '../Layout/Wrapper';
import Select from 'react-select';

class AssetFilter extends Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.stickyAction();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => {
      this.stickyAction();
    });
  }

  stickyAction = () => {
    let element = this.filterRef.current;
    if (element) {
      let offset = element.offsetTop + 400;
  
      window.pageYOffset > offset ?
      this.addStickyClass(element) :
      this.removeStickyClass(element);
    }
  }

  addStickyClass = async elem => {
    await elem.classList.add('sticky-filter');
  }

  removeStickyClass = async elem => {
    await elem.classList.remove('sticky-filter');
  }

  render() {
    console.log(this.props);
    const styles = {
      control: (base,state) => ({
        ...base,
        background: '#111e29',
        color: 'white',
        fontSize: '1.2rem',
        "&:hover": {
          cursor: 'pointer'
        },
        borderColor: state.isFocused
          ? '#df233a' : '#111e29',
        boxShadow: state.isFocused ? 0 : 0,
      }),
      singleValue: base => ({
        ...base,
        color: "#fff"
      }),
      input: base => ({
        ...base,
        color: "#fff"
      }),
      placeholder: base => ({
        ...base,
        color: 'white'
      }),
      menu: base => ({
        ...base,
        background: '#111e29',
        borderRadius: 0,
        marginTop: 0,
        fontSize: '1.2rem',
        color: 'white',
      }),
      option: base => ({
        ...base,
        background: '#111e29',
        color: 'white',
        "&:hover": {
          background: '#111e29',
          cursor: 'pointer'
        }
      }),
      menuList: base => ({
        ...base,
        padding: 0,
        color: 'white',
        "&:hover": {
          background: '#111e29',
          cursor: 'pointer'
        },
      }),
      container: base => ({
        ...base,
        width: '100%',
        color: 'white'
      })
    };
    const categoryOptions = [
      { value: 'all', label: 'All' },
      { value: 'art', label: 'Art' },
      { value: 'aircraft', label: 'Aircraft' },
      { value: 'Real Estate', label: 'Real Estate' },
      { value: 'collectible', label: 'Collectibles' },
      { value: 'auto', label: 'Automobiles' },
      { value: 'boat', label: 'Boats' },
    ];

    const dateSortOptions = [
      { value: 'new', label: 'Sort By: Newest' },
      { value: 'old', label: 'Sort By: Oldest' },
    ]

    const priceOptions = [
      { value: 0, label: '$0' },
      { value: 10000, label: '$10k' },
      { value: 25000, label: '$25k' },
      { value: 50000, label: '$50k' },
      { value: 100000, label: '$100k' },
      { value: 500000, label: '$500k' },
      { value: 1000000, label: '$1m' },
      { value: 10000000, label: '$10m' },
      { value: 25000000, label: '$25m+' },
    ]

    let { 
      setOrder, 
      setCategory, 
      setSale, 
      setSwap, 
      setLease, 
      setEnquire,
      setMinPrice,
      setMaxPrice
    } = this.props;

    return (
      <div className="asset-filter" ref={this.filterRef}>
        <Wrapper>
          <div className="filter-flex">

            {/* Sort By Date Filter */}
            <div className="filter-flex-option">
              <Select 
                styles={styles}
                placeholder="Sort By: Newest"
                options={dateSortOptions} 
                // onChange={(e) => setOrder(e.value)}
              />
            </div>

            {/* Category Filter */}
            <div className="filter-flex-option">
              <Select 
                styles={styles}
                options={categoryOptions} 
                placeholder="Category: All"
                // onChange={(e) => setCategory(e.value)}
              />
            </div>

            {/* Price Minimum */}
            <div className="filter-flex-option">
              <Select 
                styles={styles}
                options={priceOptions} 
                placeholder="Price Min"
                // onChange={(e) => setMinPrice(e.value)}
              />
            </div>

            {/* Price Maximum */}
            <div className="filter-flex-option">
              <Select 
                styles={styles}
                options={priceOptions} 
                placeholder="Price Max"
                // onChange={(e) => setMaxPrice(e.value)}
              />
            </div>

            {/* Sort By Asset Preference */}
            <div className="filter-flex-option--pref">
              <label className="checkbox-container">
                Sale
                {/* <input type="checkbox" onChange={() => setSale()}/> */}
                <input type="checkbox"/>
                <span className="checkbox-custom"></span>
              </label>
              <label className="checkbox-container">
                Swap
                {/* <input type="checkbox" onChange={() => setSwap()}/> */}
                <input type="checkbox"/>
                <span className="checkbox-custom"></span>
              </label>
              <label className="checkbox-container">
                Lease
                {/* <input type="checkbox" onChange={() => setLease()}/> */}
                <input type="checkbox"/>
                <span className="checkbox-custom"></span>
              </label>
              <label className="checkbox-container">
                Enquire
                {/* <input type="checkbox" onChange={() => setEnquire()}/> */}
                <input type="checkbox"/>
                <span className="checkbox-custom"></span>
              </label>
            </div>
          </div>
        </Wrapper>
      </div>
    )
  }
}

export default AssetFilter;