import React, { Component } from "react";
import { MessageSquare } from "react-feather";
import { connect } from "react-redux";
import PubNub, { generateUUID } from "pubnub";
import { PubNubProvider } from "pubnub-react";

class Room extends Component {
    constructor(props){
        super(props)
        this.state ={
            pubnub: null,
            chatData: this.props.location.state.chat.messages,
            channel: null,
            rating: null,
            ratingVisible: false,
            messagesLoaded: false,  
        }
    }
  componentDidMount() {
    const pubnub = new PubNub({
      publishKey: "pub-c-9d3669c9-65a0-4632-9db7-5cb4202c53f6",
      subscribeKey: "sub-c-7ded1c68-997b-11ea-8e71-f2b83ac9263d",
      uuid: generateUUID,
    });
    // pubnub.setUUID(generateUUID);
    // console.log("[props]",this.props.location.state.chat.chat.channel );
    this.setState(
      {
        pubnub,
        chatData: this.props.location.state.chat.messages,
        channel: this.props.location.state.chat.chat.channel,
      },
      () => {
        // this.handleGetMessage();
      }
    );

    const listener = {
      message: (envelope) => {
        // console.log("[props]",envelope);
        this.updateMessageList(envelope);
      },
    };

    pubnub && pubnub.addListener(listener);
    pubnub.subscribe({
      channels: [this.props.location.state.chat.chat.channel],
    });
  }

  updateMessageList = (message) => {
    const { chatData } = this.state;
    let newChatData = { ...chatData };
    console.log("[props]",message);
    console.log("[props]",chatData);

    if (newChatData.messages) {
      newChatData.push(message);
      this.setState({ chatData: newChatData });
    } else {
      newChatData = [];
      newChatData.push(message);
      this.setState({ chatData: newChatData });
    }
  };
  sendMessage = () => {
    // Clear the input field.
    const { input, channel, pubnub } = this.state;
    const { userInfo } = this.props;
    // Create the message with random `id`.
    // console.log("[props]",input);
    const message = {
      title: input,
      description: input,
      author: userInfo._id,
    };

    // Publish our message to the channel `chat`
    pubnub.publish({
      channel,
      message,
    });
    this.setState({input: ''});
  };

  render() {
    const { chatRooms } = this.props;

    // console.log("[props]", this.state.chatData);
    return (
      <div className="click-for-chat">
        <i className="far fa-comments fa-2x"></i>
        <h4>Select a Conversation</h4>
        <p>
          Select a conversation to begin <br />
          negotiating your swap.
        </p>
        {this.state.chatData &&
          this.state.chatData.map((item) => (
            <scroll>
              <div>
                <button>{item.message.description}</button>
              </div>
            </scroll>
            // console.log('[chatRooms]', item)
          ))}
        <input
          placeholder="type"
          onChange={(value) => this.setState({ input: value.nativeEvent.data })}
        />
        <button onClick={this.sendMessage}>Submit</button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chatRooms: state.chat.chatRooms,
  userInfo: state.auth.userInfo
});

export default connect(mapStateToProps, null)(Room);
