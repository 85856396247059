import ReactGA from "react-ga";


const screenAnalytics = (page) => {
    const trackingId = "UA-154267652-1";
    ReactGA.initialize(trackingId);

    ReactGA.set({ page }); // Update the user's current page
    ReactGA.pageview(page); // Record a pageview for the given page
}

const eventsAnalytics = (category, action) => {
    ReactGA.event({
        category,
        action,
      });
}

export const Analytics = {
    screenAnalytics,
    eventsAnalytics
}