import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import PaymentCheckout from "./PaymentCheckout";

class Payment extends Component {
  render() {
    return (
      <StripeProvider apiKey="pk_test_XKI6pYjH9RmYyguogO2yFqTI">
        <Elements>
          <PaymentCheckout />
        </Elements>
      </StripeProvider>
    );
  }
}

export default Payment;
