import React, { Component } from "react";
import { Link } from "react-router-dom";

class AccountSettingsAssetsRow extends Component {
  state = {
    enabled: null
  };

  componentDidMount() {
    this.setState({
      enabled: this.props.asset._source.enabled
    });
  }

  onChange = (assetId, category) => {
    this.props.toggleVisibility(assetId, category);

    this.setState(prevState => ({
      enabled: !prevState.enabled
    }));
  };

  render() {
    const { postTitle, updatedAt, assetId, category } = this.props.asset._source;
    const {_id} = this.props.asset;
    const { enabled } = this.state;
    return (
      <div className="accountsettings__assets__row" key={assetId}>
        <div className="accountsettings__assets__row__item">
          <Link to={`/assets/${category}/${_id}`}>{postTitle}</Link>
        </div>
        <div className="accountsettings__assets__row__item">{updatedAt}</div>
        <div className="accountsettings__assets__row__item">
          <Link to={`/user/accountsettings/userassets/${assetId}`}>
            <div className="btn accountsettings__assets__btn">Edit</div>
          </Link>
          {enabled ? (
            <div
              className="btn accountsettings__assets__btn accountsettings__assets__btn--red"
              onClick={() => this.onChange(assetId, category)}
            >
              Disable
            </div>
          ) : (
            <div
              className="btn accountsettings__assets__btn accountsettings__assets__btn--green"
              onClick={() => this.onChange(assetId, category)}
            >
              Enable
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AccountSettingsAssetsRow;
