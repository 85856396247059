import React, { Component } from "react";
import Wrapper from "./Wrapper";
import ListingCard from "./ListingCard";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { categories } from "./../../utility/constants";
import { ListingsLoader } from "./Loaders";

class Listings extends Component {
  state = {
    isSmallScreen: false,
  };
  componentDidMount() {
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
  }
  updateWindowSize = () => {
    const { innerWidth } = window;
    if (innerWidth < 1000) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }
  };

  ListingsComponent = (props) => {
    if (props.isLoaded) {
      console.log(props);
      if (props.categoryParams && props.listings.length < 1) {
        return (
          <Wrapper>
            <h3>0 {props.category.value} listings found...</h3>
          </Wrapper>
        );
      }

      if (props.categoryParams && props.listings.length > 0) {
        let filteredListings =
        props.category.value === "All Listings" ||
        props.category.value === "All"
            ? props.listings
            : props.listings.filter((x) => x.category === props.category.value);
        return (
          <Wrapper>
            <h2 style={{ padding: "2rem 0rem 1rem", opacity: 0.6 }}>
              {filteredListings.length} {props.category.value} listings found...
            </h2>
            <div className="listings__grid-container">
              {filteredListings.map((listing, index) => (
                <ListingCard
                  key={index}
                  item={listing}
                  calculateDistance={props.calculateDistance}
                />
              ))}
            </div>
          </Wrapper>
        );
      }

      if (props.cityParams && props.listings.length < 1) {
        return (
          <Wrapper>
            <h2
              style={{
                padding: "2rem 0rem 1rem",
                opacity: 0.6,
                fontSize: "2.5rem",
                fontWeight: "400",
              }}
            >
              0 listings found in {props.city}...
            </h2>
          </Wrapper>
        );
      }

      if (props.listings.length > 0) {
        console.log("[props]", props.category.value, props.listings);
        let filteredListings =
          props.category.value === "All Listings" ||
          props.category.value === "All"
            ? props.listings
            : props.listings.filter((x) => x.category === props.category.value);
        return (
          <Wrapper>
            <h2
              style={{
                padding: "2rem 0rem 1rem",
                opacity: 0.6,
                fontSize: "2.5rem",
                fontWeight: "400",
              }}
            >
              {filteredListings.length} listings found...
            </h2>
            <div
              className="listings__grid-container"
              style={{ display: "flex", flexDirection:this.state.isSmallScreen ? "column" : 'row'}}
            >
              {filteredListings.map((listing, index) => (
                <ListingCard
                  key={index}
                  item={listing}
                  calculateDistance={props.calculateDistance}
                />
              ))}
            </div>
          </Wrapper>
        );
      }

      if (props.isSearchQuery && props.searchListings.length > 0) {
        let filteredListings =
          props.category.value === "All"
            ? props.searchListings
            : props.searchListings.filter(
                (x) => x.category === props.category.value
              );
        return (
          <Wrapper>
            <h2
              style={{
                padding: "2rem 0rem 1rem",
                opacity: 0.6,
                fontSize: "2.5rem",
                fontWeight: "400",
              }}
            >
              {filteredListings.length} listings found...
            </h2>
            <div className="listings__grid-container">
              {filteredListings.map((listing, index) => (
                <ListingCard
                  key={index}
                  item={listing}
                  calculateDistance={props.calculateDistance}
                />
              ))}
            </div>
          </Wrapper>
        );
      }

      if (props.isSearchQuery && props.searchListings.length < 1) {
        return (
          <Wrapper>
            <h2
              style={{
                padding: "2rem 0rem 1rem",
                opacity: 0.6,
                fontSize: "2.5rem",
                fontWeight: "400",
              }}
            >
              0 listings found for {props.query}...
            </h2>
          </Wrapper>
        );
      }

      let filteredListings =
        props.category.value === "All"
          ? props.listings
          : props.listings.filter((x) => x.category === props.category.value);

      // console.log('[props]', filteredListings)
      return (
        <Wrapper>
          <div className="listings__grid-container">
            {filteredListings.map((listing, index) => (
              <ListingCard
                key={index}
                item={listing}
                calculateDistance={props.calculateDistance}
              />
            ))}
          </div>
        </Wrapper>
      );
    } else {
      return <ListingsLoader />;
    }
  };
  render() {
    return this.ListingsComponent(this.props);
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.asset.listingsData,
    listingsError: state.asset.listingsDataError,
    userInfo: state.auth.userInfo,
  };
};

export default connect(mapStateToProps, actions)(Listings);
