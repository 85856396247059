import React from "react";
import FormInput from "../../FormInput/FormInput";
import NumberInput from "../../FormInput/NumberInput";
import SelectFormInput from "../../FormInput/SelectFormInput";
const AssetFormAircraftDetails = () => {

  const subCategoryOptions = [
    {
      display: "Choose A Subcategory",
      disable: true,
      value: ""
    },
    {
      display: "Leisure Planes",
      disable: false,
      value: "Leisure Planes"
    },
    {
      display: "Business Jets",
      disable: false,
      value: "Business Jets"
    },
    {
      display: "Helicopters",
      disable: false,
      value: "Helicopters"
    }];
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Details of Aircraft</div>
      <SelectFormInput 
            {...{name: "subCategory", 
            label: "Aircraft Subcategory", 
            type:"text", 
            options: subCategoryOptions}} 
            key={"a key"}
        />
      <FormInput label="Tail Number" name="tailNumber" />
      <FormInput label="airCert" name="airCert" />
      <FormInput label="faaCert" name="faaCert" />
      <FormInput label="Registration Cert" name="registrationCert" />
      <FormInput label="Ownership History" name="ownershipHistory" />
      <FormInput label="Damage History" name="damageHistory" />
      <FormInput label="faa337" name="faa337" />
      <NumberInput label="Operation Costs Per Hour" name="opCostsPerHr" />
      <FormInput label="Maintenance (Private)" name="maintenance.private" type="text"/>
      <FormInput label="Maintenance (Outsourced managed)" name="maintenance.managed" />
      <FormInput label="maintenanceReport" name="maintenanceReport" />
      <FormInput label="charterHistory" name="charterHistory" />


      <FormInput label="Avionics" name="specs.avionics" />
      <FormInput label="Exterior: Condition" name="specs.exterior.condition" />
      <FormInput label="Exterior: Last Painted Date" name="specs.exterior.lastPainted" />
      <NumberInput label="Total Hours Flied" name="specs.totalHours" />
      <NumberInput label="Total Landings Made " name="specs.totalLandings" />
      <FormInput label="Aux Power Unit" name="specs.auxPowerUnit" />
      <FormInput label="Manufacturer" name="specs.manufacturer" />
      <FormInput label="Model" name="specs.model" />
      <FormInput label="Serial Number" name="specs.serialNumber" />
      <FormInput label="Engine: Manufacturer" name="specs.engine.manufacturer" />
      <FormInput label="Engine: Total Time" name="specs.engine.engineTimeTotal" />
      <FormInput label="Engine: Cycles" name="specs.engine.engineCycles" />
      <FormInput label="Engine: Serial Number" name="specs.engine.serialNumber" />

      <FormInput label="Features" name= "features" />
      <FormInput label="Inspection Status" name="inspectionStatus" />
      <NumberInput label="Gross Weight " name="weight.gross" />
      <NumberInput label="Maximum Take off Weight " name="weight.maxTakeOff" />
      <NumberInput label="Maximum Landing Weight " name="weight.maxLanding" />
      <NumberInput label="Maximum Basic Weight " name="weight.basic" />
      <NumberInput label="Weight When Empty" name="weight.whenEmpty" />
      <NumberInput label="Fuel Capacity Weight" name="weight.fuelCap" />
      {/* Need suffix in the inputs */} 
    </div>
  );
};

export default AssetFormAircraftDetails;
