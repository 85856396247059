import {
  USER_GET_INVITES,
  USER_GET_GROUPS,
  USER_CURRENT_CHAT,
  USER_NEW_MESSAGE,
  USER_LEAVE_GROUP,
  USER_NEW_GROUP,
  USER_SOCKET,
  USER_GET_GROUP_ASSETS,
  FETCH_FIND_ROOMS,
  FETCH_FIND_ROOMS_SUCCESS,
  FETCH_FIND_ROOMS_FAILURE
} from "../actions/types";
//import console = require("console");
//import console = require("console");

const INITIAL_STATE = {
  messages: {},
  // groups: [],
  chatRooms: [],
  isLoading: false,
  error: {},
  invites: [],
  socket: {},
  groupAssets: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case USER_GET_MESSAGES:
    //   let messages = { ...state.messages };
    //   const receiverId = action.payload && action.payload.receiverId;
    //   const data = action.payload && action.payload.data;
    //   messages[receiverId] = data;
    //   return {
    //     messages
    //   };
    case FETCH_FIND_ROOMS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_FIND_ROOMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chatRooms: action.payload,
      };
    case FETCH_FIND_ROOMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case USER_GET_INVITES:
      return { ...state, invites: action.payload };

    case USER_GET_GROUPS:
      return { ...state, groups: action.payload };

    case USER_CURRENT_CHAT:
      const chatHistory = action.payload.messages;
      const memberInfo = state.groups[action.payload.groupID].members;
      const currentChat = chatHistory
        .map((msg) => ({
          text: msg.text,
          user: {
            id: msg.user.id,
            name: memberInfo[msg.user.id],
          },
        }))
        .reverse();
      console.log(currentChat);
      return { ...state, currentChat: currentChat };

    case USER_NEW_MESSAGE:
      var res = state.currentChat;
      res.unshift({
        text: action.payload.text,
        user: {
          id: action.payload.user.id,
          name: action.payload.user.name,
        },
      });
      console.log(res);
      return { ...state, currentChat: res };

    case USER_LEAVE_GROUP:
      // return {
      //   ...state,
      //   groups: state.groups.map(
      //     (group, i) =>group.groupID == payload.groupID ? group.members.map(member => )
      //   )
      // }
      break;

    case USER_GET_GROUP_ASSETS:
      return { ...state, groupAssets: action.payload };

    case USER_NEW_GROUP:
      var groups = state.groups;
      var newGroup = action.payload;
      groups[newGroup.groupID] = {
        archive: newGroup.archive,
        members: newGroup.members,
        owner: newGroup.owner,
      };
      return { ...state, groups: groups };

    case USER_SOCKET:
      return { ...state, socket: action.payload };

    default:
      return state;
  }
}
