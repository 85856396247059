import React, { Component } from 'react';
import axios from 'axios';
import qs from 'query-string';
import Wrapper from "../Layout/Wrapper";
import Layout from "../Layout/Layout";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";


const status = [
  'Gathering account information...',
  'Validating your stripe account...'
]

class StripeComponent extends Component {
  state = {
    count: 0,
    queryCode: '',
    currentStatus: ['Initializing connection...'],
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(this.state);
  }

  handleUI = () => {
    let { currentStatus, count } = this.state;

    this.setState({
      currentStatus: [...currentStatus, status[count]],
      count: count + 1
    });
  }

  parseQuery = async () => {
    const { location: { search} } = this.props;
    await this.setState({
      queryCode: qs.parse(search).code
    });
    await this.retrieveStripeId();
  }

  retrieveStripeId = () => {
    axios.post('http://localhost:4000/api/v1/user/stripe/token', {
      code: this.state.queryCode,
      userId: this.props.userInfo._id
    })
    .then(res => {
      if ( res.status === 200 ) {
        this.setState({
          currentStatus: [...this.state.currentStatus, "Success! Redirecting to post assets..."]
        });
      } else {
        this.setState({
          currentStatus: [...this.state.currentStatus, "Ooops, there has been an error. Please contact us."]
        })
      }
    })
    .then(() => {
      setTimeout(() => {
        this.props.history.push('/user/asset/addasset');
      }, 3000);
    })
    .catch(err => console.log(err));
  }

  componentDidMount() {
    setTimeout(() => {
      this.handleUI();
    }, 1200)

    setTimeout(() => {
      this.handleUI();
    }, 3200)

    setTimeout(() => {
      this.parseQuery();
    }, 6000)
  }

  render() {
    let { userInfo } = this.props;
    let { currentStatus } = this.state;
    console.log(this.props);
    return (
      <Layout>
        <Wrapper>
          <div className="stripe-confirm-container">
            <div className="stripe-confirm-updates">
              <div className="stripe-status">
                <div className="loader"></div>
                {
                  currentStatus.reverse().map((status, index) => {
                    if (index !== 0) 
                      return ( <h1 key={index} style={{"opacity": 0.15, "margin": "1rem 0rem"}}>{status}</h1> )
                    else  
                      return ( <h1 key={index} style={{"margin": "1rem 0rem"}}>{status}</h1> )
                  })
                }
              </div>
            </div>
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(StripeComponent);