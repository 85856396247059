import React, { Component } from "react";
import Slider from "react-slick";

class ShowAssetProductFiles extends Component {
  displayCarousel = (images, videos = null) => {
    const imagesResult = images.map((img, idx) => {
      return (
        <div className="show-asset__product__files__box" key={idx}>
          <img
            className="show-asset__product__files__img"
            src={img}
            alt={`p ${idx + 1}`}
          />
        </div>
      );
    });

    // const videosResult = videos.map(({ videoUrl }, idx) => {
    //   return (
    //     <div className="show-asset__product__files__box" key={idx}>
    //       <video className="show-asset__product__files__img" controls>
    //         <source src={videoUrl} type="video/mp4" />
    //       </video>
    //     </div>
    //   );
    // });
    return [...imagesResult];
  };

  handleClick = e => {
    e.stopPropagation();
  };

  render() {
    const { image } = this.props;
    // const settings = {
    //   customPaging: function(i) {
    //     if (images[i]) {
    //       return (
    //         <a>
    //           <img
    //             className="show-asset__product__files__preview"
    //             src={images[i]}
    //             alt={`mini ${i}`}
    //           />
    //         </a>
    //       );
    //     } 
    //     // else {
    //     //   return (
    //     //     <a>
    //     //       <img
    //     //         className="show-asset__product__files__preview"
    //     //         src={videos[0].thumbnailUrl}
    //     //         alt={`mini ${i}`}
    //     //       />
    //     //     </a>
    //     //   );
    //     // }
    //   },
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   adaptiveHeight: true,
    // };

    return (
      <div className="show-asset__product__files" onClick={this.handleClick}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          overflow: 'scroll',
          paddingTop: 50,
          paddingBottom: 50
        }}
      >
        {/* <Slider {...settings}>{this.displayCarousel(images)}</Slider> */}
        <img
          // className="show-asset__product__modal__img"
          src={image}
          alt="display"
          style={{
            // height: window.innerHeight - 100, 
            width: window.innerWidth - 100,
          }}
        />
      </div>
    );
  }
}

export default ShowAssetProductFiles;
