import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import bchainLogo from "../../images/bchain-logo.png";
import { reduxForm } from "redux-form";
import FormInput from "../FormInput/FormInput";

class ValidatePhone extends Component {
  componentDidMount() {
    if (this.props.userInfo && this.props.phoneSuccess) {
      this.props.history.push("/assets");
    }
    console.log(this.props);
  }

  onPhoneValidate = value => {
    console.log(value);
    let values = { ...value, ...this.props.userInfo };
    this.props.phoneValidate(values, () => {
      this.props.history.push("/login");
    });
  };

  onResendCode = () => {
    this.props.userResendCode(this.props.userInfo);
  };

  render() {
    return (
      <div className="form">
        <img className="form__logo" src={bchainLogo} alt="logo" />

        <div className="form__message u-margin-bottom-small">
          A code was sent to the registered phone number
        </div>

        <form onSubmit={this.props.handleSubmit(this.onPhoneValidate)}>
          <FormInput
            name="passCode"
            label="Please enter the validation code"
            type="text"
          />
          <button className="form__button">Validate</button>
        </form>
        <div
          className="form__button u-margin-top-small"
          onClick={this.onResendCode}
        >
          Resend Code
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = ["passCode"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    phoneSuccess: state.auth.phoneSuccess,
    redirectPath: state.auth.redirectPath
  };
};

export default reduxForm({ validate, form: "phoneValidate" })(
  connect(mapStateToProps, actions)(withRouter(ValidatePhone))
);
