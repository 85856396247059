import React from "react";
import Select from "react-select";
import { categories } from "./../../utility/constants";

const distance_by_radius = [
  { label: "5 Mile Radius", value: 5 },
  { label: "10 Mile Radius", value: 10 },
  { label: "25 Mile Radius", value: 25 },
  { label: "50 Mile Radius", value: 50 },
  { label: "100 Mile Radius", value: 100 }
];

const locationOptions = [
  { label: "Get My Location", value: "geolocate" },
  { label: "Enter a zipcode", value: "zipcode" }
];

const Filter = ({
  handleUpdate,
  location,
  category,
  estimate,
  distance,
  filterForResults,
  toggleDistance,
  toggleGeolocation
}) => {
  return (
    <div className="filter_container">
      {/* <div className="location__update">
        <h4 style={{ 'color': '#DF233A'}}>Filter Listings &rarr;</h4>
      </div> */}
      <div className="location__update">
        <h4>{location}</h4>
        <h3 onClick={toggleGeolocation}>Update</h3>
      </div>
      <div className="location__update">
        <h4>Within {distance.value} mi</h4>
        <h3 onClick={toggleDistance}>Update</h3>
      </div>
      <Select
        value={category}
        onChange={handleUpdate}
        name={"selectedCategory"}
        options={categories}
        placeholder={"Filter by Category"}
        className="category__filter"
      />
    </div>
  );
};

export default Filter;
