import React, { Component, Fragment } from "react";
import Camera from "./Camera";

class CapturePhoto extends Component {
  state = {
    camera: true
  };

  captureClickHandler = videoPlayer => {
    const self = this;
    this.setState(
      {
        camera: false
      },
      () => {
        self.canvas
          .getContext("2d")
          .drawImage(videoPlayer, 0, 0, self.canvas.width, self.canvas.height);
        videoPlayer.srcObject &&
          videoPlayer.srcObject.stop &&
          videoPlayer.srcObject.stop();
      }
    );
  };

  submitClickHandler = () => {
    this.canvas.toBlob(blob => {
      this.props.addPhotoFile("userImage", blob);
      this.props.onFormForward();
    });
  };

  reCaptureClickHandler = () => {
    this.setState({
      camera: true
    });

    this.props.removePhotoFile("userImage");
  };

  renderImage = () => (
    <Fragment>
      <div className="center-flex">
        <canvas
          ref={ref => {
            this.canvas = ref;
          }}
          width={240}
          height={180}
        />
      </div>

      <div>
        <button
          className="btn validate__capture__btn"
          onClick={this.reCaptureClickHandler}
        >
          Retake Photo
        </button>
        <button
          className="btn validate__capture__btn"
          onClick={this.submitClickHandler}
        >
          Continue
        </button>
      </div>
    </Fragment>
  );

  render() {
    return (
      <div className="validate__capture__photo">
        {this.state.camera && (
          <Camera clickHandler={this.captureClickHandler.bind(this)} />
        )}
        {!this.state.camera && this.renderImage()}
      </div>
    );
  }
}

export default CapturePhoto;
