import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import App from "./components/App/App";
import reducers from "./redux/reducers";
import "./sass/main.css";
import PubNub, { generateUUID } from "pubnub";
import { PubNubProvider } from "pubnub-react";

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
const pubnub = new PubNub({
  publishKey: "pub-c-9d3669c9-65a0-4632-9db7-5cb4202c53f6",
  subscribeKey: "sub-c-7ded1c68-997b-11ea-8e71-f2b83ac9263d",
  uuid: generateUUID,
});

ReactDOM.render(
  <PubNubProvider client={pubnub}>
    <Provider store={store}>
      <App className="app" />
    </Provider>
  </PubNubProvider>,
  document.getElementById("root")
);
