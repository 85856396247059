import React from "react";
import checkedIcon from "../../../icons/checked-icon.svg";
import minusIcon from "../../../icons/minus-icon.svg";

const AssetFormActionsButton = ({ checked, handleActions, label, icon }) => {
  return (
    <div
      className={ 
        checked
          ? `add-asset__actions__card add-asset__actions__card--selected`
          : `add-asset__actions__card`
      }
      onClick={() => handleActions(label.toLowerCase())}
    >
      <div className="add-asset__actions__card__box">
        <div className="add-asset__actions__card__box__label">{label}</div>
        <img
          className="add-asset__actions__card__box__icon"
          src={checked ? checkedIcon : minusIcon}
          alt="checked"
        />
      </div>
      <img className="add-asset__actions__card__img" src={icon} alt="label" />
    </div>
  );
};

export default AssetFormActionsButton;
