import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import auth from './auth';
import asset from './asset';
import chat from './chat';

export default combineReducers({
  auth,
  asset,
  chat,
  form: reduxForm
});
