import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import AssetbarView from "./AssetbarView";
import AssetbarAdd from "./AssetbarAdd";
import { CSSTransition, TransitionGroup } from "react-transition-group";
//import console = require("console");

class ChatroomAssetbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: 0,
      userID: "",
      name: "",
      assetID: ""
    };
  }

  prevScreen = () => {
    this.setState({ screen: 0 });
  };

  nextScreen = () => {
    this.setState({ screen: 1 });
  };

  display() {
    if (this.state.screen === 0)
      return (
        <CSSTransition key={0} timeout={500} classNames="left">
          <AssetbarView
            //groupID="5d1a685bb17a9b2647d8867f"
            groupID={this.props.activeChat}
            nextScreen={this.nextScreen}
            assetIndex={0}
            showModal={this.props.showModal}
            toggleAssetBar={this.props.toggleAssetBar}
            toggleAssetBarClass={this.props.toggleAssetBarClass}
            toggleExit={this.props.toggleExit}
          />
        </CSSTransition>
      );
    else if (this.state.screen === 1)
      return (
        <CSSTransition key={1} timeout={500} classNames="right">
          <AssetbarAdd
            prevScreen={this.prevScreen}
            userID={this.state.userID}
            name={this.state.name}
            toggleAssetBar={this.props.toggleAssetBar}
            toggleAssetBarClass={this.props.toggleAssetBarClass}
          />
        </CSSTransition>
      );
  }

  async componentDidMount() {
    await this.props.getAssetsByUserId(this.props.user._id, () => {});
  }

  render() {
    return (
      <div
        className={`chatroom__asset-sidebar ${this.props.toggleAssetBarClass}`}
      >
        {/* START - Exit button for mobile sidebar only */}
        <div className="exit-assetbar">
          <button onClick={this.props.toggleAssetBar}>
            <i className="far fa-times-circle fa-2x" />
          </button>
        </div>
        {/* END - Exit button for mobile sidebar only */}

        <TransitionGroup className="transition">
          {this.display()}
        </TransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(ChatroomAssetbar);
