import React, { Component } from 'react';
import { ZoomIn, ZoomOut, RotateCcw } from 'react-feather';

class CropForm extends Component {
  render() {
    let { prevFrame, nextFrame, category } = this.props;

    return (
      <div className="crop-container">
        <h1 onClick={prevFrame}>&larr;&nbsp; Back to Uploads</h1>
        <div className="crop-container__content">
          <div className="crop-photo">
            <div className="crop-photo-square">
              Square
            </div>
            <button>
              <ZoomOut size={12} /><br />
              Zoom Out
            </button>
            <button>
              <ZoomIn size={12} /><br />
              Zoom In
            </button>
            <button>
              <RotateCcw size={12} /><br />
              Rotate
            </button>
          </div>
          <div className="crop-list">
            <h4>Category: Art - Collection</h4>
            <h1>Crop Image(s)</h1>
            <div className="crop-list--items">
              <div className="crop-list--item">
                <img src="https://images.unsplash.com/photo-1533213443190-1d96641c17ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="list" />
                <h3>Titles</h3>
              </div>
              <div className="crop-list--item">
                <img src="https://images.unsplash.com/photo-1533213443190-1d96641c17ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="list" />
                <h3>Titles</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button
            onClick={nextFrame}
          >Add Information &nbsp;&rarr;</button>
        </div>
      </div>
    )
  }
}

export default CropForm;