import React, { Component } from "react";
import Wrapper from "../Layout/Wrapper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { categories, chunk } from "./../../utility/constants";

const chunkedCategories = chunk(categories, 8);

class ModernMenuDrop extends Component {
  searchCategory = async (e, query, assetType) => {
    await e.preventDefault();
    await this.props.closeDropdown();
    await this.props.getCategoryResults(query, assetType, this.redirectSearch);
    console.log(query, assetType);
  };

  redirectSearch = async e => {
    this.props.history.push(`/search/assets`);
  };

  render() {
    let displayed = this.props.shown
      ? "show-dropdown-menu"
      : "hide-dropdown-menu";

    return (
      <div className={`homepage__explore--dropdown ${displayed}`}>
        <div className="dropdown__links">
          <div className="dropdown__links--title">
            <h3>Explore All Categories (A-Z)</h3>
          </div>
          <div className="dropdown__links--lists">
            {chunkedCategories.map((category, index) => {
              return (
                <ul key={index}>
                  {category.map((name, idx) => (
                    <a href={`/explore/${name.label}`} key={idx}>
                      <li className="title">{name.label}</li>
                    </a>
                  ))}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(withRouter(ModernMenuDrop));
