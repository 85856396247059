import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

import Dropdown from "react-dropdown";

import downArrow from "../../images/down-arrow.png";
import upArrow from "../../images/up-arrow.png";

class AssetbarView extends Component {
  constructor(props) {
    super(props);
    this.state = { imageIndex: 0 };
  }

  /**
   * set state value when a different option in the dropdown menu is selected
   *
   * @param {string} value Selected dropdown menu option (asset title)
   */
  onChange = value => {
    this.setState({ imageIndex: 0 });
    this.props.groupAssets.forEach(asset => {
      if (asset.postTitle === value) {
        this.setState({ asset: asset });
      }
    });
  };

  /**
   * gets value to be put in the selected field of the dropdown menu, default to the first asset of the group
   */
  dropdownMenuValue = () => {
    if (!this.state.asset) return this.props.groupAssets[0].postTitle;
    else return this.state.asset.postTitle;
  };

  /**
   * renders the dropdown menu
   */
  renderMenu = () => {
    if (this.props.groupAssets.length === 0) return "";
    else {
      var assets = this.props.groupAssets;
      const options = assets.map(asset => asset.postTitle);
      return (
        <Dropdown
          controlClassName="control"
          placeholderClassName="placeholder"
          menuClassName="menuclass"
          options={options}
          value={this.dropdownMenuValue()}
          arrowClosed={<img className="arrow" src={downArrow} alt = "down arrow"/>}
          arrowOpen={<img className="arrow" src={upArrow} alt = "up arrow"/>}
          onChange={e => this.onChange(e.value)}
        />
      );
    }
  };

  /**
   * renders preferred trades in asset detail page
   *
   * @param {object} asset
   */
  renderPreferred = asset => {
    var preferred = [];
    var details = asset.swapDetails;
    if (details[0]) details = details[0]; //special case, will change model
    Object.keys(details).map(key => {
      if (details[key]) preferred.push(key);
    });
    return (
      <ul>
        {preferred.map(item => (
          <li> {item}</li>
        ))}
      </ul>
    );
  };

  /**
   * gets specs to display, for assets of type "art"
   *
   * @param {object} asset
   */
  getArtSpecs = asset => {
    const specs = (({
      address,
      appraisalValue,
      authenticityCert,
      condition,
      declaredOrigin,
      lease,
      paid,
      purchaseHistory,
      sell,
      swap
    }) => ({
      address,
      appraisalValue,
      authenticityCert,
      condition,
      declaredOrigin,
      lease,
      paid,
      purchaseHistory,
      sell,
      swap
    }))(asset);
    return specs;
  };

  /**
   * gets specs to display, for assets of type "boat"
   *
   * @param {object} asset
   */
  getBoatSpecs = asset => {
    const specs = (({
      address,
      annualOpExp,
      appraisalValue,
      lease,
      ownershipCert,
      paid,
      sell,
      specs,
      swap,
      vesselType
    }) => ({
      address,
      annualOpExp,
      appraisalValue,
      lease,
      ownershipCert,
      paid,
      sell,
      specs,
      swap,
      vesselType
    }))(asset);
    return specs;
  };

  /**
   * gets specs to display, for assets of type "collectible"
   *
   * @param {object} asset
   */
  getCollectibleSpecs = asset => {
    const specs = (({
      address,
      appraisalValue,
      authenticityCert,
      declaredOrigin,
      gradingReport,
      lease,
      paid,
      purchaseHistory,
      sell,
      swap
    }) => ({
      address,
      appraisalValue,
      authenticityCert,
      declaredOrigin,
      gradingReport,
      lease,
      paid,
      purchaseHistory,
      sell,
      swap
    }))(asset);
    return specs;
  };

  /**
   * gets specs to display, for assets of type "aircraft"
   *
   * @param {object} asset
   */
  getAircraftSpecs = asset => {
    const specs = (({
      address,
      airCert,
      charterHistory,
      damageHistory,
      faaCert,
      features,
      inspectionStatus,
      lease,
      maintenanceReport,
      opCostsPerHr,
      ownershipHistory,
      paid,
      registrationCert,
      sell,
      specs,
      swap,
      tailNumber,
      weight
    }) => ({
      address,
      airCert,
      charterHistory,
      damageHistory,
      faaCert,
      features,
      inspectionStatus,
      lease,
      maintenanceReport,
      opCostsPerHr,
      ownershipHistory,
      paid,
      registrationCert,
      sell,
      specs,
      swap,
      tailNumber,
      weight
    }))(asset);
    return specs;
  };

  /**
   * gets specs to display, for assets of type "realestate"
   *
   * @param {object} asset
   */
  getRealEstateSpecs = asset => {
    const specs = (({
      address,
      annualHoldCosts,
      appraisalValue,
      assetHeldBy,
      contracts,
      disclosureForms,
      generateIncome,
      landArea,
      lease,
      livingArea,
      paid,
      sell,
      swap
    }) => ({
      address,
      annualHoldCosts,
      appraisalValue,
      assetHeldBy,
      contracts,
      disclosureForms,
      generateIncome,
      landArea,
      lease,
      livingArea,
      paid,
      sell,
      swap
    }))(asset);
    return specs;
  };

  /**
   * gets specs to display, for assets of type "auto"
   *
   * @param {object} asset
   */
  getAutoSpecs = asset => {
    const specs = (({
      make,
      model,
      year,
      specs,
      mileage,
      mpg,
      vin,
      features,
      sell,
      swap,
      lease,
      address,
      paid
    }) => ({
      make,
      model,
      year,
      specs,
      mileage,
      mpg,
      vin,
      features,
      sell,
      swap,
      lease,
      address,
      paid
    }))(asset);
    return specs;
  };

  /**
   * gets specs to display, calls helper function for each asset type
   *
   * @param {object} asset
   */
  getSpecs = asset => {
    if (asset.category === "art") return this.getArtSpecs(asset);
    else if (asset.category === "boat") return this.getBoatSpecs(asset);
    else if (asset.category === "collectible")
      return this.getCollectibleSpecs(asset);
    else if (asset.category === "aircraft") return this.getAircraftSpecs(asset);
    else if (asset.category === "real estate")
      return this.getRealEstateSpecs(asset);
    else if (asset.category === "auto") return this.getAutoSpecs(asset);
  };

  /**
   * converts camel case to words separated by space, converts hard special cases
   *
   * @param {string} name
   */
  convertCamelCase = name => {
    const specials = {
      annualOpExp: "Annual Operating Expenses",
      authenticityCert: "Authenticity Certification",
      faaCert: "FAA Certification",
      opCostsPerHr: "Operating Cost Per Hour"
    };
    if (Object.keys(specials).includes(name)) return specials[name];
    else {
      var res = name
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1");
      return res.charAt(0).toUpperCase() + res.slice(1);
    }
  };

  /**
   * converts a boolean field to text
   *
   * @param {boolean} input
   */
  toString = input => {
    if (typeof input === "boolean") return input ? "yes" : "no";
    else return input;
  };

  /**
   * flatterns nested objects to a list of all fields using recursion
   *
   * @param {object} specs
   */
  renderSpecsRecursive = specs => {
    return (
      <ul>
        {Object.keys(specs).map(key =>
          typeof specs[key] !== "object" ||
          Array.isArray(specs[key]) ||
          Object.keys(specs[key]).length === 0 ? (
            <li>
              {this.convertCamelCase(key)}:&nbsp; {this.toString(specs[key])}
            </li>
          ) : (
            <li>{this.renderSpecsRecursive(specs[key])}</li>
          )
        )}
      </ul>
    );
  };

  /**
   * renders specs of an asset
   *
   * @param {object} asset
   */
  renderSpecs = asset => {
    const specs = this.getSpecs(asset);
    return this.renderSpecsRecursive(specs);
  };

  /**
   * renders documents of an asset
   *
   * @param {object} asset
   */
  renderDocs = asset => {
    return (
      <ul>
        {asset.documents.map((link, index) => (
          <li>
            <a href={link} target="_blank">
              Document #{index + 1}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  /**
   * renders the first image of an asset, and renders slideshow of all images upon user clicking on the image
   *
   * @param {array} images
   */
  renderImages = images => {
    return (
      <div className="assetDetail__images">
        <img
          className="assetDetail__images-main"
          src={images[this.state.imageIndex]}
          onClick={() => this.props.showModal(this.state.asset.images)}
        />
      </div>
    );
  };

  /**
   * converts a number to a string with commas as thousands separators
   *
   * @param {number} x
   */
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  renderAsset = () => {
    if (!this.state.asset) return "";
    else {
      var asset = this.state.asset;
      return (
        <div className="assetDetail" ref="overflowBox">
          {this.renderImages(asset.images)}

          <div className="assetDetail__title">{asset.postTitle}</div>
          <div className="assetDetail__price">
            &#x24; &nbsp; {this.numberWithCommas(asset.price)}
          </div>
          <div className="assetDetail__description">
            <p>Full Summary:</p> {asset.fullDescription}
          </div>
          <div className="assetDetail__preferred">
            <p>Preferred trades:</p> {this.renderPreferred(asset)}
          </div>
          <div className="assetDetail__specs">
            <p>Specifications:</p> {this.renderSpecs(asset)}
          </div>
          <div className="assetDetail__documents">
            <p>Documents:</p> {this.renderDocs(asset)}
            <br />
          </div>
        </div>
      );
    }
  };

  async componentDidMount() {
    if (this.props.groupAssets) {
      const cur_asset = this.props.groupAssets[0];
      this.setState({ asset: cur_asset });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.groupID !== this.props.groupID) {
      await this.props.getGroupAssets(this.props.groupID);
      const cur_asset = this.props.groupAssets[0];
      this.setState({ asset: cur_asset });
    }

    if (this.state.asset) {
      var el = this.refs.overflowBox;
      el.scrollTop = 0;
    }
  }

  render() {
    if (!this.props.groupID || !this.props.groups) {
      return (
        <div className={`briefcase ${this.props.toggleAssetBarClass}`}>
          <div className={`exit-assetbar ${this.props.toggleExit}`}>
            <button onClick={this.props.toggleAssetBar}>
              <i className="far fa-times-circle fa-2x" />
            </button>
          </div>
          <div className="briefcase__header">
            {/*  "click a chat to start" message?  */}
          </div>
        </div>
      );
    } else {
      return (
        <div className={`briefcase ${this.props.toggleAssetBarClass}`}>
          <div className={`exit-assetbar ${this.props.toggleExit}`}>
            <button onClick={this.props.toggleAssetBar}>
              <i className="far fa-times-circle fa-2x" />
            </button>
          </div>

          <div
            className="briefcase__addAsset"
            onClick={() => this.props.nextScreen()}
          >
            <p>Add More &nbsp; &#x3e;</p>
          </div>

          {this.renderMenu()}
          {this.renderAsset()}
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites,
    groupAssets: state.asset.groupAssets
  };
};

export default connect(
  mapStateToProps,
  actions
)(AssetbarView);
