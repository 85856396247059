import React from "react";
import FormInput from "../../FormInput/FormInput";
import NumberInput from "../../FormInput/NumberInput";
import SelectFormInput from "../../FormInput/SelectFormInput";
const AssetFormBoatDetails = () => { 
  const subCategoryOptions = [
    {
      display: "Choose A Subcategory",
      disable: true,
      value: ""
    },
    {
      display: "Yachts",
      disable: false,
      value: "Yachts"
    },
    {
      display: "Sailboats",
      disable: false,
      value: "Sailboats"
    }];
  const crewOptions = [
    {
      display: "Crew Required?",
      disable: true,
      value: ""
    },
    {
      display: "Yes",
      disable: false,
      value: true
    },
    {
      display: "No",
      disable: false,
      value: false
    }];
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Details of Boat</div>
      <SelectFormInput 
        {...{name: "subCategory", 
        label: "Boat Subcategory", 
        type:"text", 
        options: subCategoryOptions}} 
        key={"categoryKey"}
        />
      <FormInput label="Vessel Type " name="vesselType" />
      <FormInput label="Ownership Cert" name="ownershipCert" />
      <FormInput label="Charter History" name="charterHistory" />
      <FormInput label="Annual Operation Expense" name="annualOpExp" />
      <NumberInput label="Annual Income (if any)" name="annualIncome" />
      <FormInput label="Architect (if known)" name="architect" />
      <FormInput label="Maximum allowed guests number" name="specs.interior.guests" />
      <NumberInput label="Interior staterooms" name="specs.interior.staterooms" />
      <SelectFormInput 
        {...{name: "specs.interior.crewRequired", 
        label: "Boat Subcategory", 
        type:"boolean", 
        options: crewOptions}} 
        key={"crewRequired"}
        />
      <FormInput label="Interior stylist (if any)" name="specs.interior.stylist" />
      <FormInput label="Exterior Builder" name="specs.exterior.build.builder" />
      <FormInput label="Builder's Location" name="specs.exterior.build.builderLocation" />
      <FormInput label="Exterior Builder" name="specs.exterior.build.stylist" />
      <NumberInput label="Hull: length" name="specs.exterior.hull.length" />
      <FormInput label="Hull: beam" name="specs.exterior.hull.beam" />
      <FormInput label="Hull: draft" name="specs.exterior.hull.draft" />
      <FormInput label="Engine" name="specs.performance.engines" />
      <FormInput label="Engine Model" name="specs.performance.engineModel" />
      <NumberInput label="Maximum Speed (knot)" name="specs.performance.maxSpeed" />
      <NumberInput label="Horsepower" name="specs.performance.horsepower" />
      <NumberInput label="Cruise Speed" name="specs.performance.cruiseSpeed" />
      <NumberInput label="Cruise Range" name="specs.performance.cruiseRange" />
      <NumberInput label="Water Capacity" name="specs.performance.waterCapacity" />
      <NumberInput label="Fuel Capacity" name="specs.performance.fuelCapacity" />
    </div>
  );
};

export default AssetFormBoatDetails;
