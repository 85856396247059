import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from 'react-bootstrap'
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import TopNavbar from "./TopNavbar";
import MobileNavbar from "./MobileNavbar";
import MainNavbar from "./MainNavbar";
import Wrapper from "../Layout/Wrapper";
import { Search, User } from "react-feather";
import Logo from "../../icons/Bex_Logo.png";
import ModernMenuDrop from "../Menu/ModernMenuDrop";

class UserNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropMenu: false,
      query: "",
      screenWidth: window.innerWidth,
      displaySecSearchInput: false
    };
  }

  componentDidMount () {
    window.addEventListener('resize', this.onWindowRezise)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onWindowRezise)
  }

  onWindowRezise = e => this.setState({screenWidth: e.target.innerWidth})
  onHoverEnter = () => this.setState({ showDropMenu: true });
  onHoverExit = () => this.setState({ showDropMenu: false });

  handleInputChange = e => {
    console.log(e.target.value);
    this.setState({ query: e.target.value });
  };

  handleQuerySubmit = e => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/search",
      search: `?query=${this.state.query}`
    });

    console.log("Submitting: ", this.state.query);
  };

  userSignOut = () => {
    this.props.userSignOut(() => {
      this.props.history.push("/");
    });
  };

  render() {
    let { showDropMenu, screenWidth, displaySecSearchInput } = this.state;
    return (
      <div className="homepage__nav">
        <Wrapper>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div className="homepage__logo">
              <a href="/">
                <img src={Logo} alt="logo" />
              </a>
            </div>

            {screenWidth < 675 ? 
            <Button variant="success"
              onClick={()=>{
                this.setState({displaySecSearchInput: !displaySecSearchInput})
              }}
            >Search</Button>: 
            <div className="homepage__nav--search">
              <form onSubmit={this.handleQuerySubmit}>
                <input
                  type="text"
                  value={this.state.query}
                  onChange={this.handleInputChange}
                  placeholder="Search Bazaarxchanges..."
                />
                <Search size={14} />
              </form>
            </div>}
            
            {/* <div
              className="homepage__explore"
              onMouseEnter={this.onHoverEnter}
              onMouseLeave={this.onHoverExit}
            >
              Explore&nbsp;&nbsp;<i className="fas fa-chevron-down fa-xs"></i>
              <ModernMenuDrop
                shown={showDropMenu}
                closeDropdown={this.onHoverExit}
              />
            </div> */}
            {/* <div className="homepage__about">
              <a href="/events">Events</a>
            </div> */}
            <div className="homepage__login">
              <a href="/messages">Chat</a>
            </div>
            <div className="homepage__login">
              <a href="/user">Account</a>
            </div>
            <div className="homepage__signup">
              <a href="/upload">
                <button>Add Item</button>
              </a>
            </div>
            {/* <div className="homepage__signup">
              <button onClick={this.userSignOut}>Add Item</button>
            </div> */}
            
          </div>
          {displaySecSearchInput ? 
          <div style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <div className="homepage__nav--search"
              style={{width: '100%'}}
            >
              <form onSubmit={this.handleQuerySubmit}>
                <input
                  type="text"
                  value={this.state.query}
                  onChange={this.handleInputChange}
                  placeholder="Search Bazaarxchanges..."
                />
                <Search size={14} />
              </form>
            </div>
          </div> : null}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    query: state.asset.query
  };
};

export default connect(mapStateToProps, actions)(withRouter(UserNavbar));
