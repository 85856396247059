import React, { Component } from "react";
import { assetFormFields } from "../../formFields/formFields";

class NewForm extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const asset = this.props.asset;
    return (
      <form>
        {assetFormFields[asset].map((field, index) => (
          <div key={index}>
            <p>{field.label}</p>
            <input name={field.name} type={field.type || "text"} />
          </div>
        ))}
      </form>
    );
  }
}
export default NewForm;
