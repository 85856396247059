import React, { Component } from "react";
import SingleAssetAircraft from "./SingleAssetAircraft";
import SingleAssetArt from "./SingleAssetArt";
import SingleAssetAuto from "./SingleAssetAuto";
import SingleAssetBoat from "./SingleAssetBoat";
import SingleAssetRealEstate from "./SingleAssetRealEstate";
import SingleAssetCollectible from "./SingleAssetCollectible";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import ImageGallery from "react-image-gallery";

class SingleAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideshow: false
    };
  }

  displaySlideshow = links => {
    this.setState({ slideshow: true, slideshowLinks: links });
  };

  hideSlideshow = () => {
    this.setState({ slideshow: false });
  };

  renderItem = item => {
    return (
      <div>
        <img className="carouselSlide__image" src={item.original} alt = "carouselSlide" />
      </div>
    );
  };

  renderSlideShow = () => {
    if (this.state.slideshow) {
      const asset = this.props.single[0].asset;
      const images = asset.images.map(link => ({
        original: link,
        thumbnail: link,
        originalClass: "featured-slide"
      }));
      return (
        <div className="chatroom__container-modal">
          <span
            className="chatroom__container-modal-close"
            onClick={() => this.hideSlideshow()}
          >
            &times;
          </span>

          <div className="chatroom__container-modal-content">
            <ImageGallery
              items={images}
              renderItem={item => this.renderItem(item)}
              showPlayButton={false}
              showBullets={true}
              showFullscreenButton={false}
              showIndex={true}
            />
          </div>
        </div>
      );
    }
  };

  async componentDidMount() {
    let { category, assetId } = this.props.match.params;
    await this.props.userGetSingleAsset(assetId, category);
  }

  async componentDidUpdate() {
    let userId = this.props.userInfo._id;
    let { asset } = this.props.single[0];

    await this.props.userAddRecentPage( userId, asset);
  }

  render() {
    if (!this.props.single[0]) return <div>Loading</div>;
    let { asset } = this.props.single[0];
    console.log(this.props);
    let displayAsset =
      asset.category === "aircraft" ? (
        <SingleAssetAircraft
          {...this.props}
          displaySlideshow={this.displaySlideshow}
        />
      ) : asset.category === "art" ? (
        <SingleAssetArt
          {...this.props}
          displaySlideshow={this.displaySlideshow}
        />
      ) : asset.category === "auto" ? (
        <SingleAssetAuto
          {...this.props}
          displaySlideshow={this.displaySlideshow}
        />
      ) : asset.category === "realestate" ? (
        <SingleAssetRealEstate
          {...this.props}
          displaySlideshow={this.displaySlideshow}
        />
      ) : asset.category === "boat" ? (
        <SingleAssetBoat
          {...this.props}
          displaySlideshow={this.displaySlideshow}
        />
      ) : asset.category === "collectible" ? (
        <SingleAssetCollectible
          {...this.props}
          displaySlideshow={this.displaySlideshow}
        />
      ) : (
        "Component Not Found"
      );

    return (
      <div>
        {this.renderSlideShow()}
        {displayAsset}     
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    asset: state.asset.assetInfo,
    single: state.asset.singleAsset
  };
};

export default connect(
  mapStateToProps,
  actions
)(SingleAsset);
