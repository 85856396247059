import {
  FETCH_USER_BCHAIN_ADD_ASSET,
  FETCH_USER_BCHAIN_ADD_ASSET_SUCCESS,
  FETCH_USER_BCHAIN_ADD_ASSET_FAILURE,
  USER_ADD_ASSET,
  USER_GET_ASSET,
  USER_GET_MY_ASSETS,
  USER_FIND_ASSET,
  USER_FIND_RELEVANT_ASSET,
  USER_MADE_PAYMENT,
  USER_GET_CONNECTIONS_LIST,
  USER_TOGGLE_ASSET_FILTER,
  USER_GET_SINGLE_ASSET,
  SEARCH_ASSET,
  UPDATE_QUERY,
  SEARCH_ASSETS_BY_USERID,
  DISPLAY_KEYWORD,
  GROUP_ASSETS,
  GET_ALL_ELASTIC_RESULTS,
  GET_ASSETS_BY_USER_ID,
  GET_ALL_ARTISTS,
  GET_LOCATION_AND_LISTINGS,
} from "../actions/types";
//import console = require("console");

const INITIAL_STATE = {
  assetId: "",
  assetInfo: {},
  assets: [],
  myAssets: [],
  searchAssets: [],
  singleAsset: [],
  revelvantAsset: [],
  assetPaymentSuccess: false,
  connections: [],
  showAssetFilter: false,
  query: "",
  queryResults: [],
  userAssetsResults: [],
  keyword: "" ,
  groupAssets: [],
  elasticResults: [],
  artists: [],
  listingsData: [],
  filteredListingsData: [],
  listingsDataError: null,
  isLoading:true,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_BCHAIN_ADD_ASSET:
      return {
        ...state,
        isLoading:true,
      };
    case FETCH_USER_BCHAIN_ADD_ASSET_SUCCESS:
      return {
        ...state,
        isLoading:false,
        listingsData: [...state.listingsData, {...action.payload}]
      };
      case FETCH_USER_BCHAIN_ADD_ASSET_FAILURE:
      return {
        ...state,
        isLoading:false,
      };
    case USER_ADD_ASSET:
      return {
        ...state,
        assetId: action.payload
      };
    case USER_GET_ASSET:
      return {
        ...state,
        assetInfo: action.payload
      };
    case USER_GET_MY_ASSETS:
      return {
        ...state,
        myAssets: action.payload
      };
    case USER_FIND_ASSET:
      return {
        ...state,
        assets: action.payload
      };
    case USER_GET_SINGLE_ASSET:
      return {
        ...state,
        singleAsset: action.payload
      };
    case SEARCH_ASSET:
      return {
        ...state,
        queryResults: action.payload
      };
    case UPDATE_QUERY:
      return {
        ...state,
        query: action.payload
      };
    case SEARCH_ASSETS_BY_USERID:
      return {
        ...state,
        userAssetsResults: action.payload
      };
    case USER_FIND_RELEVANT_ASSET:
      return {
        ...state,
        relevantAsset: action.payload
      };
    case USER_GET_CONNECTIONS_LIST:
      return {
        ...state,
        connections: action.payload
      };
    case USER_MADE_PAYMENT:
      return {
        ...state,
        assetPaymentSuccess: true
      };
    case USER_TOGGLE_ASSET_FILTER:
      let response =
        action.payload === false || action.payload
          ? action.payload
          : !state.showAssetFilter;

      return {
        ...state,
        showAssetFilter: response
      };
    case DISPLAY_KEYWORD:
      return {
        ...state,
        keyword: action.payload
      };
    case GROUP_ASSETS:
      return {
        ...state,
        groupAssets: action.payload
      };
    case GET_ALL_ELASTIC_RESULTS:
      return {
        ...state,
        elasticResults: action.payload
      }
    case GET_ASSETS_BY_USER_ID:
      return {
        ...state,
        myAssets: action.payload
      }
    case GET_ALL_ARTISTS:
      return {
        ...state,
        artists: action.payload
      }
    case GET_LOCATION_AND_LISTINGS:
      return action.payload.error
        ? {
          ...state,
          listingsDataError: action.payload.error
        }
        : {
          ...state,
          listingsDataError: null,
          listingsData: action.payload,
          filteredListingsData: action.payload
        }
    default:
      return state;
  }
}
