import TagManager from 'react-gtm-module'


const initializeTagManager = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-TR28JB5',
        dataLayerName: 'PageDataLayer'
    }
     
    TagManager.initialize(tagManagerArgs)
}

export const TagManagerTools = {
    initializeTagManager,
}