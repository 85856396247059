import React from "react";
import AssetFormActionsButton from "./AssetFormActionsButton";
import sellIcon from "../../../icons/sell-icon.svg";
import swapIcon from "../../../icons/swap-icon.svg";
import leaseIcon from "../../../icons/lease-icon.svg";

class AssetFormActions extends React.Component {
  render() {
    const { sell, swap, lease, handleActions } = this.props;

    return (
      <div className="add-asset__actions">
        <div className="add-asset__actions__description">
          Please select how you would like your asset to be displayed on the
          platform.
        </div>
        <div className="add-asset__actions__selected">
          Currently Selected: {sell ? "Sell" : null} {swap ? "Swap" : null}{" "}
          {lease ? "Lease" : null}
        </div>
        <div className="add-asset__actions__cards">
          <AssetFormActionsButton
            checked={sell}
            handleActions={handleActions}
            label="Sell"
            icon={sellIcon}
          />
          <AssetFormActionsButton
            checked={swap}
            handleActions={handleActions}
            label="Swap"
            icon={swapIcon}
          />
          <AssetFormActionsButton
            checked={lease}
            handleActions={handleActions}
            label="Lease"
            icon={leaseIcon}
          />
        </div>
      </div>
    );
  }
}

export default AssetFormActions;
