import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper";
import PopCardSearch from "../Cards/PopCardSearch";
import Script from "../../images/bchain-logo-script-red.png";

class SearchAssets extends Component {
  render() {
    const searchTerm = this.props.keyWord;
    if (this.props.queryResults.length === 0) {
      return (
        <Layout>
          <Wrapper>
            <div className="no-results">
              <img src={Script} alt="logo" />
              <h1>
                Oops! <br /> No search results found...
              </h1>
              <a href="/">
                <button>&larr;&nbsp;&nbsp;Return to Home Page</button>
              </a>
            </div>
          </Wrapper>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <Wrapper>
            <div className="wrapper__searchpage">
              <div className="wrapper__searchpage--container">
                <div className="search-stats">
                  Displaying A Total of <em>{this.props.queryResults.length}</em>{" "}
                  Results Related to <strong>{searchTerm}</strong>
                </div>
                <div className="wrapper__searchpage--container-results">
                  <PopCardSearch results={this.props.queryResults} />
                </div>
              </div>
            </div>
          </Wrapper>
        </Layout>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    queryResults: state.asset.queryResults,
    keyWord: state.asset.keyword
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(SearchAssets));

//input into state
/*
from controller hit the backend route with that input
send the result back to the front end
*/
