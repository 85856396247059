import React from "react";
import FormInput from "../../FormInput/FormInput";
import NumberInput from "../../FormInput/NumberInput";
import SelectFormInput from "../../FormInput/SelectFormInput";
const AssetFormPropertyDetails = () => {
  const subCategoryOptions = [
    {
      display: "Choose A Subcategory",
      disable: true,
      value: ""
    },
    {
      display: "Commercial",
      disable: false,
      value: "Commercial"
    },
    {
      display: "Residential",
      disable: false,
      value: "Residential"
    }];
  const contractOptions = [
    {
      display: "Do you have contracts on the property?",
      disable: true,
      value: ""
    },
    {
      display: "Yes",
      disable: false,
      value: true
    },
    {
      display: "No",
      disable: false,
      value: false
    }
  ];
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Details of Property</div>
      <SelectFormInput 
        {...{name: "subCategory", 
        label: "Property Subcategory", 
        type:"text", 
        options: subCategoryOptions}} 
        key={"categoryKey"}
        />
      <FormInput label="Asset Held By" name="assetHeldBy" />
      <FormInput
        label="List your disclosure forms here"
        name="disclosureForms"
      />
      <FormInput label="Website" name="website" />
      <FormInput label="Annual Hold Costs" name="annualHoldCosts" />
      <SelectFormInput 
        {...{name: "contracts", 
        label: "Contracts selector", 
        type:"boolean", 
        options: contractOptions}} 
        key={"contractsKey"}
        />
      <NumberInput
        label="Living Area (sq/mt)"
        name="livingArea"
        suffix=" sq/mt"
      />
      <NumberInput label="Land Area (sq/mt)" name="landArea" suffix=" sq/mt" />
    </div>
  );
};

export default AssetFormPropertyDetails;
