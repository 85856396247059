import React from "react";
import Wrapper from "./Wrapper";
import { popular_locations, chunk } from "../../utility/constants";

const chunked_cities = chunk(popular_locations, 9);

const Locales = () => {
  return (
    <Wrapper>
      <div className="locale-container">
        <h1 className="locales-title">Popular Locations</h1>
        <div className="locale-grid">
          {chunked_cities.map((elem, indx) => (
            <ul key={indx}>
              {elem.map((item, index) => (
                <a key={index} href={`/city/${item.city}`}>
                  <h3>{item.city}</h3>
                </a>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default Locales;
