import React, { Component } from 'react';
import Underline from "./Underline";
import axios from 'axios';

class UpcomingEvent extends Component {
  state = {
    eventData: [],
    eventDataLoaded: false,
    singleEvent: {}
  }
  
  componentDidMount() {
    this.getEventData();
  }

  getEventData = () => {
    const eventsUrl = "https://api-dev.bazaarxchanges.com/api/v1/events/all";
    axios.get(eventsUrl)
    .then(res => {
      let data = [...res.data[0].events].filter(x => x !== null && x.type === "exhibit");
      let singleEventIndex = Math.floor(Math.random() * (data.length-1)) + 0;
      this.setState({
        events: data,
        eventDataLoaded: true,
        singleEvent: data[singleEventIndex]
      });
      console.log(this.state);
    })
    .catch(err => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="upcoming-events__event"> 
        <div className="event-date">
          <h5>Events</h5>
          <h6 dangerouslySetInnerHTML={{__html: this.state.singleEvent.date}}></h6>
          <h2 dangerouslySetInnerHTML={{__html: this.state.singleEvent.title}}></h2>
          <span>{this.state.singleEvent.org}</span>
          <a href="/events">
            View All Auctions &amp; Exhibitions&nbsp;&nbsp;&rarr;
          </a>
        </div>
        <img src={this.state.singleEvent.image} alt=""/>
      </div>
    )
  }
}

export default UpcomingEvent;
