import React, { Component } from "react";
import { reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Layout from "../Layout/Layout";
import AssetFormChoose from "./AssetFormComponents/AssetFormChoose";
import AssetFormArt from "./AssetForm/AssetFormArt";
import AssetFormAircraft from "./AssetForm/AssetFormAircraft";
import AssetFormBoat from "./AssetForm/AssetFormBoat";
import AssetFormCar from "./AssetForm/AssetFormCar";
import AssetFormCollectible from "./AssetForm/AssetFormCollectible";
import AssetFormProperty from "./AssetForm/AssetFormProperty";
import AssetComplete from "./AssetComplete";

class AddAsset extends Component {
  state = {
    formNumber: 1,
    formFields: {
      assetType: "",
      sell: true,
      swap: true,
      lease: true,
      swapDetails: {
        art: true,
        property: true,
        boat: true,
        aircraft: true,
        car: true,
        collectible: true
      },
      leaseDetails: {
        art: true,
        property: true,
        boat: true,
        aircraft: true,
        car: true,
        collectible: true
      },
      photos: {},
      videos: {},
      pdfs: {},
      address: ""
    }
  };

  handleActions = name => {
    this.setState(
      prevState => ({
        ...prevState,
        formFields: {
          ...prevState.formFields,
          [name]: !prevState.formFields[name]
        }
      }),
      () => {
        const { sell, swap, lease } = this.state.formFields;
        if (!sell && !swap && !lease) {
          this.setState(prevState => ({
            ...prevState,
            formFields: {
              ...prevState.formFields,
              [name]: true
            }
          }));
        }
      }
    );
  };

  handleDetailChange = (detail, type) => {
    this.setState(prevState => ({
      ...prevState,
      formFields: {
        ...prevState.formFields,
        [detail]: {
          ...prevState.formFields[detail],
          [type]: !prevState.formFields[detail][type]
        }
      }
    }));
  };

  updateFiles = (files, field) => {
    this.props.dispatch(change("assetForm", field, files));

    this.setState({
      formFields: {
        ...this.state.formFields,
        [field]: files
      }
    });
  };

  editAddress = address => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        address
      }
    });
  };

  resetState = () => {
    this.setState({
      formNumber: 1,
      formFields: {
        sell: true,
        swap: true,
        lease: true,
        swapDetails: {
          art: true,
          property: true,
          boat: true,
          aircraft: true,
          car: true,
          collectible: true
        },
        leaseDetails: {
          art: true,
          property: true,
          boat: true,
          aircraft: true,
          car: true,
          collectible: true
        },
        photos: {},
        videos: {},
        address: ""
      }
    });
  };

  onFormForward = values => {
    this.setState(prevState => ({
      formNumber: prevState.formNumber + 1,
      formFields: { ...prevState.formFields, ...values }
    }));
  };

  onFormBackward = () => {
    this.setState(prevState => ({
      formNumber: prevState.formNumber - 1
    }));
  };

  removeFormatPrice = price => {
    return price && price.replace(/[^0-9]/g, "");
  };

  onFormSubmit = values => {
    const { photos, videos, pdfs } = this.state.formFields;
    const formData = new FormData();
    Object.keys(photos).forEach(photo => {
      formData.append("images", photos[photo]);
    });

    if (videos) {
      Object.keys(videos).forEach(video => {
        formData.append("videos", videos[video]);
      });
    }

    let assetData = {
      ...this.state.formFields,
      ...values,
    };
    assetData.price = this.removeFormatPrice(assetData.price);
    assetData.appraisalValue = this.removeFormatPrice(assetData.appraisalValue);
    
    delete assetData.photos;
    delete assetData.videos;
    delete assetData.pdfs;

    formData.append("assetData", JSON.stringify(assetData));
    this.props.userAddAsset(formData, pdfs, () => {
      this.setState(prevState => ({
        formNumber: prevState.formNumber + 1
      }));
    });
  };

  renderForm = () => {
    const {
      formNumber,
      formFields,
      formFields: { assetType, videos, photos }
    } = this.state;
    if (formNumber === 1) {
      return (
        <AssetFormChoose
          onFormForward={this.onFormForward}
          resetState={this.resetState}
        />
      );
    } else if (formNumber === 2 && assetType === "AIRCRAFT") {
      return (
        <AssetFormAircraft
          onFormBackward={this.onFormBackward}
          onFormSubmit={this.onFormSubmit}
          formFields={formFields}
          videos={videos}
          photos={photos}
          handleActions={this.handleActions}
          handleDetailChange={this.handleDetailChange}
          updateFiles={this.updateFiles}
          editAddress={this.editAddress}
        />
      );
    } else if (formNumber === 2 && assetType === "ART") {
      return (
        <AssetFormArt
          onFormBackward={this.onFormBackward}
          onFormSubmit={this.onFormSubmit}
          formFields={formFields}
          videos={videos}
          photos={photos}
          handleActions={this.handleActions}
          handleDetailChange={this.handleDetailChange}
          updateFiles={this.updateFiles}
          editAddress={this.editAddress}
        />
      );
    } else if (formNumber === 2 && assetType === "BOAT") {
      return (
        <AssetFormBoat
          onFormBackward={this.onFormBackward}
          onFormSubmit={this.onFormSubmit}
          formFields={formFields}
          videos={videos}
          photos={photos}
          handleActions={this.handleActions}
          handleDetailChange={this.handleDetailChange}
          updateFiles={this.updateFiles}
          editAddress={this.editAddress}
        />
      );
    } else if (formNumber === 2 && assetType === "CAR") {
      return (
        <AssetFormCar
          onFormBackward={this.onFormBackward}
          onFormSubmit={this.onFormSubmit}
          formFields={formFields}
          videos={videos}
          photos={photos}
          handleActions={this.handleActions}
          handleDetailChange={this.handleDetailChange}
          updateFiles={this.updateFiles}
          editAddress={this.editAddress}
        />
      );
    } else if (formNumber === 2 && assetType === "COLLECTIBLE") {
      return (
        <AssetFormCollectible
          onFormBackward={this.onFormBackward}
          onFormSubmit={this.onFormSubmit}
          formFields={formFields}
          videos={videos}
          photos={photos}
          handleActions={this.handleActions}
          handleDetailChange={this.handleDetailChange}
          updateFiles={this.updateFiles}
          editAddress={this.editAddress}
        />
      );
    } else if (formNumber === 2 && assetType === "PROPERTY") {
      return (
        <AssetFormProperty
          onFormSubmit={this.onFormSubmit}
          onFormBackward={this.onFormBackward}
          formFields={formFields}
          videos={videos}
          photos={photos}
          handleActions={this.handleActions}
          handleDetailChange={this.handleDetailChange}
          updateFiles={this.updateFiles}
          editAddress={this.editAddress}
        />
      );
    } else {
      return <AssetComplete />;
    }
  };

  render() {
    return (
      <Layout>
        <div className="add-asset">{this.renderForm()}</div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    assetPaymentSuccess: state.asset.assetPaymentSuccess
  };
};

export default reduxForm({
  form: "assetForm",
  destroyOnUnmount: true
})(
  connect(
    mapStateToProps,
    actions
  )(AddAsset)
);
