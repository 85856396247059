import React, { Component } from "react";
import { connect } from "react-redux";
import RegisterForm from "./RegisterForm";
import ValidatePhone from "../ValidatePhone/ValidatePhone";

class Register extends Component { 
  render() {
    console.log(this.props)
    const { userRegisterSuccess, userRegisterError } = this.props;
    return (
      <div className="register">
        {/* {!userRegisterSuccess ? ( */}
          <RegisterForm userRegisterError={userRegisterError} />
         {/* ) : (
          <ValidatePhone /> 
        )}  */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userRegisterSuccess: state.auth.userRegisterSuccess,
    userRegisterError: state.auth.userRegisterError
  };
};

export default connect(mapStateToProps)(Register);
