import React, { Component } from "react";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class PaymentCheckout extends Component {
  state = {
    name: ""
  };

  onChange = e => {
    this.setState({
      name: e.target.value
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { assetId } = this.props;

    if (this.props.stripe) {
      this.props.stripe.createToken().then((payload) => {
        if (payload.error) {
          throw new Error('error in creating token');
        }
        const token = payload.token.id;

        this.props.userMakePayment({ assetId, token });
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    if (!this.props.assetId) {
      return <div>Loading...</div>
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="payment__row">
          <div>Name:</div>
          <div>
            <input
              type="text"
              className="form__input"
              placeholder="Full Name"
              onChange={this.onChange}
            />
          </div>
        </div>

        <div className="payment__row">
          <div>Card number:</div>
          <div>
            <CardNumberElement className="form__input" />
          </div>
        </div>

        <div className="payment__row">
          <div>Expiration Date:</div>
          <div>
            <CardExpiryElement className="form__input" />
          </div>
        </div>

        <div className="payment__row">
          <div>CVC:</div>
          <div>
            <CardCVCElement className="form__input" />
          </div>
        </div>

        <button className="form__button payment__button">Pay</button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    assetId: state.asset.assetId
  };
};

export default connect(
  mapStateToProps,
  actions
)(injectStripe(PaymentCheckout));
