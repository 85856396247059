import React from 'react';

const hexCodeDateParse = (str) => {
  if (str.indexOf("&") === -1) return;

  let split = str.split("");
  split.splice(str.indexOf("&"), 8, " — ");
  return split.join("");
}

const hexCodeParse = (str) => {
  return { __html: str}
}

const parseHexTitle = (str) => {
  let style = { lineHeight: "2.2rem" }
  if ( str.indexOf("&") !== -1 ) {
    return <div style={style} dangerouslySetInnerHTML={hexCodeParse(str)} />;
  } else {
    return str;
  }
}

const parseHexLocation = (str) => {
  if ( str.indexOf("&") !== -1 ) {
    return <div dangerouslySetInnerHTML={hexCodeParse(str)} />;
  } else {
    return str;
  }
}

const EventsExhibits = (props) => {
  if (!props.data) {
    return <div>Loading...</div>
  }
  

  return (
    <div className="events-container">
      {
        props.data.map((item, id) => {
          return (
            <div className="events-card" key={id}>
              <div className="events-card--org">
                <span className="circle"></span>
                {item.org}
              </div>
              {
                item.image ? 
                <div className="events-card--image">
                  <img src={item.image} alt="auction"/>
                </div> :
                ""
              }
              <div className="events-card--title">
                {parseHexTitle(item.title)}
              </div>
              <div className="events-card--date">
                {hexCodeDateParse(item.date)}
              </div>
              <div className="events-card--location">
                {parseHexLocation(item.location)}
              </div>
              <div className="events-card--link">
                {
                  item.link ?
                  <a href={item.link}>Learn More &nbsp;&rarr;</a> : ""
                }
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

export default EventsExhibits;