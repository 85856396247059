import React from "react";
import Wrapper from "./Wrapper";

const Divider = () => {
  return (
    <Wrapper>
      <div className="divider-gray"></div>
    </Wrapper>
  );
};

export default Divider;
