import React, { Component } from "react";
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper";
import ShowAssetProduct from "./ShowAssetProduct";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Invite from "../Chat/StandardInvite";
import Breadcrumbs from "./Breadcrumbs";
import ClearHeart from "../../images/heart-clear-icon.png";
import FilledHeart from "../../images/heart-filled-icon.png";
import Preferences from "./Preferences";
import axios from 'axios';
import {
  ForSaleIcon,
  ForLeaseIcon,
  ForSwapIcon,
  FullOptions,
  SaleOrLease,
  SaleOrSwap,
  LeaseOrSwap
} from "./SingleIcons";

class SingleAssetAircraft extends Component {

  handleAccordion = async id => {
    await this.setState({
      activePanel: {
        [id]: !this.state.activePanel[id]
      }
    });
  };

  capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  addAssetToCart = (item) => {
    axios.post('http://localhost:4000/api/v1/cart/add', {
      userId: this.props.userInfo._id,
      item
    }).catch(err => console.log(err));
  }

  render() {
    if (!this.props.single[0]) return <div>Loading</div>;
    let { asset } = this.props.single[0];

    return (
      <Layout>
        <Wrapper>
          <Breadcrumbs {...this.props} />
          <div className="single-asset__container">
            <ShowAssetProduct
              {...this.props.single[0]}
              displaySlideshow={this.props.displaySlideshow}
            />
            <div className="single-asset__content">
              <div className="asset-title-sub">
                {this.capitalize(asset.category)}&nbsp;&nbsp;
                <span>&#8227;</span>
                &nbsp;&nbsp;
                {asset.subCategory}
              </div>
              <div className="product-id">Product Id: #{asset._id}</div>
              {asset.sell && asset.swap && asset.lease ? (
                <FullOptions />
              ) : asset.sell && asset.swap ? (
                <SaleOrSwap />
              ) : asset.sell && asset.lease ? (
                <SaleOrLease />
              ) : asset.lease && asset.swap ? (
                <LeaseOrSwap />
              ) : asset.sell ? (
                <ForSaleIcon />
              ) : asset.swap ? (
                <ForSwapIcon />
              ) : (
                <ForLeaseIcon />
              )}
              <div className="asset-title">{asset.postTitle}</div>
              <div className="asset-price">
                { !isNaN(asset.price)
                  ? parseInt(asset.price).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD"
                    })
                  : "Contact for Pricing"}
              </div>
              {
                asset.price <= 50000 ?
                <div className="asset-buttons">
                  <button 
                    className="cart-btn"
                    onClick={this.addAssetToCart(asset)}
                  > Add To Cart
                  </button>
                  <button className="heart-btn">
                    <img src={ClearHeart} />
                  </button>
                </div> :
                <div className="invite-btn">
                  <Invite
                    assetID={asset._id}
                    ownerID={asset.userId}
                    title={asset.postTitle}
                    receiverID={asset.userId}
                    assetCategory={asset.category}
                    assetId={asset._id}
                    assetTitle={asset.postTitle}
                    assetLink={`/assets/${asset.category}/${asset._id}`}
                    assetImageUrl={asset.images[0]}
                    assetPrice={asset.price}
                    assetUserId={this.props.single[0].userId}
                  />
                </div>
              }
              <div className="content-border" />
              <div className="asset-summary">
                <h4>Summary:</h4>
                <p>{asset.fullDescription}</p>
              </div>
              <div className="content-border" />
              <h4 className="pref-title">Swap Preferences:</h4>
              <div className="asset-preferences">
                <Preferences details={asset.swapDetails} />
              </div>
              <div className="content-border" />
              <div className="asset-details">
                <h4>Details:</h4>
                <ul className="asset-details--list">
                  <li>
                    Tail Number: <p>{asset.tailNumber}</p>
                  </li>
                  <li>
                    Air Certification: <p>{asset.airCert}</p>
                  </li>
                  <li>
                    FAA Certification: <p>{asset.faaCert}</p>
                  </li>
                  <li>
                    Registration Certification:{" "}
                    <p>{asset.registrationCert[0]}</p>
                  </li>
                  <li>
                    Ownership History: <p>{asset.ownershipHistory[0]}</p>
                  </li>
                  <li>
                    Damage History: <p>{asset.damageHistory[0]}</p>
                  </li>
                  <li>
                    FAA 337: <p>{asset.faa337}</p>
                  </li>
                  <li>
                    Operation Costs / Hr: <p>{asset.opCostsPerHr}</p>
                  </li>
                  <li>
                    Maintenance Report: <p>{asset.maintenanceReport}</p>
                  </li>
                  <li>
                    Charter History: <p>{asset.charterHistory}</p>
                  </li>
                  <li>
                    Avionics: <p>{asset.specs.avionics}</p>
                  </li>
                  <li>
                    Exterior: <p>{asset.specs.exterior.condition}</p>
                  </li>
                  <li>
                    Total Hours: <p>{asset.specs.totalHours}</p>
                  </li>
                  <li>
                    Total Landings: <p>{asset.specs.totalLandings}</p>
                  </li>
                  <li>
                    Auxiliary Power Unit: <p>{asset.specs.auxPowerUnit}</p>
                  </li>
                  <li>
                    Manufacturer: <p>{asset.specs.manufacturer}</p>
                  </li>
                  <li>
                    Model: <p>{asset.specs.model}</p>
                  </li>
                  <li>
                    Serial No: <p>{asset.specs.serialNumber}</p>
                  </li>
                  <li>
                    Engine Manufacturer:{" "}
                    <p>{asset.specs.engine.manufacturer}</p>
                  </li>
                  <li>
                    Engine Total Time:{" "}
                    <p>{asset.specs.engine.engineTimeTotal}</p>
                  </li>
                  <li>
                    Engine Cycles: <p>{asset.specs.engine.cycles}</p>
                  </li>
                  <li>
                    Engine Serial No: <p>{asset.specs.engine.serialNumber}</p>
                  </li>
                  <li>
                    Features: <p>{asset.specs.features}</p>
                  </li>
                  <li>
                    Inspection Status: <p>{asset.specs.inspectionStatus}</p>
                  </li>
                  <li>
                    Maintenance Program: <p>{asset.specs.maintenanceProgram}</p>
                  </li>
                  <li>
                    Gross Weight: <p>{asset.weight.gross}</p>
                  </li>
                  <li>
                    Max Take Off Weight: <p>{asset.weight.maxTakeOff}</p>
                  </li>
                  <li>
                    Max Landing Weight: <p>{asset.weight.maxLanding}</p>
                  </li>
                  <li>
                    Basic Weight: <p>{asset.weight.basic}</p>
                  </li>
                  <li>
                    Empty Weight: <p>{asset.weight.whenEmpty}</p>
                  </li>
                  <li>
                    Fuel Capacity: <p>{asset.weight.fuelCapacity}</p>
                  </li>
                </ul>
              </div>
              <div className="content-border" />
              <div className="asset-documents">
                <h4>Documents:</h4>
                {this.props.userInfo ? (
                  asset.documents.length > 0 ? (
                    asset.documents.map((doc, index) => (
                      <a href={doc} key={index}>
                        <p>Document #{index}</p>
                      </a>
                    ))
                  ) : (
                    <p>No Documents Found...</p>
                  )
                ) : (
                  <p>
                    Please <a href="/login">LOGIN</a> or{" "}
                    <a href="register">REGISTER</a> to view documents
                  </p>
                )}
              </div>
            </div>
          </div>
        </Wrapper>
        {
          this.props.userInfo ? 
          <Wrapper>
            <h1 className="assets-visited-title">Recently Visited</h1>
            <div className="assets-visited">
              <div className="assets-visited-row">
                {
                  this.props.userInfo.recentlyVisited.map((item, index) => (
                    <div className="assets-visited-item">
                      <a href={`/assets/${item.category}/${item._id}`} key={index}>
                        <h6>{item.postTitle}</h6>
                        <img src={item.images[0]} alt="asset"/>
                        <h6>
                        {
                          !isNaN(item.price)
                          ? parseInt(item.price).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD"
                            })
                          : "Contact for Pricing"
                        }
                        </h6>
                      </a>
                    </div>
                  ))
                }
              </div>
            </div> 
          </Wrapper> : ""
        }
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    asset: state.asset.assetInfo,
    single: state.asset.singleAsset,
    userInfo: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(SingleAssetAircraft);
