import React, { Component } from "react";
import { assetFormFields } from "../../formFields/formFields";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { listing_conditions, categories } from "../../utility/constants";

const animatedComponents = makeAnimated();
class UploadForm extends Component {
  render() {
    let {
      category,
      description,
      estimatedValue,
      swapPreferences,
      condition,
      handleUploadState,
      title,
      setCondition,
      isCollection,
      inProgress,
      setPreferences,
      submitListing
    } = this.props;

    return (
      <div className="info-container">
        <h1 onClick={this.props.prevFrame}>&larr;&nbsp; Back to Images</h1>
        <div className="info-container__title">
          <h2>Add Details</h2>
          {<h3>{isCollection ? "Collection" : "Single Listing"}</h3>}
          <h3>Category: {category.value} </h3>
        </div>
        <div className="info-container__form-container">
          <form className={"form-height-std"}>
            <input
              type="text"
              placeholder="Add Title"
              value={title}
              onChange={e => handleUploadState("title", e)}
            />
            <input
              type="text"
              placeholder="Add Estimated Value ( ex. 1,234,567 )"
              value={estimatedValue}
              onChange={e => handleUploadState("value", e)}
            />
            <Select
              value={condition}
              onChange={setCondition}
              options={listing_conditions}
              placeholder={"Select a Condition"}
              className="condition__filter"
              classNamePrefix="react-select"
            />
            <Select
              value={swapPreferences}
              onChange={setPreferences}
              components={animatedComponents}
              options={categories}
              placeholder={"Select your Swap Preferences"}
              className="condition__filter"
              classNamePrefix="react-select"
              isMulti
            />
            <textarea
              type="text"
              placeholder="Add Description"
              onChange={e => handleUploadState("description", e)}
              value={description}
            />
          </form>
          <div className="btn-container">
            <button onClick={submitListing}>Submit Listing &nbsp;&rarr;</button>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadForm;
