import React from "react";
import WebBanner from "../../images/bex-banner-web.png";
import MobileBanner from "../../images/bex-banner-mobile2.png";
import AndroidDownwloadButton from "../../images/download-on-the-app-store-button.png";
import AppleDownwloadButton from "../../images/download-on-the-apple-store-button.png";
import { Analytics } from "../../utility/analytics";
import AppleDownloadButton from "../../images/apple_download.jpg";
import GoogleDownloadButton from "../../images/google_download.png";
import Loader from "../Loader/AssetLoader";

const Banner = ({ screenWidth, bannerEvent }) => {
  return (
    <div className="hero_container">
      <div className="hero_container__left-item">
        {screenWidth <= 900 ? (
          <div style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                zIndex: 1,
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <img
                onClick={() => {
                  Analytics.eventsAnalytics(
                    "download-link",
                    "Android download"
                  );
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bexmobile&hl=en_US",
                    "_blank"
                  );
                }}
                src={AndroidDownwloadButton}
                style={{
                  cursor: "pointer",
                  width: 174,
                  height: 45,
                  position: "absolute",
                  bottom: 30,
                }}
              />
              <img
                onClick={() => {
                  Analytics.eventsAnalytics("download-link", "iOS download");
                  window.open(
                    "https://apps.apple.com/us/app/bazaarxchanges/id1494528970",
                    "_blank"
                  );
                }}
                src={AppleDownwloadButton}
                style={{
                  width: 174,
                  height: 90,
                  position: "absolute",
                  bottom: 60,
                  cursor: "pointer",
                }}
              />
            </div>
            <img src={MobileBanner} alt="mobile banner" />
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                left: 10,
              }}
            >
              <img
                onClick={() => {
                  Analytics.eventsAnalytics(
                    "download-link",
                    "Android download"
                  );
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bexmobile&hl=en_US",
                    "_blank"
                  );
                }}
                src={AndroidDownwloadButton}
                style={{
                  width: 104,
                  height: 30,
                  marginRight: 20,
                  cursor: "pointer",
                }}
              />
              <img
                onClick={() => {
                  Analytics.eventsAnalytics(
                    "download-link",
                    "iOS download"
                  );
                  window.open(
                    "https://apps.apple.com/us/app/bazaarxchanges/id1494528970",
                    "_blank"
                  );
                }}
                src={AppleDownwloadButton}
                style={{ width: 104, height: 60,  cursor: "pointer", }}
              />
            </div>
            <img src={WebBanner} alt="web banner" />
            {/* <img src={AppleDownloadButton} alt="mobile banner" style={{width:80, height:80}} /> */}
            {/* <button
              className="download_button"
              style={{ outline: "none", borderColor: "transparent" }}
            >
              <img
                src={GoogleDownloadButton}
                alt="mobile banner"
                style={{ width: 130, height: 50 }}
              />
              <img
                src={GoogleDownloadButton}
                alt="mobile banner"
                style={{ width: 130, height: 50 }}
              />
            </button> */}
          </div>
        )}
      </div>

      {bannerEvent ? (
        <div className="hero_container__right-item">
          <img src={bannerEvent.image} alt="place" />
          <h3>{bannerEvent.org}</h3>
          <h2>
            {bannerEvent.title.length > 50
              ? bannerEvent.title.substring(0, 50) + "..."
              : bannerEvent.title}
          </h2>
          <h4>
            <span>{bannerEvent.location + " "}</span>
            {bannerEvent.date}
          </h4>
        </div>
      ) : // <Loader />
      null}
    </div>
  );
};

export default Banner;
