import React, { Component } from "react";
import Wrapper from "../Layout/Wrapper";
import Layout from "../Layout/Layout";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";
import * as actions from "../../redux/actions";

import { IoIosClose, IoMdArrowForward, IoMdArrowBack } from "react-icons/io";
import { Alert } from "react-bootstrap";
import DeleteModalAlert from "./DeleteAlertModal";

class UserProfile extends Component {
  state = {
    isFilterVisible: false,
    rangeValue: 0,
    isSmallScreen: false,
    showSelectedListings: false,
    imageIndex: 0,
    showDeleteModal: false,
    deleteItem:false,
    item: {}
  };

  componentDidMount() {
    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
  }
  updateWindowSize = () => {
    const { innerWidth } = window;
    if (innerWidth < 608) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }
  };

  handleFilterClick = async () => {
    console.log("clicked!");
    await this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    });
    await console.log(this.state.isFilterVisible);
  };

  handleRangeSlider = (e) => {
    console.log(e.target.value);
    this.setState({
      rangeValue: e.target.value,
    });
  };

  handleDeleteListing = async (item) => {
    this.setState({item, showDeleteModal:true})
  };

  onClickYes = async () => {
    const { item } = this.state;
    const {
      deleteListing,
      userInfo: { _id },
    } = this.props;
    const data = {
      itemId: item._id,
      userId: _id,
    };
    this.setState({showDeleteModal: false, deleteItem: true})
    await deleteListing(data);
    window.location.reload();
  }

  onClickNo = () => {
    this.setState({showDeleteModal: false, deleteItem:false, item:{}})
  }

  showSelectedListing = () => {
    const { selectedListings, imageIndex,showDeleteModal } = this.state;
    console.log("[selectedListings]", selectedListings);
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100vh",
          backgroundColor: "black",
          opacity: 0.9,
          backgroundBlendMode: "multiply",
        }}
      >
        <h1
          style={{ position: "absolute", top: 30, left: "50%", color: "white" }}
        >
          {`${this.state.imageIndex + 1}/${selectedListings.length}`}
        </h1>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            // marginTop:50,
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "40vh",
            backgroundColor: "black",
          }}
        >
          <button
            style={{
              outline: "none",
              position: "absolute",
              top: 40,
              backgroundColor: "transparent",
              right: 30,
              // borderColor: "transparent",
              opacity: 1,
            }}
            onClick={() => {
              if (
                this.state.imageIndex <
                this.state.selectedListings.length - 1
              ) {
                this.setState({ imageIndex: this.state.imageIndex + 1 });
              }
            }}
          >
            <IoMdArrowForward
              size={80}
              style={{
                color: "white",
              }}
            />
          </button>
          <button
            style={{
              outline: "none",
              position: "absolute",
              top: 40,
              left: 30,
              backgroundColor: "transparent",
              right: 30,
              // borderColor: "transparent",
              opacity: 1,
            }}
            onClick={() => {
              if (this.state.imageIndex > 0) {
                this.setState({ imageIndex: this.state.imageIndex - 1 });
              }
            }}
          >
            <IoMdArrowBack size={80} style={{ color: "white" }} />
          </button>
          <button
            style={{
              position: "absolute",
              left: 10,
              top: 5,
              outline: "none",
              borderRadius: 100,
              backgroundColor: "transparent",
              opacity: 0.8,
              width: 30,
              height: 30,
            }}
            onClick={() =>
              this.setState({
                showSelectedListings: !this.state.showSelectedListings,
                imageIndex: 0,
              })
            }
          >
            <IoIosClose size={22} style={{ color: "white" }} />
          </button>
          {/* {selectedListings.map((listing, index) => (
          <div style={{}}>
            <img src={listing} style={{ width: '40vw', height: '40vh', backgroundPosition:'top' }} />
          </div>
        ))} */}
          <div style={{}}>
            <img
              src={selectedListings[imageIndex]}
              style={{
                width: 300,
                height: 300,
                marginTop: this.state.isSmallScreen ? 300 : 150,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { isFilterVisible, showSelectedListings, showDeleteModal } = this.state;

    let hiddenFilter = {
      position: "absolute",
      zIndex: "-2",
      opacity: 0,
    };

    let showFilter = {
      position: "static",
      opacity: 1,
      transition: "opacity 1s ease-in-out",
    };

    let filterStyle = isFilterVisible ? showFilter : hiddenFilter;
    let placeholder =
      "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";
    let { userInfo } = this.props;
    let signupDate = new Date(userInfo.createdAt);

    return (
      <Layout>
        {showSelectedListings && this.showSelectedListing()}
        <Wrapper>
          <div className="user-container">
          {showDeleteModal && <DeleteModalAlert onClickYes={this.onClickYes} onClickNo={this.onClickNo}/>}
            <div className="user-container--sidebar">
              <div className="sidebar-user-content--avatar">
                <img src={userInfo.avatar_image || placeholder} alt="avatar" />
              </div>
              <div className="sidebar-user-content">
                <div className="sidebar-user-content--verified">
                  <span>Verified</span>
                </div>
                <div className="sidebar-user-content--name">
                  {userInfo.name}
                </div>
                <div className="sidebar-user-content--item">
                  {userInfo.email}
                </div>
                <div className="sidebar-user-content--item">
                  Joined {signupDate.toDateString()}
                </div>
                <div className="sidebar-user-content--item">
                  {`Rated ${userInfo.rating.toFixed(2)}/5  (${userInfo.rating_votes} votes)`}
                </div>
                <div className="sidebar-user-content--item">
                  PromoCode: {userInfo.promoCode || "---"}
                </div>
                {/* <div
                  className="sidebar-user-content--item"
                  id="interact"
                  style={{ color: "#007AFF", opacity: 1 }}
                >
                  Change Avatar Photo
                </div> */}
                {/* <div
                  className="sidebar-user-content--item"
                  id="interact"
                  style={{ color: "#007AFF", opacity: 1 }}
                >
                  Update/Change Password
                </div> */}
                <div
                  className="sidebar-user-content--item"
                  id="exit-app"
                  style={{ color: "#DF233A", opacity: 1 }}
                  onClick={() => {
                    window.localStorage.removeItem("bexToken");
                    this.props.userSignOut(this.props.history.push("/"));
                  }}
                >
                  Sign Out of Bazaarxchanges
                </div>
              </div>
            </div>
            <h1 style={{ margin: "1rem 0rem", fontWeight: 400, opacity: 0.6 }}>
              {userInfo.items_posted.length} Posts
            </h1>
            <div
              className="user-container--assets"
              style={{
                display: "flex",
                flexDirection: this.state.isSmallScreen ? "column" : "row",
                alignItems: "center",
              }}
            >
              {userInfo.items_posted &&
                userInfo.items_posted.map((item, index) => (
                  <div
                    // onClick={() => {
                    //   this.setState({
                    //     selectedListings: item.images,
                    //     showSelectedListings: !this.state.showSelectedListings,
                    //   });
                    // }}
                    key={index}
                    className="edit-card"
                    style={{
                      width: 340,
                      marginRight: 10,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#757575",
                      borderRadius: 20,
                      padding: 10,
                    }}
                  >
                    <img
                     onClick={() => {
                      this.setState({
                        selectedListings: item.images,
                        showSelectedListings: !this.state.showSelectedListings,
                      });
                    }}
                      src={item.images[0]}
                      alt="item image"
                      style={{ width: 300, marginRight: 10, cursor:'pointer'  }}
                    />
                    <h3>{item.title}</h3>
                    <TimeAgo date={item.createdAt} />
                    <div className="edit-btn-group">
                      {/* <a href={`/edit/${item._id}`}>
                        <button className="edit-btn">Edit</button>
                      </a> */}
                      <button
                        onClick={() => this.handleDeleteListing(item)}
                        className="delete-btn"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    userInfo: state.auth.userInfo,
  };
};

export default connect(mapStateToProps, actions)(UserProfile);
