import React from "react";
import Logo from "../../images/bazaarxchanges-black.png";
import Wrapper from "../Layout/Wrapper";

// const searchCategory = async (e, query, assetType) => {
//   await e.preventDefault();
//   await this.props.getCategoryResults(query, assetType, this.redirectSearch);
// }

// const redirectSearch = async e => {
//   this.props.history.push(`/search/assets`);
// }
// href={`/explore/${name.label}`}

const Footer = () => {
  return (
    <div className="extended-footer">
      <Wrapper>
        {/* <div className="footer-title">Discover</div> */}
        <div className="extended-footer-container">
          <ul>
            <li className="list-title">Top Categories</li>
            <a href="/explore/Art">
              <li>Art</li>
            </a>
            <a href="/explore/Memorabilia">
              <li>Memorabilia</li>
            </a>
            <a href="/explore/Home & Garden">
              <li>Home &amp; Garden</li>
            </a>
            <a href="/explore/Cellular Phones">
              <li>Cellular Phones</li>
            </a>
            <a href="/explore/Shoes & Sneakers">
              <li>Shoes &amp; Sneakers</li>
            </a>
          </ul>
          <ul>
            <li className="list-title">Top Cities</li>
            <a href="/city/New York">
              <li>New York</li>
            </a>
            <a href="/city/Los Angeles">
              <li>Los Angeles</li>
            </a>
            <a href="/city/Denver">
              <li>Denver</li>
            </a>
            <a href="/city/Seattle">
              <li>Seattle</li>
            </a>
            <a href="/city/Miami">
              <li>Miami</li>
            </a>
          </ul>
          <ul>
            <li className="list-title">Bazaarxchanges</li>
            <a href="/terms">
              <li>Terms of Service</li>
            </a>
            <a href="/privacy">
              <li>Privacy Policy</li>
            </a>
            {/* <a href="/copyright">
              <li>Copyright Policy</li>
            </a>
            <a href="/assets/sculpture">
              <li>My Account</li>
            </a> */}
          </ul>
          <ul>
            <li className="list-title">About Us</li>
            {/* <a href="/assets/paintings">
              <li>Careers</li>
            </a>
            <a href="/assets/autos">
              <li>Internships</li>
            </a> */}
            <a href="/contact">
              <li>Contact Us</li>
            </a>
          </ul>
        </div>
        <div className="extended-footer-container top-border">
          <div className="sub-footer">
            <div className="footer-branding">
              <img src={Logo} alt="logo" />
              {/* <div className="media">
                <i className="fab fa-instagram fa-2x" />
                <i className="fab fa-facebook-f fa-2x" />
                <i className="fab fa-twitter fa-2x" />
                <i className="fab fa-linkedin-in fa-2x" />
                <i className="far fa-envelope fa-2x" />
              </div> */}
            </div>
            <div className="statement">
              <p>
                ©2020. BChain, LLC. 1330 Avenue of the Americas, New York, NY
                10019. All Rights Reserved. | Terms & Conditions |&nbsp;
                <a href="/privacy">Privacy Policy</a>&nbsp;| Accessibility
                Statement BChain, LLC® and the BazaarXchange Logo are service
                marks owned by BChain, LLC. All information provided herein has
                been obtained from sources believed reliable, but may be subject
                to errors, omissions, change of price, prior sale, or withdrawal
                without notice. BazaarXchange makes no representation, warranty
                or guaranty as to accuracy of any information contained herein.
                You should consult your advisor for an independent verification
                of any properties or assets.
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Footer;
