import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class LowerNavbar extends Component {
  captureQuery = e => {
    this.props.userUpdateQuery(e);
  }

  redirectSearch = e => {
    this.props.history.push(`/search/assets`);
  }
  
  onSearch = (e, subCategory, assetType) => {
    e.preventDefault();
    // const assetType = prompt("Enter the asset type you want to search!");
    this.props.getCategoryResults(subCategory, assetType, this.redirectSearch);
  }

  render() {
    return (
      <ul className="lower_navbar">
        <li>
          <a href="/buyer" className="link">
            Collectible&nbsp;&amp;<br/>Accessories 
            <i className="fas fa-angle-down fa-fw"></i>
          </a>
          <ul className="dropdown">
            <li className="dropdown--item"
              onClick={(e) => this.onSearch(e, "Watches", "collectibles")} >
              <a>
                Watches
              </a>
            </li>
            <li className="dropdown--item" 
              onClick={(e) => this.onSearch(e, "Gemstones", "collectibles")} >
              <a>
                Gemstones
              </a>
            </li>
            <li className="dropdown--item"
              onClick={(e) => this.onSearch(e, "Antiques", "collectibles")}>
              <a>
                Collectibles
              </a>
            </li>
            <li onClick={(e) => this.onSearch(e, "All", "collectibles")} 
              className="dropdown--item">
                <a className="link">
                All Collectibles&nbsp;&rarr;
                </a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/seller" className="link inner_link">
            Yachts&nbsp;&amp;<br/>Sailboats
            <i className="fas fa-angle-down fa-fw"></i>
          </a>
          <ul className="dropdown drop_inner">
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Yacht", "boats")} >
              <a className="link">
                Yachts
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Sailboats", "boats")} >
              <a className="link">
                Sailboats
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "All", "boats")} >
              <a className="ueslessLink">
                All Boats&nbsp;&rarr;
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/register" className="link inner_link">
            Art&nbsp;&amp;<br/>Collectibles
            <i className="fas fa-angle-down fa-fw"></i>
          </a>
          <ul className="dropdown drop_inner">
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Ceramics", "arts")} >
              <a className="ueslessLink">
                Ceramics
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Illustrations", "arts")} >
              <a className="ueslessLink">
                Illustrations
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Paintings", "arts")} >
              <a className="ueslessLink">
                Paintings
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Photography", "arts")}>
              <a className="ueslessLink">
                Photography
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Sculpture", "arts")}>
              <a className="ueslessLink">
                Sculpture
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "All", "arts")}>
              <a className="ueslessLink">
                All Art&nbsp;&rarr;
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/buyer" className="link inner_link">
            Cars&nbsp;&amp;<br/>Exotics
            <i className="fas fa-angle-down fa-fw"></i>
          </a>
          <ul className="dropdown drop_inner">
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Sports Cars", "autos")}>
              <a className="ueslessLink">
                Sports Cars
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Sedans", "autos")}>
              <a className="ueslessLink">
                Sedans
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Trucks\\/SUV", "autos")}>
              <a className="ueslessLink">
                Trucks/SUV&rsquo;s
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "All", "autos")}>
              <a className="ueslessLink">
                All Autos&nbsp;&rarr;
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/register" className="link inner_link">
            Private<br/>Planes
            <i className="fas fa-angle-down fa-fw"></i>
          </a>
          <ul className="dropdown drop_inner">
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Small Aircraft", "aircrafts")}>
              <a className="ueslessLink">
                Small Aircraft
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Business Jets", "aircrafts")}>
              <a className="ueslessLink">
                Business Jets
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "All", "aircrafts")}>
              <a className="ueslessLink">
                All Planes&nbsp;&rarr;
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/seller" className="link">
            Real<br/>Estate
            <i className="fas fa-angle-down fa-fw"></i>
          </a>
          <ul className="dropdown">
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Commercial", "realestates")}>
              <a className="ueslessLink">
                Commercial
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "Residential", "realestates")}>
              <a className="ueslessLink">
                Residential
              </a>
            </li>
            <li className="dropdown--item"
            onClick={(e) => this.onSearch(e, "All", "realestates")}>
              <a className="ueslessLink">
                All Property&nbsp;&rarr;
              </a>
            </li>
          </ul>
        </li>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    query: state.asset.query  
  };
};

export default connect(mapStateToProps, actions)(withRouter(LowerNavbar));
