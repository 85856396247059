import React from "react";
import Checkmark from "../../images/checkmark-green.png";

const capitalize = str => {
  if (str === "property") {
    return str.toUpperCase();
  }
  return str.toUpperCase() + "S";
};

const Preferences = props => {
  let { details } = props;

  return Object.keys(details).map((asset, index) => {
    if (details[asset]) {
      return (
        <div className="asset-bool" key={index}>
          <img src={Checkmark} />
          <p>{capitalize(asset)}</p>
        </div>
      );
    }
  });
};

export default Preferences;
