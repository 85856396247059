import React from "react";

const Breadcrumbs = props => {
  let { asset } = props.single[0];
  console.log(props);
  return (
    <div className="breadcrumbs">
      <a href="/">Home</a>
      <div className="breadcrumbs-arrow">></div>
      <a href="/assets/art">
        {asset.category.charAt(0).toUpperCase() + asset.category.slice(1)}
      </a>
      <div className="breadcrumbs-arrow">></div>
      <a href="/assets/art/paintings">{asset.subCategory}</a>
      <div className="breadcrumbs-arrow">></div>
      <p>{asset.postTitle}</p>
    </div>
  );
};

export default Breadcrumbs;
