import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, reset } from "redux-form";
import * as actions from "../../../redux/actions";
import AssetFormChooseButton from "./AssetFormChooseButton";
import { assetChoices } from "../../../formFields/formFields";

class AssetFormChoose extends Component {
  componentDidMount() {
    this.props.resetState();
    this.props.dispatch(reset("assetForm"));
  }

  render() {
    const { onFormForward, handleSubmit } = this.props;

    return (
      <div className="add-asset__choose">
        <div className="add-asset__choose__block">
          <div className="add-asset__choose__title">Choose a Type of Asset</div>
          <div className="add-asset__choose__choices">
            {assetChoices.map(({ iconName, faName }) => (
              <AssetFormChooseButton
                key={faName}
                iconName={iconName}
                faName={faName}
                onFormForward={onFormForward}
                handleSubmit={handleSubmit}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: "assetForm"
})(
  connect(
    null,
    actions
  )(AssetFormChoose)
);
