import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from 'axios';
import Logo from "../../images/bx-logo-main-black.png";
import { XSquare, Check } from 'react-feather';

class AddOrRemoveAssetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssets: [],
      addAssetFrame: 0
    }
  }

  addSelection = (item) => {
    console.log("adding...")
    this.setState({
      selectedAssets: [...this.state.selectedAssets, item]
    });
    console.log(this.state);
  }

  removeSelection = (item) => {
    console.log("removing");
    let selected = [...this.state.selectedAssets]
    this.setState({
      selectedAssets: selected.filter(x => x._id !== item._id)
    });
    console.log(this.state);
  }

  toggleSelection = (item) => {
    const { selectedAssets } = this.state;
    selectedAssets.includes(item) ?
    this.removeSelection(item) :
    this.addSelection(item);
  }

  closeModal = async () => {
    await this.props.handleAssetModal();
    await this.setState({ selectedAssets: []})
  }

  addAssetsToGroup = async () => {
    const { userInfo, briefcase, currentRoomId, reRender } = this.props;
    const { selectedAssets } = this.state;
    const status = userInfo._id === briefcase.owner ? "buyer" : "seller";

    axios.patch(`http://localhost:4000/api/v1/groupchat/updateGroupAssets/${briefcase._id}`, {
      status,
      items: selectedAssets
    })
    .then(async () => {
      await this.setState({
        addAssetFrame: 1
      });
    })
    .then(async () => {
      await setTimeout(() => {
        this.setState({
          addAssetFrame: 2
        });
      }, 2000)
  
      setTimeout(async () => {
        await this.setState({
          addAssetFrame: 0,
        });
        await this.closeModal();
        await reRender(currentRoomId);
      }, 3000)
    })
    .catch(err => console.log(err));
  }

  componentDidMount() {
    let { userInfo } = this.props;
    this.props.getUserAssets(userInfo._id);
  }

  render() {
    let {
      assetModalAction,
      myAssets,
    } = this.props;
    let { selectedAssets, addAssetFrame } = this.state;

    if (myAssets !== undefined && addAssetFrame === 0) {
      return (
        <div className={`chat-asset-modal ${assetModalAction}`}>
          <div className="chat-asset-modal-container">
            <XSquare 
              size={18} 
              color="black" 
              className="icon"
              onClick={this.closeModal}
            />
            <div className="chat-asset-modal-logo">
              <img src={Logo} alt="logo" />
            </div>
            <h1>Add / Remove Assets in Negotation</h1>
            <div className="chat-asset-modal-container-list">
              {
                myAssets.map((item, index) => {
                  if (item.asset !== null) {
                    return (
                      <div 
                        className={`listed-asset
                          ${selectedAssets.includes(item.asset) ? 'selected' : ''}
                        `} 
                        key={index}
                        onClick={() => this.toggleSelection(item.asset)}
                      >
                        <img src={item.asset.images[0]} alt="asset"/>
                        <div className="listed-asset-content">
                          <h2>
                            {item.asset.postTitle}
                            <span 
                              className={`payment ${item.asset.paid ? 'isPaid' : ''}`}
                            >
                              {item.asset.paid ? "Paid" : ""}
                            </span>
                          </h2>
                          <h3>{parseInt(item.asset.price).toLocaleString('en-US', { style: 'currency', currency: 'USD'})}</h3>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            <div className="selection-tracked">
              {
                selectedAssets.length > 0 ?
                <h4>{selectedAssets.length} assets added</h4> :
                <h4>0 assets added</h4>
              }
            </div>
            <button
              disabled={ selectedAssets.length <= 0 ? true : false }
              onClick={this.addAssetsToGroup}
            >
              Add&nbsp;&amp;&nbsp;Make Payment
            </button>
          </div>
        </div>
      );
    } else if (addAssetFrame === 1 ) {
      return (
        <div className={`chat-asset-modal ${assetModalAction}`}>
            <div className="chat-asset-modal-container">
            <div className="chat-asset-modal-logo">
              <img src={Logo} alt="logo" />
            </div>
            <div className="chat-email-modal-content-loader"> 
              <br/>
              <br/>
              <br/> 
              <br/>
              <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
              <h4>Adding your assets...</h4>
            </div>
          </div>
        </div>
      )
    } else if (addAssetFrame === 2 ) {
      return (
        <div className={`chat-asset-modal ${assetModalAction}`}>
            <div className="chat-asset-modal-container">
            <div className="chat-asset-modal-logo">
              <img src={Logo} alt="logo" />
            </div>
            <div className="chat-email-modal-content-success">  
              <br/>
              <br/>
              <br/> 
              <br/>
              <Check size={100} color={"green"} className="check-success" />
              <h4>Success!</h4>
          </div>
          </div>
        </div>
      )
    } else {
      return <div>Loading...</div>
    }
  }
};

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    briefcase: state.chat.groupAssets,
    myAssets: state.asset.myAssets
  };
};

export default connect(mapStateToProps, actions)(AddOrRemoveAssetModal);