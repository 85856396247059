import React from "react";
import SellIcon from "../../images/sale-icon.png";
import LeaseIcon from "../../images/lease-icon.png";
import SwapIcon from "../../images/swap-icon.png";

export const ForSaleIcon = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={SellIcon} />
        <p>FOR SALE</p>
      </div>
    </div>
  );
};

export const ForLeaseIcon = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={LeaseIcon} />
        <p>FOR LEASE</p>
      </div>
    </div>
  );
};

export const ForSwapIcon = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={SwapIcon} />
        <p>FOR SWAP</p>
      </div>
    </div>
  );
};

export const FullOptions = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={SellIcon} />
        <p>FOR SALE</p>
      </div>
      <div className="icon-description">
        <img src={LeaseIcon} />
        <p>FOR LEASE</p>
      </div>
      <div className="icon-description">
        <img src={SwapIcon} />
        <p>FOR SWAP</p>
      </div>
    </div>
  );
};

export const SaleOrSwap = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={SellIcon} />
        <p>FOR SALE</p>
      </div>
      <div className="icon-description">
        <img src={SwapIcon} />
        <p>FOR SWAP</p>
      </div>
    </div>
  );
};

export const saleOrLease = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={SellIcon} />
        <p>FOR SALE</p>
      </div>
      <div className="icon-description">
        <img src={LeaseIcon} />
        <p>FOR LEASE</p>
      </div>
    </div>
  );
};

export const LeaseOrSwap = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={LeaseIcon} />
        <p>FOR LEASE</p>
      </div>
      <div className="icon-description">
        <img src={SwapIcon} />
        <p>FOR SWAP</p>
      </div>
    </div>
  );
};

export const SaleOrLease = () => {
  return (
    <div className="asset-icons">
      <div className="icon-description">
        <img src={SellIcon} />
        <p>FOR SALE</p>
      </div>
      <div className="icon-description">
        <img src={LeaseIcon} />
        <p>FOR LEASE</p>
      </div>
    </div>
  );
};
