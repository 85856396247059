import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

const styles = {
  root: {
    color: "#6eabf1",
    "&$checked": {
      color: "#6eabf1"
    }
  },
  checked: {},
  fontSize: "30"
};

class AssetFormSwapChoices extends Component {
  render() {
    const {
      art,
      property,
      boat,
      aircraft,
      car,
      collectible,
      classes,
      handleDetailChange,
      detailType
    } = this.props;
    return (
      <div className="assets__filter__actions">
        <div className="assets__filter__actions__checkbox">
          <div className="assets__filter__actions__checkbox__label">Art</div>
          <Checkbox
            checked={art}
            onChange={() => handleDetailChange(detailType, "art")}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </div>
        <div className="assets__filter__actions__checkbox">
          <div className="assets__filter__actions__checkbox__label">
            Real Estate
          </div>
          <Checkbox
            checked={property}
            onChange={() => handleDetailChange(detailType, "property")}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </div>
        <div className="assets__filter__actions__checkbox">
          <div className="assets__filter__actions__checkbox__label">Boats</div>
          <Checkbox
            checked={boat}
            onChange={() => handleDetailChange(detailType, "boat")}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </div>
        <div className="assets__filter__actions__checkbox">
          <div className="assets__filter__actions__checkbox__label">
            Aircraft
          </div>
          <Checkbox
            checked={aircraft}
            onChange={() => handleDetailChange(detailType, "aircraft")}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </div>
        <div className="assets__filter__actions__checkbox">
          <div className="assets__filter__actions__checkbox__label">Car</div>
          <Checkbox
            checked={car}
            onChange={() => handleDetailChange(detailType, "car")}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </div>
        <div className="assets__filter__actions__checkbox">
          <div className="assets__filter__actions__checkbox__label">
            Collectible
          </div>
          <Checkbox
            checked={collectible}
            onChange={() => handleDetailChange(detailType, "collectible")}
            classes={{
              root: classes.root,
              checked: classes.checked
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AssetFormSwapChoices);
