import axios from "axios";
import {
  CHECK_USER_STATUS,
  FORGOT_PASSWORD,
  PHONE_VALIDATE,
  SAVE_REDIRECT_PATH,
  USER_LOGIN,
  USER_REGISTER,
  USER_RESET_PASSWORD,
  USER_UPDATE_INFO,
  USER_UPDATE_INFO_ERROR,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_ERROR,
  USER_SIGNOUT,
  REFRESH_USER,
} from "./types";

axios.defaults.withCredentials = true;

function getUrl(api) {
  // const baseUrl = process.env.REACT_APP_API_ENDPOINT || "";
  // deployment url
  // return "https://api-dev.bazaarxchanges.com" + api;

  // local development
  return "https://api-dev.bazaarxchanges.com" + api;
  // return "http://localhost:4000" + api;
}

// User login / register / signout

const userRegister = (values, redirectPath) => async (dispatch) => {
  try {
    // await axios.post(getUrl("/api/v2/auth/validate"), values);
    const response = await axios.post(
      getUrl("/api/v2/client/websiteRegistration"),
      values
    );
    dispatch({ type: USER_REGISTER, payload: values });
    redirectPath();
  } catch (error) {
    console.log("[register]", error);
    dispatch({ type: USER_REGISTER, payload: { error: error } });
  }
};

const userLogin = (values, redirectPath) => async (dispatch) => {
  try {
    console.log(values);
    // const response = await axios.post(getUrl("/api/v2/auth/login"), values);
    const response = await axios.post(
      getUrl("/api/v2/client/tempWebsiteLogin"),
      values
    );
    console.log(response);
    await window.localStorage.setItem("bexToken", response.data._id);
    dispatch({ type: USER_LOGIN, payload: response.data });
    redirectPath();
  } catch (error) {
    console.log("[Login]", error);
    dispatch({ type: USER_LOGIN, payload: { error: error } });
  }
};

const userLoginWithSocialMedia = (values, redirectPath) => async (dispatch) => {
  try {
    const response = await axios.post(getUrl("/api/v2/auth/register"), values);
    await window.localStorage.setItem("bexToken", response.data._id);
    dispatch({ type: USER_LOGIN, payload: response.data });
    redirectPath();
  } catch (error) {
    console.log("[Login]", error);
    dispatch({ type: USER_LOGIN, payload: { error: error } });
  }
};
// const userLogin = (values, redirectPath) => async dispatch => {
//   try {
//     const response = await axios.post(getUrl("/api/v1/login"), values);
//     dispatch({ type: USER_LOGIN, payload: response.data });
//     redirectPath();
//   } catch (error) {
//     console.log(error);
//     dispatch({ type: USER_LOGIN, payload: { error: error.response.data } });
//   }
// };

const userSignOut = () => async (dispatch) => {
  try {
    await axios.get(getUrl("/api/v1/logout"));
    await window.localStorage.removeItem("bexToken");
    console.log(window.localStorage);
    // redirectPath();
    dispatch({ type: USER_SIGNOUT, payload: "" });
  } catch (error) {
    console.log(error);
  }
};

const checkUserStatus = () => async (dispatch) => {
  try {
    const token = await window.localStorage.getItem("bexToken");
    const response = await axios.get(getUrl(`/api/v2/client/refresh/${token}`));
    dispatch({ type: CHECK_USER_STATUS, payload: response });
  } catch (error) {
    dispatch({ type: CHECK_USER_STATUS, payload: { error: error } });
  }
};

// const checkUserStatus = () => async dispatch => {
//   try {
//     const response = await axios.get(getUrl("/api/v1/user"));
//     dispatch({ type: CHECK_USER_STATUS, payload: response });
//   } catch (error) {
//     dispatch({ type: CHECK_USER_STATUS, payload: { error: error } });
//   }
// };

// User update password, profile

const userForgotPassword = (value, redirectPath) => async (dispatch) => {
  await axios.post(getUrl("/api/v1/user/forgotPassword"), value);
  dispatch({ type: FORGOT_PASSWORD, payload: value });

  setTimeout(() => {
    redirectPath();
  }, 7000);
};

const userResetPassword = (values, redirectPath) => async (dispatch) => {
  try {
    await axios.post(getUrl("/api/v1/user/resetPassword"), values);
    dispatch({ type: USER_RESET_PASSWORD, payload: values });

    setTimeout(() => {
      redirectPath();
    }, 3000);
  } catch (error) {
    console.log(error);
  }
};

const userUpdateInfo = (values) => async (dispatch) => {
  try {
    await axios.put(getUrl("/api/v1/user"), values);
    dispatch({ type: USER_UPDATE_INFO, payload: values });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_INFO_ERROR,
      payload: "There was an error with the update, Please try again.",
    });
  }
};

const userUpdatePassword = (values) => async (dispatch) => {
  try {
    await axios.post(getUrl("/api/v1/user/updatePassword"), values);

    dispatch({
      type: USER_UPDATE_PASSWORD,
      payload: "Password successfully updated!",
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PASSWORD_ERROR,
      payload: "Password not updated, Please try again",
    });
  }
};

const saveRedirectPath = (path) => async (dispatch) => {
  dispatch({
    type: SAVE_REDIRECT_PATH,
    payload: path,
  });
};

// User validation

const userValidate = (values) => async (dispatch) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    await axios.post(getUrl("/api/v1/verifyUser/documents"), values, {
      headers,
    });
  } catch (error) {
    console.log(error);
  }
};

const phoneValidate = (values, redirectPath) => async (dispatch) => {
  try {
    console.log("In phoneValidate", values);
    await axios.post(getUrl("/api/v2/auth/register"), values);
    dispatch({ type: PHONE_VALIDATE, payload: values });
    redirectPath();
  } catch (error) {
    dispatch({ type: PHONE_VALIDATE, payload: { error: error } });
  }
};

const userResendCode = (value) => async (dispatch) => {
  try {
    await axios.post(getUrl("/api/v1/login/resendCode"), value);
  } catch (error) {
    console.log(error);
  }
};

const refreshUser = (id) => async (dispatch) => {
  try {
    const response = await axios.get(getUrl(`/api/v2/client/refresh/${id}`));
    console.log("REFRESH RESPONSE: ", response);
    dispatch({ type: REFRESH_USER, payload: response.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: REFRESH_USER, payload: { error: error.response.data } });
  }
};

export {
  userRegister,
  userLogin,
  userLoginWithSocialMedia,
  userSignOut,
  checkUserStatus,
  userForgotPassword,
  userResetPassword,
  userUpdateInfo,
  userUpdatePassword,
  saveRedirectPath,
  userValidate,
  phoneValidate,
  userResendCode,
  refreshUser,
};
