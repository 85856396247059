import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AccountSettingsLayout from "../AccountSettingsLayout";
//import Chat from "../../Chat/Chat";

class AccountSettingsPassword extends Component {
  render() {
    return <AccountSettingsLayout>{/* <Chat /> */}</AccountSettingsLayout>;
  }
}

const mapStateToProps = state => {
  return {
    userEmail: state.auth.userInfo.email
  };
};

export default connect(
  mapStateToProps,
  actions
)(AccountSettingsPassword);
