import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class AssetbarView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };
  }

  /**
   * set state to display checked items in checklist
   */
  onSelect = assetID => {
    var selected = this.state.selected;
    if (selected.includes(assetID)) {
      selected = selected.filter(value => {
        return value !== assetID;
      });
    } else selected.push(assetID);
    this.setState({ selected: selected });
  };

  /**
   * *** needs implementation ***
   */
  onConfirm = () => {
    console.log(this.state.selected);
  };

  renderAssetDetail = asset => {
    return (
      <div>
        <div className="briefcase__assetList-wrapper-item-title">
          {asset._source.postTitle}
        </div>
        <div className="briefcase__assetList-wrapper-item-description">
          description: &nbsp;
          {asset._source.fullDescription}
        </div>
      </div>
    );
  };

  renderAssetList = () => {
    const assets = this.props.assets;
    return assets.map(asset =>
      this.state.selected.includes(asset._source.assetId) ? (
        <div
          className="briefcase__assetList-wrapper-item selected"
          onClick={() => this.onSelect(asset._source.assetId)}
        >
          {this.renderAssetDetail(asset)}
        </div>
      ) : (
        <div
          className="briefcase__assetList-wrapper-item"
          onClick={() => this.onSelect(asset._source.assetId)}
        >
          {this.renderAssetDetail(asset)}
        </div>
      )
    );
  };

  render() {
    return (
      <div className={`briefcase ${this.props.toggleAssetBarClass}`}>
        <div className="exit-assetbar">
          <button onClick={this.props.toggleAssetBar}>
            <i className="far fa-times-circle fa-2x" />
          </button>
        </div>

        <div
          className="briefcase__back"
          onClick={() => this.props.prevScreen()}
        >
          &#x3c; &nbsp; Back
        </div>
        <div className="briefcase__assetList">
          <div className="briefcase__assetList-wrapper">
            {this.renderAssetList()}
          </div>
        </div>
        <div className="briefcase__payment">
          <div
            className="briefcase__payment-confirm"
            onClick={() => this.onConfirm()}
          >
            Comfirm &amp; Pay
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    assets: state.asset.userAssetsResults,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(AssetbarView);
