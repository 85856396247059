import React, { Component } from 'react';
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper"; 
import { compareValues } from "../../helperFunctions/helperFunctions";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { ArrowRight, ArrowLeft } from 'react-feather';
import ScrollToTopBtn from "../Scroll/ScrollToTopButton";

class SingleArtist extends Component {
  state = {
    artists: []
  }

  componentDidMount() {
    this.props.getArtistDirectory();
  }
  
  render() {
    let { artists } = this.props;
    let sorted = artists.sort(compareValues('name'));
    let artIndex = sorted.findIndex(a => a.name === this.props.match.params.id);
    console.log(artIndex);
    
    if (sorted[artIndex]) {
      return (
        <Layout>
          <ScrollToTopBtn/>
          <Wrapper>
            <div className="single-artist-container">
              <div className="single-artist-directory-title">
                <h1>{sorted[artIndex].name}</h1>
                <h3>{sorted[artIndex].bio}</h3>
              </div>
              {
                sorted[artIndex].images.map((image, index) => (
                  <div className="single-artist-directory-image" key={index}>
                    <img src={image} alt={sorted[artIndex].name + "art"}/>
                  </div>
                ))
              }
              <div className="single-artist-selection">
                {
                  artIndex - 1 > -1 ?
                  <a href={`/directory/artists/${sorted[artIndex-1].name}`}><h2><ArrowLeft size={50}/>&nbsp;{sorted[artIndex-1].name}</h2></a> :
                  <a href="/directory/artists"><h2><ArrowLeft size={50}/>&nbsp;Directory</h2></a> 
                }
                {
                  artIndex + 1 === sorted.length ?
                  "" :
                  <a href={`/directory/artists/${sorted[artIndex+1].name}`}>
                    <h2>{sorted[artIndex+1].name}&nbsp;<ArrowRight size={50}/></h2>
                  </a>
                }
              </div>
            </div>
          </Wrapper>
        </Layout>
      )
    } else {
      return (
        <Layout>
          <Wrapper>
            <div>Loading...</div>
          </Wrapper>
        </Layout>
      )
    }
    
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    // userInfo: state.auth.userInfo,
    // asset: state.asset.assetInfo,
    artists: state.asset.artists
  };
};

export default connect(
  mapStateToProps,
  actions
)(SingleArtist);