import React from 'react';

const EventsAuctions = (props) => {

  if (!props.data) {
    return <div>Loading...</div>
  }
  
  return (
    <div className="events-container">
      {
        props.data.map((item, id) => {
          return (
            <div className="events-card" key={id}>
              <div className="events-card--org">
                <span className="circle"></span>
                {item.org} Auctions
              </div>
              {
                item.image ? 
                <div className="events-card--image">
                  <img src={item.image} alt="auction"/>
                </div> :
                ""
              }
              <div className="events-card--title">
                {item.title}
              </div>
              <div className="events-card--date">
                {item.date}
              </div>
              <div className="events-card--location">
                {item.location}
              </div>
              <div className="events-card--link">
                {
                  item.link ?
                  <a href={item.link}>Learn More &nbsp;&rarr;</a> : ""
                }
              </div>
            </div>
          )
        })
      }
    </div>
  );
};

export default EventsAuctions;