import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import AccountSettingsNavLink from "./AccountSettingsNavLink";

class AccountSettingsNav extends Component {
  userSignOut = () => {
    this.props.userSignOut(() => {
      this.props.history.push("/");
    });
  };

  render() {
    const { location } = this.props;
    return (
      <div className="accountsettings__nav">
        <AccountSettingsNavLink
          location={location.pathname}
          path="/user/accountsettings/userassets"
          name="My Assets"
        />
        <AccountSettingsNavLink
          location={location.pathname}
          path="/user/accountsettings/messages"
          name="Messages"
        />
        <AccountSettingsNavLink
          location={location.pathname}
          path="/user/accountsettings/usersettings"
          name="User Settings"
        />
        <AccountSettingsNavLink
          location={location.pathname}
          path="/user/accountsettings/changepassword"
          name="Password Settings"
        />
        <AccountSettingsNavLink
          location={location.pathname}
          path="/user/accountsettings/validateaccount"
          name="Validate Account"
        />

        <div
          className="accountsettings__nav__item accountsettings__nav__link"
          onClick={this.userSignOut}
        >
          Sign Out
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(withRouter(AccountSettingsNav));
