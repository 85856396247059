import React from "react";
import { MessageSquare } from "react-feather";
import { Link } from "react-router-dom";
import ScrollArea from 'react-scrollbar';
import { connect } from "react-redux";

const ClickForChat = ({ chatRooms }) => {
  return (
    <div />
  //   <ScrollArea
  //   speed={0.8}
  //   className="area"
  //   contentClassName="content"
  //   horizontal={false}
  //   >
  //   <div className="click-for-chat">
  //     <i className="far fa-comments fa-2x"></i>
  //     <h4>Select a Conversation</h4>
  //     <p>
  //       Select a conversation to begin <br />
  //       negotiating your swap.
  //     </p>
  //     {chatRooms.map((item) => (
  //         <div style={{width:400}}>
  //           <button style={{width:400}}>
  //           <Link
  //             to={{
  //               pathname: "/room",
  //               state: { chat: item },
  //             }}
  //             >{item.chat.senderName}
  //             :
  //             {item.chat.recipientName}
  //           </Link>
  //           </button>
  //         </div>
  //     ))}
  //   </div>
  //   </ScrollArea>
  );
};

const mapStateToProps = (state) => ({
  chatRooms: state.chat.chatRooms,
});

export default connect(mapStateToProps, null)(ClickForChat);
