export const registerFormFields = [
  // { name: "userName", label: "User Name", type: "text" },
  { name: "name", label: "Full Name", type: "text" },
  { name: "lastName", label: "Last Name", type: "text" },
  { name: "email", label: "Email", type: "email" },
  { name: "phoneNumber", label: "Phone Number", type: "text" },
  { name: "confirmedPassword", label: "Create Password", type: "password" },
  { name: "password", label: "Confirm Password", type: "password" }
];

// Asset form fields

export const assetFormFields = {
  realestate: [
    { label: "Title", name: "postTitle" },
    { label: "Asset Held By", name: "assetHeldBy" },
    { label: "Appraisal Value", name: "appraisalValue" },
    { label: "Price", name: "price" },
    { label: "Website", name: "website" },
    { label: "List your disclosure forms here", name: "disclosureForms" },
    { label: "Annual Hold Costs", name: "annualHoldCosts" },
    {
      label: "Living Area",
      name: "livingArea",
      suffix: " sq/mt",
      type: "number"
    },
    { label: "Land Area", name: "landArea", suffix: " sq/mt", type: "number" },
    { label: "Address", name: "address" }
  ],

  art: [
    { label: "Title", name: "postTitle" },
    { label: "Artist Name", name: "artist" },
    { label: "Declared Origin", name: "declaredOrigin" },
    { label: "Authenticity Certificate", name: "authenticityCert" },
    { label: "Appraisal Value", name: "appraisalValue", type: "number" },
    { label: "Condition", name: "condition" },
    { label: "Purchase History", name: "purchaseHistory" },
    { label: "Price", name: "price", type: "number" }
  ],

  boat: [
    { label: "Title", name: "postTitle" },
    { label: "Price", name: "price", type: "number" },
    { label: "Vessel Type", name: "vesselType" },
    { label: "Ownership Cert", name: "ownershipCert" },
    { label: "Appraisal Value", name: "appraisalValue" },
    { label: "Charter History", name: "charterHistory" },
    { label: "Annual Operation Expense", name: "annualOpExp" },
    { label: "Annual Income (if any)", name: "annualIncome" },
    { label: "Architect (if known)", name: "architect" },
    { label: "Maximum allowed guests number", name: "specs.interior.guests" },
    { label: "Interior staterooms", name: "specs.interior.staterooms" },
    { label: "Interior stylist (if any)", name: "specs.interior.stylist" },
    { label: "Exterior Builder", name: "specs.exterior.build.builder" },
    {
      label: "Builder's Location",
      name: "specs.exterior.build.builderLocation"
    },
    { label: "Exterior Stylist", name: "specs.exterior.build.stylist" },
    { label: "Hull: length", name: "specs.exterior.hull.length" },
    { label: "Hull: beam", name: "specs.exterior.hull.beam" },
    { label: "Hull: draft", name: "specs.exterior.hull.draft" },
    { label: "Engine", name: "specs.performance.engines" },
    { label: "Engine Model", name: "specs.performance.engineModel" },
    { label: "Maximum Speed (knot)", name: "specs.performance.maxSpeed" },
    { label: "Horsepower", name: "specs.performance.horsepower" },
    { label: "Cruise Speed", name: "specs.performance.cruiseSpeed" },
    { label: "Cruise Range", name: "specs.performance.cruiseRange" },
    { label: "Water Capacity", name: "specs.performance.waterCapacity" },
    { label: "Fuel Capacity", name: "specs.performance.fuelCapacity" }
  ],

  auto: [
    { label: "Title", name: "postTitle" },
    { label: "Price", name: "price", type: "number" },
    { label: "Make", name: "make" },
    { label: "Model", name: "model" },
    { label: "Year", name: "year" },
    { label: "Interior Condition", name: "specs.interior.condition" },
    { label: "Interior Material", name: "specs.interior.material" },
    { label: "Passenger Capacity", name: "specs.interior.passengerCapacity" },
    { label: "Exterior Condition", name: "specs.exterior.condition" },
    { label: "Exterior Color", name: "specs.exterior.color" },
    { label: "Drivetrain", name: "specs.drivetrain" },
    { label: "Engine Cylinder", name: "specs.engine.cylinder" },
    { label: "Engine Horsepower", name: "specs.engine.horsepower" },
    { label: "Mileage", name: "mileage" },
    { label: "Miles Per Gallon (mpg)", name: "mpg" },
    { label: "VIN", name: "vin" },
    { label: "Features", name: "features" }
  ],

  collectible: [
    { label: "Title", name: "postTitle" },
    { label: "Price", name: "price", type: "number" },
    { label: "Declared Origin", name: "declaredOrigin" },
    { label: "Authenticity Cert", name: "authenticityCert" },
    { label: "Purchase History", name: "purchaseHistory" },
    { label: "Grading Report", name: "gradingReport" },
    { label: "Appraisal Value", name: "appraisalValue" }
  ],

  aircraft: [
    { label: "Title", name: "postTitle" },
    { label: "Tail Number", name: "tailNumber" },
    { label: "airCert", name: "airCert" },
    { label: "faaCert", name: "faaCert" },
    { label: "Registration Cert", name: "registrationCert" },
    { label: "Ownership History", name: "ownershipHistory" },
    { label: "Damage History", name: "damageHistory" },
    { label: "faa337", name: "faa337" },
    { label: "Price", name: "price", type: "number" },
    { label: "Operation Costs Per Hour", name: "opCostsPerHr", type: "number" },
    { label: "Maintenance (Private)", name: "maintenance.private" },
    { label: "Maintenance (Outsourced managed)", name: "maintenance.managed" },
    { label: "maintenanceReport", name: "maintenanceReport" },
    { label: "charterHistory", name: "charterHistory" },
    { label: "Avionics", name: "specs.avionics" },
    { label: "Exterior: Condition", name: "specs.exterior.condition" },
    {
      label: "Exterior: Last Painted Date",
      name: "specs.exterior.lastPainted"
    },
    { label: "Total Hours Flied", name: "specs.totalHours", type: "number" },
    {
      label: "Total Landings Made ",
      name: "specs.totalLandings",
      type: "number"
    },
    { label: "Aux Power Unit", name: "specs.auxPowerUnit" },
    { label: "Manufacturer", name: "specs.manufacturer" },
    { label: "Model", name: "specs.model" },
    { label: "Serial Number", name: "specs.serialNumber" },
    { label: "Engine: Manufacturer", name: "specs.engine.manufacturer" },
    { label: "Engine: Total Time", name: "specs.engine.engineTimeTotal" },
    { label: "Engine: Cycles", name: "specs.engine.engineCycles" },
    { label: "Engine: Serial Number", name: "specs.engine.serialNumber" },
    { label: "Features", name: "features" },
    { label: "Inspection Status", name: "inspectionStatus" },
    { label: "Gross Weight ", name: "weight.gross", type: "number" },
    {
      label: "Maximum Take off Weight ",
      name: "weight.maxTakeOff",
      type: "number"
    },
    {
      label: "Maximum Landing Weight ",
      name: "weight.maxLanding",
      type: "number"
    },
    { label: "Maximum Basic Weight ", name: "weight.basic", type: "number" },
    { label: "Weight When Empty", name: "weight.whenEmpty", type: "number" },
    { label: "Fuel Capacity Weight", name: "weight.fuelCap" }
  ]
};

export const addressFormFields = [
  { label: "Country", name: "country" },
  { label: "Street Address", name: "streetAddress" },
  { label: "City", name: "city" },
  { label: "State", name: "state" },
  { label: "Zipcode", name: "zipcode" }
];

export const assetChoices = [
  { iconName: "ART", faName: "fas fa-palette" },
  { iconName: "PROPERTY", faName: "fas fa-building" },
  { iconName: "BOAT", faName: "fas fa-ship" },
  { iconName: "AIRCRAFT", faName: "fas fa-plane-departure" },
  { iconName: "CAR", faName: "fas fa-car" },
  { iconName: "COLLECTIBLE", faName: "fas fa-gem" }
];
