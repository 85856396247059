import React from "react";
import bchainLogo from "../../images/bchain-logo.png";

const ValidateFinished = ({ validated }) => {
  return (
    <div className="validate__finished">
      <img className="form__logo" src={bchainLogo} alt="logo" />

      {validated ? (
        <div className="validate__finished__message">
          Your documents have been verified! You now have access to the Bchain
          Asset platform.
        </div>
      ) : (
        <div className="validate__finished__message">
          Your documents are currently under review. Please allow a few days for
          the information to be verified. You will be notified by email when
          your account has been given access.
        </div>
      )}
    </div>
  );
};

export default ValidateFinished;
