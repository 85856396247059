import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class NavbarSearch extends Component {
  state = {
    assetType: "all"
  }

  onSelectCategory = (e) => {
    this.setState({
      assetType: e.target.value,
      query: "" //why resetting the query to null?
    })
  }
  
  captureQuery = e => {
    this.props.userUpdateQuery(e);
  }

  redirectSearch = e => {
    this.props.history.push(`/search/assets`);
  }
  
  onSearch = (e, query, assetType) => {
    e.preventDefault();
    // const assetType = prompt("Enter the asset type you want to search!");
    this.props.getSearchResults(query, assetType, this.redirectSearch);
  }
  
  render() {
    let { assetType } = this.state;
    return (
      <div className="main-navbar__search">
        <form onSubmit={(e) => this.onSearch(e, this.props.query, assetType)}>
          <input 
            type="text" 
            placeholder="Search Assets" 
            onChange={this.captureQuery}
            value={this.props.query}
          />
        </form>
        <select 
          name="assets" 
          id="assets" 
          onChange={this.onSelectCategory}
        >
          <option value="all">All Categories</option>
          <option value="aircrafts">Aircraft</option>
          <option value="arts">Art</option>
          <option value="autos">Automobiles</option>
          <option value="boats">Boats</option>
          <option value="collectibles">Collectibles</option>
          <option value="realestates">Real Estate</option>
        </select>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    query: state.asset.query  
  };
};
export default connect(mapStateToProps, actions)(withRouter(NavbarSearch));