import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import ShowAssetModal from "./ShowAssetModal";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "@material-ui/core/Modal";

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none"
  }
});

class ShowAssetProduct extends Component {
  state = {
    open: false,
    imageIndex: 0
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleViewImage = () => {};

  render() {
    if (!this.props.asset) return <div>Loading...</div>;

    const { imageIndex } = this.state
    const { images } = this.props.asset;
    console.log(this.state.open);

    return (
      <div className="single-asset__image">
        <img
          onClick={this.handleOpen}
          className="show-asset__product__modal__img"
          src={images[imageIndex]}
          alt="display"
          style={{height: 600}}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div onClick={this.handleClose}>
            <ShowAssetModal image={images[imageIndex]} handleClose={this.handleClose} />
          </div>
        </Modal>
        <div className="single-asset__image--grid">
          {images.map((elem, index) => (
            <img src={elem} alt={`listing-${index}`} key={index} onClick={()=>this.setState({imageIndex: index})}/>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    assetInfo: state.asset.assetInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(ShowAssetProduct));
