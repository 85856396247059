import React from 'react';
import { XSquare } from 'react-feather';

const calculateTotal = (arry) => {
  const total = arry.reduce((acc, curr) => {
    return acc + parseInt(curr.price);
  }, 0)
  return total.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
}

const CartSummary = (props) => {
  const { currentCart } = props;

  return (
    <div className="shopping-cart">
      <div className="shopping-cart--header">
        <i className="fas fa-shopping-cart fa-3x"></i>
        <h2>Cart Summary</h2>
      </div>
      {
        currentCart.map((item, index) => (
          <div className="cart-item-container" key={index}>
            <img src={item.images[0]} alt="asset"/>
            <div className="cart-item-content">
              <div className="cart-info">
                <h6>{item.category.charAt(0).toUpperCase() + item.category.slice(1)}</h6>
                <h3>{item.postTitle}</h3>
                <br/>
                <button
                  onClick={() => props.removeItem(item)}
                >
                  <XSquare size={15}/>
                  &nbsp;
                  Remove Item
                </button>
              </div>
              <div className="cart-price">
                <h4>{parseInt(item.price).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h4>
              </div>
            </div>
          </div>
        ))
      }
      <div className="total-price">
        <h3>Cart Total: </h3>
        <h3>{ calculateTotal(currentCart) }</h3>
      </div>
    </div>
  );
};

export default CartSummary;