import React, { Component } from "react";

class GDPR extends Component {
  state = {
    policyAccepted: false,
    showGDPR: false
  };

  createCookie = (name, value, days) => {
    let expires;
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  };

  readCookie = name => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  checkCookie = name => {
    if (this.readCookie(name)) {
      this.setState({
        policyAccepted: true,
        showGDPR: false
      });
      return true;
    } else {
      this.setState({
        policyAccepted: false,
        showGDPR: true
      });
    }
  };

  handleAccept = async (name, value, days) => {
    await this.createCookie(name, value, days);
    await this.setState({
      policyAccepted: true,
      showGDPR: false
    });
  };

  componentDidMount() {
    setTimeout(async () => {
      await this.checkCookie("bazaar-cookie");
    }, 2000);
  }

  render() {
    let show = { bottom: "0rem" };
    let hide = { bottom: "-12rem" };

    return (
      <div className="gdpr" style={this.state.showGDPR ? show : hide}>
        <p>
          Welcome! Our site uses cookies so that we can remember you and
          understand how you use our site. This site requires anonymous cookies
          and various 3rd-party services to function properly. To continue using
          BazaarXchanges, you must consent to our Cookie and{" "}
          <a href="/privacy">Privacy</a> policies.
        </p>
        <button
          onClick={e => this.handleAccept("bazaar-cookie", "accepted", 5)}
        >
          I&nbsp;Agree
        </button>
      </div>
    );
  }
}

export default GDPR;
