import React, { Component } from "react";
import { Field } from "redux-form";

class FormInput extends Component {
  state = {
    errorMsg: ""
  };

  onChange = e => {
    const { onChange } = this.props.input;

    if (e.target.files.length > 10) {
      this.clearField();

      this.setState({
        errorMsg:
          "Photo limit: There is a limit of 10 photos, Please try again."
      });
    } else {
      onChange(e.target.files);
      this.props.addFiles(e.target.files, this.props.input.name);
      this.resetError();
    }
  };

  clearField = () => {
    this.resetError();
    this.fileInput.value = "";
    this.props.clearList(this.props.input.name);
  };

  resetError = () => {
    this.setState({
      errorMsg: ""
    });
  };

  render() {
    const {
      label,
      meta: { touched, error }
    } = this.props;

    const { errorMsg } = this.state;
    return (
      <div className="form__clearable">
        <label
          className={
            touched && error ? `form__label form__label--error` : `form__label`
          }
          htmlFor="file"
        >
          <div>{label}</div>

          <div className="form__label__icon">
            <i className="fas fa-upload" />
          </div>
        </label>

        <input
          className="form__input form__file"
          type="file"
          id="file"
          accept="image/jpg, image/png, image/jpeg"
          multiple
          onChange={this.onChange}
          ref={ref => (this.fileInput = ref)}
          required
        />

        <div className="form__error">{errorMsg}</div>

        <div className="form__file__clear" onClick={this.clearField}>
          &times;
        </div>
      </div>
    );
  }
}

const FileInput = ({ name, label, clearList, addFiles }) => {
  return (
    <div className="form__group">
      <Field
        name={name}
        component={FormInput}
        label={label}
        clearList={clearList}
        addFiles={addFiles}
      />
    </div>
  );
};

export default FileInput;
