import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import BexLogo from "../../icons/Bex_Logo.png";
import FullLogoWhite from "../../images/bazaarxchanges-white.png";
import { categories } from "../../utility/constants";
import { Container, StylesProvider } from "@material-ui/core";
import { Search, User } from "react-feather";
import axios from "axios";

class MobileNavbar extends Component {
  state = {
    isOpen: false,
    query: "",
    bexToken: false,
  };

  async componentDidMount() {
    const bexToken = await window.localStorage.getItem("bexToken");
    if (bexToken) {
      this.setState({ bexToken: true });
    } else {
      this.setState({ bexToken: false });
    }
  }

  toggleSidebar = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleInputChange = (e) => {
    console.log(e.target.value);
    this.setState({ query: e.target.value });
  };

  handleQuerySubmit = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: "/search",
      search: `?query=${this.state.query}`,
    });

    console.log("Submitting: ", this.state.query);
  };

  render() {
    let style = this.state.isOpen ? { width: "80%" } : { width: "0%" };
    return (
      <div className="wrapper__mobile-navbar">
        <div className="mobile_navbar">
          <div className="hamburger" onClick={this.toggleSidebar}>
            <i className="fas fa-bars fa-3x"></i>
          </div>
          <a
            href="/"
            style={{
              marginLeft: 20,
              width: 75,
              height: 75,
            }}
          >
            <img src={BexLogo} alt="logo" />
          </a>
          {this.props.user &&
            <div style={{display:'flex', flexDirection:'row'}}>
          <div
          style={{
            display: "flex",
            backgroundColor: "#DF233A",
            alignItems: "center",
            justifyContent: "center",
            width: 80,
            height: 20,
            borderRadius:20,
            marginRight:4
          }}
        >
          <a href="/messages">
            <button
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
                color:'white',
                fontWeight:'bold'
              }}
            >
             Chat
            </button>
          </a>
        </div>
          <div
          style={{
            display: "flex",
            backgroundColor: "#DF233A",
            alignItems: "center",
            justifyContent: "center",
            width: 80,
            height: 20,
            borderRadius:20,
            marginRight:4
          }}
        >
        <a href="/user">
            <button
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
                color:'white',
                fontWeight:'bold'
              }}
            >
             Account
            </button>
          </a>
        </div>
          <div
          style={{
            display: "flex",
            backgroundColor: "#DF233A",
            alignItems: "center",
            justifyContent: "center",
            width: 80,
            height: 20,
            borderRadius:20
          }}
        >
          <a href="/upload">
            <button
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
                color:'white',
                fontWeight:'bold'
              }}
            >
              Add Item
            </button>
          </a>
        </div>
        </div>
        }
        </div>
        <div className="mobile_sidenav" style={style}>
          <div className="mobile_sidenav__close">
            <Button style={styles.xButton} onClick={this.toggleSidebar}>
              x
            </Button>
          </div>
          <div
            className="mobile_sidenav__search-container"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "80%",
                padding: 5,
                backgroundColor: "rgba(240,240,240,0.1)",
              }}
            >
              <form onSubmit={this.handleQuerySubmit}>
                <input
                  type="text"
                  value={this.state.query}
                  onChange={this.handleInputChange}
                  className="mobile_sidenav__search"
                  placeholder="Search Bazaarxchanges..."
                />
              </form>
            </div>
          </div>
          <div className="mobile_sidenav__links">
            {!this.state.bexToken && (
              <div className="sidenav_link">
                <a href="/register" style={{ color: "#df233a" }}>
                  Register
                </a>
              </div>
            )}
            {!this.state.bexToken && (
              <div className="sidenav_link">
                <a href="/login" style={{ color: "#df233a" }}>
                  Login
                </a>
              </div>
            )}
            {this.state.bexToken && (
              <div
                className="sidenav_link"
                onClick={async () => {
                  console.log("[window]");
                  // await axios.get("http://localhost:4000/api/v1/logout");
                  await axios.get(
                    "https://api-dev.bazaarxchanges.com/api/v1/logout"
                  );
                  await window.localStorage.removeItem("bexToken");
                  window.location.reload();
                }}
              >
                <a style={{ color: "#df233a" }}>Logout</a>
              </div>
            )}
            {categories.map((category, index) => (
              <div className="sidenav_link" key={index}>
                <a href={`/explore/${category.label}`}>{category.label}</a>
              </div>
            ))}
            <div className="sidenav_link">
              <a href="/contact" style={{ color: "#df233a" }}>
                Contact&nbsp;Us
              </a>
            </div>
          </div>
          <div className="mobile_sidenav__branding">
            <img src={FullLogoWhite} alt="mobile logo" />
            {/* <div className="mobile__social-icons">
              <i className="fab fa-instagram fa-3x" />
              <div className="spacer" />
              <i className="fab fa-facebook-f fa-3x" />
              <div className="spacer" />
              <i className="fab fa-twitter fa-3x" />
              <div className="spacer" />
              <i className="fab fa-linkedin-in fa-3x" />
            </div> */}
            <p>
              ©2019. BChain, LLC. 757 Third Avenue, New York, NY 10017. All
              Rights Reserved. | Terms & Conditions | Privacy Policy |
              Accessibility Statement BChain, LLC® and the BazaarXchange Logo
              are service marks owned by BChain, LLC. All information provided
              herein has been obtained from sources believed reliable, but may
              be subject to errors, omissions, change of price, prior sale, or
              withdrawal without notice. BazaarXchange makes no representation,
              warranty or guaranty as to accuracy of any information contained
              herein. You should consult your advisor for an independent
              verification of any properties or assets.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  searchContainer: {
    width: "80%",
    padding: 5,
    borderColor: "#FFF",
    borderWidth: 1,
    borderRadius: 5,
  },
  xButton: {
    backgroundColor: "rgba(0,0,0,0)",
  },
};

export default withRouter(MobileNavbar);
