import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

const styles = theme => ({
  focused: {
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "black !important"
    }
  }
});

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      messageOnTab1: "hi! i'm " + this.props.user.firstName,
      messageOnTab2: "hi! i'm " + this.props.user.firstName,
      checkedValues: [],
      tab: 0,
      email: "",
      invalidEmail: false,
      disableTab2: true,
      usernameInput: "",
      usernameResult: "",
      usernameResultID: ""
    };
  }

  /**
   * resets state to initial values when user clicks cancel
   */
  cancelSelect() {
    this.setState({
      open: false,
      checkedValues: [],
      messageOnTab1: "hi! i'm " + this.props.user.firstName,
      messageOnTab2: "hi! i'm " + this.props.user.firstName,
      tab: 0,
      email: "",
      invalidEmail: false,
      disableTab2: true,
      usernameInput: "",
      usernameResult: "",
      usernameResultID: ""
    });
  }

  /**
   * validates email address
   *
   * @param {string} email
   */
  validateEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  /**
   * sends invite
   *  - on tab 0: sends invite through email *** needs implementation ***
   *  - on tab 1: saves invite to database, and sends to receiver through socket
   */
  async handleSend() {
    if (this.state.tab === 0) {
      const email = this.state.email;

      if (this.validateEmail(email)) {
        console.log("valid");
        console.log("email: " + email);
        this.cancelSelect();
      } else {
        this.setState({ invalidEmail: true });
      }
    } else if (this.state.tab === 1) {
      let { groupID, socket } = this.props;
      await axios.patch(
        "http://localhost:4000/api/v1/groupchat/addGroupInvite",
        {
          groupID: groupID,
          userID: this.state.usernameResultID,
          message: this.state.messageOnTab2
        }
      );
      socket.emit("invite", this.state.usernameResultID);
      this.cancelSelect();
    }
  }

  /**
   * searches the username user typed in, set state according to result
   */
  async handleUserSearch() {
    let res = await axios({
      method: "get",
      url:
        "http://localhost:4000/api/v1/user/searchUsername/" +
        this.state.usernameInput,
      validateStatus: status => {
        return status;
      }
    });
    if (res.status === 200) {
      this.setState({
        disableTab2: false,
        usernameResultID: res.data,
        usernameResult: this.state.usernameInput
      });
    } else {
      this.setState({
        disableTab2: true,
        usernameResultID: "",
        usernameResult: ""
      });
    }
  }

  /**
   * saves invitation message to state according to current tab
   *
   * @param {string} text Invitation message from user input
   * @param {number} tab 0 or 1
   */
  handleMessage(text, tab) {
    if (tab === 0) this.setState({ messageOnTab1: text });
    else this.setState({ messageOnTab2: text });
  }

  /**
   * gets invitation message on the tab
   *
   * @param {number} tab Index of current tab
   */
  getMessage(tab) {
    return tab === 0 ? this.state.messageOnTab1 : this.state.messageOnTab2;
  }

  /**
   * renders message input box
   *
   * @param {number} tab Index of current tab
   */
  renderMessageBox(tab) {
    const { classes } = this.props;
    return (
      <Box p={1}>
        <TextField
          label="Message"
          autoFocus
          multiline={true}
          variant="outlined"
          rows={6}
          id="invitation"
          fullWidth
          value={this.getMessage(tab)}
          onChange={e => this.handleMessage(e.target.value, tab)}
          InputLabelProps={{
            style: {
              fontSize: 20
            }
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            },
            style: { fontSize: 15 }
          }}
        />
      </Box>
    );
  }

  /**
   * renders tab according to tab index
   *
   * @param {number} tab Index of current tab
   */
  renderTab(tab) {
    const { classes } = this.props;
    return (
      <Typography
        component="div"
        style={{
          padding: 8 * 3,
          height: 350,
          width: 400
        }}
      >
        {this.renderMessageBox(tab)}
        {tab === 0 && (
          <Box p={1}>
            <TextField
              label="Email"
              autoFocus
              fullWidth
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
              InputLabelProps={{
                style: {
                  fontSize: 12
                }
              }}
              InputProps={{
                style: { fontSize: 15 },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
            {this.state.invalidEmail ? (
              <p style={{ color: "tomato", fontSize: 15, paddingTop: "20px" }}>
                invalid email
              </p>
            ) : (
              ""
            )}
          </Box>
        )}
        {tab === 1 && (
          <Box p={2} m={2}>
            <TextField
              label="User name"
              autoFocus
              fullWidth
              value={this.state.usernameInput}
              onChange={e => this.setState({ usernameInput: e.target.value })}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => this.handleUserSearch()}>
                    <SearchIcon />
                  </IconButton>
                ),
                style: {
                  fontSize: 15
                },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                style: {
                  fontSize: 12
                }
              }}
            />

            {this.state.usernameResultID === "" ? (
              <p style={{ color: "#8d8f94", fontSize: 15, paddingTop: "20px" }}>
                None found
              </p>
            ) : (
              <p style={{ color: "#46474a", fontSize: 15, paddingTop: "20px" }}>
                Sending invite to {this.state.usernameResult}
              </p>
            )}
          </Box>
        )}
      </Typography>
    );
  }

  render() {
    return (
      <div>
        <button
          className="invite"
          onClick={() => this.setState({ open: true })}
          disabled={this.props.groupID === ""} //change button inactive look
        >
          <i className="far fa-plus-square fa-lg" />
          <p>Send Invite</p>
        </button>

        <Dialog
          bodystyle={{ margin: 0, padding: 0 }}
          keepMounted
          open={this.state.open}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          BackdropProps={{
            style: {
              opacity: 0.4
            }
          }}
        >
          <DialogTitle id="confirmation-dialog-title">
            Send invite to chatroom
          </DialogTitle>
          <DialogContent dividers>
            <Tabs
              value={this.state.tab}
              onChange={(e, v) => this.setState({ tab: v })}
              // indicatorColor="primary"
              // textColor="primary"
              variant="fullWidth"
              aria-label="Full width tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#df223a"
                }
              }}
            >
              <Tab
                label={
                  <span style={{ fontSize: 12, color: "#0c0c0c" }}>
                    Invite By Email
                  </span>
                }
              />
              <Tab
                label={
                  <span style={{ fontSize: 12, color: "#0c0c0c" }}>
                    Invite By Username
                  </span>
                }
              />
            </Tabs>
            {this.renderTab(this.state.tab)}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.cancelSelect()}
              color="primary"
              style={{ fontSize: 12 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.handleSend()}
              color="primary"
              style={{ fontSize: 12 }}
              disabled={this.state.tab === 1 && this.state.disableTab2}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(PopUp));
