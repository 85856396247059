import React, { Component } from "react";
import { CornerLeftDown, CornerRightUp, PlusCircle } from "react-feather";

class ChatBriefcaseSwap extends Component {
  convertToCurrency = price => {
    if (typeof price !== "number") return "N/A";
    return price.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  };

  parseCategory = category => {
    let parsedStr = category.charAt(0).toUpperCase() + category.slice(1) + "s";
    return parsedStr;
  };

  render() {
    let { briefcase, handleAssetModal } = this.props;

    if (!briefcase.sender) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="briefcase">
          <div className="assetsBid">
            <div className="swap-for">
              <div className="briefcase-header">
                <h4>
                  Swap Overview &nbsp;&nbsp;
                  <CornerLeftDown size={12} />
                  <CornerRightUp size={12} />
                </h4>
                <p>
                  View overall swap proposal information. Clicking view details
                  reveals specifics for each individual asset. If you are the
                  purveyor of the above or below assets, you can add more of
                  your own assets to the deal for a small one-time fee.* If the
                  fee for the selected assets has been paid previously, then the
                  purveryor can add that asset to any particular deal at no
                  additional charge.
                </p>
                {/* <button className="swap-for-btn" onClick={handleAssetModal}>
                    <h4>
                      <PlusCircle size={20}/>&nbsp;Add More Assets
                    </h4>
                  </button> */}
                <br />
              </div>
            </div>
            <br />
            <div className="briefcase-header">
              <h4>ASSETS BID:</h4>
            </div>
            <div className="assetBid-container">
              <img src={briefcase.sender.imageUrl} alt="asset" />
              <div className="briefcase-header">
                <h4>{this.convertToCurrency(briefcase.sender.price)}</h4>
                <p>{briefcase.sender.title}</p>
                <button
                  onClick={() =>
                    this.props.handleNext(
                      briefcase.sender.id,
                      briefcase.sender.category
                    )
                  }
                >
                  <h4>View Details&nbsp;&#10230;</h4>
                </button>
              </div>
            </div>
          </div>
          <div className="assetsOffered">
            <div className="briefcase-header">
              <h4>ASSETS OFFERED:</h4>
            </div>
            <br />
            <div className="assetOffer-container">
              <img src={briefcase.receiver.images[0]} alt="asset" />
              <div className="briefcase-header">
                <h4>
                  {this.convertToCurrency(briefcase.receiver.estimated_value)}
                </h4>
                <p>{briefcase.receiver.title}</p>
                <button
                  onClick={() =>
                    this.props.handleNext(
                      briefcase.receiver._id,
                      briefcase.receiver.category
                    )
                  }
                >
                  <h4>View Details&nbsp;&#10230;</h4>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ChatBriefcaseSwap;
