import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "axios";
import TimeAgo from "react-timeago";
import { chatkitConnect } from "./ChatManager";
import Wrapper from "../Layout/Wrapper";
import { Trash2, HelpCircle } from "react-feather";
import { Container } from "@material-ui/core";

const chatUrl = api => {
  return "https://api-dev.bazaarxchanges.com" + api;
};

class ChatkitInvites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetPrice: null,
      pendingInvites: this.props.pending
    };
  }

  ignoreInvite = async invite => {
    let { _id, recipient, sent_by } = invite;
    let values = {
      userId: recipient,
      clientId: sent_by,
      pendingId: _id
    };
    axios
      .patch(chatUrl("/api/v2/client/update/pending"), values)
      .then(() => {
        axios.delete(chatUrl(`/api/v2/pending/remove/${_id}`));
      })
      .then(() => {
        let updatedArr = this.state.pendingInvites.filter(
          x => x._id !== invite._id
        );
        this.setState({
          pendingInvites: updatedArr
        });
      })
      .catch(err => console.log(err));
  };

  acceptInvite = async (userId, sender, receiver, groupId, roomName) => {
    const chatManager = await chatkitConnect(userId);
    const cryptoString = Math.floor(Math.random() * 1000000 + 1).toString();
    await chatManager
      .connect()
      .then(currentUser => {
        currentUser.createRoom({
          id: groupId + cryptoString,
          name: roomName,
          private: false,
          addUserIds: [sender.owner._id],
          customData: {
            sender,
            receiver,
            memberIds: [userId, sender.owner._id]
          }
        });
      })
      .then(() => {
        this.ignoreInvite({
          _id: groupId,
          recipient: userId,
          sent_by: sender.owner._id
        });
      })
      .catch(err => console.log(err));
  };

  calculateTotalOffer = invite => {
    let total = 0;
    invite.offer_item.forEach((x, i) => {
      total += x.price;
    });
    return total;
  };

  handleInviteAccepted = () => {};

  async componentDidMount() {
    // await this.props.getPendingInvites(this.props.user._id);
    // await this.props.getGroups(this.props.user._id);
  }

  render() {
    if (!this.props.pending) {
      return <div>Loading</div>;
    }

    if (this.props.pending.length < 1) {
      return (
        <div className="invites-container">
          <Wrapper>
            <div className="blank-invites">
              <h2>No new invitations found.</h2>
            </div>
          </Wrapper>
        </div>
      );
    }

    return (
      <div className="invites-container">
        <Wrapper>
          {this.state.pendingInvites
            .sort((a, b) => {
              a = new Date(a.createdAt);
              b = new Date(b.createdAt);
              return b - a;
            })
            .map((invite, index) => {
              const client = {
                clientId: invite.offer_item[0].owner._id,
                clientName: `${invite.offer_item[0].owner.name}`
              };

              return (
                <div className="invitation" key={index}>
                  <div className="invitation-container">
                    <div className="invite-content">
                      <h2>
                        <span>FROM:</span>
                        &nbsp;
                        {invite.offer_item[0].owner.name}
                        &nbsp; &nbsp;
                        <TimeAgo date={invite.createdAt} />
                        &nbsp;&nbsp;
                        <HelpCircle size={14} />
                      </h2>
                      {/* <h5 className="invite-message">{invite.message}</h5> */}
                      <br />
                      <h5 className="invite-value">
                        Total Bid Value: &nbsp;
                        {invite.bid_item.estimated_value.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        ) || "N/A"}
                      </h5>
                      <h5 className="invite-value">
                        Total Offer Value: &nbsp;
                        {this.calculateTotalOffer(invite).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD"
                          }
                        ) || "N/A"}
                      </h5>
                      <h5 className="invite-value">
                        Value Difference: &nbsp;
                        <span
                          style={
                            typeof (
                              this.calculateTotalOffer(invite) -
                              invite.bid_item.estimated_value
                            ) === "number" &&
                            this.calculateTotalOffer(invite) -
                              invite.bid_item.estimated_value >
                              0
                              ? { color: "#26C281" }
                              : { color: "#DF233A" }
                          }
                        >
                          {(
                            this.calculateTotalOffer(invite) -
                            invite.bid_item.estimated_value
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                          }) || "N/A"}
                        </span>
                      </h5>
                      <div className="invite-choices">
                        <button
                          onClick={() =>
                            this.acceptInvite(
                              this.props.user._id,
                              invite.offer_item[0],
                              invite.bid_item,
                              invite._id,
                              invite.bid_item.title
                            )
                          }
                          className="accept-invite-btn"
                        >
                          Accept&nbsp;&rarr;&nbsp;Start Talking
                        </button>
                        <button
                          onClick={() => this.ignoreInvite(invite)}
                          className="ignore-invite-btn"
                        >
                          Ignore
                        </button>
                      </div>
                    </div>
                    <div className="invite-target">
                      <h2>
                        <span>BID FOR:</span>
                        <br />
                        <br />
                      </h2>
                      <div className="invite-img-top-container">
                        <div className="invite-img" key={index}>
                          <h2>{invite.bid_item.title}</h2>
                          <div className="invite-img-container">
                            <div className="invite-img">
                              <a
                                href={`http://localhost:3000/listings/${invite.bid_item._id}`}
                                target="_blank"
                              >
                                <img
                                  src={invite.bid_item.images[0]}
                                  alt="asset image"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="invite-price">
                            {invite.bid_item.estimated_value.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD"
                              }
                            ) || "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="invite-target">
                      <h2>
                        <span>OFFERING:</span>
                        <br />
                        <br />
                      </h2>
                      <div className="invite-img-top-container invite-offer-container">
                        {invite.offer_item.map((asset, index) => (
                          <div className="invite-img" key={index}>
                            <h2>{asset.title}</h2>
                            <div className="invite-img-container">
                              <div className="invite-img">
                                <a
                                  href={`http://localhost:3000/listings/${asset.id}`}
                                  target="_blank"
                                >
                                  <img src={asset.imageUrl} alt="asset image" />
                                </a>
                              </div>
                            </div>
                            <div className="invite-price">
                              {asset.price.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD"
                              }) || "N/A"}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pending: state.auth.userInfo.pending_matches
  };
};

export default connect(mapStateToProps, actions)(ChatkitInvites);
