import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ColorHash from "color-hash";

const styles = theme => ({
  focused: {
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "black !important"
    }
  }
});

const groupchatUrl = api => {
  return "http://localhost:4000/api/v1/groupchat/" + api;
};

class PrivateChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessageSender: false,
      open: false,
      message: ""
    };
  }

  /**
   * resets state to initial values when user clicks cancel
   */
  cancelSelect() {
    this.setState({
      open: false,
      message: ""
    });
  }

  /**
   * sends message
   */
  async handleSend() {
    const message = this.state.message;
    const receiverID = this.props.id;
    let res = await axios.post(groupchatUrl("privateChat"), {
      groupName: "*private message*",
      owner: this.props.user._id,
      member: receiverID,
      message: message
    });
    const messageObj = res.data;
    const groupID = messageObj.groupID;
    this.props.socket.emit("newGroupNotify", {
      groupID: groupID,
      members: [this.props.user._id, receiverID]
    });
    await this.props.getGroups(this.props.user._id);
    await this.props.sendPrivateMessage(messageObj);
    this.cancelSelect();
    //await this.props.loadChat(groupID);
  }

  /**
   * renders message input box
   */
  renderMessageBox() {
    const { classes } = this.props;
    return (
      <Box p={1}>
        <TextField
          //label="Message"
          autoFocus
          multiline={true}
          variant="outlined"
          rows={6}
          id="invitation"
          fullWidth
          value={this.state.message}
          onChange={e => this.setState({ message: e.target.value })}
          InputLabelProps={{
            style: {
              fontSize: 20
            }
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            },
            style: { fontSize: 15 }
          }}
        />
      </Box>
    );
  }

  openDialog = () => {
    if (!this.state.isMessageSender) this.setState({ open: true });
  };

  async componentDidMount() {
    if (this.props.id === this.props.user._id)
      this.setState({ isMessageSender: true });
  }

  render() {
    var colorHash = new ColorHash();
    const name = this.props.name;
    if (this.state.isMessageSender)
      return (
        <p
          style={{
            fontSize: "12px",
            color: colorHash.hex(name)
          }}
        >
          {name}
        </p>
      );
    else
      return (
        <div>
          <button
            className={"privateChat"}
            onClick={() => this.openDialog(this.props.id)}
          >
            <p
              style={{
                fontSize: "12px",
                color: colorHash.hex(name)
              }}
            >
              {name}
            </p>
          </button>

          <Dialog
            bodystyle={{ margin: 0, padding: 0 }}
            open={this.state.open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            BackdropProps={{
              style: {
                opacity: 0.4
              }
            }}
          >
            <DialogTitle id="confirmation-dialog-title">
              Private Message to {name}
            </DialogTitle>
            <DialogContent dividers>{this.renderMessageBox()}</DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.cancelSelect()}
                color="primary"
                style={{ fontSize: 12 }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.handleSend()}
                color="primary"
                style={{ fontSize: 12 }}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(PrivateChat));
