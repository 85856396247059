import React from 'react';
import { XSquare, Upload } from 'react-feather';

const toggleImage = (props, src) => {
  if (props.selectedImages.includes(src)) {
    console.log("removing");
    return props.removeImage(src);
  }
  console.log("adding...")
  return props.addImage(src);
}


const ChatkitUpload = (props) => {
  const { 
    allAssets, 
    chatAssets, 
    toggleUpload, 
    uploadImgUrl,
    isChatOwner
  } = props;

  if (props && isChatOwner) {

    const filtered = chatAssets.map((chatAsset) => {
      if (chatAsset !== undefined) {
        return allAssets.filter((asset) => {
          return asset.asset !== null && asset.asset._id === chatAsset.id
        })
      }
    });

    return (
      <div className="upload-dialog">
        <XSquare size={18} onClick={toggleUpload} color={"white"}/>
        <div className="selection">
          <h2>Select an image to upload:</h2>
          <br/>
          <div className="selection-images" >
            {
              filtered.map((x,i) => (
                x[0].asset.images.map((imgSrc, index) => (
                  <img 
                    src={imgSrc} 
                    key={index} 
                    alt="asset"
                    onClick={() => toggleImage(props, imgSrc)} 
                    className={
                      props.selectedImages.includes(imgSrc) ?
                      "selected" : ""
                    } 
                  />
                ))
              ))
            }
          </div>
        </div>
        <div className="upload-action">
          <button onClick={uploadImgUrl}><Upload size={15}/>&nbsp;Upload</button>
        </div>
      </div>
    );
  } else if (props) {
    
    let currentAssetsBid = chatAssets.filter((x) => x !== null)
    let currentFiltered = currentAssetsBid.map((curr) => {
      return allAssets.filter((all) =>  {
        return all.asset !== null && all.asset._id === curr.id
      })
    })

    return (
      <div className="upload-dialog">
        <XSquare size={18} onClick={toggleUpload} color={"white"}/>
        <div className="selection">
          <h2>Select an image to upload:</h2>
          <br/>
          <div className="selection-images" >
            {
              currentFiltered.map((x,i) => (
                x[0].asset.images.map((imgSrc, index) => (
                  <img 
                    src={imgSrc} 
                    key={index} 
                    alt="asset"
                    onClick={() => toggleImage(props, imgSrc)} 
                    className={
                      props.selectedImages.includes(imgSrc) ?
                      "selected" : ""
                    } 
                  />
                ))
              ))
            }
          </div>
        </div>
        <div className="upload-action">
          <button onClick={uploadImgUrl}><Upload size={15}/>&nbsp;Upload</button>
        </div>
      </div>
    )

  } else {
    return <div>Loading...</div>
  }
};

export default ChatkitUpload;