import React, { Component } from "react";
import AssetFileInput from "./AssetFileInput";
import AssetReviewFiles from "../../AssetReview/AssetReviewFiles";

class AssetFileUpload extends Component {
  addFiles = (files, field) => {
    let oldFiles = { ...this.props.photos };
    let index;

    if (Object.keys(oldFiles).length) {
      index = Math.max(...Object.keys(oldFiles)) + 1;
    } else {
      index = 0;
    }

    let current = [];

    Object.keys(oldFiles).forEach(key => {
      current.push(oldFiles[key].name);
    });

    Object.keys(files).forEach(key => {
      if (!current.includes(files[key].name)) {
        oldFiles[index] = files[key];
        index++;
      }
    });

    this.props.updateFiles(oldFiles, field);
  };

  editList = (key, field) => {
    const files = this.props[field];
    if (Object.keys(files).length === 1 && field === "photos") {
      return;
    }
    let updatedFiles = Object.keys(files)
      .filter(id => id !== key)
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: files[key]
        };
      }, {});

    this.props.updateFiles(updatedFiles, field);
  };

  clearList = field => {
    this.props.updateFiles({}, field);
  };

  render() {
    const { name, photos, videos } = this.props;

    return (
      <div className="add-asset__template__box">
        <div className="add-asset__template__label">Upload Photos</div>
        <div className="add-asset__file">
          <AssetFileInput
            name={name}
            label="Upload Photos"
            addFiles={this.addFiles}
            clearList={this.clearList}
          />
        </div>
        <AssetReviewFiles
          photos={photos}
          editList={this.editList}
          videos={videos}
        />
      </div>
    );
  }
}

export default (AssetFileUpload);
