import React, { Component } from 'react';
import TimeAgo from "react-timeago";

class MessageList extends Component {
  render() {
    const { user, messages } = this.props;

    return (
      <ul>
        {messages.map((message, msgIndex) => {
          return message.parts.map((part, partIndex) => {

            // External Attachments
            {/* if (part.payload.type.split("/")[0].includes('image')) {
              return (
                <li key={part.payload._downloadURL} className="message-attachment">
                  <div>
                    <img src={part.payload._downloadURL} alt='attachment' />
                  </div>
                  <br/>
                </li>
              )
            } */}


            // Internal Attachments - User asset images
            if (message.senderId === user && part.payload.type.split("/")[0].includes('image')) {
              return (
                <li key={partIndex} className="message-attachment">
                  <div className="message-avatar">
                    <span>{message.userStore.users[message.senderId].name.charAt(0).toUpperCase()}</span>
                  </div>
                  <div>
                    <img src={part.payload.url} alt='attachment' />
                  </div>
                  <br/>
                </li>
              )
            }


            if (message.senderId !== user) {
              if (part.payload.type.split("/")[0].includes('image')) {
                return (
                  <li key={partIndex} className="message-attachment-sender">
                    <div className="sender-img">
                      <img src={part.payload.url} alt='attachment' />
                    </div>
                    <div className="message-avatar">
                      <span>{message.userStore.users[message.senderId].name.charAt(0).toUpperCase()}</span>
                    </div>
                    <br/>
                  </li>
                )
              }

              return (
                <li key={partIndex} className="message-content-user">
                  <div className="message-content-user-text-container">
                    <p className="message-text">{message.parts[0].payload.content}</p>
                    <span className="message-username">
                      {message.userStore.users[message.senderId].name}
                      &nbsp;&nbsp;
                      <TimeAgo date={message.createdAt}/>
                    </span>&nbsp;&nbsp;
                  </div>
                  <div className="message-avatar">
                    <span>{message.userStore.users[message.senderId].name.charAt(0).toUpperCase()}</span>
                  </div>
                  <br/>
                </li>
              )
            } 
  
  
            return (
              <li key={partIndex} className="message-content">
                <div className="message-avatar">
                  <span>{message.userStore.users[message.senderId].name.charAt(0).toUpperCase()}</span>
                </div>
                <div className="message-content-text-container">
                  <p className="message-text">{message.parts[0].payload.content}</p>
                  <span className="message-username">
                    {message.userStore.users[message.senderId].name}
                    &nbsp;&nbsp;
                    <TimeAgo date={message.createdAt}/>
                  </span>&nbsp;&nbsp;
                  
                </div>
                <br/>
              </li>
            )


          })
          





        })}
      </ul>
    );
  }
}

export default MessageList;