import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import ValidateFormTwo from "./ValidateFormTwo";
import ValidateFormThree from "./ValidateFormThree";
import ValidateFormConfirm from "./ValidateFormConfirm";
import ValidateFinished from "./ValidateFinished";

class ValidateAccount extends Component {
  state = {
    formNumber: 1,
    formFields: {}
  };

  onFormForward = values => {
    this.setState(prevState => ({
      formNumber: prevState.formNumber + 1,
      formFields: { ...prevState.formFields, ...values }
    }));
  };

  onFormBackward = () => {
    this.setState(prevState => ({
      formNumber: prevState.formNumber - 1
    }));
  };

  addPhotoFile = (id, photo) => {
    this.setState(prevState => ({
      formFields: { ...prevState.formFields, [id]: photo }
    }));
  };

  removePhotoFile = id => {
    this.setState(prevState => ({
      formFields: { ...prevState.formFields, [id]: null }
    }));
  };

  onFormSubmit = () => {
    const { formFields } = this.state;
    const formData = new FormData();
    formData.append("userImage", formFields.userImage);
    formData.append("userDocument", formFields.userDocument);
    this.props.userValidate(formData);

    this.setState(prevState => ({
      formNumber: prevState.formNumber + 1
    }));
  };

  renderForm = () => {
    const { formNumber, formFields } = this.state;

    if (formNumber === 1) {
      return (
        <ValidateFormTwo
          onFormForward={this.onFormForward}
          onFormBackward={this.onFormBackward}
          addPhotoFile={this.addPhotoFile}
          removePhotoFile={this.removePhotoFile}
          formFields={formFields}
        />
      );
    } else if (formNumber === 2) {
      return (
        <ValidateFormThree
          onFormForward={this.onFormForward}
          onFormBackward={this.onFormBackward}
          addPhotoFile={this.addPhotoFile}
          removePhotoFile={this.removePhotoFile}
          formFields={formFields}
        />
      );
    } else if (formNumber === 3) {
      return (
        <ValidateFormConfirm
          onFormSubmit={this.onFormSubmit}
          onFormBackward={this.onFormBackward}
          formFields={formFields}
        />
      );
    } else if (formNumber === 4) {
      return <ValidateFinished validated={formFields.photoVerified} />;
    }
  };

  render() {
    return (
      <div className="validate">
        <div className="accountsettings__account">
          <div className="accountsettings__title">Validate Account</div>
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(ValidateAccount);
