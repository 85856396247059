import React, { Component } from "react";
import { reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import FormInput from "../FormInput/FormInput";
import FileInput from "../FormInput/FileInput";

const formFields = [
  {
    name: "firstName",
    label: "Legal First Name",
    type: "text"
  },
  {
    name: "middleName",
    label: "Legal Middle Name",
    type: "text"
  },
  {
    name: "lastName",
    label: "Legal Last Name",
    type: "text"
  }
];

class ValidateFormTwo extends Component {
  displayPhoto = file => {
    this.props.addPhotoFile("userDocument", file);
  };

  removePhoto = () => {
    this.props.removePhotoFile("userDocument");

    this.props.dispatch(change("validateFormTwo", "userDocument", null));
  };

  render() {
    const { userDocument } = this.props.formFields;

    return (
      <div className="form">
        <form onSubmit={this.props.handleSubmit(this.props.onFormForward)}>
          {formFields.map((field, idx) => {
            return <FormInput {...field} key={idx} />;
          })}

          <FileInput
            name="userDocument"
            label={
              userDocument
                ? userDocument.name
                : "Please upload a valid Photo ID"
            }
            displayPhoto={this.displayPhoto}
            removePhoto={this.removePhoto}
          />

          {userDocument ? (
            <div className="validate__photo">
              <img
                className="validate__photo__img"
                src={URL.createObjectURL(userDocument)}
                alt="identification"
              />
            </div>
          ) : null}

          <div className="validate__btn">
            <button className="form__button">Continue</button>
          </div>
        </form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = ["firstName", "lastName", "dob", "userDocument"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

export default reduxForm({
  validate,
  form: "validateFormTwo",
  destroyOnUnmount: false
})(
  connect(
    null,
    actions
  )(ValidateFormTwo)
);
