import React from 'react';

const abbreviateNumber = (n) => {
  if (n < 1e3) return "$"+n;
  if (n >= 1e3 && n < 1e6) return "$"+(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return "$"+(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return "$"+(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return "$"+(n / 1e12).toFixed(1) + "T";
}

const parsePrice = (n) => {
  let price = abbreviateNumber(n);
  if (price === "$1000.0K") {
    return "$1M"
  } else {
    return price;
  }
}

const PopCardSearch = props => {
  let { results } = props;

  return (
    <div className="items-grid">
    {results.map((asset, index) => (
      <div className="pop__card" key={index}>
        <img src={asset._source.images[0]} alt="product"/>
        <div className="asset-title-card">
          <a href={`/assets/${asset._source.category}/${asset._id}`} className="post-title">
            <p>{asset._source.postTitle}</p>
          </a>
          <div className="favorites">
            <i className="far fa-heart fa-2x"></i>
          </div>
        </div>
        <div className="pop__card-content">
          <div className="card-location">
            {asset._source.address}
          </div>
          <div className="asset-category">
            <div className="name">
              {
                asset._source.category === "realestate" ? "Real Estate" :
                asset._source.category.charAt(0).toUpperCase() + asset._source.category.slice(1)
              }&nbsp;&#x2014;&nbsp;{asset._source.subCategory}
            </div>
          </div>
          <div className="pref">
            {
              asset._source.sell && asset._source.swap && asset._source.lease ? "SALE | SWAP | LEASE" :
              asset._source.sell && asset._source.swap ? "FOR SALE or SWAP" :
              asset._source.sell && asset._source.lease ? "FOR SALE or LEASE" :
              asset._source.lease && asset._source.swap ? "SWAP or LEASE" :
              asset._source.sell ? "FOR SALE" :
              asset._source.swap ? "SWAP ONLY" : "LEASE ONLY"
            }
          </div>
          <div className="asset-price-card">
            <a href={`/assets/${asset._source.category}/${asset._id}`}>
              <button className="value">
                {parsePrice(asset._source.price) || "Enquire"}
              </button>
            </a>
          </div>
        </div>
      </div>
    ))}
    </div>
  )
}

export default PopCardSearch;