import React, { Component } from "react";
import AccountSettingsLayout from "../AccountSettingsLayout";
import ValidateAccount from "../../ValidateAccount/ValidateAccount";

class AccountSettingsValidate extends Component {
  render() {
    return (
      <AccountSettingsLayout>
        <ValidateAccount />
      </AccountSettingsLayout>
    );
  }
}

export default AccountSettingsValidate;
