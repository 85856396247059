import React from "react";
import { Field } from "redux-form";
const required = value => value ? undefined : 'Required'

const renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => {
  console.log(label);
  return (
    <div>
      <div>
        <select 
          className={
            touched && error ? "form__input form__input--error half-input" : "form__input half-input"
          }
          placeholder={label}
          {...input}>
          {children}
        </select>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
}

const SelectFormInput = values => {
  const { name, label, type, options } = values;

  return (
    <div className="form__group">
      <Field 
        name={name}
        component= {renderSelectField}
        label={label}
        type={type} 
        validate={required}
      >
        {options.map((data, i) => {
            return (
                <option value={data.value} key={i} disabled={data.disable}>{data.display}</option>
            );
        })}  
      </Field>
    </div>
  );
};

export default SelectFormInput;
