import React, { Component } from "react";
import { Field } from "redux-form";

class FormInput extends Component {
  onChange = e => {
    const { onChange } = this.props.input;

    onChange(e.target.files);
    this.props.addFiles(e.target.files, this.props.input.name);
  };

  clearField = () => {
    this.fileInput.value = "";
    this.props.clearList(this.props.input.name);
  };

  render() {
    const {
      label,
      meta: { touched, error }
    } = this.props;

    const fileLength = Object.keys(this.props.input.value).length;

    return (
      <div className="form__clearable">
        <label
          className={
            touched && error ? `form__label form__label--error` : `form__label`
          }
          htmlFor="pdf"
        >
          <div>{fileLength ? `${fileLength} documents uploaded` : label}</div>

          <div className="form__label__icon">
            <i className="fas fa-upload" />
          </div>
        </label>

        <input
          className="form__input form__file"
          type="file"
          id="pdf"
          accept=".pdf, application/pdf"
          multiple
          onChange={this.onChange}
          ref={ref => (this.fileInput = ref)}
        />

        <div className="form__file__clear" onClick={this.clearField}>
          &times;
        </div>
      </div>
    );
  }
}

const FileInput = ({ name, label, clearList, addFiles }) => {
  return (
    <div className="form__group">
      <Field
        name={name}
        component={FormInput}
        label={label}
        clearList={clearList}
        addFiles={addFiles}
      />
    </div>
  );
};

export default FileInput;
