import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
// import AssetAddressEdit from "./AssetAddressEdit";
import AssetDescEdit from "./AssetDescEdit";
import AssetInfoEdit from "./AssetInfoEdit";
import AssetPhotoEdit from "./AssetPhotoEdit";
import AssetVideoEdit from "./AssetVideoEdit";
import AssetFormActions from "../../AddAsset/AssetFormComponents/AssetFormActions";
import AssetFormSwap from "../../AddAsset/AssetFormComponents/AssetFormSwap";
import AccountSettingsLayout from "../AccountSettingsLayout";
import AssetDocEdit from "./AssetDocEdit";
class AccountSettingsAssetsItemEdit extends Component {
  state = {
    photos: {},
    videos: {},
    pdfs: {},
    //swapDetails: {},
    loading: false
  };

  async componentDidMount() {
    //TODO this needs to be changed
    await this.props.getAssetsByUserId(this.props.userId, () => {});
    let asset = this.props.assets.filter(
      asset => asset._source.assetId === this.props.match.params.id
    )[0];

    let photos = await this.getBlobs(asset._source.images || []);

    let videoUrls = (asset.videos || []).map(video => video.videoUrl);
    let videos = await this.getBlobs(videoUrls || []);
    // let pdfs = await this.getBlobs(asset._source.documents || []);
    let formFields = { ...asset._source };

    this.setState({
      formFields,
      photos,
      videos
      // pdfs
    });

    this.props.initialize({
      ...formFields,
      photos,
      videos
      // pdfs
    });
  }

  getBlobs = async images => {
    const promises = images.map(img => {
      // .replace(/^https:\/\//i, "http://")
      return fetch(img, { cache: "no-cache" }).then(response => {
        return response.blob();
      });
    });
    return await Promise.all(promises);
  };

  removeFormatPrice = price => {
    if (isNaN(price)) {
      return price && price.replace(/[^0-9]/g, "");
    } else {
      return price;
    }
  };

  onUpdateSubmit = values => {
    const {
      photos,
      videos,
      pdfs,
      formFields,
      formFields: { assetId }
    } = this.state;
    let assetData = {};

    this.setState({
      loading: true
    });

    const formData = new FormData();

    Object.keys(photos).forEach(img => {
      formData.append("images", photos[img]);
    });

    if (videos) {
      Object.keys(videos).forEach(video => {
        formData.append("videos", videos[video]);
      });
    }
    console.log(values);

    if (values.appraisalValue) {
        assetData = {
          ...values,
          swap: formFields.swap,
          sell: formFields.sell,
          lease: formFields.lease,
          swapDetails: formFields.swapDetails,
          assetType: formFields.category.toUpperCase(),
          appraisalValue: this.removeFormatPrice(values.appraisalValue),
          price: this.removeFormatPrice(values.price)
      };
    } else {
        assetData = {
          ...values,
          swap: formFields.swap,
          sell: formFields.sell,
          lease: formFields.lease,
          swapDetails: formFields.swapDetails,
          assetType: formFields.category.toUpperCase(),
          price: this.removeFormatPrice(values.price)
      }
    }

    delete assetData.images;
    delete assetData.videos;
    delete assetData.pdfs;
    formData.append("assetData", JSON.stringify(assetData));

    this.props.userUpdateAsset(assetId, formData, pdfs, () => {
      this.props.history.push(`/user/accountsettings/userassets`);
    });
  };

  updateFiles = (files, field) => {
    this.props.dispatch(change("updateUserInfo", field, files));

    this.setState({
      [field]: files
    });
  };

  handleActions = name => {
    this.setState(
      prevState => ({
        ...prevState,
        formFields: {
          ...prevState.formFields,
          [name]: !prevState.formFields[name]
        }
      }),
      () => {
        const { sell, swap, lease } = this.state.formFields;
        if (!sell && !swap && !lease) {
          this.setState(prevState => ({
            ...prevState,
            formFields: {
              ...prevState.formFields,
              [name]: true
            }
          }));
        }
      }
    );
  };

  handleDetailChange = (detail, type) => {
    this.setState(prevState => ({
      ...prevState,
      formFields: {
        ...prevState.formFields,
        [detail]: {
          ...prevState.formFields[detail],
          [type]: !prevState.formFields[detail][type]
        }
      }
    }));
  };

  render() {
    const { formFields, photos, videos, loading } = this.state;
    if (!this.state.formFields) return <div>Loading...</div>;
    return (
      <AccountSettingsLayout>
        <div className="form__close form__back accountsettings__assets__edit__back">
          <Link to={`/user/accountsettings/userassets`}>&#8617;</Link>
        </div>

        <form
          className="accountsettings__assets__edit__form"
          onSubmit={this.props.handleSubmit(this.onUpdateSubmit)}
        >
          <AssetFormActions
            handleActions={this.handleActions}
            {...formFields}
          />
          {formFields.swap && (
            <AssetFormSwap
              swapDetails={formFields.swapDetails}
              handleDetailChange={this.handleDetailChange}
            />
          )}
          <AssetPhotoEdit
            photos={photos || []}
            updateFiles={this.updateFiles}
          />
          <AssetDocEdit
            name="pdfs"
            pdfs={formFields.documents || []}
            updateFiles={this.updateFiles}
          />
          <AssetInfoEdit formFields={formFields} />
          {/* <AssetAddressEdit formFields={formFields} /> */}
          <AssetDescEdit formFields={formFields} />
          <AssetVideoEdit videos={videos || []} />
          <div className="u-center-text center-flex">
            <button className="btn accountsettings__assets__edit__form__btn">
              <div className="accountsettings__assets__edit__form__btn__label">
                Submit
              </div>
              {loading && (
                <div className="accountsettings__assets__edit__form__btn__spinner">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              )}
            </button>
          </div>
        </form>
      </AccountSettingsLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    assets: state.asset.userAssetsResults,
    userId: state.auth.userInfo._id
  };
};

function validate(values) {
  const errors = {};
  const categories = ["photos"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

export default reduxForm({
  validate,
  form: "updateUserInfo"
})(
  connect(
    mapStateToProps,
    actions
  )(withRouter(AccountSettingsAssetsItemEdit))
);
