import React, { Component } from "react";
import withStyles from '@material-ui/core/styles/withStyles'
import AssetFormHeader from "../AssetFormComponents/AssetFormHeader";
import AssetFormArtDetails from "./AssetFormArtDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsIndicator: {
    backgroundColor: "#111"
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    marginRight: theme.spacing(4),
    fontFamily: "Questrial",
    "&:hover": {
      color: "#111",
      opacity: 1
    },
    fontSize: "1.6rem",
    fontWeight: "bold",
    "&$tabSelected": {
      color: "#111",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#111"
    }
  },
  tabSelected: {}
});
 
class AssetFormArt extends Component {
  state = {
    value: "single"
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { classes } = this.props;
    return (
      <AssetFormHeader {...this.props}>
        <div className="add-asset__art">
          <Tabs
            value={value}
            onChange={this.handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator
            }}
          > 
            <Tab
              label="Single"
              value="single"
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
            <Tab
              label="Collection"
              value="collection"
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
          </Tabs>
        </div>
        <AssetFormArtDetails {...this.props} {...this.state} />
      </AssetFormHeader>
    );
  }
}

export default withStyles(styles)(AssetFormArt);
