import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";

class AssetFormHeader extends Component {
  render() {
    const { children, onFormBackward } = this.props;
    return (
      <div className="add-asset__template">
        <button className="form__close form__back" onClick={onFormBackward}>
          &#8617;
        </button>

        <div className="add-asset__template__header">Add a New Asset</div>
        <div className="add-asset__template__instructions">
          <span className="font-bold">NOTE:</span> &nbsp; Please fill in the
          details for the asset you will like to add. The documents uploaded
          will be not be shared with other users unless permission is given.
        </div>

        {children}
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(AssetFormHeader);
