import React from "react";
import { Field } from "redux-form";

const required = value => value ? undefined : 'Required'

const renderTextField = ({ input, label, placeholder, type, meta: { touched, error } }) => {
  console.log(label)
  return (
    <input
      {...input}
      className={
        label === "First Name" || 
        label === "Last Name" || 
        label === "Create Password" || 
        label === "Confirm Password" ?
        touched && error ? "form__input form__input--error half-input" : "form__input half-input" :
        touched && error ? "form__input form__input--error" : "form__input"
      }
      placeholder={placeholder || label}
      type={type || "text"}
    /> 
  )
};

const FormInput = values => {
  const { name, label, type } = values;
  let halved = label === "First Name" || 
  label === "Last Name" || 
  label === "Create Password" || 
  label === "Confirm Password" ?
  "form__half-group" : "";

  return (
    <div className={`form__group ${halved}`}>
      <Field
        name={name}
        component={renderTextField}
        label={label}
        type={type} 
        validate={required}
      />
    </div>
  );
};

export default FormInput;
