import React, { Component } from "react";
import ChatroomNotification from "./ChatroomNotification";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import PrivateChat from "./PrivateChat";

class ChatroomMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      typingMulti: false,
      input: "",
      typingUser: ""
    };
  }

  /**
   * displays typing status, sends typing status to all other group chat member
   *
   * @param {object} e Event of input change
   */
  handleInputChange = e => {
    this.setState({
      input: e.target.value
    });
    this.props.socket.emit("typing", {
      groupID: this.props.currentChat,
      userID: this.props.user._id,
      username: this.props.user.userName
    });
  };

  /**
   * detects when user presses enter key
   *
   * @param {object} e Event of key press
   */
  handleKeyPress = e => {
    let key = e.which || e.keyCode;
    if (key === 13) {
      this.onSubmitMessage();
    }
  };

  /**
   * sends message upon user pressing enter key, and clears text input field
   */
  onSubmitMessage = () => {
    let userMsg = {
      text: this.state.input,
      userID: this.props.user._id,
      groupID: this.props.currentChat
    };
    this.props.onSend(userMsg);
    this.setState({
      input: ""
    });
  };

  /**
   * renders group members' typing status from props
   */
  renderTypingStatus() {
    var names = [];
    Object.keys(this.props.typingStatus).map(key => {
      if (this.props.typingStatus[key].username) {
        if (key === this.props.user._id) {
          return names.push("me");
        } else {
          return names.push(this.props.typingStatus[key].username);
        }
      }
    });
    if (names.length > 1) {
      return (
        <div className="chatroom__main--view-typingStatus">
          <p>{names.join(", ").concat(" are typing...")}</p>
        </div>
      );
    } else if (names.length === 1) {
      return (
        <div className="chatroom__main--view-typingStatus">
          <p>
            {names[0] === "me" ? "typing..." : names[0].concat(" is typing...")}
          </p>
        </div>
      );
    } else {
      return "";
    }
  }

  /**
   * renders message content
   *
   * @param {object} message
   * {
   *  text: text message, if message is normal text message
   *  image: image URL, if message is shared image
   *  user:
   *    {
   *      id: user ID
   *      name: user firstname + lastname
   *    }
   *  createdAt: time when message was created
   * }
   * @param {number} index Index of current message content box
   */
  renderMessageContent = (message, index) => {
    if (!message.image)
      return (
        <div className="chatroom__main--bubble" key={index}>
          {message.text}
        </div>
      );
    else
      return (
        <div
          className="chatroom__main--imageBubble"
          key={index}
          onClick={() => this.props.zoomIn(message.image)}
        >
          <p>shared an image</p>
          <img src={message.image} alt="shared"/>
        </div>
      );
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messages !== this.props.messages) {
      this.setState({ messages: this.props.messages });
    }
    if (this.props.currentChat !== "") {
      var el = this.refs.overflowBox;
      el.scrollTop = el.scrollHeight;
    }
  }

  render() {
    let { messages } = this.state;
    return (
      <div className="chatroom__main">
        <ChatroomNotification
          toggleSidebar={this.props.toggleSidebar}
          toggleAssetBar={this.props.toggleAssetBar}
          groupID={this.props.currentChat}
          socket={this.props.socket}
          loadChat={this.props.loadChat}
        />
        {this.props.currentChat === "" ? (
          <h1 style={{ color: "grey" }}>
            &nbsp; {"Click a chat room to start"}
          </h1>
        ) : (
          <div className="chatroom__main--view">
            {/* <div className="chatroom__main--view-scrollbarCover" /> */}

            <div ref="overflowBox" className="chatroom__main--view-overflowBox">
              {messages.map((message, index) => (
                <div className="chatroom__main--bubbleContainer" key={index}>
                  {/* <p
                    style={{
                      fontSize: "12px",
                      color: colorHash.hex(message.user.name)
                    }}
                  >
                    {message.user.name}
                  </p> */}
                  <PrivateChat
                    name={message.user.name}
                    id={message.user.id}
                    socket={this.props.socket}
                    sendPrivateMessage={this.props.sendPrivateMessage}
                    loadChat={this.props.loadChat}
                  />

                  {this.renderMessageContent(message, index)}
                </div>
              ))}
            </div>

            {this.renderTypingStatus()}
          </div>
        )}

        <div className="chatroom__main--message">
          <form onSubmit={e => this.props.onSend(e)}>
            <textarea
              type="text"
              value={this.state.input}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleInputChange}
            />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(ChatroomMain);
