import React, { Component } from "react";
import SidebarMenu from "../Menu/SidebarMenu";
import UserModernMenu from "../Menu/UserModernMenu";
import Wrapper from "../Layout/Wrapper";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import { withRouter } from "react-router-dom";

class UploadImage extends Component {
  render() {
    return (
      <div className="page">
        <UserModernMenu user={this.props.userInfo} />
        <SidebarMenu />
        <Wrapper>
          <div className="uploadForm-container">
            <div className="uploadForm-container-inputs">
              <div className="add-images">
                <div className="add-images-container">
                  <div className="add-images-container-base">
                    <div className="instruct">
                      <i className="fas fa-cloud-upload-alt fa-2x"></i>
                      <p>
                        Drag and Drop <br /> or click to upload
                      </p>
                      <p className="recommendation">
                        Recommendation: Use high-quality .jpg files less than
                        32MB
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-slide-btn">
                  <button>Upload</button>
                </div>
              </div>
              <div className="asset-intro-form">
                <input
                  type="text"
                  className="title"
                  placeholder="Enter Title"
                />
                <br />
                <textarea
                  type="text"
                  className="description"
                  placeholder="Give a summary"
                />
                <input
                  type="text"
                  className="location"
                  placeholder="Location"
                />
                <input type="text" className="price" placeholder="Price" />
                <div className="form-slide-btn">
                  <button className="next-frame-btn">
                    Next&nbsp;&nbsp;&rarr;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(UploadImage));
