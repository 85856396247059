import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import * as actions from "../../redux/actions";
import bchainLogo from "../../images/bchain-logo.png";
import FormInput from "../FormInput/FormInput";

class ForgotPassword extends Component {
  state = {
    forgotFormSubmitted: false
  };

  onForgotPassword = value => {
    this.props.userForgotPassword(value, () => {
      this.props.history.push("/login");
    });

    this.setState({
      forgotFormSubmitted: true
    });
  };

  onClose = () => {
    this.props.history.push("/login");
  };

  render() {
    const { forgotFormSubmitted } = this.state;
    return (
      <div className="forgotpassword">
        <div className="form">
          <Link to="/login" className="form__close">
            &#8617;
          </Link>
          <img className="form__logo" src={bchainLogo} alt="logo" />

          {forgotFormSubmitted ? (
            <div>
              <div className="forgotpassword__submitted">
                <div>Check your email and follow the instructions.</div>
                <div>
                  *You will be automatically redirected to the login page.*
                </div>
              </div>
              <button className="form__button" onClick={this.onClose}>
                Close
              </button>
            </div>
          ) : (
            <form onSubmit={this.props.handleSubmit(this.onForgotPassword)}>
              <div className="forgotpassword__title">Forgot password?</div>
              <div className="forgotpassword__content">
                Enter your email and we will send you a link to reset your
                password.
              </div>
              <FormInput
                name="email"
                label="Please enter your email"
                type="email"
              />

              <button className="form__button">Send password reset link</button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = ["email"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

export default reduxForm({ validate, form: "forgotPassword" })(
  connect(
    null,
    actions
  )(withRouter(ForgotPassword))
);
