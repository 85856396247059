import React, { Component } from "react";
import AssetFormSwapChoices from "./AssetFormSwapChoices";

class AssetFormSwap extends Component {
  render() {
    const { swapDetails, handleDetailChange } = this.props;
    return ( 
      <div className="add-asset__template__box">
        <div className="add-asset__template__label">Swap Details</div>
        <div className="add-asset__actions__description u-center-text">
          Please select which types of assets you would like to swap with:
        </div>
        <AssetFormSwapChoices
          {...swapDetails}
          handleDetailChange={handleDetailChange}
          detailType="swapDetails"
        />
      </div>
    );
  }
}

export default AssetFormSwap;
