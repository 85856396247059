import React from "react";

const DeleteModalAlert = ({onClickYes, onClickNo}) => {
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        zIndex: 1,
        flexDirection: "column",
        backgroundColor: "white",
        width:250,
        height: 160,
        top:'30vh',
        left: 0,
        right: 0,
        marginRight:'auto',
        marginLeft:'auto',
        justifyContent:'center',
        boxShadow: "0px 3px 3px 3px #9E9E9E",
        borderRadius:20
      }}
    >
      <h1 style={{textAlign:'center', marginBottom:30, lineHeight:1.2}}>Are you sure you want to delete this listing?</h1>
      <div style={{ display: "flex", flexDirection: "row", justifyContent:'space-evenly'  }}>
        <button onClick={onClickYes} style={{width:60, height:40, borderRadius:10, outline:'none', fontWeight:'bold', letterSpacing:1.2 }}>Yes</button>
        <button onClick={onClickNo} style={{width:60, height:40, borderRadius:10, outline:'none', fontWeight:'bold', letterSpacing:1.2 }}>No</button>
      </div>
    </div>
  );
};

export default DeleteModalAlert;
