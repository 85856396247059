import React from 'react'

const Description = props => {
  return props.isQuery ? 
  (
    <div className="description" style={{width: `${props.width}`}}>
      <div className="title">
        {props.title}
      </div>
      <div className="sub-description">
        {props.sub}
      </div>
      <div className="description-link" onClick={props.clicked} style={{cursor: "pointer"}}>
        {props.linkto}&nbsp;&nbsp;&rarr;
      </div>
    </div>
  ) : (
    <div className="description" style={{width: `${props.width}`}}>
      <div className="title">
        {props.title}
      </div>
      <div className="sub-description">
        {props.sub}
      </div>
      <a href={`${props.url}`}>
        <div className="description-link">
          {props.linkto}&nbsp;&nbsp;&rarr;
        </div>
      </a>
    </div>
  )
}

export default Description;