import React, { Component } from "react";
import ChatAvatarGroup from "./ChatAvatarGroup";

import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "axios";

import TimeAgo from "react-timeago";

const groupchatUrl = api => {
  return "http://localhost:4000/api/v1/groupchat/" + api;
};

class ChatroomInvites extends Component {
  getMemberNames = groupID => {
    let { invites } = this.props;

    if (invites[groupID]) {
      var memberNames = Object.keys(invites[groupID].members).map(memberID => {
        return invites[groupID].members[memberID];
      });

      return memberNames;
    }
  };

  acceptInvite = async (inviteID, groupID, ownerID) => {
    await axios.patch(groupchatUrl("acceptInvite/"), {
      inviteID: inviteID,
      groupID: groupID,
      userID: this.props.user._id
    });
    this.props.socket.emit("newGroupNotify", {
      groupID: groupID,
      members: [ownerID]
    });
    this.props.socket.emit("joinNewGroup", groupID);
    this.props.getPendingInvites(this.props.user._id);
  };

  acceptGroupInvite = async (inviteID, groupID) => {
    await axios.patch(groupchatUrl("acceptGroupInvite/"), {
      inviteID: inviteID,
      groupID: groupID,
      userID: this.props.user._id
    });
    this.props.socket.emit("joinNewGroup", groupID);
    this.props.getPendingInvites(this.props.user._id);
  };

  ignoreInvite = async inviteID => {
    await axios.patch(groupchatUrl("ignoreInvite/"), {
      inviteID: inviteID,
      userID: this.props.user._id
    });
    await this.props.getPendingInvites(this.props.user._id);
  };

  renderIndividualInvite = (invite, index) => {
    //console.log(invite);
    const ownerID = invite.group.members[0]._id;
    const ownerName = `${invite.group.members[0].firstName} ${invite.group
      .members[0].lastName || ""}`;
    return (
      <div className="chat__invite" key={index}>
        <div className="chat__invite--info">
          <div className="info-title">
            <div className="info-title-category">
              <p>Chat with: </p>
            </div>
            <div className="info-title-name">
              <p>{ownerName}</p>
            </div>
          </div>

          <div className="info-detail">
            <div className="detail-message">
              <div className="message-date">
                <TimeAgo date={invite.createdAt} />
                {/* {this.dateToString(invite.createdAt)} */}
              </div>
              <div className="message-body">{`Included message: ${
                invite.message
              }`}</div>
            </div>
          </div>

          <div className="info-buttonGroup">
            <button
              className="accept"
              onClick={() =>
                this.acceptInvite(invite._id, invite.group._id, ownerID)
              }
            >
              <p>Accept</p>
            </button>
            <button
              className="ignore"
              onClick={() => this.ignoreInvite(invite._id)}
            >
              <p>Ignore</p>
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderGroupInvite = (invite, index) => {
    return (
      <div className="chat__invite" key={index}>
        <div className="chat__invite--info">
          <div className="info-title">
            <div className="info-title-category">
              <p>Join group: </p>
            </div>
            <div className="info-title-name">
              <p>{invite.group.groupName}</p>
            </div>
          </div>

          <div className="info-detail">
            <div className="detail-avatar">
              <ChatAvatarGroup
                names={invite.group.members.map(
                  member => `${member.firstName} ${member.lastName || ""}`
                )}
              />
            </div>

            <div className="detail-message">
              <div className="message-date">
                <TimeAgo date={invite.createdAt} />
              </div>
              <div className="message-body">{`Included message: ${
                invite.message
              }`}</div>
            </div>
          </div>

          <div className="info-buttonGroup">
            <button
              className="accept"
              onClick={() =>
                this.acceptGroupInvite(invite._id, invite.group._id)
              }
            >
              <p>Accept</p>
            </button>
            <button
              className="ignore"
              onClick={() => this.ignoreInvite(invite._id)}
            >
              <p>Ignore</p>
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderInvites = () => {
    return this.props.pendingInvites.map((invite, index) =>
      invite.group.pending
        ? this.renderIndividualInvite(invite, index)
        : this.renderGroupInvite(invite, index)
    );
  };

  componentDidMount() {
    //this.getMemberNames();
    
  }

  render() {
    //console.log(this.props.pendingInvites);
    if (!this.props.pendingInvites) {
      return <div>Loading</div>;
    }
    return (
      <div className="chatroom__sidebar--groupchat">{this.renderInvites()}</div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(ChatroomInvites);
