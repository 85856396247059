import React from 'react';
import { Lock, CreditCard, MessageSquare } from 'react-feather';

const CartFeatures = () => {
  return (
    <div className="cart-container--features">
      <div className="feature">
        <Lock size={22} />
        <div className="feature-content">
          <h2>SECURE SHOPPING</h2>
          <h4>
            We love the latest SSL security technology to encrypt your personal information.
          </h4>
        </div>
      </div>
      <div className="feature">
        <CreditCard size={22} />
        <div className="feature-content">
          <h2>PAYMENT OPTIONS</h2>
          <h4>
            We accept all major credit and debit cards, Visa, Mastercard, American Express.
          </h4>
        </div>
      </div>
      <div className="feature">
        <MessageSquare size={22} />
        <div className="feature-content">
          <h2>TRANSACTION SUPPORT</h2>
          <h4>
            Reach out to us anytime if there's any issue regarding payments, disputes
            or refunds.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default CartFeatures;