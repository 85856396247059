import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import ChatBriefcaseSwaps from "./ChatBriefcaseSwap";
import ChatBriefcaseDetail from "./ChatBriefcaseDetail";
import ChatBriefcaseSelectAssets from "./ChatBriefcaseSelectAssets";

class ChatBriefcase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetsBid: [],
      assetsOffered: [],
      briefcaseFrame: 0,
      assetId: "",
      assetCategory: "",
      assetDetails: []
    };
  }

  handleDetailsFrame = async (id, category) => {
    let { briefcaseFrame } = await this.state;
    await this.setState({
      briefcaseFrame: briefcaseFrame + 1,
      assetId: id,
      assetCategory: category
    });
    // await this.props.userGetSingleAsset(id, category);
  };

  handleChangeFrameNext = async () => {
    let { briefcaseFrame } = await this.state;
    await this.setState({
      briefcaseFrame: briefcaseFrame + 1
    });
  };

  handleChangeFramePrev = () => {
    let { briefcaseFrame } = this.state;
    briefcaseFrame >= 1
      ? this.setState({ briefcaseFrame: briefcaseFrame - 1 })
      : this.setState({ briefcaseFrame: 0 });
  };

  render() {
    let { briefcase, handleAssetModal } = this.props;
    let { briefcaseFrame, assetId } = this.state;

    let framing =
      briefcaseFrame === 0 ? (
        <ChatBriefcaseSwaps
          briefcase={briefcase}
          handleNext={this.handleDetailsFrame}
          handlePrev={this.handleChangeFramePrev}
          handleAssetModal={handleAssetModal}
        />
      ) : briefcaseFrame === 1 ? (
        <ChatBriefcaseDetail
          itemId={assetId}
          handleNext={this.handleChangeFrameNext}
          handlePrev={this.handleChangeFramePrev}
        />
      ) : (
        <ChatBriefcaseSelectAssets />
      );

    if (!briefcase.sender) {
      return <div>None Available.</div>;
    }

    return framing;
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(mapStateToProps, actions)(ChatBriefcase);
