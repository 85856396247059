import React, { Component } from 'react';

class ScrollToTopButton extends Component {
  state = {
    toTopDisplay: false
  }

  handleScroll = () => {
    window.scrollY > 600 ? 
    this.setState({ toTopDisplay: true }) :
    this.setState({ toTopDisplay: false })
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    let toTopBtnStyle = {
      display: this.state.toTopDisplay ? "block" : "none"
    }
    return (
      <button 
        className="scroll-to-top-btn"
        style={toTopBtnStyle}
        onClick={() => window.scrollTo(0,0)}
      >
        <i className="fas fa-chevron-up fa-lg"></i>
        <p>Top</p>
      </button>
    );
  }
}

export default ScrollToTopButton;