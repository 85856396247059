import React, { Component } from "react";
import ChatAvatarGroup from "./ChatAvatarGroup";

import { connect } from "react-redux";
import * as actions from "../../redux/actions";

import TimeAgo from "react-timeago";

class ChatroomGroups extends Component {
  constructor(props) {
    super(props);

    //get last message from redux
    var lastMessage = {};
    Object.keys(props.groups).map(groupID => {
      lastMessage[groupID] = props.groups[groupID].lastMessage;
    });

    this.state = {
      activeChat: this.props.activeChat,
      lastMessage: lastMessage
    };
  }

  /**
   * Get group member names from the group info saved in redux
   *
   * @param {string} groupID
   */
  getMemberNames = groupID => {
    let { groups } = this.props;
    if (groups[groupID]) {
      var memberNames = Object.keys(groups[groupID].members).map(memberID => {
        return groups[groupID].members[memberID];
      });
      return memberNames;
    }
  };

  /**
   * get message preview string from state
   *
   * @param {string} groupID
   */
  messagePreview = groupID => {
    if (
      !this.state.lastMessage[groupID] ||
      Object.keys(this.state.lastMessage[groupID]).length <= 1
    ) {
      return "";
    } else {
      let message = this.state.lastMessage[groupID];
      if (message.text) {
        return `${message.senderID.firstName} ${message.senderID.lastName ||
          ""}: ${message.text}`;
      } else {
        return `${message.senderID.firstName} ${message.senderID.lastName ||
          ""}: [image]`;
      }
    }
  };

  /**
   * display last message's send date from state
   *
   * @param {string} groupID
   */
  messageDate = groupID => {
    if (
      !this.state.lastMessage[groupID] ||
      Object.keys(this.state.lastMessage[groupID]).length <= 1
    ) {
      return "";
    } else {
      let message = this.state.lastMessage[groupID];
      const date = new Date(message.createdAt);
      return <TimeAgo date={date} />;
    }
  };

  /**
   * load a chatroom upon user clicking on the chatroom in sidebar
   *
   * @param {string} groupID
   */
  loadChat = groupID => {
    this.setState({ activeChat: groupID });
    this.props.loadChat(groupID);
  };

  /**
   * render groups - avatars, group title, and last message
   */
  renderGroups = () => {
    return Object.keys(this.props.groups).map((groupID, index) => (
      <div
        className="chat__group"
        onClick={() => this.loadChat(groupID)}
        key={index}
      >
        {groupID === this.props.activeChat ? <div id="border" /> : ""}

        <ChatAvatarGroup names={this.getMemberNames(groupID)} />
        <div className="chat__group--info">
          <div className="info-title">
            {this.props.groups[groupID].groupName}
          </div>
          <div className="info-date">{this.messageDate(groupID)}</div>
          <div className="info-msg">{this.messagePreview(groupID)}</div>
        </div>
      </div>
    ));
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.lastMessage !== this.props.lastMessage) {
      console.log(this.props.lastMessage);
      var groupID = this.props.lastMessage.groupID;
      var dict = this.state.lastMessage;

      dict[groupID] = this.props.lastMessage;

      this.setState({ lastMessage: dict });
    }
  }

  render() {
    if (!this.props.groups) {
      return <div>Loading</div>;
    }
    return (
      <div className="chatroom__sidebar--groupchat">{this.renderGroups()}</div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(ChatroomGroups);
