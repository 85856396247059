import React, { Component } from 'react';
import axios from 'axios';
import Wrapper from '../Layout/Wrapper';
import Logo from '../../images/bx-logo-main-white.png';
import MarketingVid from '../../images/video/BX-Marketing-BG.mp4';
import ImgRealEstate from '../../images/daylight-dining.jpg';
import GooglePlay from '../../images/gplay.png';
import AppleStore from '../../images/applestore.png';
import ImgArt from '../../images/art_photography.jpg';
import ImgAuto from '../../images/cars.jpg';
import ImgJewelry from '../../images/diamond-ring.jpg';
import BgAuto from '../../images/autos-cars.jpg';

class Marketing extends Component {
  state = {
    email: '',
    hasError: false,
    emailError: '',
    emailSuccess: false,
    isSmallDevice: false,
  }

  _handleEmailSubmit = async e => {
    await e.preventDefault();

    await axios.post("https://api-dev.bazaarxchanges.com/api/v1/email/signups", {
      email: this.state.email
    })
      .then((res) => {
        this.setState({
          emailSuccess: res.data.success
        });
      })
      .catch(async (error) => {
        await this.setState({
          emailError: error.response.data.message || 'Please use a valid email',
          hasError: true
        });
      });
  }

  _handleChange = e => {
    this.setState({
      email: e.target.value
    });
  }

  _handleDeviceWidth = () => {
    window.addEventListener('resize', (event) => {
      console.log(event.target.innerWidth);
      if (event.target.innerWidth <= 600) {
        console.log('less than 600!');
        this.setState({
          isSmallDevice: true
        });
      } else {
        this.setState({
          isSmallDevice: false
        })
      }
    });
  }

  componentDidMount() {
    this._handleDeviceWidth();
  }

  render() {
    let { email, emailError, hasError, emailSuccess, isSmallDevice } = this.state;
    let shadeOverlayStyle = {
      opacity: isSmallDevice ? 0.75 : 0.4
    };

    return (
      <div className="marketing-container">
        {
          isSmallDevice ?
            <img src={BgAuto} className="sm-device-image" alt="mobile bg" /> :
            <video
              loop
              muted
              autoPlay
              className="fullscreen-vid"
              key={"video-bg"}
            >
              <source
                type="video/mp4"
                src={MarketingVid}
              />
            </video>
        }
        <div className="cover-overlay" style={shadeOverlayStyle}></div>
        <div className="centered-content">
          <h1>The Future is <br />a New Asset</h1>
          <h3>Non-Commodity Physical Asset Swaps</h3>
          <div className="banner-badges">
            <img src={AppleStore} alt="apple" />
            <img src={GooglePlay} alt="google" />
          </div>
          <h5>
            Coming Soon
          </h5>
          <h4>WINTER 2019</h4>
        </div>
        <Wrapper>
          <div className="marketing-nav-links">
            <img src={Logo} alt="logo" />
            <ul>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#works">How It Works</a>
              </li>
              <li>
                <a href="#swaps">Why Swaps</a>
              </li>
              <li>
                <a href="#join">Join</a>
              </li>
            </ul>
          </div>
        </Wrapper>
        <div className="marketing-content">
          <Wrapper>
            <div className="marketing-asset-content">
              <h1>Buy, Sell, &amp; Swap</h1>
              <div className="marketing-asset-content-images">
                <div className="marketing-asset-content-desc">
                  <img src={ImgArt} alt="art" />
                  <h5>Art</h5>
                </div>
                <div className="marketing-asset-content-desc">
                  <img src={ImgAuto} alt="auto" />
                  <h5>Automobiles</h5>
                </div>
                <div className="marketing-asset-content-desc">
                  <img src={ImgJewelry} alt="jewelry collectibles" />
                  <h5>Jewelry &amp; Collectibles</h5>
                </div>
                <div className="marketing-asset-content-desc">
                  <img src={ImgRealEstate} alt="realty" />
                  <h5>Real Estate</h5>
                </div>
              </div>
              <h4>And More...</h4>
            </div>
          </Wrapper>
        </div>
        <div className="marketing-content" id="about">
          <Wrapper>
            <h1>Why <span>BazaarXchanges</span></h1>
            <ul>
              <li>
                <h3>Make Connections</h3>
                <h2>
                  Connect with other collectors, artists, aficionados, creatives and more.
                </h2>
              </li>
              <li>
                <h3>Discover New Culture</h3>
                <h2>
                  Get exposed to both established and upcoming artists and build a unique and vibrant collection.
                </h2>
              </li>
              <li>
                <h3>Driven By Data</h3>
                <h2>
                  We use A.I. to analyze your interests and your assets in order to find the best matches.
                </h2>
              </li>
              <li>
                <h3>Secured By Stripe</h3>
                <h2>
                  We use Stripe as a payments provider to provide secure and seamless online transactions.
                </h2>
              </li>
            </ul>
          </Wrapper>
        </div>
        <div className="marketing-content" id="works">
          <Wrapper>
            <h1>How It <span>Works</span></h1>
            <div className="steps">
              <h4>1 _</h4>
              <h2>
                Choose from any of our major categories incl. such as Art, Automobiles Aircraft, Yachts &amp; Sailboats, Jewelry, Antiques &amp; Collectibles, and Real Estate. Artists also have the separate option of adding a short bio and a few photos of their works to our directory for art buyers.
              </h2>
            </div>
            <div className="steps">
              <h4>2 _</h4>
              <h2>
                Upload your asset(s) or creative work free of charge to our platform and list your item as either any single listing preference or a mix of for sale, for lease or for swap.
              </h2>
            </div>
            <div className="steps">
              <h4>3 _</h4>
              <h2>
                Field offers and invites to negotiate for your listing and/or allow buyers to purchase your asset immediately.
              </h2>
            </div>
            <div className="steps">
              <h4>4 _</h4>
              <h2>
                Negotiations and offers take place in a live chat between the two parties. Chat allows both users to include 3rd party individuals such as legal reps, brokers and others who can prove vital to help the transaction proceed.
              </h2>
            </div>
            <div className="steps">
              <h4>5 _</h4>
              <h2>
                Send a final invoice offer and recieve payment directly.
              </h2>
            </div>
          </Wrapper>
        </div>
        <div className="marketing-content" id="swaps">
          <Wrapper>
            <h1>Why <span>Swaps</span></h1>
            <div className="steps">
              <h2>
                There are many benefits of value for value transactions. In some challenging economic or market environments, demand for your asset makes the potential sale price lower than the expected return. Subsequently, just the sale of your current assets could trigger a potentially negative tax event which could be avoided or delayed via a swap. Potential trading partners exist on a worldwide scale and trades can consist of not only just assets, but also added cash within the transaction, increasing opportunities to find relevant matches.
              </h2>
            </div>
          </Wrapper>
        </div>
        <div className="marketing-content">
          <Wrapper>
            <h1>Join <span>Us</span></h1>
            <div className="steps-signup">
              <h2>
                We are currently still in the midst of development and bringing this idea to life. Sign up with your email below and get notified when we have launched.
              </h2>
              {
                emailSuccess ?
                  <h3>
                    You have successfully signed up for notifications. We will be in contact with you soon.
                  </h3> :
                  <form onSubmit={this._handleEmailSubmit}>
                    <input
                      type="text"
                      placeholder="Enter Your Email"
                      value={email}
                      className={hasError ? 'error-input' : ""}
                      onChange={(e) => this._handleChange(e)}
                    />
                    <input type="submit" />
                  </form>
              }
              <h4>{emailError}</h4>
            </div>
          </Wrapper>
        </div>
        <div className="marketing-content" id="join">
          <Wrapper>
            <div className="marketing-content-footer-links">
              <ul>
                <li><a href="#">&#169;&nbsp;2019&nbsp;BAZAARXCHANGES</a></li>
                <li><a href="#">CONTACT</a></li>
                <li><a href="#">LINKEDIN</a></li>
                <li><a href="#">INSTAGRAM</a></li>
              </ul>
            </div>
            <div className="marketing-content-footer">
              <h6>
                BAZAARXCHANGES IS A CONNECTION AND ECOMMERCE PLATFORM, WE ARE NOT A BROKER-DEALER, GALLERY, OR LEGAL GROUP. ASSET SWAPS ARE HIGHLY ILLIQUID AND RISKY AND ARE NOT SUITABLE FOR ALL. YOU SHOULD SPEAK WITH YOUR FINANCIAL ADVISOR, ACCOUNTANT,AND/OR ATTORNEY WHEN EVALUATING PRIVATE SWAP OFFERINGS. YOU SHOULD NOT RELY SOLEY ON STATEMENTS MADE BY COUNTERPARTIES BUT SHOULD CAREFULLY EVALUATE THE OFFERING MATERIALS IN ASSESSING ANY PRIVATE SWAP OPPORTUNITY OR PURCHASE, INCLUDING THE COMPLETE SET OF RISK FACTORS THAT ARE PROVIDED FOR YOUR CONSIDERATION.
              </h6>
              <h6>
                BCHAIN, LLC® AND THE BAZAARXCHANGES LOGO ARE SERVICE MARKS OWNED BY BCHAIN, LLC. ALL INFORMATION PROVIDED HEREIN HAS BEEN OBTAINED FROM SOURCES BELIEVED RELIABLE, BUT MAY BE SUBJECT TO ERRORS, OMISSIONS, CHANGE OF PRICE, PRIOR SALE, OR WIHDRAWAL WITHOUT NOTICE. BAZAARXCHANGES MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE AS TO ACCURACY OF ANY INFORMATION CONTAINED HEREIN. YOU SHOULD CONSULT YOUR ADVISOR FOR AN INDEPENDENT VERFICATION OF ANY PROPERTIES OR ASSETS.
              </h6>
            </div>
          </Wrapper>
        </div>
      </div>
    )
  }
}

export default Marketing;