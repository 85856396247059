import React, { Component } from "react";
import Wrapper from "../Layout/Wrapper";
import ScriptLogo from "../../images/bx-logo-script-white-rotated.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class ModernMenuSearchDrop extends Component {
  state = {
    assetType: "all"
  };

  redirectSearch = e => {
    this.props.history.push(`/search/assets`);
  };

  onSearch = async (e, query, assetType) => {
    await e.preventDefault();
    assetType = await "all";
    await this.props.handleSearch();
    await this.props.getSearchResults(query, assetType, this.redirectSearch);
  };

  captureQuery = e => {
    this.props.userUpdateQuery(e);
  };

  render() {
    let { assetType } = this.state;
    return (
      <div className={`dropdown__searchbar ${this.props.visible}`}>
        <Wrapper>
          <div className="dropdown__searchbar--outer-container">
            <div className="logo-box">
              <img src={ScriptLogo} alt="brand logo script" />
            </div>
            <div className="dropdown__searchbar--inner-container">
              <form
                onSubmit={e => this.onSearch(e, this.props.query, assetType)}
              >
                <input
                  type="text"
                  placeholder="Search..."
                  onChange={this.captureQuery}
                  value={this.props.query}
                />
              </form>
              <div className="searchbar-icon">
                <i className="fas fa-search fa-2x"></i>
              </div>
            </div>
            <button onClick={this.props.handleSearch}>x</button>
          </div>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    query: state.asset.query
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(ModernMenuSearchDrop));
