import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import * as actions from "../../redux/actions";
import loginBg from "../../images/login-bg.jpg";
import bchainLogo from "../../images/bchain-logo.png";
import FormInput from "../FormInput/FormInput";

class ResetPassword extends Component {
  state = {
    resetFormSubmitted: false
  };

  onResetPassword = value => {
    this.props.userResetPassword(value, () => {
      this.props.history.push("/login");
    });

    this.setState({
      resetFormSubmitted: true
    });
  };

  render() {
    const { resetFormSubmitted } = this.state;
    return (
      <div className="resetpassword">
        <img className="form__image" src={loginBg} alt="login-bg" />
        <div className="form">
          <img className="form__logo" src={bchainLogo} alt="logo" />
          {resetFormSubmitted ? (
            <div>
              <div className="resetpassword__submitted resetpassword__submitted--blue">
                Password successfully reset!
              </div>
              <div className="resetpassword__submitted">
                *You will be automatically redirected to the login page.*
              </div>
            </div>
          ) : (
            <form onSubmit={this.props.handleSubmit(this.onResetPassword)}>
              <div className="resetpassword__content">
                Please type in a new password and confirm.
              </div>
              <FormInput
                name="newPassword"
                label="New Password"
                type="password"
              />
              <FormInput
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
              />

              <button className="form__button">Reset Password</button>
            </form>
          )}
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = ["newPassword", "confirmPassword"];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });

  if (values["newPassword"] !== values["confirmPassword"]) {
    errors["confirmPassword"] = "Passwords dont match";
  }

  return errors;
}

export default reduxForm({ validate, form: "resetPassword" })(
  connect(
    null,
    actions
  )(withRouter(ResetPassword))
);
