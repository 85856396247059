import React, { Component } from "react";
import { Field } from "redux-form";

class RenderTextArea extends Component {
  state = {
    message: ""
  };

  onChange = e => {
    this.setState({
      message: e.target.value.slice(0, 400)
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ message: this.props.input.value });
    }
  }

  render() {
    const {
      input,
      label,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <textarea
          {...input}
          className={
            touched && error ? "form__input form__input--error" : "form__input"
          }
          placeholder={label}
          cols="50"
          rows="6"
          value={this.state.message}
          // value={this.state.message || this.props.input.value}
          onChange={this.onChange}
        />
        <div className="form__textarea__length">
          {this.state.message.length} / 400
        </div>
      </div>
    );
  }
}

const FormInput = values => {
  const { name, label } = values;

  return (
    <div className="form__group">
      <Field name={name} component={RenderTextArea} label={label} />
    </div>
  );
};

export default FormInput;
