import React, { Component } from "react";
import ChatroomSidebar from "./ChatroomSidebar";
import ChatroomMain from "./ChatroomMain";
import ChatroomAssetbar from "./ChatroomAssetbar";

import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import socketIO from "socket.io-client";
import axios from "axios";

import ImageGallery from "react-image-gallery";

//for mobile overlay
// const displayVisible = { display: "block" };
// const displayInvisible = { display: "none" };

const groupchatUrl = api => {
  return "http://localhost:4000/api/v1/groupchat/" + api;
};

class Chatroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileSidebarActive: false, //show sidebar (only shows in mobile view)
      isMobileAssetBarActive: false, //show asset bar (only shows in mobile view)
      activeChat: "", //currenly active chatroom
      activeChatInfo: {},
      lastMessage: {},
      typingStatus: {},
      slideshow: false, //show slideshow from asset bar
      showImageZoom: false //display image from clicking an image shared to chat
    };
    this.slideIndex = 0;
  }

  /**
   * initialize socket, join channels for system notification, and set socket to receive notifications
   */
  initializeSocket = async () => {
    const socket = socketIO(`http://localhost:4001`);
    socket.on("connect", () => {
      console.log("socket connected");

      //join channels to receive system notifications
      socket.emit("userID", this.props.user._id);
      socket.emit("joinGroups", this.props.user._id);

      //system notifications
      socket.on("inviteUpdated", () => {
        this.props.getPendingInvites(this.props.user._id);
      });
      socket.on("groupUpdated", () => {
        this.props.getGroups(this.props.user._id);
      });
      socket.on("toggleArchive", () => {
        this.props.getGroups(this.props.user._id);
      });
      socket.on("newGroup", async groupID => {
        socket.emit("joinOneGroup", groupID);
        await this.props.getGroups(this.props.user._id);
        await this.loadChat(groupID); 
      });

      //new message
      socket.on("message", data => {
        //display message if it goes to the active chatroom
        if (data.groupID === this.state.activeChat) {
          this.setState({ messages: [...this.state.messages, data] });
        }

        //display message as "last message" in sidebar
        var text;
        if (data.text) {
          text = data.text; //for regular text message
        } else {
          text = "[image]"; // for image sharing
        }
        this.setState({
          lastMessage: {
            senderID: {
              _id: data.user.id,
              firstName: data.user.name
            },
            groupID: data.groupID,
            text: text,
            createdAt: data.createdAt
          }
        });
      });

      //receive and display typing status
      socket.on("typing", async data => {
        var userID = data.userID;
        var username = data.username;
        var userStatus = this.state.typingStatus[userID];
        if (userStatus) {
          if (userStatus.username) {
            clearTimeout(userStatus.timeoutVar); //if user's previous typing status timer is still running, cancel the timer
          }
        }
        var newStatus = this.state.typingStatus;
        newStatus[userID] = {
          username: username,
          timeoutVar: window.setTimeout(() => {
            var x = this.state.typingStatus;
            x[userID] = {};
            this.setState(x);
          }, 2000)
        };

        this.setState({ typingStatus: newStatus }); //set new timer
      });

      this.socket = socket;
      //this.props.saveSocket(socket);
    });
  };

  /**
   * show (mobile view) side bar <=> hide (mobile view) side bar
   */
  toggleSidebar = () => {
    this.setState({
      isMobileSidebarActive: !this.state.isMobileSidebarActive
    });
  };

  /**
   * show (mobile view) asset bar <=> hide (mobile view) asset bar
   */
  toggleAssetBar = () => {
    this.setState({
      isMobileAssetBarActive: !this.state.isMobileAssetBarActive
    });
  };

  /**
   * load chat upon user clicking on a group in side bar
   */
  loadChat = async groupID => {
    this.setState({
      activeChat: groupID,
      messages: [],
      activeChatInfo: this.props.groups[groupID]
    });

    //subscribe to group chat channel
    this.socket.emit("joinChat", groupID);

    //memberInfo maps each member's user ID to user name
    let res = await axios.get(groupchatUrl("memberInfo/") + groupID);
    let memberInfo = res.data;

    //get chat history
    await axios
      .get(groupchatUrl("getMessages/" + this.state.activeChat))
      .then(res => {
        res.data.forEach(message => {
          const newMessage = {
            // format messages for display purpose
            text: message.text,
            image: message.image,
            user: {
              id: message.senderID,
              name: memberInfo[message.senderID]
            },
            createdAt: message.createdAt
          };
          this.setState({ messages: [...this.state.messages, newMessage] });
        });
      });
  };

  /**
   * sends a message - saves to backend, saves to state, and sends to receiver through socket
   *
   * @param {object} message Message object with type 0 for regular message, and type 1 for image sharing
   */
  async onSend(message) {
    var res = await axios.post(groupchatUrl("newMessage/"), {
      type: 0,
      groupID: message.groupID,
      senderID: message.userID,
      text: message.text
    });
    this.setState({
      lastMessage: res.data
    });

    var date = new Date();

    this.socket.emit("message", {
      text: message.text,
      user: {
        name: `${this.props.user.firstName} ${this.props.user.lastName}`,
        id: this.props.user._id
      },
      createdAt: date,
      groupID: message.groupID
    });
  }

  /**
   * displays asset image slideshow
   *
   * @param {string} links Source link of an image
   */
  displaySlideshow = links => {
    this.setState({ slideshow: true, slideshowLinks: links });
  };

  /**
   * hides asset image slideshow
   */
  hideSlideshow = () => {
    this.setState({ slideshow: false });
  };

  /**
   * sends an image sharing message (type 1) - saves to database, saves to state, sends to receiver through socket
   */
  shareImage = async () => {
    const link = this.state.slideshowLinks[this.slideIndex];

    await axios.post(groupchatUrl("newMessage/"), {
      type: 1,
      groupID: this.state.activeChat,
      senderID: this.props.user._id,
      image: link
    });

    this.setState({
      lastMessage: "[image]"
    });

    var date = new Date();

    this.socket.emit("message", {
      image: link,
      user: {
        name: `${this.props.user.firstName} ${this.props.user.lastName}`,
        id: this.props.user._id
      },
      createdAt: date,
      groupID: this.state.activeChat
    });
  };

  /**
   * zooms in a shared image upon user clicking on it
   */
  showImageZoom = link => {
    this.setState({ showImageZoom: true, imageZoomLink: link });
  };

  /**
   * displays a shared image full screen upon user clicking on it
   */
  renderImageZoom = () => {
    if (this.state.showImageZoom) {
      return (
        <div className="chatroom__container-modal">
          <span
            className="chatroom__container-modal-close"
            onClick={() => this.setState({ showImageZoom: false })}
          >
            &times;
          </span>

          <div className="chatroom_container-modal-content">
            <img src={this.state.imageZoomLink} alt="shared" />
          </div>
        </div>
      );
    }
  };

  /**
   * props for ImageGallery
   *
   * @param {object} item One of the image objects passed into ImageGallery
   */
  renderItem = item => {
    return (
      <div>
        <img
          className="carouselSlide__image"
          src={item.original}
          alt="slideshow"
        />
      </div>
    );
  };

  /**
   * props for ImageGallery
   *
   * @param {number} index Current slide index
   */
  onSlide = index => {
    this.slideIndex = index; //(reason for not using state: would cause bug in ImageGallery)
  };

  /**
   * renders asset image slideshow
   */
  renderSlideShow = () => {
    if (this.state.slideshow) {
      const images = this.state.slideshowLinks.map(link => ({
        original: link,
        thumbnail: link,
        originalClass: "featured-slide"
      }));
      return (
        <div className="chatroom__container-modal">
          <span
            className="chatroom__container-modal-share"
            onClick={() => this.shareImage()}
          >
            Share to group
          </span>
          <span
            className="chatroom__container-modal-close"
            onClick={() => this.hideSlideshow()}
          >
            &times;
          </span>

          <div className="chatroom__container-modal-content">
            <ImageGallery
              onSlide={index => this.onSlide(index)}
              items={images}
              renderItem={item => this.renderItem(item)}
              showPlayButton={false}
              showBullets={true}
              showFullscreenButton={false}
              showIndex={true}
            />
          </div>
        </div>
      );
    }
  };

  sendPrivateMessage = async messageObj => {
    this.setState({ lastMessage: messageObj });
  };

  async componentDidMount() {
    this.initializeSocket();
    await this.props.getPendingInvites(this.props.user._id);
    await this.props.getGroups(this.props.user._id);
  }

  render() {
    let { isMobileAssetBarActive, isMobileSidebarActive } = this.state;
    let toggleSidebarClass = isMobileSidebarActive ? "width_75" : "";
    let toggleAssetBarClass = isMobileAssetBarActive
      ? "mobile__show-assetbar"
      : "";
    let toggleExit = isMobileAssetBarActive ? "show__exit" : "";

    return (
      <div className="chatroom__container chatroom">
        {/* <div
          className="mobile__overlay"
          style={
            isMobileSidebarActive || isMobileAssetBarActive
              ? displayVisible
              : displayInvisible
          }
        /> */}
        {this.renderImageZoom()}
        {this.renderSlideShow()}

        <ChatroomSidebar
          toggleActive={this.toggleSidebar}
          sidebarClass={toggleSidebarClass}
          lastMessage={this.state.lastMessage}
          loadChat={this.loadChat}
          activeChat={this.state.activeChat}
          socket={this.socket}
        />
        <ChatroomMain
          toggleSidebar={this.toggleSidebar}
          toggleAssetBar={this.toggleAssetBar}
          messages={this.state.messages}
          onSend={msg => this.onSend(msg)}
          currentChat={this.state.activeChat}
          socket={this.socket}
          typingUser={this.state.typingUser}
          typingStatus={this.state.typingStatus}
          zoomIn={this.showImageZoom}
          sendPrivateMessage={this.sendPrivateMessage}
          loadChat={this.loadChat}
        />

        <ChatroomAssetbar
          toggleAssetBar={this.toggleAssetBar}
          toggleAssetBarClass={toggleAssetBarClass}
          activeChat={this.state.activeChat}
          showModal={this.displaySlideshow}
          toggleExit={toggleExit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites
  };
};

export default connect(
  mapStateToProps,
  actions
)(Chatroom);
