import React, { Component } from "react";
import Navbar from "../Navbar/Navbar";
import Wrapper from "../Layout/Wrapper";
import Layout from "../Layout/Layout";
import UploadSelection from "./UploadSelection";
import ArtSelection from "./ArtSelection";
import UploadImages from "./UploadImages";
import CropForm from "./CropForm";
import UploadForm from "./UploadForm";
import * as actions from "../../redux/actions";
import { connect } from "react-redux";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFrame: 0,
      images: [],
      category: "",
      isCollection: false,
      title: "",
      value: "",
      displayedValue: "",
      preferences: "",
      description: "",
      condition: "",
      longitude: null,
      latitude: null,
      formError: "",
      submitInProgress: false,
    };
  }

  _handleListingSubmit = async () => {
    const { addNewListing, userInfo } = this.props;
    let latitude = this.state.latitude;
    let longitude = this.state.longitude;
    let owner = userInfo;
    let values = {
      // images: this.state.images[0].tempUrl.slice(5),
      images: this.state.images,
      category: this.state.category.value,
      title: this.state.title,
      estimated_value: parseInt(this.state.value),
      description: this.state.description,
      owner,
      condition: this.state.condition.value,
      preferences: this.state.preferences.map((x) => x.value),
      location: { coordinates: [longitude, latitude] },
      priceCut: false,
      hidePrice: false,
      hideCondition: false,
    };
    // console.log('[response]', this.state.images[0].tempUrl.slice(5)[0] )
    addNewListing(values, this.props.history);
  };

  _handleGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {
          coords: { latitude, longitude },
        } = position;
        this.setState({ latitude, longitude });
      },
      () => this.setDefaultLatLng(),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 10000 }
    );
  };

  setDefaultLatLng = async () => {
    this.setState({
      latitude: 40.76203408358093,
      longitude: -73.97892881890559,
    });
  };

  _setCategory = async (category) => {
    console.log(category);
    this.setState({ category });
  };

  _setPreferences = (pref) => {
    console.log(pref);
    this.setState({
      preferences: pref,
    });
  };

  _setCondition = (condition) => {
    this.setState({ condition: condition });
  };

  _displayNextFrame = () => {
    this.setState({
      currentFrame: this.state.currentFrame + 1,
    });
  };

  _displayPreviousFrame = () => {
    this.setState({
      currentFrame: this.state.currentFrame - 1,
    });
  };

  _uploadSelection = (str) => {
    if (str === "single") {
      this.setState({
        isCollection: false,
      });
    } else {
      this.setState({
        isCollection: !this.state.artCollection,
      });
    }
  };

  _imagesSelectedHandler = (evt) => {
    const images = Array.from(evt.target.files);
    [...images].map(
      (file, index, arr) =>
        (images[index].tempUrl = URL.createObjectURL(arr[index]))
    );
    console.log("[images]", images);
    this.setState({
      images,
    });
  };

  _removePhoto = (item) => {
    let updatedImages = this.state.images.filter((x) => x !== item);
    this.setState({
      images: updatedImages,
    });
  };

  _handleUploadState = (name, evt) => {
    const {
      target: { value },
    } = evt;
    if (name === "value") {
      let formatNumber = parseInt(value.replace(/,/gi, "")).toLocaleString();
      this.setState({
        value: parseInt(value),
        displayedValue: formatNumber,
      });
    }

    this.setState({
      [name]: value,
    });
  };

  _displayFrame = () => {
    let {
      currentFrame,
      isCollection,
      images,
      category,
      description,
      displayedValue,
      value,
      condition,
      title,
      preferences,
      submitInProgress,
      formError,
    } = this.state;
    switch (currentFrame) {
      case 0:
        return (
          <ArtSelection
            history={this.props.history}
            nextFrame={this._displayNextFrame}
            handleSelection={this._uploadSelection}
            isCollection={isCollection}
          />
        );
        break;
      case 1:
        return (
          <UploadImages
            previewImages={images}
            nextFrame={this._displayNextFrame}
            prevFrame={this._displayPreviousFrame}
            removePhoto={this._removePhoto}
            selectImages={this._imagesSelectedHandler}
            category={category}
            setCategory={this._setCategory}
            formError={formError}
          />
        );
        break;
      // case 2:
      //   return (
      //     <CropForm
      //       nextFrame={this._displayNextFrame}
      //       prevFrame={this._displayPreviousFrame}
      //     />
      //   );
      //   break;
      case 2:
        return (
          <UploadForm
            nextFrame={this._displayNextFrame}
            prevFrame={this._displayPreviousFrame}
            title={title}
            category={category}
            description={description}
            estimatedValue={displayedValue}
            swapPreferences={preferences}
            condition={condition}
            isCollection={isCollection}
            setCondition={this._setCondition}
            handleUploadState={this._handleUploadState}
            inProgress={submitInProgress}
            formError={formError}
            setPreferences={this._setPreferences}
            submitListing={this._handleListingSubmit}
          />
        );
        break;
    }
  };

  componentDidMount() {
    this._handleGeolocation();
  }

  render() {
    return (

          <div className="uploads">{this._displayFrame()}</div>


    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, actions)(Upload);
