import React, { Component } from "react";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";

class PopCardUserAssets extends Component {
  state = {
    enabled: null
  };

  componentDidMount() {
    this.setState({
      enabled: this.props.asset._source.enabled
    });
  }

  onChange = (assetId, category) => {
    this.props.toggleVisibility(assetId, category);

    this.setState(prevState => ({
      enabled: !prevState.enabled
    }));
  };

  parsePrice = n => {
    let price = this.abbreviateNumber(n);
    if (price === "$1000.0K") {
      return "$1M";
    } else {
      return price;
    }
  };

  abbreviateNumber = n => {
    if (n < 1e3) return "$" + n;
    if (n >= 1e3 && n < 1e6) return "$" + (n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return "$" + (n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return "$" + (n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return "$" + (n / 1e12).toFixed(1) + "T";
  };

  render() {
    console.log("props:", this.props);
    console.log("states: ", this.state);
    const { postTitle, 
            price, 
            updatedAt, 
            createdAt,
            assetId, 
            category, 
            subCategory,
            address,
            images 
          } = this.props.asset._source;
    const {_id} = this.props.asset;
    const { enabled } = this.state;
    return (
      <div className="pop__card">
        <a href={`/assets/${category}/${_id}`}>
          <img src={images[0]} alt="product" />
        </a>
        <div className="asset-title-card">
          <a
            href={`/assets/${category}/${_id}`}
            className="post-title"
          >
            <p>{postTitle}</p>
          </a>
          <div className="timeLastUpdate">
            {updatedAt === createdAt ? "Created" : "Updated"}
            <br />
            <TimeAgo date={updatedAt} />
          </div>
        </div>
        <div className="pop__card-content">
          <div className="card-location">{address}</div>
          <div className="asset-category">
            <div className="name">
              {category.charAt(0).toUpperCase() + category.slice(1)} -{" "}
              {subCategory}
            </div>
          </div>
          <div className="pref"></div>
          <div className="expense">
            {this.parsePrice(price) || "Enquire"}
          </div>
          <div className="asset-price-card">
            <a href={`/user/accountsettings/userassets/${assetId}`}>
              <button className="value-edit">EDIT</button>
            </a>
            {/* <button className="value-enable">ENABLE</button> */}
            {/* <button className="value-disable">DISABLE</button> */}
            {enabled ? (
            <div
              className="value-disable"
              onClick={() => this.onChange(assetId, category)}
            >
              DISABLE
            </div>
            ) : (
            <div
              className="value-enable"
              onClick={() => this.onChange(assetId, category)}
            >
              ENABLE
            </div>
            )}
          </div>
        </div>
      </div> 
    );
  }




}
export default PopCardUserAssets;

















// const PopCardUserAssets = props => {
//   console.log(props);
//   return (
//     <div className="pop__card">
//       <img src={props.images[0]} alt="product" />
//       <div className="asset-title-card">
//         <a
//           href={`/assets/${props.urlCategory}/${props.id}`}
//           className="post-title"
//         >
//           <p>{props.postTitle}</p>
//         </a>
//         <div className="timeLastUpdate">
//           {props.updatedAt === props.createdAt ? "Created" : "Last Updated"}
//           <br />
//           <TimeAgo date={props.updatedAt} />
//         </div>
//       </div>
//       <div className="pop__card-content">
//         <div className="card-location">{props.address}</div>
//         <div className="asset-category">
//           <div className="name">
//             {props.category.charAt(0).toUpperCase() + props.category.slice(1)} -{" "}
//             {props.subCategory}
//           </div>
//         </div>
//         <div className="pref">{props.pref}</div>
//         <div className="expense">
//           Price: &nbsp;
//           {parsePrice(props.price || "Enquire")}
//         </div>
//         <div className="asset-price-card">
//           <a href={`/user/accountsettings/userassets/${props.assetId}`}>
//             <button className="value-edit">EDIT</button>
//           </a>
//           {/* <button className="value-enable">ENABLE</button> */}
//           <button className="value-disable">DISABLE</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PopCardUserAssets;
