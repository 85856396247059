import React, { Component } from 'react'
const allAssets = [
  {
    type: 'ART',
    image: 'https://live.staticflickr.com/7322/9733644511_950c3a6c27_b.jpg'
  },
  {
    type: 'AIRCRAFTS',
    image: 'https://live.staticflickr.com/866/40246376885_d31df7e87b_b.jpg'
  },
  {
    type: 'AUTOMOBILES',
    image: 'https://live.staticflickr.com/4631/39252255284_7354a3a24f_b.jpg'
  },
  {
    type: 'BOATS',
    image: 'https://live.staticflickr.com/8252/8607714051_c7da978982_b.jpg'
  },
  {
    type: 'ANTIQUES & MEMORABILIA',
    image: 'https://cdn.pixabay.com/photo/2019/06/14/16/53/antiques-4274006_960_720.jpg'
  },
  {
    type: 'REAL ESTATE',
    image: 'https://thenypost.files.wordpress.com/2019/02/95_greenest.jpg?quality=90&strip=all&w=618&h=410&crop=1'
  }];
  
class UploadSelection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { setCategory } = this.props;
    
    return (
      <div className="uploads__asset-selection">
        <div className="component-title">
          <h1>Select your asset to upload:</h1>
        </div>
        {/* Each asset selection item */}
        <div className="uploads__asset-selection-container">
          {
            allAssets.map((asset, index) => (
              <div 
                key={index} 
                onClick={() => setCategory(asset.type.toLowerCase())}
                className="uploads__asset-selection--item"
              >
                <img src={asset.image} alt="asset"/>
                <h3>{asset.type}</h3>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

 export default UploadSelection;