import React, { Component } from 'react';
import Description from './Description';
import Wrapper from "../Layout/Wrapper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import AbstractArt from "../../images/abstract_art.jpg";
import GoldPineapple from "../../images/jewelry-collectibles.jpg";
import Garden from "../../images/flora.jpg";

class HeroGrid extends Component {

  captureQuery = e => {
    this.props.userUpdateQuery(e);
  }

  redirectSearch = e => {
    this.props.history.push(`/search/assets`);
  }
  
  onSearch = (e, query, assetType) => {
    e.preventDefault();
    // const assetType = prompt("Enter the asset type you want to search!");
    this.props.getCategoryResults(query, assetType, this.redirectSearch);
    // console.log(query, assetType);
  }


  render() {
    return (
      <div className="hero-grid">
        <Wrapper>
          <div className="hero-layout">
            <div className="hero-main">
              <img src={AbstractArt} alt="painting"/>
              <Description
                width="80%"
                title="The Art of the Trade"
                sub="Explore all of the worldwide art treasures list for sale or swap,
                only on BazaarXchange. View everything from contemporary art to sculpture, photography, illustrations and more"
                isQuery={true}
                linkto="View All Art"
                clicked={(e) => this.onSearch(e, "All", "arts")}
              />
            </div>
            <div className="hero-sq-a">
              <img src={Garden} alt="house garden"/>
              <Description
                width="100%"
                title="Non-Commodity Physical Asset Swaps"
                sub="Whether it's exotic cars, fine art, real estate, private jets, or fine  jewelry, our online platform can facilitate the perfect trade"
                isQuery={false}
                linkto="How It Works"
                url="/how-to"
              />
            </div>
            <div className="hero-sq-b">
              <img src={GoldPineapple} alt="gold jewelry"/>
              <Description
                width="100%"
                title="Exquisite Taste Required"
                sub="We create connections between collectors, tastemakers, influencers and aficianados"
                isQuery={false}
                linkto="Register Now"
                url="/register"
              />
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    query: state.asset.query  
  };
};

export default connect(mapStateToProps, actions)(withRouter(HeroGrid));