import React from "react";

const abbreviateNumber = n => {
  if (n < 1e3) return "$" + n;
  if (n >= 1e3 && n < 1e6) return "$" + (n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return "$" + (n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return "$" + (n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return "$" + (n / 1e12).toFixed(1) + "T";
};

const parsePrice = n => {
  let price = abbreviateNumber(n);
  if (price === "$1000.0K") {
    return "$1M";
  } else {
    return price;
  }
};

const PopCard = props => {
  return (
    <div className="pop__card">
      <a href={`/assets/${props.urlCategory}/${props.id}`} className="pop__card-link-img">
        <img src={props.imgSrc} alt="product" />
      </a>
      <div className="asset-title-card">
        <a
          href={`/assets/${props.urlCategory}/${props.id}`}
          className="post-title"
        >
          <p>
            {props.postTitle}
            <br/>
            <span>{ props.urlCategory === 'art' ? " by " + props.byLine : '' }</span>
          </p>
        </a>
        <div className="favorites">
          <i className="far fa-heart fa-2x"></i>
        </div>
      </div>
      <div className="pop__card-content">
        <div className="card-location">{props.location}</div>
        <div className="asset-category">
          <div className="name">{props.category}</div>
        </div>
        <div className="pref">{props.pref}</div>
        <div className="asset-price-card">
          <a href={`/assets/${props.urlCategory}/${props.id}`}>
            <button className="value">
              {parsePrice(props.price) || "Enquire"}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PopCard;
