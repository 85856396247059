import React from "react";
import Avatar from "react-avatar";

import ColorHash from "color-hash";

const ChatAvatar = props => {
  var colorHash = new ColorHash();

  return (
    <Avatar
      size={props.size || 25}
      round={true}
      textSizeRatio={1}
      textMarginRatio={0.2}
      color={colorHash.hex(props.name)}
      name={props.name}
    />
  );
};

export default ChatAvatar;
