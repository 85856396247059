import React, { Component } from 'react'
import Logo from "../../images/bchain-full-logo-white.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import NavbarSearch from "./NavbarSearch";
import LowerNavbar from "./LowerNavbar";


class MainNavbar extends Component {

  renderUserLinks = (props) => {
    let userLinks;
    return userLinks = this.props.userInfo ? (
      <div className="main-navbar">
        <a href="/assets">
          <img src={Logo} alt="Logo"/>
        </a>
        <NavbarSearch/>
        <a href="/user/accountsettings/usersettings" className="nav_login" style={{marginRight: "20px", lineHeight: "20px"}}>My Account</a>
        <a href="/user/asset/addasset" className="nav_register">
          <button>Add Asset</button>
        </a>
      </div>
    ) : (
      <div className="main-navbar">
        <a href="/">
          <img src={Logo} alt="Logo"/>
        </a>
        <NavbarSearch/>
        <a href="/login" className="nav_login">Login</a>
        <a href="/register" className="nav_register">
          <button>Register</button>
        </a>
      </div>
    )
  }

  render() {
    return (
      <div className="wrapper__main-navbar">
        {this.renderUserLinks()}
        <LowerNavbar />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo,
  } 
}

export default connect(
mapStateToProps, 
actions
)(withRouter(MainNavbar));

