import {
    FETCH_ADD_FAVORITE,
    FETCH_ADD_FAVORITE_SUCCESS,
    FETCH_ADD_FAVORITE_FAILURE,
    FETCH_GET_FAVORITE,
    FETCH_GET_FAVORITE_SUCCESS,
    FETCH_GET_FAVORITE_FAILURE,
  } from '../actions/types';
  
  import axios from 'axios';
  
  const getUrl = (api) => {
    return 'https://api-dev.bazaarxchanges.com' + api;
    // return "http://localhost:4000" + api;
  };
  
  const addFavoriteUpdated = values => async dispatch => {
    console.log('[addFavoriteUpdated]', values)
    try {
      dispatch({
        type: FETCH_ADD_FAVORITE,
      });
      const {data} = await axios.post(getUrl('/api/v2/client/create/favorites'), values);
      console.log('[response]', data);
      if( values.userId === data.favorite_by){
        console.log('[response]', true)
      dispatch({type: FETCH_ADD_FAVORITE_SUCCESS, isFavorite: true});
      }
      else   dispatch({type: FETCH_ADD_FAVORITE_SUCCESS, isFavorite: false});
    } catch (error) {
      dispatch({type: FETCH_ADD_FAVORITE_FAILURE,   error: error});
    }
  };
  
  const getMyFavorites = userId => async dispatch => {
    try {
      dispatch({
        type: FETCH_GET_FAVORITE,
      });
      const {data} = await axios.get(
        getUrl(`/api/v2/listings/findMyListings/${userId}`),
      );
      const result = JSON.parse(data);
      dispatch({
        type: FETCH_GET_FAVORITE_SUCCESS,
        payload: result,
      });
      return result;
    } catch (err) {
      dispatch({
        type: FETCH_GET_FAVORITE_FAILURE,
        error: err,
      });
    }
  };
  
  export {addFavoriteUpdated, getMyFavorites};
  