import React from "react";

const AssetReviewFiles = ({ photos, editList, videos }) => {
  return (
    <div className="add-asset__review__info"> 
      <div className="add-asset__review__files">
        {Object.keys(photos || {}).length || Object.keys(videos || {}).length ? (
          <div className="add-asset__review__files__comment">
            *** Please review the files that you have added. Click the X on any
            files that you want to remove from the list. ***
          </div> 
        ) : null}

        <div className="add-asset__review__files__list">
          {Object.keys(photos).map(key => {
            return (
              <div className="add-asset__review__files__list__item" key={key}>
                <div
                  className="form__close add-asset__review__files__list__item__close"
                  onClick={() => editList(key, "photos")}
                >
                  &times;
                </div>
                <img
                  className="add-asset__review__files__list__item__img"
                  src={URL.createObjectURL(photos[key])}
                  alt={`key - ${key}`}
                />
              </div>
            );
          })}
        </div>

        {videos ? (
          <div className="add-asset__review__files__list">
            {Object.keys(videos).map(key => {
              return (
                <div className="add-asset__review__files__list__item" key={key}>
                  <div
                    className="form__close add-asset__review__files__list__item__close"
                    onClick={() => editList(key, "videos")}
                  > 
                    &times;
                  </div>
                  <video
                    className="add-asset__review__files__list__item__img"
                    controls
                  >
                    <source
                      src={URL.createObjectURL(videos[key])}
                      type="video/mp4"
                    />
                  </video>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AssetReviewFiles;
