import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AccountSettingsLayout from "../AccountSettingsLayout";
import AccountSettingsAssetRow from "../AccountSettingsAssetsItem/AccountSettingsAssetsRow";
import Wrapper from "../../Layout/Wrapper";
import PopCardUser from "../../Cards/PopCardUserAssets";
import ScrollToTopBtn from "../../Scroll/ScrollToTopButton"

class AccountSettingsAssets extends Component {
  async componentDidMount() {
    await this.props.getAssetsByUserId(this.props.userId, () => {});
  }

  toggleVisibility = (assetId, category) => {
    this.props.userToggleVisibility(assetId, category);
  };

  renderAssets = (assets = []) => {
    return assets.map(asset => {
      return (
        <AccountSettingsAssetRow
          key={asset._source.assetId}
          asset={asset}
          toggleVisibility={this.toggleVisibility}
        />
      );
    });
  };

  render() {
    return (
      <AccountSettingsLayout>
        <ScrollToTopBtn/>
        <Wrapper>
          <div className="items-grid">
            {this.props.assets.reverse().map((asset, id) => (
              <PopCardUser  key={asset._source.assetId}
              asset={asset}
              toggleVisibility={this.toggleVisibility} />
            ))}
          </div>
        </Wrapper>
      </AccountSettingsLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    assets: state.asset.userAssetsResults,
    userId: state.auth.userInfo._id
  };
};

export default connect(
  mapStateToProps,
  actions
)(AccountSettingsAssets);
