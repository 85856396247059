import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "axios";

import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  focused: {
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "black !important",
    },
  },
});

class StandardInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "hi! i'm " + this.props.user.name,
      groupname: this.props.title,
      checkedValues: [],
    };
  }

  /**
   * opens dialog
   */
  handleClick() {
    this.setState({ open: true });
  }

  /**
   * closes dialog, sets state to initial values
   */
  cancelSelect() {
    this.setState({
      open: false,
      message: "hi! i'm " + this.props.user.name,
      groupname: this.props.title,
      checkedValues: [],
    });
  }

  /**
   * sends new group info to backend to create a new group, closes dialog
   * currently no socket available
   */
  async handleOk() {
    let {
      assetTitle,
      assetLink,
      ownerID,
      assetImageUrl,
      assetPrice,
      assetCategory,
      assetID,
      assetUserId,
      fullItem,
    } = this.props;
    var groupAssets = this.state.checkedValues;

    console.log("groupAssets: ", groupAssets[0]);
    console.log("fullItem", fullItem);

    await axios.post("https://api-dev.bazaarxchanges.com/api/v2/pending/create", {
    // await axios.post("http://localhost:4000/api/v2/pending/create", {
      // senderID: this.props.user._id,
      // receiverID: this.props.receiverID,
      // ownerEmail: assetUserId.email,
      // message: this.state.message,
      // groupName: this.state.groupname,
      // bidAssets: userAssets,
      // offerAssets: groupAssets
      sent_by: this.props.user._id,
      recipient: fullItem.owner._id,
      bid_item: fullItem,
      offer_item: groupAssets[0],
      pending: true,
    });

    this.cancelSelect();
  }

  /**
   * handles the event when user clicks on an option -
   * if the option is already selected, uncheck the option, otherwise check the option
   *
   * @param {string} option asset ID
   */
  handleCheck(option) {
    this.setState((state) => ({
      checkedValues:
        state.checkedValues.filter((x) => x.id === option.id).length > 0
          ? state.checkedValues.filter((x) => x.id !== option.id)
          : [option],
    }));
  }

  renderMessageBox() {
    const { classes } = this.props;
    return (
      <Box p={1}>
        {/* <TextField
          label="Group name"
          autoFocus
          variant="outlined"
          id="groupname"
          fullWidth
          value={this.state.groupname}
          onChange={e => this.setState({ groupname: e.target.value })}
          InputLabelProps={{
            style: {
              fontSize: 15
            }
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline
            },
            style: { fontSize: 15 }
          }}
        /> */}
        <p style={{ padding: "10px" }} />
        <TextField
          label="Message"
          autoFocus
          multiline={true}
          variant="outlined"
          rows={6}
          id="invitation"
          fullWidth
          value={this.state.message}
          onChange={(e) => this.setState({ message: e.target.value })}
          InputLabelProps={{
            style: {
              fontSize: 20,
            },
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
            },
            style: { fontSize: 15 },
          }}
        />
      </Box>
    );
  }

  async componentDidMount() {
    // await this.props.getAssetsByUserId(this.props.user._id, () => {});
  }

  render() {
    // console.log(this.props);
    let alreadySent =
      this.props.user.pending_matches.filter(
        (x) => x._id === this.props.fullItem._id
      ).length > 0;
    if (!this.props.user) {
      return (
        <button className="send-message" disabled>
          Login to Chat
        </button>
      );
    } else if (
      this.props.fullItem.owner._id === this.props.user._id ||
      alreadySent
    ) {
      return (
        <button className="send-message" disabled>
          Start Negotiation
        </button>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <button
            className="send-message"
            onClick={() => this.handleClick()}
            style={{ width: "100%" }}
          >
            Start a Swap
          </button>

          <Dialog
            bodystyle={{ margin: 0, padding: 0 }}
            keepMounted
            open={this.state.open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            BackdropProps={{
              style: {
                opacity: 0.4,
              },
            }}
          >
            <DialogTitle id="confirmation-dialog-title">
              <span style={{ fontSize: 12, color: "#0c0c0c" }}>
                Send invite to chat
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <Typography
                component="div"
                style={{
                  padding: 8 * 3,
                  // height: 450,
                  width: 400,
                }}
              >
                {/* {this.renderMessageBox()} */}

                {this.props.user.items_posted.length === 0 ? (
                  <p style={{ fontSize: 15, padding: "15px" }}>
                    You currently don't have an available asset to swap.
                  </p>
                ) : (
                  <div style={{ padding: "5px" }}>
                    <p style={{ fontSize: 15, padding: "15px" }}>
                      Available assets:
                    </p>
                    <FormGroup>
                      {this.props.user.items_posted.map((asset, index) => {
                        // Object for each selected item in groupAssets state
                        const swapAssets = {
                          id: asset._id,
                          category: asset.category,
                          condition: asset.condition,
                          description: asset.description,
                          createdAt: asset.createdAt,
                          title: asset.title,
                          images: [asset.images[0]],
                          estimated_value: asset.estimated_value,
                          hideCondition: false,
                          hidePrice: false,
                          inCollection: asset.inCollection,
                          link: `/listings/${asset._id}`,
                          userId: asset.owner._id,
                          owner: asset.owner,
                          favorite_by_list: asset.favorite_by_list,
                          location: asset.location,
                          preferences: asset.preferences,
                          priceCut: 0,
                          promotedListing: false,
                          promotionRanking: 0,
                          updatedAt: asset.updatedAt,
                          visitors: asset.visitors,
                          zipcode: asset.zipcode,
                        };

                        return (
                          <FormControlLabel
                            key={index}
                            label={
                              <span style={{ fontSize: 15, color: "#0c0c0c" }}>
                                {asset.title}
                              </span>
                            }
                            control={
                              <Checkbox
                                onChange={() => this.handleCheck(swapAssets)}
                                checked={
                                  this.state.checkedValues.filter(
                                    (x) => x.id === asset._id
                                  ).length > 0
                                }
                              />
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.cancelSelect()}
                color="primary"
                style={{ fontSize: 12 }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.handleOk()}
                color="primary"
                style={{ fontSize: 12 }}
                disabled={this.state.checkedValues.length === 0}
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    user: state.auth.userInfo,
    assets: state.asset.userAssetsResults,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(StandardInvite));
