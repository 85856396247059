import React from "react";
import AIRCRAFT from "../../../images/aircraft-jet.jpg";
import BOAT from "../../../images/hero_yacht.jpg";
import ART from "../../../images/art_painting.jpg";
import COLLECTIBLE from "../../../images/jewelry-gemstones.jpg";
import PROPERTY from "../../../images/santa-monica-house.jpg";
import CAR from "../../../images/suvs.jpg";


const AssetFormChooseButton = ({ faName, iconName, onFormForward }) => {
  let bgImages = {
    BOAT,
    AIRCRAFT,
    ART,
    COLLECTIBLE,
    PROPERTY,
    CAR
  }

  let btnStyle = {
    backgroundPosition: "center center",
    backgroundImage: `url(${bgImages[iconName]})`,
    backgroundSize: "cover",
    overflow: "hidden"
  }

  return (
    <div
      style={btnStyle}
      className="add-asset__choose__choice"
      onClick={() => onFormForward({ assetType: iconName })}
    >
      <div className="add-asset__choose__choice__icon">
        {/* <i className={faName} /> */}
      </div>
      <div className="add-asset__choose__choice__name">{iconName}</div>
    </div>
  );
};

export default AssetFormChooseButton;
