import React from 'react';
import { XSquare, Check } from 'react-feather';

const ChatInviteModal = ({ modalAction, handleModal, imgLogo, handleChatInvite, receipientEmail, handleEmail, emailError, emailFrame }) => {
  
  if (emailFrame === 'default') {
    return (
      <div className={`chat-email-modal ${modalAction}`}>
        <XSquare size={35} onClick={handleModal} color={"black"}/>
        <div className="chat-email-modal-container">
          <img src={imgLogo} alt="logo"/>
          <br/>
          <div className="chat-email-modal-content">  
            <h1>Send Chat Invitation</h1>
            <h4>Send chat invite by email</h4>
            <br/>
            <form onSubmit={handleChatInvite}>
              <input 
                type="text" 
                placeholder="Email"
                onChange={(e) => handleEmail(e)}
                value={receipientEmail}  
              />
              <button type="submit">SEND</button>
            </form>
            {
              emailError ?
              <h6 className="email-error">
              &#8259;&nbsp;{emailError}
              </h6> : ""
            }
            <br/>
            <h5>
              * If email exists as a current BazaarXchanges user,  your invite will be sent as an internal invite instead of being sent as an email
            </h5>
          </div>
        </div>
      </div>
    );
  } else if (emailFrame === 'loading') {
    return (
      <div className={`chat-email-modal ${modalAction}`}>
        <XSquare size={35} onClick={handleModal} color={"black"}/>
        <div className="chat-email-modal-container">
          <img src={imgLogo} alt="logo"/>
          <br/>
          <div className="chat-email-modal-content-loader">  
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          <h4>Sending Invite...</h4>
          </div>
        </div>
      </div>
    )
  } else if (emailFrame === 'success') {
    return (
      <div className={`chat-email-modal ${modalAction}`}>
        <XSquare size={35} onClick={handleModal} color={"black"}/>
        <div className="chat-email-modal-container">
          <img src={imgLogo} alt="logo"/>
          <br/>
          <div className="chat-email-modal-content-success">  
          <Check size={50} color={"green"} className="check-success" />
          <h4>Invite Successfully Sent!</h4>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`chat-email-modal ${modalAction}`}>
        <Check color={"limegreen"} size={35} onClick={handleModal}/>
        <div className="chat-email-modal-container">
          <img src={imgLogo} alt="logo"/>
          <br/>
          <div className="chat-email-modal-content-loader">  
          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          <h4>Invite Successfully Sent!</h4>
          </div>
        </div>
      </div>
    )
  }
};

export default ChatInviteModal;