import React from "react";
import NumberInput from "../../FormInput/NumberInput";

const AssetFormPrice = () => {
  return (
    <div className="add-asset__template__box">
      <div className="add-asset__template__label">Value</div>
      <NumberInput label="Price" name="price" prefix="$" />
      <NumberInput label="Appraisal Value" name="appraisalValue" prefix="$" />
    </div>
  );
};

export default AssetFormPrice;
