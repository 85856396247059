import React from "react";
import { Link } from "react-router-dom";

const AccountSettingsNavLink = props => {
  return (
    <div className="accountsettings__nav__link">
      <Link
        className={
          props.location === props.path
            ? "accountsettings__nav__selected"
            : "accountsettings__nav__item"
        }  
        to={props.path}
      >
        {props.name}
      </Link>
    </div>
  );
};

export default AccountSettingsNavLink;
