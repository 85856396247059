import React, { Component } from "react";
import CapturePhoto from "../CapturePhoto/CapturePhoto";

class ValidateFormThree extends Component {
  render() {
    const { addPhotoFile, removePhotoFile, onFormForward } = this.props;
    return (
      <div className="form">
        <button
          className="form__close form__back validate__back"
          onClick={this.props.onFormBackward}
        >
          &#8617;
        </button>

        <div className="validate__capture__instructions">
          <div className="validate__capture__instructions__title">
            Please read before continuing:
          </div>
          <div>1. Make sure your webcam is turned on with good lighting.</div>
          <div>2. Look straight into the camera.</div>
          <div>3. Take a photo, feel free to retake if necessary.</div>
        </div>

        <CapturePhoto
          addPhotoFile={addPhotoFile}
          removePhotoFile={removePhotoFile}
          onFormForward={onFormForward}
        />
      </div>
    );
  }
}

export default ValidateFormThree;
