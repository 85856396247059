import React, { Component } from "react";
import axios from "axios";
import Wrapper from "../Layout/Wrapper";
import Layout from "../Layout/Layout";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import ChatkitInvites from "./ChatkitInvites";
import Chatrooms from "./ChatkitRooms";
import ChatkitPending from "./ChatkitPending";

class Chatkit extends Component {
  constructor() {
    super();
    this.state = {
      invitesPageActive: true,
      pendingPageActive: false,
      chatroomsPageActive: false,
      totalOfferPrice: null,
    };
  }

  handlePageActive = (e) => {
    let name = e.target.name;
    name === "invites"
      ? this.setState({
          invitesPageActive: true,
          chatroomsPageActive: false,
          pendingPageActive: false,
        })
      : name === "pending"
      ? this.setState({
          invitesPageActive: false,
          pendingPageActive: true,
          chatroomsPageActive: false,
        })
      : this.setState({
          invitesPageActive: false,
          pendingPageActive: false,
          chatroomsPageActive: true,
        });
  };

  render() {
    let { user } = this.props;
    let {
      invitesPageActive,
      chatroomsPageActive,
      pendingPageActive,
      totalOfferPrice,
    } = this.state;
    let activeInvitePageClass = invitesPageActive ? "active" : "";
    let activeChatroomsClass = chatroomsPageActive ? "active" : "";
    let activePendingClass = pendingPageActive ? "active" : "";
    let pendingInvites = user.pending_matches.filter(
      (x) => x.offer_item[0].owner._id === user._id
    );

    return (
      <Layout>
        <Wrapper>
          <div className="headings">
            {/* <button
              name="invites"
              onClick={e => this.handlePageActive(e)}
              className={`headings-invites ${activeInvitePageClass}`}
            >
              Your Invites
            </button>
            <button
              name="pending"
              onClick={e => this.handlePageActive(e)}
              className={`headings-chatrooms ${activePendingClass}`}
            >
              Pending Invites
            </button> */}
            <button
              name="chatrooms"
              onClick={(e) => this.handlePageActive(e)}
              className={`headings-chatrooms ${activeChatroomsClass}`}
            >
              Chatrooms
            </button>
          </div>
        </Wrapper>
        {/* {invitesPageActive ? (
          <ChatkitInvites />
        ) : pendingPageActive ? (
          <ChatkitPending pendingInvites={pendingInvites} />
        ) : ( */}
        <Chatrooms />
        {/* )} */}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    myAssets: state.asset.myAssets,
  };
};

export default connect(mapStateToProps, actions)(Chatkit);
