import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import TextArea from "../../FormInput/TextArea";

class AssetDescEdit extends Component {
  render() {
    return (
      <div className="accountsettings__account">
        <div className="accountsettings__title">Edit Description Of Asset</div>
        <TextArea
          label="Asset Description"
          name="fullDescription"
          type="textarea"
        />
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(AssetDescEdit);
