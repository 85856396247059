import React, { Component } from 'react';
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper"; 
import { compareValues } from "../../helperFunctions/helperFunctions";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import ScrollToTopBtn from "../Scroll/ScrollToTopButton";

class ArtistDirectory extends Component {
  state = {
    artists: []
  }

  componentDidMount() {
    this.props.getArtistDirectory();
  }

  

  render() {
    let { artists } = this.props;
    let sortedArtists = artists.sort(compareValues('name'));

    return (
      <Layout>
        <ScrollToTopBtn/>
        <Wrapper>
          <div className="artist-directory-container">
            <div className="artist-directory-title">
              <h1>Creativity Takes Courage</h1>
              <h5>
                We're proud to feature a vast array of works showcased by the artists below and by the people who love their work.
              </h5>
            </div>
            <div className="artist-cards-container">
              {
                sortedArtists.map((artist, index) => (
                  <div className="artist-card" key={index}>
                    <a href={`/directory/artists/${artist.name}`}>
                      <img src={artist.mainImage} alt=""/>
                      <h4>{artist.name}</h4>
                    </a>
                  </div>
                ))
              }
            </div>
          </div>
        </Wrapper>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    // userInfo: state.auth.userInfo,
    // asset: state.asset.assetInfo,
    artists: state.asset.artists
  };
};

export default connect(
  mapStateToProps,
  actions
)(ArtistDirectory);