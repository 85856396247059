import React, { Component } from "react";
import Layout from '../Layout/Layout';
import Wrapper from '../Layout/Wrapper';
import axios from "axios";

const createTemplateObject = values => {
  let {
    email,
    subject,
    content,
    firstname,
    lastname
  } = values;
  return {
    
    "Messages": [
      {
        "From": {
          "Email": "support@bazaarxchanges.com",
          "Name": firstname
        },
        "To": [
          {
            "Email": "sam.zhang@bchainco.com"
          }
        ],
        "Subject": `${subject}-${email}-${firstname}`,
        "TextPart": "Customer Support",
        "HTMLPart": `${content}`
      }
    ]
  };
}

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      subject: '',
      content: '',
      loading: false
    }
  }

  validate = values => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
    && values.firstname !== '' 
    && values.lastname !== '' 
    && values.subject !== '' 
    && values.content !== '') {
      return true;
    } else {
      return false;
    }
  }

  handleChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
    console.log(this.state[e.target.name]);
  }

  handleSubmit = async e => {
    e.preventDefault();
    await this.setState({
      loading: true
    });
    let values = await createTemplateObject(this.state);

    if (this.validate(this.state)) {
      try {
        console.log("sending email");
        await axios.post("https://api-dev.bazaarxchanges.com/api/v1/user/email", values)
          .then(res => {
            this.setState({
              firstname: '',
              lastname: '',
              email: '',
              subject: '',
              content: '',
              loading: false
            })
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      window.alert("One or more fields you have entered are invalid");
    }
    
  }

  render() {
    const { loading } = this.state;
    return (
      <Layout>
        <Wrapper>
          <div className='contact-form-title'>
            <h1>Contact Us</h1>
          </div>
        <div className='contact-form-container'>
          <form onSubmit={this.handleSubmit}> 
            <div className="contact-form-input">
              <label>Email</label>
              <input 
                name="email"
                type="text" 
                placeholder="Enter your email here" 
                onChange={ (e) => this.handleChange(e) }
              />
            </div>
            <div className="contact-form-input">
              <label>First Name</label>
              <input 
                name="firstname"
                type="text" 
                placeholder="First Name" 
                onChange={ (e) => this.handleChange(e) }
              />
            </div>
            <div className="contact-form-input">
              <label>Last Name</label>
              <input 
                name="lastname"
                type="text" 
                placeholder="Last Name" 
                onChange={ (e) => this.handleChange(e) }
              />
            </div>
            <div className="contact-form-input">
              <label>Subject</label>
              <input 
                name="subject"
                type="text" 
                placeholder="Subject" 
                onChange={ (e) => this.handleChange(e) }
              />
            </div>
            <div className="contact-form-input">
              <label>Content</label>
              <textarea 
                name="content"
                type="text" 
                placeholder="Content" 
                onChange={ (e) => this.handleChange(e) }
              />
            </div>
            <button type="Submit" className="contact-btn">
              <div>
                Send
              </div>
              {loading && (
                <div className="accountsettings__assets__edit__form__btn__spinner">
                  <i className="fas fa-spinner fa-spin" />
                </div>
              )}
            </button>
          </form>
          {this.state.error}
        </div>
        </Wrapper>
      </Layout>
    );
  }
}

export default ContactUs;