import React, { Component } from "react";
import Wrapper from "../Layout/Wrapper";
import UpcomingEvent from "./UpcomingEvent";
import Underline from "./Underline";
import Vehicles from "../../images/g-wagon-white.jpg";
import Football from "../../images/football.jpg";
import BeachHouse from "../../images/beach-house.jpg";
import Gulfstream from "../../images/pj-interior.jpg";
import Yachts from "../../images/yachts.jpg";
import Monaco from "../../images/monaco.jpg";
import HeroGrid from "./HeroGrid";
import Layout from "../Layout/Layout";
import ArtBaselMiami from "../../images/art_painting.jpg";
import ScriptLogo from "../../images/bx-logo-script-red-rotated.png";
import ScrollToTopButton from "../Scroll/ScrollToTopButton";
import {
  PopCardSortByDateDesc,
  PopCardSortByPriceDesc
} from "../Cards/PopCardSort";
import GDPR from "../gdpr/GDPR";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";

class Home extends Component {
  captureQuery = e => {
    this.props.userUpdateQuery(e);
  };

  redirectSearch = e => {
    this.props.history.push(`/search/assets`);
  };

  searchCategory = async (e, query, assetType) => {
    await e.preventDefault();
    await this.props.getCategoryResults(query, assetType, this.redirectSearch);
  };

  componentDidMount() {
    this.props.getElasticResults();
  }

  render() {
    console.log(this.props);
    return (
      <Layout>
        <GDPR />
        <ScrollToTopButton />
        <HeroGrid />
        <Wrapper>
          <div className="home-highlight">
            <div className="home-highlight__content">
              <h2>Artist Highlight</h2>
              <div className="home-highlight__content--title">
                <div className="discovery">DISCOVERIES</div>
                <h1>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde consequuntur animi odit officia at?
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt distinctio consequatur necessitatibus rem voluptatem aspernatur, rerum hic animi? Eius, sed!
                </p>
                <a href="/directory/artists/banksy/highlight">
                  <button>READ NOW</button>
                </a>
              </div>
            </div>
            <img src="https://cdn.pixabay.com/photo/2018/12/06/12/17/artist-3859731_960_720.jpg" alt="artist" />
          </div>
          <div className="all-artist-highlight">
            <img src="https://p2.piqsels.com/preview/239/816/19/art-poster-typography-color.jpg" alt="artist" />
            <div className="all-artist-highlight-content">
              <h1>Discover Artists</h1>
              <p>
                View our directory of astounding artists both past and present. Ranging from modern fine art to contemporary street art, discover someone new or feel nostalgic for the classics.
              </p>
              <a href="/directory/artists">
                <button>VIEW DIRECTORY</button>
              </a>
            </div>
          </div>
          <div className="art-grid">
            <div className="items-title">
              <h4>DISCOVER ART</h4>
              <p>Browse through a bevy of unique pieces created by independent artists from all over the world and other treasures held by esteemed collectors.</p>
            </div>
            <div
              className="art-grid__item"
              onClick={e => this.searchCategory(e, "Paintings", "arts")}
            >
              <img src="https://cdn.swarez.co.uk/wp-content/uploads/2015/05/Large-red-splash-and-drip-style-painting.jpg" alt="" />
              <div className="art-grid__item--overlay"></div>
              <h5>Paintings</h5>
            </div>
            <div
              className="art-grid__item"
              onClick={e => this.searchCategory(e, "Sculpture", "arts")}
            >
              <img src="https://media.nga.gov/iiif/public/objects/4/6/7/1/6/46716-primary-0-nativeres.ptif/full/!740,560/0/default.jpg" alt="" />
              <div className="art-grid__item--overlay"></div>
              <h5>Sculpture</h5>
            </div>
            <div
              className="art-grid__item"
              onClick={e => this.searchCategory(e, "Photography", "arts")}
            >
              <img src="http://moodysoody.com/wp-content/uploads/2017/02/100-Best-Examples-of-Beautiful-Fine-Art-Photography-1.jpe" alt="" />
              <div className="art-grid__item--overlay"></div>
              <h5>Photography</h5>
            </div>
            <div
              className="art-grid__item"
              onClick={e => this.searchCategory(e, "Illustrations", "arts")}
            >
              <img src="http://blog.iso50.com/wp-content/uploads/2010/05/byenspuls-450x489.jpg" alt="" />
              <div className="art-grid__item--overlay"></div>
              <h5>Illustrations</h5>
            </div>
            <div
              className="art-grid__item"
              onClick={e => this.searchCategory(e, "Ceramics", "arts")}
            >
              <img src="http://mymodernmet.com/wp/wp-content/uploads/2017/05/contemporary-ceramic-artists-28.jpg" alt="" />
              <div className="art-grid__item--overlay"></div>
              <h5>Ceramics</h5>
            </div>
            <div
              className="art-grid__all"
              onClick={e => this.searchCategory(e, "All", "arts")}
            >
              <button>DISCOVER ALL ART</button>
            </div>
          </div>
        </Wrapper>
        <div className="upcoming-events">
          <Wrapper>
            <UpcomingEvent />
          </Wrapper>
        </div>
        <div className="asset-items">
          <Wrapper>
            <div className="asset-items__popular">
              <div className="items-title">
                <h4>RECENTLY LISTED</h4>
                <p>Take a peek at a few of our recently posted listings.</p>
              </div>
              <div className="items-grid-small">
                <PopCardSortByDateDesc results={this.props.elasticResults} />
              </div>
            </div>
          </Wrapper>
        </div>
        <Wrapper>
          <div className="automobiles">
            <div className="items-title">
              <h4>DISCOVER AUTOMOBILES</h4>
              <p>Browse through our automobile listings. </p>
            </div>
            <div className="automobiles__image">
              <img src="https://newsroom.bugatti/var/assets/press-releases/2019/190818_le-domaine-bugatti/1024xauto-q80/01_bugatti_pebble-beach_2019.jpg" alt="automobiles" />
              <div className="automobiles__image--overlay"></div>
              <div className="automobiles__content">
                <button onClick={e => this.searchCategory(e, "Sports Cars", "autos")}>
                  SPORTS &amp; EXOTICS&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="automobiles__image">
              <img src="http://www.luxury-things.com/wp-content/uploads/2014/08/mercedes-s-class-sedan-luxury-white.jpg" alt="automobiles" />
              <div className="automobiles__image--overlay"></div>
              <div className="automobiles__content">
                <button onClick={e => this.searchCategory(e, "Sedans", "autos")}>
                  LUXURY SEDANS&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="automobiles__image">
              <img src={Vehicles} alt="automobiles" />
              <div className="automobiles__image--overlay"></div>
              <div className="automobiles__content">
                <button onClick={e => this.searchCategory(e, "Trucks & SUV's", "autos")}>
                  TRUCKS &amp; SUV'S&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="automobiles__all">
              <button onClick={e => this.searchCategory(e, "All", "autos")}>DISCOVER ALL AUTO</button>
            </div>
          </div>
        </Wrapper>
        <Wrapper>
          <div className="collectibles">
            <div className="items-title">
              <h4>DISCOVER COLLECTIBLES</h4>
              <p>Browse through our listings of exclusive artifacts, memorabilia, exquisite gems and more. </p>
            </div>
            <div className="collectibles-grid-item">
              <img src="https://torange.biz/photo/52/IMAGE/watch-watches-time-52987.jpg" alt="" />
              <div className="collectibles-grid-item--overlay"></div>
              <div className="collectibles-grid-item__content">
                <button onClick={e => this.searchCategory(e, "Watches", "collectibles")}>
                  WATCHES&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="collectibles-grid-item">
              <img src="https://storage.needpix.com/rsynced_images/jewelry-2186580_1280.jpg" alt="" />
              <div className="collectibles-grid-item--overlay"></div>
              <div className="collectibles-grid-item__content">
                <button onClick={e => this.searchCategory(e, "Gemstones", "collectibles")}>
                  JEWELRY&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="collectibles-grid-item">
              <img src="https://upload.wikimedia.org/wikipedia/commons/0/00/Antiques_being_sold_on_Colaba_Causeway.jpg" alt="" />
              <div className="collectibles-grid-item--overlay"></div>
              <div className="collectibles-grid-item__content">
                <button onClick={e => this.searchCategory(e, "Antiques", "collectibles")}>
                  ANTIQUES&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="collectibles-grid-item">
              <img src="https://live.staticflickr.com/7244/6925176670_0a5209b7f0_b.jpg" alt="" />
              <div className="collectibles-grid-item--overlay"></div>
              <div className="collectibles-grid-item__content">
                <button onClick={e => this.searchCategory(e, "Memorabilia", "collectibles")}>
                  MEMORABILIA&nbsp;&rarr;
                </button>
              </div>
            </div>
            <div className="collectibles__all">
              <button onClick={e => this.searchCategory(e, "All", "collectibles")}>DISCOVER ALL COLLECTIBLES</button>
            </div>
          </div>
        </Wrapper>
        <div className="xplore">
          <div className="items-title">
            <h4>DISCOVER REAL ESTATE</h4>
            <p>Browse through our listings of exclusive commercial and residential properties. </p>
          </div>
          <Wrapper>
            <div className="explorer">
              <div className="explorer-content">
                <div className="explorer-content-b">
                  <img src={BeachHouse} alt="real estate" />
                  <div className="automobiles__image--overlay"></div>
                  <div className="automobiles__content">
                    <button
                      onClick={e =>
                        this.searchCategory(e, "Residential", "realestates")
                      }
                    >
                      RESEDENTIAL PROPERTIES&nbsp;&rarr;
                    </button>
                  </div>
                </div>
                <div className="explorer-content-a">
                  <img src="https://live.staticflickr.com/5590/14904719671_9730e330e4_b.jpg" alt="Autographed Football" />
                  <div className="automobiles__image--overlay"></div>
                  <div className="automobiles__content">
                    <button
                      onClick={e =>
                        this.searchCategory(e, "Commercial", "realestates")
                      }
                    >
                      COMMERCIAL PROPERTIES&nbsp;&rarr;
                    </button>
                  </div>
                </div>
              </div>
              <div className="explorer__all">
                <button onClick={e => this.searchCategory(e, "All", "realestates")}>DISCOVER ALL PROPERTIES</button>
              </div>
            </div>
          </Wrapper>
        </div>
        {/* <div className="asset-items">
          <Wrapper>
            <div className="asset-items__popular">
              <div className="items-title">
                Most Popular
              </div>
              <div className="items-grid">
                <PopCardSortByPriceDesc results={this.props.elasticResults} />
              </div>
            </div>
          </Wrapper>
        </div> */}
        <div className="xplore">
          <Wrapper>
            <div className="explorer">
              <div className="items-title">
                <h4>DISCOVER AIR &amp; SEA</h4>
                <p>Browse through a number of different aircraft and boat listings, taking your travels through the skies and across the seas.</p>
              </div>
              <div className="explorer-content">
                <div className="explorer-content-b">
                  <img src={Yachts} alt="boats" />
                  <div className="automobiles__image--overlay-red"></div>
                  <div className="automobiles__content">
                    <p className="automobiles__content--title">
                      Explore yachts &amp; sailboats
                    </p>
                    <button
                      onClick={e => this.searchCategory(e, "All", "boats")}
                    >
                      DISCOVER ALL BOATS&nbsp;&rarr;
                    </button>
                  </div>
                </div>
                <div className="explorer-content-a">
                  <img src={Gulfstream} alt="private jet" />
                  <div className="automobiles__image--overlay-red"></div>
                  <div className="automobiles__content">
                    <p className="automobiles__content--title">
                      Explore helicopters &amp; aircraft
                    </p>
                    <button
                      onClick={e => this.searchCategory(e, "All", "aircrafts")}
                    >
                      DISCOVER ALL AIRCRAFT&nbsp;&rarr;
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
        <div className="upcoming-events">
          <Wrapper>
            <UpcomingEvent />
          </Wrapper>
        </div>
        <div className="about-us">
          <Wrapper>
            <div className="items-title">
              <h4>ABOUT BAZAARXCHANGES</h4>
            </div>
            <div className="about-us-content">
              <p>
                BazaarXchanges stands alone in the world of online commerce. As our name implies, we pride ourselves on facilitating the trade of luxurious or bazaar high-end assets. Our users mainly consist of high net worth individuals and successful companies; however, a bevy of eclectic artists are also featured on BzazaarXchanges. The assets we showcase consist of automobiles, art, real estate, and even aircrafts.
              </p>
              <br />
              <p>
                At times, online shoppers lose the power to negotiate the price and payment terms that may exist in local stores. BazaarXchanges was designed as the solution to these unpleasant issues. BazaarXchanges connects sellers and buyers by offering a line of communication that consists of selling, leasing, or swapping.
              </p>
              <br />
              <p>
                Firstly, the sign up and logging in process takes but a moment. Once signed in you enter a coveted network of users exchanging vast assets. We exist for you. Our chat room is in real time; therefore, your future asset transaction can coincide with any questions or concerns you may have about the product. Easily send and receive invites to other assets holders and get started Xchanging bazaar assets!
              </p>
              <br />
            </div>
            <div className="bottom-banner">
              <img src={ScriptLogo} alt="banner logo" />
            </div>
          </Wrapper>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    query: state.asset.query,
    elasticResults: state.asset.elasticResults
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(Home));
