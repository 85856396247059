import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AssetVideoInput from "../../AddAsset/AssetUpload/AssetVideoInput";

class AssetVideoEdit extends Component {
  clearList = () => {
    console.log("hi");
  };

  render() {
    const videos = this.props.videos || [];
    return (
      <div className="accountsettings__account">
        <div className="accountsettings__title">Upload Your Videos</div>
        <div className="accountsettings__assets__edit__files__comment">
          ***Please note if you upload a video, the previous videos will be
          lost.***
        </div>
        <div className="add-asset__review__files__list">
          {Object.keys(videos).map(key => {
            return (
              <div className="add-asset__review__files__list__item" key={key}>
                <div
                  className="form__close add-asset__review__files__list__item__close"
                  // onClick={() => editList(key, "videos")}
                >
                  &times;
                </div>
                <video
                  className="add-asset__review__files__list__item__img"
                  controls
                >
                  <source
                    src={URL.createObjectURL(videos[key])}
                    type="video/mp4"
                  />
                </video>
              </div>
            );
          })}
        </div>

        <div className="add-asset__file">
          <AssetVideoInput
            name="newVideos"
            label="Upload Videos"
            clearList={this.clearList}
          />
        </div>
      </div>
    );
  }
}


export default (
  connect(
    null,
    actions
  )(AssetVideoEdit)
);

