import React, { Component } from 'react';
import Wrapper from '../Layout/Wrapper';
import { Paperclip } from 'react-feather';

const SendMessageForm = props => {
  let { text, handleInput, submitText, handleDialog } = props;

  return (
    <div className="messaging">
      <form onSubmit={e => submitText(e)}>
        <input
          className="text-input"
          type="text"
          name="text"
          onChange={e => handleInput(e)}
          placeholder="Type here..."
          value={text}
        />
        {/* <input className="chat-submission" type="submit"/> */}
        <Paperclip size={22} className="attachment-icon" onClick={handleDialog}/>
      </form>
    </div>
  );
}

export default SendMessageForm;