import React, { Component } from 'react';
import { GOOGLE_MAP_API_KEY } from "../../utility/google_maps";

class GoogleMaps extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: this.props.lat, lng: this.props.lng },
      zoom: this.props.zoom
    });
    const circle = new window.google.maps.Circle({
      map: map,
      radius: 750,
      fillColor: '#AA0000',
      fillOpacity: 0.2,
      strokeOpacity: 0,
      center: new window.google.maps.LatLng(this.props.lat, this.props.lng)
    });
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }} id="map"/>
    )
  };
}

export default GoogleMaps;
