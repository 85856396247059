import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Layout from '../Layout/Layout';
import Wrapper from '../Layout/Wrapper';
import MyCartCheckout from "./MyCartCheckout";
import CartFeatures from "./CartFeatures";
import CartSummary from "./CartSummary";

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoppingCart: []
    }
  }

  removeCartItem = async (item) => {
    axios.post('http://localhost:4000/api/v1/cart/remove', {
      userId: this.props.userInfo._id,
      item
    })
    .then(async () => {
      const { shoppingCart } = this.state;
      let cartItems = [...shoppingCart].filter(x => x._id !== item._id);
      
      await cartItems.filter(x => x._id !== item._id)
      await this.setState({ shoppingCart: cartItems });
    })
    .catch(err => console.log(err));
  }

  componentDidMount() {
    this.setState({
      shoppingCart: this.props.userInfo.shoppingCart 
    })
  }

  render() {
    return (
      <Layout>
        <Wrapper>
          <div className="cart-container">
            <div className="cart-container--header">
              <h1>Your Shopping Cart</h1>
              <div className="cart-underline"></div>
            </div>
            <CartFeatures />
            <div className="payments-container">
              <MyCartCheckout />
              <CartSummary 
                removeItem={this.removeCartItem}
                currentCart={this.state.shoppingCart}   
              />
            </div>
          </div>
        </Wrapper>
      </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo
  };
};

export default connect(
  mapStateToProps,
  actions
)(ShoppingCart);