import React, { Component } from "react";
import Layout from "../Layout/Layout";
import Wrapper from "../Layout/Wrapper";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import axios from "axios";
// import TimeAgo from "react-timeago";
import { chatkitConnect } from "./ChatManager";
import PubNub, { generateUUID } from "pubnub";
import ChatScreen from "./ChatScreen";
import ChatBriefcase from "./ChatBriefcase";
import ClickForChat from "./ClickForChat";
import { Link } from "react-router-dom";
import { Search } from "react-feather";
import moment from "moment";
import dateFormat from "dateformat";

import ChatRoomMessages from "./ChatRoomMessages";
import { FaFacebookMessenger } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { Button } from "react-bootstrap";
import EmptyChat from "../../images/emptychat1.png";
import EmptyChat2 from "../../images/emptychat2.png";

const brandColor = "#DF233A";

class ChatkitRooms extends Component {
  state = {
    currentUserRooms: [],
    filteredRooms: [],
    showRoom: false,
    roomId: "",
    roomIndex: 0,
    query: "",
    isUserOnline: false,
    assetModalIsOpen: false,
    messages: null,
    pubnub: null,
    chatData: null,
    channel: null,
    rating: null,
    ratingVisible: false,
    messagesLoaded: false,
    isSmallScreen: false,
    isLeftPanelShown: false,
  };

  async componentDidMount() {
    const { _id } = this.props.user;
    this.props.findRooms(_id);
    const pubnub = new PubNub({
      publishKey: "pub-c-9d3669c9-65a0-4632-9db7-5cb4202c53f6",
      subscribeKey: "sub-c-7ded1c68-997b-11ea-8e71-f2b83ac9263d",
      uuid: generateUUID,
    });
    this.setState(
      {
        pubnub,
      },
      () => {
        // this.handleGetMessage();
      }
    );

    this.updateWindowSize();
    window.addEventListener("resize", this.updateWindowSize);
    // const listener = {
    //   message: (envelope) => {
    //     console.log("[props]", envelope);
    //     this.updateMessageList(envelope);
    //   },
    // };

    // pubnub && pubnub.addListener(listener);
    // pubnub.subscribe({
    //   channels: [this.state.channel],
    // });
  }

  updateWindowSize = () => {
    const { innerWidth } = window;
    if (innerWidth < 1000) {
      this.setState({ isSmallScreen: true });
    } else {
      this.setState({ isSmallScreen: false });
    }
  };

  // componentDidUpdate(prevProps) {
  //   if(prevProps.chatRooms !== this.props.chatRooms){
  //     const listener = {
  //       message: (envelope) => {
  //         console.log("[props]", envelope);
  //         this.updateMessageList(envelope);
  //       },
  //     };

  //     pubnub && pubnub.addListener(listener);
  //     pubnub.subscribe({
  //       channels: [this.state.channel],
  //     });
  //   }
  // }

  updateMessageList = (message) => {
    const { chatData, messages } = this.state;
    let newChatData = [...messages];
    // console.log("[message]", messages);
    // console.log("[message]", message);
    console.log("[message]", newChatData);

    if (message) {
      console.log("[message]", true);
      newChatData.push(message);
      this.setState({ messages: newChatData });
    } else {
      console.log("[message]", false);
      newChatData = [];
      newChatData.push(message);
      this.setState({ messages: newChatData });
    }
  };

  handleUserPresence = (status) => {
    if (status === "online") {
      this.setState({ isUserOnline: !this.state.isUserOnline });
    } else {
      this.setState({ isUserOnline: false });
    }
  };

  renderRoom = async (id, index) => {
    await this.setState({ showRoom: false, roomId: id, roomIndex: index });
    await this.setState({ showRoom: true, roomId: id, roomIndex: index });
    // await this.props.getGroupsInfo(this.state.roomId);
  };

  handleInputChange = async (e) => {
    let { currentUserRooms } = this.state;
    let query = e.target.value;
    let findUser = [...currentUserRooms];
    let results = [];

    await this.setState({ query });

    if (query === "") {
      await this.getAllChatrooms();
    } else {
      // console.log(findUser);
      await findUser.map((elem) => {
        console.log(elem);
        if (
          elem.customData &&
          elem.customData.client.clientName
            .toLowerCase()
            .includes(query.toLowerCase())
        )
          results.push(elem);
      });

      await this.setState({
        filteredRooms: results,
      });
    }
    await console.log(this.state);
  };

  handleAddAssetModal = () => {
    console.log("handling asset modal...");
    this.setState({
      assetModalIsOpen: !this.state.assetModalIsOpen,
    });
  };

  handleMessageRoom = ({ messages, chat }) => {
    const { _id } = this.props.user;
    this.props.findRooms(_id);
    {
      console.log("[chat]", chat);
    }
    this.setState({ messages: null, chat, channel: chat.channel }, () => {
      setTimeout(
        () => this.setState({ messages, chat, channel: chat.channel }),
        0.00001
      );
      console.log("[messagess]", messages);
    });
  };

  renderMessageContainer = (item) => {
    const { user } = this.props;
    if (user._id == item.message.author) {
      return (
        <div
          style={{
            display: "flex",
            alignSelf: "flex-end",
            maxWidth: 500,
            minHeight: 55,
            width: "40rem",
            boxSizing: "border-box",
            margin: 5,
            borderRadius: 10,
            boxShadow: "none",
            alignItems: "center",
            backgroundColor: "gainsboro",
          }}
        >
          <p style={{ marginLeft: 10, fontSize: 15 }}>
            {item.message.description}
          </p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
            maxWidth: 500,
            minHeight: 55,
            width: "40rem",
            borderRadius: 10,
            boxSizing: "border-box",
            margin: 5,
            boxShadow: "none",
            alignItems: "center",
            backgroundColor: "#ffa48c",
          }}
        >
          <p style={{ marginLeft: 10, fontSize: 15 }}>
            {item.message.description}
          </p>
        </div>
      );
    }
  };
  sendMessage = () => {
    const { input, channel, pubnub } = this.state;
    const { user } = this.props;
    const message = {
      title: input,
      description: input,
      author: user._id,
    };
    // Publish our message to the channel `chat`
    pubnub.publish({
      channel,
      message,
    });
    this.setState({ input: "", channel });
  };

  leftPanel = () => {
    const { isSmallScreen, isLeftPanelShown } = this.state;
    let chats = this.props.chatRooms.map((item) => (
      <button
        onClick={() => {
          this.handleMessageRoom(item);
          this.setState({ isLeftPanelShown: false });
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            style={{
              width: 30,
              height: 30,
              borderRadius: 100,
              marginRight: 20,
              marginLeft: 10,
              marginBottom: 10,
              marginTop: 10,
            }}
            src={item.chat.recipientItemImage}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 10,
              width: 100,
            }}
          >
            <p style={{ fontWeight: "bold" }}>{item.chat.senderItemName} </p>
            <p>
              with{" "}
              {this.props.user._id === item.chat.senderId
                ? item.chat.recipientName
                : item.chat.senderName}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", flex: 1 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {console.log("[time2]", item.chat.createdAt)}
          {moment
            .utc(item.chat.createdAt)
            .local()
            .startOf("seconds")
            .fromNow()}{" "}
          {/* <p>Right Arrow</p> */}
        </div>
      </button>
    ));

    if (isSmallScreen) {
      if (isLeftPanelShown) {
        return (
          <div
            className="left-chat-panel"
            style={
              isSmallScreen && isLeftPanelShown
                ? {
                    position: "absolute",
                    left: 10,
                    backgroundColor: "#FFF",
                    zIndex: 1,
                    width: "80%",
                  }
                : null
            }
          >
            {chats}
          </div>
        );
      }
    } else {
      return (
        <div
          className="left-chat-panel"
          style={
            isSmallScreen && isLeftPanelShown
              ? {
                  position: "absolute",
                  left: 10,
                  backgroundColor: "#FFF",
                  zIndex: 1,
                  width: "80%",
                }
              : null
          }
        >
          {chats.length !== 0 ? chats : <img src={EmptyChat} style={{position:'absolute', left:'35%', top:'10%', width:80, height:80}}/>}
        </div>
      );
    }
  };

  render() {
    const {
      showRoom,
      roomId,
      filteredRooms,
      assetModalIsOpen,
      roomIndex,
      isSmallScreen,
      isLeftPanelShown,
    } = this.state;
    let assetModalAction = assetModalIsOpen ? "reveal-modal" : "hide-modal";
    let briefcaseData =
      filteredRooms.length > 0 && filteredRooms[roomIndex].customData;
    return (
      <div>
        {isSmallScreen ? (
          <Button
            style={{
              backgroundColor: "#00000000",
              height: 40,
              position: "absolute",
              right: 0,
              top: -10,
            }}
            onClick={() =>
              this.setState({ isLeftPanelShown: !isLeftPanelShown })
            }
          >
            <IoIosPeople size={22} style={{ color: brandColor }} />
          </Button>
        ) : null}
        <div className="chatrooms-container">
          {this.leftPanel()}
          <div className="right-chat-panel" style={{ position: "relative" }}>
            <div className="recipient-name-container">
              <p>
                {this.state.chat
                  ? this.state.chat.recipientName
                  : "BazaarXchanges"}
              </p>
            </div>
            {this.state.messages !== null ? (
              // this.state.messages.map((item, index) => (
              <ChatRoomMessages
                chat={this.state.chat}
                messages={this.state.messages}
                messagesLength={this.state.messages.length}
                // index={index}
                // item={item}
                user={this.props.user}
                pubnub={this.state.pubnub}
                updateMessageList={this.updateMessageList}
                sendMessage={this.sendMessage}
              />
            ) : (
              // <div />
              // ))
              <div className="empty-chat" style={{display:"flex", justifyContent:'center', alignItems:'center',width:'100%',}}>
                <img src={EmptyChat2} alt="mobile logo" style={{position:'absolute', zIndex:-1, opacity:0.5, width:100}}/>
                <p>Select a conversation</p>
              </div>
            )}

            {/* {this.state.messages && (
            <div className="type-box">
              <input
                type="text"
                value={this.state.input}
                onChange={(event) =>
                  this.setState({ input: event.target.value })
                }
                placeholder="type messages..."
              />
              <button onClick={this.sendMessage}>Submit</button>
            </div>
          )} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userInfo,
    groups: state.chat.groups,
    pendingInvites: state.chat.invites,
    briefcase: state.chat.groupAssets,
    myAssets: state.asset.myAssets,
    chatRooms: state.chat.chatRooms,
  };
};

export default connect(mapStateToProps, actions)(ChatkitRooms);
