import React from 'react'

const Wrapper = props =>  (
  <div className="elem-wrapper">
    <div className="elem-wrapper__margin"></div>
    <div className="elem-wrapper__main">
      {props.children}
    </div>
    <div className="elem-wrapper__margin"></div>
  </div>
)

export default Wrapper;