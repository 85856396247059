import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Layout from "../Layout/Layout";
import { PopCardShuffle } from "../Cards/PopCardSort";
import ScrollToTopButton from "../Scroll/ScrollToTopButton";
import Wrapper from "../Layout/Wrapper";
import AssetFilter from "../AssetFilter/AssetFilter";
import AssetLoader from "../Loader/AssetLoader";
import _ from 'lodash';

class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      category: 'all',
      order: 'new',
      isSwapActive: false,
      isSellActive: false,
      isLeaseActive: false,
      isEnquireActive: false,
      minPrice: 0,
      maxPrice: 10000000000
    };
  }

  getAssets = async () => {
    await this.props.getElasticResults();
    const results = await this.props.elasticResults;
    await this.setState({ 
      results,
      filtered: results,
      dataLoaded: true
     });
  }

  filterPref = (x, type) => {
    let { isSellActive, isLeaseActive, isSwapActive, isEnquireActive } = this.state;

    if (type === 'sell') {
      return isSellActive ? x._source.sell : x;
    }

    if (type === 'swap' ) {
      return isSwapActive ? x._source.swap : x;
    }
    
    if (type === 'lease' ) {
      return isLeaseActive ? x._source.lease : x;
    }

    if (type === 'enquire' ) {
      return isEnquireActive ? x._source.price === 'Enquire' : x;
    }
  }
  

  filterData = async () => {
    let { category, results, order, minPrice, maxPrice } = this.state;
    let newResults = [...results];

    // Lodash Chain
    let filteredResults = _
      .chain(newResults)
      .filter(x => {
        return category === 'all' ?
        x : x._source.category === category
      })
      .filter(x => this.filterPref(x, 'sell'))
      .filter(x => this.filterPref(x, 'swap'))
      .filter(x => this.filterPref(x, 'lease'))
      .filter(x => this.filterPref(x, 'enquire'))
      .filter(x => this.filterPrice(x, minPrice, 'min'))
      .filter(x => this.filterPrice(x, maxPrice, 'max'))
      .sort((a,b) => {
        return order === 'new'  ?
        new Date(b._source.createdAt) - new Date(a._source.createdAt) :
        new Date(a._source.createdAt) - new Date(b._source.createdAt);
      });

    

    await this.setState({
      filtered: filteredResults.value()
    });
  }

  setMinPrice = async value => {
    await this.setState({ minPrice: value });
    this.filterData();
  };
  
  setMaxPrice = async value => {
    await this.setState({ maxPrice: value });
    this.filterData();
  };

  filterPrice = (x, limit, minMax ) => {
    if ( minMax === 'min') {
      return parseInt(x._source.price) > limit || x._source.price === 'Enquire';
    }
    if ( minMax === 'max') {
      return parseInt(x._source.price) < limit || x._source.price === 'Enquire';
    }
  }

  setCategory = async category => {
    await this.setState({ category });
    await this.filterData();
  }

  setOrder = async order => {
    await this.setState({ order });
    await this.filterData();
  }

  setSwap = async () => {
    await this.setState({ isSwapActive: !this.state.isSwapActive });
    await this.filterData();
  }

  setSale = async () => {
    await this.setState({ isSellActive: !this.state.isSellActive });
    await this.filterData();
  }

  setLease = async () => {
    await this.setState({ isLeaseActive: !this.state.isLeaseActive });
    await this.filterData();
  }

  setEnquire = async () => {
    await this.setState({ isEnquireActive: !this.state.isEnquireActive });
    await this.filterData();
  }

  async componentDidMount() {
    await this.getAssets(); 
    await this.filterData();
  }

  render() {
    let { results, dataLoaded, isCategoryActive, category } = this.state;

    if ( dataLoaded ) {
      return (
        <Layout>
          <ScrollToTopButton />
          <AssetFilter 
            setMinPrice={this.setMinPrice}
            setMaxPrice={this.setMaxPrice}
            setEnquire={this.setEnquire}
            setSwap={this.setSwap}
            setSale={this.setSale}
            setLease={this.setLease}
            setOrder={this.setOrder}
            setCategory={this.setCategory} 
            isCategoryActive={isCategoryActive}
            category={category}
          />
          <Wrapper>
            <PopCardShuffle results={this.state.filtered} />
          </Wrapper>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <Wrapper>
            <AssetLoader dataLoaded={dataLoaded} />
          </Wrapper>
        </Layout>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    assets: state.asset.assets,
    elasticResults: state.asset.elasticResults
  };
};

export default connect(
  mapStateToProps,
  actions
)(Assets);
