import React from "react";
import { Field } from "redux-form";

const required = value => value ? undefined : 'Required'

const renderTextField = ({ input, label, placeholder, type, meta: { touched, error } }) => (
  <input
    {...input}
    className={
      touched && error ? "form__input form__input--error" : "form__input"
    }
    placeholder={placeholder || label}
    type={type || "text"}
  /> 
);

const FormInputArt = values => {
  const { name, label, type } = values;

  return (
    <div className="form__group">
      <Field
        name={name}
        component={renderTextField}
        label={label}
        type={type} 
      />
    </div>
  );
};

export default FormInputArt;
