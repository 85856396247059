import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions";
import Assets from "../Assets/Assets";
import Landing from "../Landing/Landing";
import Login from "../Login/Login";
import ForgotPassword from "../Password/ForgotPassword";
import ResetPassword from "../Password/ResetPassword";
import Register from "../Register/Register";
import ChatkitInvites from "../Chatkit/ChatkitInvites";
import Chatroom from "../Chat/Chatroom";
import AccountSettingsAssets from "../AccountSettings/AccountSettingsRoutes/AccountSettingsAssets";
import AccountSettingsAssetsItemEdit from "../AccountSettings/AccountSettingsAssetsItem/AccountSettingsAssetsItemEdit";
import AccountSettingsMessages from "../AccountSettings/AccountSettingsRoutes/AccountSettingsMessages";
import AccountSettingsPassword from "../AccountSettings/AccountSettingsRoutes/AccountSettingsPassword";
import AccountSettingsUser from "../AccountSettings/AccountSettingsRoutes/AccountSettingsUser";
import AccountSettingsValidate from "../AccountSettings/AccountSettingsRoutes/AccountSettingsValidate";
import ShowAsset from "../ShowAsset/ShowAsset";
import SearchAssets from "../Search/SearchAssets";
import AddAsset from "../AddAsset/AddAsset";
//import ChatUI from "../Chat/ChatUI";
import ValidateAccount from "../ValidateAccount/ValidateAccount";
import Home from "../Landing/Home";
import ScrollToTop from "../Scroll/ScrollToTop";
import SingleAsset from "../ShowAsset/SingleAsset";
import UserProfile from "../Profile/UserProfile";
import Events from "../Events/Events";
import UploadImage from "../AddAsset/UploadImage";
import ChooseAsset from "../Menu/ChooseAsset";
import PrivacyPolicy from "../Terms/Privacy";
import TermsOfService from '../Terms/TermsOfService'
import Chatkit from "../Chatkit/Chatkit";
import ShoppingCart from "../Cart/ShoppingCart";
import Payment from "../Payment/Payment";
import StripeComponent from "../Cart/StripeComponent";
import ArtistDirectory from "../Artist/ArtistDirectory";
import SingleArtist from "../Artist/SingleArtist";
import ArtistHighlight from "../Artist/ArtistHighlight";
import Homepage from "../Layout/Homepage";
import ContactUs from "../Email/ContactUs";
import Marketing from "../Layout/Marketing";
import Upload from "../Upload/Upload";
import DropAndCrop from "../AddAsset/DropAndCrop";
import Explore from "../Layout/Explore";
import ListingsByCity from "../Layout/ListingsByCity";
import SingleListing from "../Layout/SingleListing";
import SearchListings from "../Layout/SearchListings";
import EditListing from "../Layout/EditListing";
import Room from "../Chatkit/Room";

const PrivateRoute = ({ props: { userInfo, saveRedirectPath }, ...rest }) => {
  const user = !!userInfo;
  if (!user) {
    saveRedirectPath(rest.location.pathname);
    return <Redirect to="/login" />;
  } else {
    return <Route {...rest} />;
  }
};

class App extends Component {
  componentDidMount() {
    this.props.checkUserStatus();
  }

  render() {
    if (!this.props.checkLoggedInApi) {
      return null;
    }

    return (
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            {/* <Route exact path="/home" component={Homepage} /> */}
            <Route exact path="/upload" component={Upload} />
            <Route exact path="/search" component={SearchListings} />
            {/* <Route exact path="/upload/:id" component={UploadForm} /> */}
            <Route exact path="/register" component={Register} />
            <Route exact path="/assets" component={Assets} props={this.props} />
            <Route
              exact
              path="/user"
              component={UserProfile}
              props={this.props}
            />
            <Route exact path="/events" component={Events} props={this.props} />
            <Route
              exact
              path="/explore/:category"
              component={Explore}
              props={this.props}
            />
            <Route
              exact
              path="/city/:city"
              component={ListingsByCity}
              props={this.props}
            />
            <Route
              exact
              path="/listings/:id"
              component={SingleListing}
              props={this.props}
            />
            <Route
              exact
              path="/edit/:id"
              component={EditListing}
              props={this.props}
            />
            <Route
              exact
              path="/upload"
              component={UploadImage}
              props={this.props}
            />
            <Route
              exact
              path="/newForm"
              component={ChooseAsset}
              props={this.props}
            />
            <Route
              exact
              path="/terms"
              component={TermsOfService}
              props={this.props}
            />
            <Route
              exact
              path="/privacy"
              component={PrivacyPolicy}
              props={this.props}
            />
            <Route
              exact
              path="/contact"
              component={ContactUs}
              props={this.props}
            />
            <Route
              exact
              path="/messages"
              component={Chatkit}
              props={this.props}
            />
            <Route
              exact
              path="/room"
              component={Room}
              props={this.props}
            />
            <Route
              exact
              path="/search/assets"
              component={SearchAssets}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/assets/:id"
              component={SingleAsset}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/cart"
              component={ShoppingCart}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/payment"
              component={Payment}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/stripe/confirm"
              component={StripeComponent}
              props={this.props}
            />
            <Route
              exact
              path="/assets/:category/:assetId"
              component={SingleAsset}
              props={this.props}
            />
            <Route
              exact
              path="/directory/artists"
              component={ArtistDirectory}
              props={this.props}
            />
            <Route
              exact
              path="/directory/artists/:id"
              component={SingleArtist}
              props={this.props}
            />
            <Route
              exact
              path="/directory/artists/:id/highlight"
              component={ArtistHighlight}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/accountsettings/usersettings"
              component={AccountSettingsUser}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/accountsettings/changepassword"
              component={AccountSettingsPassword}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/accountsettings/messages"
              component={AccountSettingsMessages}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/accountsettings/validateaccount"
              component={AccountSettingsValidate}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/accountsettings/userassets/:id"
              component={AccountSettingsAssetsItemEdit}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/accountsettings/userassets"
              component={AccountSettingsAssets}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/asset/addasset"
              component={AddAsset}
              props={this.props}
            />
            <Route
              exact
              path="/dragndrop"
              component={DropAndCrop}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/asset/showasset/:id"
              component={ShowAsset}
              props={this.props}
            />
            <PrivateRoute
              exact
              path="/user/validate"
              component={ValidateAccount}
              props={this.props}
            />
            {/* <Route component={Landing} /> */}
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    checkLoggedInApi: state.auth.checkLoggedInApi
  };
};

export default connect(mapStateToProps, actions)(App);
