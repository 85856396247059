import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import AssetFormPropertyDetails from "./AssetFormPropertyDetails";
import AssetFormBasics from "../AssetFormComponents/AssetFormBasics";
import AssetFormAddress from "../AssetFormComponents/AssetFormAddress";
import AssetFormPrice from "../AssetFormComponents/AssetFormPrice";
import AssetFormHeader from "../AssetFormComponents/AssetFormHeader";
import AssetFormActions from "../AssetFormComponents/AssetFormActions";
import AssetFormSwap from "../AssetFormComponents/AssetFormSwap";
import AssetFormLease from "../AssetFormComponents/AssetFormLease";
import AssetFileUpload from "../AssetUpload/AssetFileUpload";
import AssetVideoUpload from "../AssetUpload/AssetVideoUpload";
import AssetPdfUpload from "../AssetUpload/AssetPdfUpload";

class AssetFormProperty extends Component {

  render() {
    const {
      handleSubmit,
      handleActions,
      handleDetailChange,
      updateFiles,
      editAddress,
      onFormSubmit,
      formFields,
      formFields: { photos, videos, swap, lease, swapDetails, leaseDetails, pdfs }
    } = this.props;

    return (
      <AssetFormHeader {...this.props}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <AssetFormActions handleActions={handleActions} {...formFields} />

          {swap && (
            <AssetFormSwap
              swapDetails={swapDetails}
              handleDetailChange={handleDetailChange}
            />
          )}
          <AssetFileUpload
            name="photos"
            photos={photos}
            videos={videos}
            updateFiles={updateFiles}
          />
          <AssetPdfUpload
            name="pdfs"
            pdfs={pdfs}
            updateFiles={updateFiles}
          />
          <AssetFormAddress editAddress={editAddress} />
          <AssetFormBasics />
          <AssetFormPrice />
          <AssetFormPropertyDetails />

          
          <div className="add-asset__template__btn">
            <button className="form__button">Add Asset</button>
          </div>
        </form>
      </AssetFormHeader>
    );
  }
}

function validate(values) {
  const errors = {};
  const categories = [
    "title",
    "subCategory",
    "price",
    "yearCreated",
    "propertyType",
    "bedrooms",
    "bathrooms",
    "floors",
    "livingArea",
    "landArea",
    "photos"
  ];

  categories.forEach(category => {
    if (!values[category]) {
      errors[category] = `Missing value`;
    }
  });
  return errors;
}

export default reduxForm({
  validate,
  form: "assetForm",
  destroyOnUnmount: false
})(
  connect(
    null,
    actions
  )(AssetFormProperty)
);
